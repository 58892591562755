import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert } from 'src/app/model/Alert';
import { RoleNames } from 'src/app/model/Role';
import { ReviewNoteFilterBy, ReviewNoteStatus, WhoCanResolveReviewNote } from 'src/app/model/Workspace/ReviewNote';
import { WorkspaceDocumentViewModel } from 'src/app/model/WorkspaceDocumentModel';
import { WorkspaceModel } from 'src/app/model/WorkspaceModel';
import { ImageModel, ReviewNoteEngagementDetailModel, ReviewNoteMentionedUser, ReviewNoteSearchableUser, ReviewPointsWithCommentsModel, WorkspaceReviewPoint, WorkspaceReviewPointComment, WorkspaceReviewPointModel } from 'src/app/model/WorkspaceReviewPoint';
import { AlertsService } from 'src/app/services/alerts.service';
import { EngagementService } from 'src/app/services/EngagementService';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { WorkspaceReviewPointService } from 'src/app/services/workspaceReviewPointService';
import { DOCUMENT } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
declare var $;
export type ResizeAnchorType =
  | 'top'
  | 'left'
  | 'bottom'
  | 'right'

export type ResizeDirectionType =
  | 'x'
  | 'y'
  | 'xy';

@Component({
  selector: 'app-review-notes',
  templateUrl: './review-notes.component.html',
  styleUrls: ['./review-notes.component.css']
})
export class ReviewNotesComponent implements OnInit, OnDestroy {

  whoCanResolveReviewNote = WhoCanResolveReviewNote;
  @ViewChild('reviewNoteDiv', { static: false }) reviewNoteDiv: ElementRef;
  @ViewChild('reviewNoteCommentDiv', { static: false }) reviewNoteCommentDiv: ElementRef;
  reviewNoteMentionedUser: ReviewNoteMentionedUser[] = [];
  _reviewNoteSearchableUsers: ReviewNoteSearchableUser[] = [];
  childSearchableUsers: ReviewNoteSearchableUser[] = [];
  isLoggedinUserCreatedby: boolean;
  engagementId: number;
  reviewPointId: number;
  isINDUSStaffingManagerLoggedIn: boolean = false;
  isQualityUserLoggedIn: boolean = false;
  isLoggedInUserSystemAdmin: boolean = false;
  subscribtionList: Subscription[] = [];
  documentName: string = '';

  @Input() isPureNotificationOnlyProfessional: boolean = false;
  @Input() isUserTM: boolean = false;
  @Input() isUserRP: boolean = false;
  @Input() isUserEP: boolean = false;

  @Input() set reviewId(id: number) {
    if (id !== undefined && id !== null && id != 0) {
      this.engagementId = id;
      this.getReviewNoteEngagementData();
      this.getWorkspaceData();

    }
  }
  @Input() set reviewNoteSearchableUsers(searchableUsers: ReviewNoteSearchableUser[]) {
    if (searchableUsers !== undefined && searchableUsers !== null && searchableUsers.length > 0) {
      this.childSearchableUsers = searchableUsers;
      this.ConfigureReviewNoteSearch();
    }
  }
  @Input() set selectedWorkspaceDocumentId(id: string) {
    if (id != undefined && id != null && id != '') {
      this.documentName = id;
    }
    else {
      this.documentName = this.selectedWorkspaceDocumentItem.documentAliasName;

    }
  }
  reviewNoteSearchableUsersFromQualityGroups: ReviewNoteSearchableUser[] = [];
  NoReviewNotesMsg = 'No review notes';
  maxCharactersAllowedForReviewNoteDescription: number = 500;
  displayReviewNoteFlag: boolean = false;

  displayReviewNoteValidations: boolean = false;
  displayReviewNoteReplyValidations: boolean = false;
  isValidReviewNoteReplyMessage: boolean = false;
  isValidReviewNoteSubject: boolean = false;
  isValidReviewNoteMessage: boolean = false;
  isValidReviewNoteWhoResolve: boolean = false;
  isAddReviewNoteFormOpen: boolean = false;
  warningCharacterCount: number = 475;
  workspaceReviewPointsCount: number;
  workspaceOpenReviewPointsCount: number;

  workspaceReviewPointsForInsert: WorkspaceReviewPoint[] = [];
  currentworkspaceReviewPoints: WorkspaceReviewPoint = new WorkspaceReviewPoint();
  currentworkspaceReviewPointComment: WorkspaceReviewPointComment = new WorkspaceReviewPointComment();
  workspaceReviewPointCommentForInsert: WorkspaceReviewPointComment[] = [];
  workspaceReviewPointComments: WorkspaceReviewPointComment[] = [];
  imageModel: ImageModel = new ImageModel();
  workspaceReviewPointModel: WorkspaceReviewPointModel = new WorkspaceReviewPointModel();
  ReviewPointModelImages: ImageModel[] = [];
  public workspaceReviewPointModelList: WorkspaceReviewPointModel[] = [];
  public workspaceReviewPointModelListOriginal: WorkspaceReviewPointModel[] = [];
  userInfo: any;
  reviewPointsWithCommentsModel: ReviewPointsWithCommentsModel = new ReviewPointsWithCommentsModel();
  workspaceDetails: WorkspaceModel = new WorkspaceModel();
  alert: Alert;

  @Output() closeReviewNotesModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  isLoggedInUserHasWorkspaceAccess: boolean = false;

  workspaceDocumentList: WorkspaceDocumentViewModel[] = [];
  selectedWorkspaceDocumentItem = new WorkspaceDocumentViewModel();
  workspaceDocumentCount: number = 0;
  secondaryWorkspaceDataList: WorkspaceModel[] = [];
  allWorkspaceDataList: WorkspaceModel[] = [];
  CurrentWorkspaceData: WorkspaceModel = new WorkspaceModel();
  reviewNoteEngagementData: ReviewNoteEngagementDetailModel = new ReviewNoteEngagementDetailModel();
  isContinuanceEngagement: boolean = false;

  @ViewChild('resizeDiv') resizeDivRef!: ElementRef;

  position: { x: number } = { x: 50 };

  size: { w: number } = { w: 360 };

  lastPosition: { x: number };

  lastSize: { w: number };

  minSize: { w: number } = { w: 360 };

  maxSize: { w: number } = { w: 650 };

  subCloseReviewNotes: Subscription;
  isReviewNotesClose: boolean;
  
  htmlCode: string;
  htmlDoc: HTMLElement;
  constructor(private workspaceReviewPointService: WorkspaceReviewPointService,
    private workspaceService: WorkspaceService,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertsService, private engService: EngagementService,
    @Inject(DOCUMENT) private _document: Document,
    private _el: ElementRef,
    private dataService: DataService) {
    let jsonString = localStorage.getItem("currentUser");
    this.userInfo = JSON.parse(jsonString);
    this.setUserRole();
  }
  ngOnDestroy(): void {
    console.log('ondestroy called');
    this.subscribtionList.forEach(x => x.unsubscribe());
    this.CancelReviewPoints();
    this.showHideAddReviewNote('hide');
    this.subCloseReviewNotes.unsubscribe();
  }

  ngOnInit(): void {
    console.log('onint called');
    if (this.isUserEP || this.isUserRP || this.isUserTM) {
      this.isLoggedInUserHasWorkspaceAccess = true;
    }

    this.subCloseReviewNotes =
      this.dataService.isCloseReviewNotes$.subscribe(value => {
        this.isReviewNotesClose = value
        if (this.isReviewNotesClose)
          this.closeReviewNotes();
      })
  }

  showHideAddReviewNote(text: string) {
    this.displayReviewNoteValidations = false;
    this.displayReviewNoteReplyValidations = false;
    this.currentworkspaceReviewPoints.whoCanResolve = null;
    $('#reviewNoteRemainingCharacterLabel').text(this.maxCharactersAllowedForReviewNoteDescription + ' characters');
    $('#reviewNoteRemainingCharacterLabel').removeClass('warning-review-note-label');
    if (text === 'show') {
      this.isAddReviewNoteFormOpen = true;
      $('#divAddReviewNotesSection').removeClass('display-none');
      $('#divAddReviewNotesSection').show();
      $('#divReviewNotesListSection').addClass('custom-review-note-scroll');

    } else if (text === 'hide') {
      this.isAddReviewNoteFormOpen = false;
      this.currentworkspaceReviewPoints.subject = "";
      this.currentworkspaceReviewPoints.description = "";
      this.reviewNoteDiv.nativeElement.innerText = "";
      this.currentworkspaceReviewPoints.createdBy = "";
      this.currentworkspaceReviewPoints.lastModifiedBy = "";
      $('#divAddReviewNotesSection').hide();
      $('#divReviewNotesListSection').removeClass('custom-review-note-scroll');
    }
  }

  showHideReviewNotesContent(reviewPointId: number) {
    var currentReviewPointModel = this.workspaceReviewPointModelList.find(e => e.workspaceReviewPoint.reviewPointId === reviewPointId)

    if ((currentReviewPointModel.imageModelList[0].isVisible)) {
      currentReviewPointModel.imageModelList[0].isVisible = false;
      currentReviewPointModel.imageModelList[1].isVisible = true;
    } else {
      currentReviewPointModel.imageModelList[0].isVisible = true;
      currentReviewPointModel.imageModelList[1].isVisible = false;
    }
  }

  showHideReviewNotesComments(reviewPointId: number) {
    if (!$('#reviewPointCommentsSection_' + reviewPointId).is(':visible')) {
      $('#reviewPointCommentsSection_' + reviewPointId).show();
    } else {
      $('#reviewPointCommentsSection_' + reviewPointId).hide();
    }
    this.showHideReply(reviewPointId, 'hide');
  }

  showHideReply(reviewPointId: number, text: string, reviewNoteCommentDivId: string = null, maxCharacterReviewCommentLabel = null) {
    this.currentworkspaceReviewPointComment.comments = "";
    this.reviewNoteCommentDiv.nativeElement.innerText = "";
    this.workspaceReviewPointModelList.find(e => e.workspaceReviewPoint.reviewPointId === reviewPointId).replyComment = null;
    $('#' + maxCharacterReviewCommentLabel).text(this.maxCharactersAllowedForReviewNoteDescription + ' characters');
    $('#' + maxCharacterReviewCommentLabel).removeClass('warning-review-note-label');

    $('#reviewNoteCommentVaidationMessage' + reviewPointId).addClass('review-Note-Reply-Hide-Error-Message');
    if (reviewNoteCommentDivId) {
      $('#' + reviewNoteCommentDivId).html(null);
    }

    $("#txtNewComment_" + reviewPointId).val("");
    if (text === 'show') {
      $('#divNewReviewComment_' + reviewPointId).show();
    } else if (text === 'hide') {
      $('#divNewReviewComment_' + reviewPointId).hide();
    }
  }
  IsValidReviewNote() {
    let isValid = false;

    this.currentworkspaceReviewPoints.description = this.reviewNoteDiv.nativeElement.innerText.trim();
    this.currentworkspaceReviewPoints.description = this.currentworkspaceReviewPoints.description.replace(/\n/g, '<br/>');

    this.currentworkspaceReviewPoints.membersMentionedInReviewNote = this.GetMembersMentionedInReviewNote(this.reviewNoteDiv.nativeElement.innerHTML);

    this.isValidReviewNoteSubject = this.currentworkspaceReviewPoints.subject && this.currentworkspaceReviewPoints.subject.trim() != '';
    this.isValidReviewNoteMessage = this.currentworkspaceReviewPoints.description && this.currentworkspaceReviewPoints.description.trim() != '';
    this.isValidReviewNoteWhoResolve = this.currentworkspaceReviewPoints.whoCanResolve && this.currentworkspaceReviewPoints.whoCanResolve != '';
    this.currentworkspaceReviewPoints.createdBy = this.userInfo.userName;

    isValid = (this.isValidReviewNoteSubject && this.isValidReviewNoteMessage && this.isValidReviewNoteWhoResolve)

    this.displayReviewNoteValidations = !isValid;

    return isValid;
  }
  ConfigureReviewNoteSearch() {
    this._reviewNoteSearchableUsers = [];
    setTimeout(() => {
      if (this.childSearchableUsers && this.childSearchableUsers.length > 0) {
        this.childSearchableUsers.forEach(user => {
          this._reviewNoteSearchableUsers.push({ key: user.key, value: user.value, email: user.email });
        })
      }
      if (this._reviewNoteSearchableUsers.filter(item => item.email == "Team").length == 0) {
        this._reviewNoteSearchableUsers.push({ key: "Team", value: "Team", email: "Team" });
      }
      this.reviewNoteConfigurations = {
        items: this._reviewNoteSearchableUsers,
        labelKey: 'value',
        mentionSelect: (item: any) => { return `@${item.value}` },
        mentionFilter: (query: string, items: ReviewNoteSearchableUser[]) => {
          return items.filter(user =>
            user.value.toLowerCase().includes(query.toLowerCase())
          );
        }
      }
    }, 600);
  }

  itemSelected(event: any, id: string) {
    setTimeout(() => {
      this.htmlDoc = document.getElementById(id);
      this.htmlDoc.innerHTML = this.htmlDoc.innerHTML.replace(
        '@' + event.value,
        ' <span id="' +
          event.value +
          '" class="inner-textarea-link" selected-user-email="'+event.email+'" contenteditable=false>@' +
          event.value +
          '</span>&nbsp;'
      );      
      this.selectEnd();
    }, 10);
  }

  selectEnd() {
    let range, selection;
    range = document.createRange();
    range.selectNodeContents(this.htmlDoc);
    range.collapse(false);
    selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  sortSearchableReviewNoteUsers() {
    this._reviewNoteSearchableUsers.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  //Action to handler after a user mention in review note
  onMentioned(mentionedEvent: string, editableDivId, label) {
    var editableDivElement = $('#' + editableDivId);
    if (editableDivElement.text().length > this.maxCharactersAllowedForReviewNoteDescription) {
      var htmlContent = editableDivElement.html().toString();
      var currentUserLastIndex = htmlContent.lastIndexOf('<span');
      editableDivElement.html(htmlContent.substring(0, currentUserLastIndex - 1));
      setTimeout(() => {
        this.setEndOfContenteditable(editableDivId);
      }, 100);
      console.info(mentionedEvent);
    }
    else {
      let divContentLenght = editableDivElement.text().length;
      let remainingChars = this.maxCharactersAllowedForReviewNoteDescription - divContentLenght;
      $('#' + label).text(remainingChars + ' characters remaining');
      if (divContentLenght >= this.warningCharacterCount) {
        $('#' + label).addClass('warning-review-note-label');
      }
      else {
        $('#' + label).removeClass('warning-review-note-label');
      }
    }
  }
  setEndOfContenteditable(contentEditableElementId) {
    let range, selection;
    let contentEditableElement = document.getElementById(contentEditableElementId);
    if (document.createRange) {
      range = document.createRange();
      range.selectNodeContents(contentEditableElement);
      range.collapse(false);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  GetMembersMentionedInReviewNote(commentHtmlString: string): ReviewNoteMentionedUser[] {
    this.reviewNoteMentionedUser = [];
    let reviewNoteHtmlString = '';
    let searchableUsers: ReviewNoteSearchableUser[] = [];
    let mentionedUser: ReviewNoteMentionedUser = new ReviewNoteMentionedUser();
    reviewNoteHtmlString = commentHtmlString;

    //Check total user exists
    var userCount = (reviewNoteHtmlString.match(/selected-user-email/g) || []).length;

    if (userCount > 0) {

      var selectedUserEmail = '';

      //Retrieve each user email
      for (var userIndex = reviewNoteHtmlString.indexOf('selected-user-email'); userIndex >= 0 && userCount > 0; userIndex = reviewNoteHtmlString.indexOf('selected-user-email', userIndex + 1), --userCount) {

        selectedUserEmail = '';
        for (var i = userIndex + 'selected-user-email'.length + 2; i <= reviewNoteHtmlString.length; i++) {
          if (reviewNoteHtmlString[i] == "\"") {
            break;
          }
          selectedUserEmail += reviewNoteHtmlString[i];
        }
        searchableUsers = this._reviewNoteSearchableUsers.filter(user => user.email == selectedUserEmail);
        if (selectedUserEmail != ''
          && searchableUsers.length > 0
          && this.reviewNoteMentionedUser.filter(user => user.userEmailId == selectedUserEmail).length == 0) {
          this.reviewNoteMentionedUser.push({ ...mentionedUser, userName: searchableUsers[0].value, userEmailId: searchableUsers[0].email });
        }
      }
    }

    return this.reviewNoteMentionedUser;
  }

  reviewNoteConfigurations = {
    items: this._reviewNoteSearchableUsers,
    labelKey: 'value',
    mentionSelect: (item: any) => `@${item.value}`,
    mentionFilter: (query: string, items: ReviewNoteSearchableUser[]) => {      
      return items.filter(user =>
        user.value.toLowerCase().includes(query.toLowerCase())
      );
    }
  }

  buildWorkspaceReviewPointModelList() {
    this.workspaceReviewPointModelList = [];
    this.workspaceReviewPointModelListOriginal = [];
    this.ReviewPointModelImages = [];
    this.workspaceOpenReviewPointsCount = 0;

    this.imageModel = {
      imageName: 'DEFAULT',
      imageValue: 'ASC',
      imagePath: '../../../assets/images/EDP/Workspace/collapse_Review_note.svg',
      isVisible: false
    };
    this.ReviewPointModelImages.push(JSON.parse(JSON.stringify(this.imageModel)));

    this.imageModel = {
      imageName: 'Asending',
      imageValue: 'ASC',
      imagePath: '../../../assets/images/EDP/Workspace/expand_Review_note.svg',
      isVisible: true
    };
    this.ReviewPointModelImages.push(JSON.parse(JSON.stringify(this.imageModel)));

    this.reviewPointsWithCommentsModel.reviewPointList.forEach((ReviewPoint, i) => {

      let reviewPointComments = this.reviewPointsWithCommentsModel.reviewPointCommentsList.filter(comment => comment.reviewPointId === ReviewPoint.reviewPointId);
      let reviewPointCommentsCount: number = reviewPointComments.length;
      if (ReviewPoint.status == 1) {
        this.workspaceOpenReviewPointsCount = this.workspaceOpenReviewPointsCount + 1;
      }

      this.workspaceReviewPointModel = {
        workspaceReviewPoint: ReviewPoint,
        workspaceReviewPointCommentCount: reviewPointCommentsCount,
        imageModelList: this.ReviewPointModelImages,
        replyFlag: reviewPointCommentsCount > 0 ? true : false,
        replyComment: null,
        isLoggedinUserCreatedReviewPoint: this.checkCurrentLoggedinUserisCreatedBy(ReviewPoint.createdBy),
        workspaceReviewPointComments: reviewPointComments
      };
      this.workspaceReviewPointModelList.push(JSON.parse(JSON.stringify(this.workspaceReviewPointModel)));

    });
    this.sortWorkspaceReviewPointModelList();
    this.workspaceReviewPointModelListOriginal = this.workspaceReviewPointModelList;
    console.log('workspaceReviewPointModelList ==>', this.workspaceReviewPointModelList);
    this.filterReviewPoint();
  }
  sortWorkspaceReviewPointModelList() {
    this.workspaceReviewPointModelList.forEach(reviews => {
      if (reviews.workspaceReviewPointCommentCount > 0) {
        reviews.workspaceReviewPointComments.sort((a, b) => {
          const reply1 = new Date(a.createdOn);
          const reply2 = new Date(b.createdOn);
          return (reply1.getTime() > reply2.getTime()? 1 : -1);
        }

        )
      }
    })

  }

  getWorkspaceReviewPoints(isReviewNotesComponent: boolean) {
    return this.workspaceReviewPointService.getReviewPointsWithComments(this.selectedWorkspaceDocumentItem.workspaceId, isReviewNotesComponent)
      .subscribe(
        result => {
          this.reviewPointsWithCommentsModel = result;
          this.workspaceReviewPointsCount = this.reviewPointsWithCommentsModel.reviewPointList.length;
          this.workspaceOpenReviewPointsCount = this.reviewPointsWithCommentsModel.reviewPointList.filter(x => x.status == 1).length;
          if (!isReviewNotesComponent) {
            this.workspaceReviewPointService.setReviewNotesCount(this.workspaceOpenReviewPointsCount);
          }
          if (this.workspaceReviewPointsCount > 0 && isReviewNotesComponent) {
            this.buildWorkspaceReviewPointModelList();
          }

          this.loadingScreenService.stopLoading();
        },
        error => {
          this.loadingScreenService.stopLoading();
          this.reviewPointsWithCommentsModel = null;
          this.DisplaySystemAlert();
        });
  }

  InsertReviewPoints() {
    if (this.IsValidReviewNote()) {
      this.currentworkspaceReviewPoints.workspaceId = this.selectedWorkspaceDocumentItem.workspaceId;
      this.loadingScreenService.startLoading();
      this.subscribtionList.push(
        this.workspaceReviewPointService
          .InsertReviewPoints(this.currentworkspaceReviewPoints)
          .subscribe(() => {
            this.showHideAddReviewNote('hide');
            this.getWorkspaceReviewPoints(true);
            this.loadingScreenService.stopLoading();
          },
            err => {
              this.DisplaySystemAlert();
            })
      );
    }
  }

  InsertReviewPoints_new() {
    this.currentworkspaceReviewPoints.workspaceId = this.selectedWorkspaceDocumentItem.workspaceId;
    this.loadingScreenService.startLoading();
    this.subscribtionList.push(
      this.workspaceReviewPointService
        .InsertReviewPoints(this.currentworkspaceReviewPoints)
        .subscribe(
          result => {
            this.currentworkspaceReviewPoints = result;
            this.reviewPointsWithCommentsModel.reviewPointList.push(this.currentworkspaceReviewPoints);
            this.workspaceReviewPointsCount = this.reviewPointsWithCommentsModel.reviewPointList.length;
            if (this.workspaceReviewPointsCount > 0) {
              this.buildWorkspaceReviewPointModelList();
            }
            this.showHideAddReviewNote('hide');
            this.loadingScreenService.stopLoading();
          },
          error => {
            this.currentworkspaceReviewPoints = null;
            this.DisplaySystemAlert();
          })
    );
  }
  CancelReviewPoints() {
    this.currentworkspaceReviewPoints.subject = "";
    this.currentworkspaceReviewPoints.description = "";
    this.currentworkspaceReviewPoints.createdBy = "";
    this.currentworkspaceReviewPoints.lastModifiedBy = "";
    $("#modal-addReviewPoints").modal("hide");
  }
  GetReviewPointDetails(reviewPointId: number) {
    $("#modal-ViewReviewPoints").modal("show");
    this.currentworkspaceReviewPointComment.comments = null;
    return this.workspaceReviewPointService
      .getWorkspaceReviewPointById(reviewPointId)
      .subscribe(
        result => {
          this.currentworkspaceReviewPoints = result;
          setTimeout(() => {
            this.checkCurrentLoggedinUserisCreatedBy(this.currentworkspaceReviewPoints.createdBy);
            this.GetReviewPointComments(reviewPointId);
          }, 50);
        },
        () => {
          this.currentworkspaceReviewPoints = null;
        }
      );
  }

  InsertReviewPointComments(reviewPointId: number, reviewNoteCommentDivId: string, maxCharacterReviewCommentLabel) {
    this.currentworkspaceReviewPointComment.comments = $('#' + reviewNoteCommentDivId).text();
    this.isValidReviewNoteReplyMessage = this.currentworkspaceReviewPointComment.comments && this.currentworkspaceReviewPointComment.comments.trim() != '';
    $('#reviewNoteCommentVaidationMessage' + reviewPointId).addClass('review-Note-Reply-Hide-Error-Message');
    if (this.isValidReviewNoteReplyMessage) {
      this.currentworkspaceReviewPointComment.comments = this.currentworkspaceReviewPointComment.comments.replace(/\n/g, '<br/>');
      this.currentworkspaceReviewPointComment.membersMentionedInReviewNote = this.GetMembersMentionedInReviewNote($('#' + reviewNoteCommentDivId).html());
      this.currentworkspaceReviewPointComment.reviewPointId = reviewPointId
      this.currentworkspaceReviewPointComment.workSpaceId = this.selectedWorkspaceDocumentItem.workspaceId;
      this.currentworkspaceReviewPointComment.subject = this.workspaceReviewPointModelList.find(e => e.workspaceReviewPoint.reviewPointId === reviewPointId).workspaceReviewPoint.subject;

      this.loadingScreenService.startLoading();
      this.workspaceReviewPointService
        .InsertReviewPointComments(this.currentworkspaceReviewPointComment)
        .subscribe(() => {
          $('#' + reviewNoteCommentDivId).html(null);
          this.GetReviewPointComments(reviewPointId);
          this.showHideReply(reviewPointId, 'hide');
          $('#' + maxCharacterReviewCommentLabel).text(this.maxCharactersAllowedForReviewNoteDescription + ' characters');
          $('#' + maxCharacterReviewCommentLabel).removeClass('warning-review-note-label');
          this.loadingScreenService.stopLoading();
        },
          error => {
            this.DisplaySystemAlert();
          });
    }
    else {
      this.displayReviewNoteReplyValidations = true;
      $('#reviewNoteCommentVaidationMessage' + reviewPointId).removeClass('review-Note-Reply-Hide-Error-Message');
    }
  }

  GetReviewPointCommentsByWorkspace(workspaceId) {
    return this.workspaceReviewPointService
      .getWorkspaceReviewPointCommentsByWorkspace(workspaceId)
      .subscribe(
        result => {
          this.workspaceReviewPointComments = result;
        },
        error => {
          this.workspaceReviewPointComments = null;
        }
      );
  }
  GetReviewPointComments(reviewPointId: number) {
    this.reviewPointId = reviewPointId;
    return this.workspaceReviewPointService
      .getWorkspaceReviewPointComments(reviewPointId)
      .subscribe(
        result => {
          this.workspaceReviewPointComments = result;

          if (this.workspaceReviewPointModelList.length > 0) {

            for (var i = 0; i < this.workspaceReviewPointModelList.length; i++) {

              if (this.workspaceReviewPointModelList[i].workspaceReviewPoint.reviewPointId === reviewPointId) {

                this.workspaceReviewPointModelList[i].workspaceReviewPointComments.splice(0, this.workspaceReviewPointModelList[i].workspaceReviewPointComments.length)
                this.workspaceReviewPointModelList[i].workspaceReviewPointComments = this.workspaceReviewPointComments;
                this.workspaceReviewPointModelList[i].workspaceReviewPointCommentCount = this.workspaceReviewPointComments.length;
                this.workspaceReviewPointModelList[i].workspaceReviewPoint.membersMentionedInReviewNote = this.workspaceReviewPointComments[0].membersMentionedInReviewNote;
                console.log('new added Comments', this.workspaceReviewPointModelList[i].workspaceReviewPointComments);
                break;
              }
            }
          }

        },
        error => {
          this.workspaceReviewPointComments = null;
          this.DisplaySystemAlert();
        }
      );
  }


  GetWorkspaceReviewPoint(reviewPointId: number) {
    this.reviewPointId = reviewPointId;
    return this.workspaceReviewPointService
      .getWorkspaceReviewPointById(reviewPointId)
      .subscribe(
        result => {
          this.currentworkspaceReviewPoints = result;
          this.reviewPointsWithCommentsModel.reviewPointList.push(this.currentworkspaceReviewPoints);
          this.workspaceReviewPointsCount = this.reviewPointsWithCommentsModel.reviewPointList.length;
          if (this.workspaceReviewPointsCount > 0) {
            this.buildWorkspaceReviewPointModelList();
          }
        },
        error => {
          this.workspaceReviewPointComments = null;

        }
      );
  }
  reviewNoteStatus = ReviewNoteStatus;
  reviewNoteFilterBy = ReviewNoteFilterBy;
  ViewByStatus: number = ReviewNoteStatus.All;
  ViewByUser: number = ReviewNoteFilterBy.All;
  filterReviewPoint() {
    let viewByStatus: number = parseInt(this.ViewByStatus.toString());
    let viewByUser: number = parseInt(this.ViewByUser.toString());

    if (viewByStatus == ReviewNoteStatus.All) {
      this.NoReviewNotesMsg = "No review notes";
    } else if (viewByStatus == ReviewNoteStatus.Open) {
      this.NoReviewNotesMsg = "No open review notes";
    } else if (viewByStatus == ReviewNoteStatus.Resolved) {
      this.NoReviewNotesMsg = "No resolved review notes";
    }

    let filterReviewPointModelList: WorkspaceReviewPointModel[] = [];

    switch (viewByUser) {
      case ReviewNoteFilterBy.All:
        filterReviewPointModelList = this.workspaceReviewPointModelListOriginal;
        break;
      case ReviewNoteFilterBy.CreatedByMe:
        filterReviewPointModelList = this.workspaceReviewPointModelListOriginal.filter(ReviewPointItem => ReviewPointItem.workspaceReviewPoint.createdBy === this.userInfo.userName);
        break;
      case ReviewNoteFilterBy.MentionedMe:
        let reviewNoteIds: number[] = [];
        this.workspaceReviewPointModelListOriginal.forEach(reviewNote => {
          if (reviewNote.workspaceReviewPoint.membersMentionedInReviewNote
            && reviewNote.workspaceReviewPoint.membersMentionedInReviewNote.length > 0
            && reviewNote.workspaceReviewPoint.membersMentionedInReviewNote.filter(mentionedUser => mentionedUser.userEmailId === this.userInfo.userName || mentionedUser.userEmailId === 'Team').length > 0
            && !reviewNoteIds.includes(reviewNote.workspaceReviewPoint.reviewPointId)) {
            reviewNoteIds.push(reviewNote.workspaceReviewPoint.reviewPointId);
          }
        });

        filterReviewPointModelList = this.workspaceReviewPointModelListOriginal.filter(ReviewPointItem => reviewNoteIds.includes(ReviewPointItem.workspaceReviewPoint.reviewPointId));
        break;
    }

    switch (viewByStatus) {
      case ReviewNoteStatus.Open:
      case ReviewNoteStatus.Resolved:
        filterReviewPointModelList = filterReviewPointModelList.filter(ReviewPointItem => ReviewPointItem.workspaceReviewPoint.status === viewByStatus);
        break;
    }


    this.workspaceReviewPointModelList = filterReviewPointModelList;
    this.workspaceReviewPointsCount = filterReviewPointModelList.length;
  }

  ResolveReviewPoint(reviewPointId: number) {

    var currentReviewPointModel = this.workspaceReviewPointModelList.find(e => e.workspaceReviewPoint.reviewPointId === reviewPointId);
    currentReviewPointModel.workspaceReviewPoint.membersMentionedInReviewNote = [];
    currentReviewPointModel.workspaceReviewPoint.status = ReviewNoteStatus.Resolved;
    currentReviewPointModel.workspaceReviewPoint.statusName = "Resolved";
    currentReviewPointModel.workspaceReviewPoint.workspaceId = this.selectedWorkspaceDocumentItem.workspaceId;
    currentReviewPointModel.workspaceReviewPoint.lastModifiedBy = this.userInfo.userName;
    this.subscribtionList.push(
      this.workspaceReviewPointService
        .ResolveReviewPoint(currentReviewPointModel.workspaceReviewPoint)
        .subscribe(result => {
          this.workspaceReviewPointsForInsert.push(result);
          this.getWorkspaceReviewPoints(true);
        },
          error => {
            this.DisplaySystemAlert();
          })
    );
  }

  CloseViewReviewPointModal() {
    $("#modal-ViewReviewPoints").modal("hide");
    setTimeout(() => {
      this.getWorkspaceReviewPoints(true);
    }, 50);
    this.currentworkspaceReviewPoints.subject = "";
    this.currentworkspaceReviewPoints.description = "";
    this.currentworkspaceReviewPoints.createdBy = "";
    this.currentworkspaceReviewPoints.lastModifiedBy = "";
    $("#txtSubject").html("");
    $("#txtDescr").html("");
  }
  checkCurrentLoggedinUserisCreatedBy(createdBy: string) {
    this.isLoggedinUserCreatedby = this.workspaceService.checkCurrentLoggedinUserIsCreatedBy(createdBy);
    console.log('isLoggedinUserCreatedby ==>', this.isLoggedinUserCreatedby);
    return this.isLoggedinUserCreatedby
  }
  checkSpacesatBegining(event, index) {
    let text = '';
    if (event.target.id == 'txtReviewNoteDescription') {
      text = event.currentTarget.textContent.trim();
    }
    else {
      text = event.currentTarget.value.trim();
    }

    if (text.length === 0) {
      if (event.target.id == 'txtReviewNoteSubject') {
        this.currentworkspaceReviewPoints.subject = null;
      }
      else if (event.target.id == 'txtReviewNoteDescription') {
        this.currentworkspaceReviewPoints.description = null;
      }
      else if (event.target.id == 'txtNewComment_' + index) {
        this.currentworkspaceReviewPointComment.comments = null;
      }
    }
    else if (text.length > 0) {
      if (event.target.id == 'txtReviewNoteSubject') {
        this.currentworkspaceReviewPoints.subject = text;
      }
      else if (event.target.id == 'txtReviewNoteDescription') {
        this.currentworkspaceReviewPoints.description = text;
      }
      else if (event.target.id == 'txtNewComment_' + index) {
        this.currentworkspaceReviewPointComment.comments = text;
      }
    }
  }
  checkIfAllowToTypeCharacter(event) {
    if (event.which != 36  //Home
      && event.which != 35 //End
      && event.which != 46 //Delete
      && event.which != 8  //Backspace
      && event.which != 37 //Left arrow
      && event.which != 38 //up arrow
      && event.which != 39 //right arrow
      && event.which != 40 //down aarow
      && ((!event.ctrlKey && event.which != 65) || (!event.ctrlKey && event.which != 90) || (!event.ctrlKey && event.which != 88) || (!event.ctrlKey && event.which != 67) || (!event.ctrlKey && event.which != 86)) //select all, undo, cut, copy, paste
      && event.which != 17 //ctrl
      && event.currentTarget.textContent.length + 1 > this.maxCharactersAllowedForReviewNoteDescription) {
      event.preventDefault();
    }
  }

  setRemainingCharacterCount(event, label) {
    if (event.currentTarget.textContent.length == 0) {
      $('#' + label).text(this.maxCharactersAllowedForReviewNoteDescription + ' characters');
      $('#' + label).removeClass('warning-review-note-label');
    }
    else {
      $('#' + label).text(this.maxCharactersAllowedForReviewNoteDescription - event.currentTarget.textContent.length + ' characters remaining');
      if (event.currentTarget.textContent.length >= this.warningCharacterCount) {
        $('#' + label).addClass('warning-review-note-label');
      }
      else {
        $('#' + label).removeClass('warning-review-note-label');
      }
    }
  }

  verifyPastedContent(event, label) {
    if ((event.clipboardData.getData('text') + event.currentTarget.textContent).length > this.maxCharactersAllowedForReviewNoteDescription) {
      event.preventDefault();
    }
    else {
      let clipboardContentLenght = (event.clipboardData.getData('text') + event.currentTarget.textContent).length;
      let remainingChars = this.maxCharactersAllowedForReviewNoteDescription - clipboardContentLenght;
      $('#' + label).text(remainingChars + ' characters remaining');
      if (clipboardContentLenght >= this.warningCharacterCount) {
        $('#' + label).addClass('warning-review-note-label');
      }
      else {
        $('#' + label).removeClass('warning-review-note-label');
      }
    }
  }
  setUserRole() {
    if (this.userInfo != null && this.userInfo.role != null && this.userInfo.role.length > 0) {
      for (let i = 0; i < this.userInfo.role.length; i++) {
        if (this.userInfo.role[i].shortRoleName === RoleNames.SystemAdministrator) {
          this.isLoggedInUserSystemAdmin = true;
        }
        if (this.userInfo.role[i].shortRoleName == RoleNames.QualityGroup) {
          this.isQualityUserLoggedIn = true;
        }
        if (this.userInfo.role[i].shortRoleName == RoleNames.INDUSStaffingManagers) {
          this.isINDUSStaffingManagerLoggedIn = true;
        }
      } //End of For  
    }// End of If
  }

  CanResolve(createdBy: string, whoCanResolve) {

    let allowedToResolveReviewNote: boolean = false;


    // If logged in user is pure NOP, do not allow to resolve
    if (this.isPureNotificationOnlyProfessional) {
      allowedToResolveReviewNote = false;
    }

    // Creator - If logged in user is creator of review note or system admin allow him to resolve
    // Allowed - Creator(TM/EP/RP), System Admin
    // Not Allowed - Non-Creator(TM/EP/RP), Pure NOP
    else if (this.isLoggedInUserSystemAdmin || createdBy.toLowerCase() == this.userInfo.userName.toLowerCase()) {
      allowedToResolveReviewNote = true;
    }

    // Any RP - Check if logged in user is EP or RP (We consider EP as RP)
    // Allowed - Creator(TM/EP/RP), EP, RP, System Admin
    // Not Allowed - Non-Creator(TM/EP/RP), TM, Pure NOP
    else if (whoCanResolve == WhoCanResolveReviewNote.AnyRP && (this.isUserRP || this.isUserEP)) {
      allowedToResolveReviewNote = true;
    }

    // Anyone - Check if logged in user is not NOP and has workspace acces
    // Allowed - Creator, TM, EP, RP, System Admin
    // Not Allowed - Pure NOP
    else if (whoCanResolve == WhoCanResolveReviewNote.Anyone && (this.isUserTM || this.isUserRP || this.isUserEP)) {
      allowedToResolveReviewNote = true;
    }

    return allowedToResolveReviewNote;
  }

  closeReviewNotes() {
    //To expand left nav
    this.dataService.setCollapsibleLeftNav(false);
    $('.margin-all').removeClass('compressDiv');
    $('.containerDiv').removeClass('compresscontainerDiv');
    $('.containerDiv').removeClass('compressRevContainer');
    $('#btnEditReviewContainer').removeClass('colEditReviewContainer');
    $('#btnDecAppReviewContainer').removeClass('colDecAppReviewContainer');
    $('#btnTeamReviewContainer').removeClass('colTeamReviewContainer');
    $('#delDocumentBtn').removeClass('colDelDocumentBtn');
    $('.add-review-section').removeClass('addProf');        
    $('#supplDoc').removeClass('supplDocTable');
    
    $('#review-Notes_modal').modal('hide');
    this.closeReviewNotesModal.emit(true);
    this.getWorkspaceReviewPoints(false);
  }
  DisplaySystemAlert() {
    this.alertService.remove(this.alert);
    this.loadingScreenService.stopLoading();
    this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }

  showReviewNoteModal() {
    $('#review-Notes_modal').modal({
      backdrop: false,
      show: true
    });
    $('#review-Notes_modal').modal('show');
  }

  fillWorkspaceDocumentList(workspaceModel: WorkspaceModel) {
    //this.workspaceDocumentList = [];
    let workspaceDocumentViewModel = new WorkspaceDocumentViewModel();
    workspaceDocumentViewModel.workspaceDocumentId = workspaceModel.workspaceDocumentId;
    workspaceDocumentViewModel.engagementId = workspaceModel.engagementId;
    workspaceDocumentViewModel.workspaceId = workspaceModel.workspaceId;
    workspaceDocumentViewModel.workItemId = workspaceModel.hdWorkItemId;
    workspaceDocumentViewModel.documentId = '';
    workspaceDocumentViewModel.documentName = '';
    workspaceDocumentViewModel.mimeType = '';
    workspaceDocumentViewModel.workItemVersionId = workspaceModel.hdWorkItemVersionId;
    workspaceDocumentViewModel.templatePackageVersionId = workspaceModel.hdTemplatePackageVersionId
    workspaceDocumentViewModel.documentAliasName = workspaceModel.documentAliasName;
    workspaceDocumentViewModel.lastActivityBy = workspaceModel.lastActivityBy;
    workspaceDocumentViewModel.lastActivityDate = workspaceModel.lastActivityDate;
    workspaceDocumentViewModel.orginalHDWorkItemVersionId = workspaceModel.orginalHDWorkItemVersionId;
    workspaceDocumentViewModel.orginalHDWorkItemId = workspaceModel.orginalHDWorkItemId;
    workspaceDocumentViewModel.isPrimaryWorkspaceId = workspaceModel.isPrimaryWorkspaceId;
    workspaceDocumentViewModel.engagementState = workspaceModel.engagementState;
    this.workspaceDocumentList.push(workspaceDocumentViewModel);
    console.log("workspaceDocumentList==>", this.workspaceDocumentList);
  }

  WorkspaceDocumentChange() {
    const selectedWorkspaceDocumentText = $('#ddReviewNotesDocument option:selected').text().trim();
    if (selectedWorkspaceDocumentText == undefined || selectedWorkspaceDocumentText == '') {
      this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.find(docItem => docItem.isPrimaryWorkspaceId === true);
    }
    else {
      this.selectedWorkspaceDocumentItem = this.workspaceDocumentList.find(docItem => docItem.documentAliasName === selectedWorkspaceDocumentText);
      console.log('CurrentWorkspaceData', this.CurrentWorkspaceData);
    }
    if (this.documentName == '' || this.documentName == undefined) {
      this.documentName = this.selectedWorkspaceDocumentItem.documentAliasName;
    }
    this.getWorkspaceReviewPoints(true);


  }
  GetWorkspaceDocumentCountbyEngId() {
    var engagementState = 'Acceptance';
    this.workspaceService.GetWorkspaceDocumentCountbyEngId(this.engagementId, engagementState).subscribe(
      resultValue => {
        this.workspaceDocumentCount = resultValue;
        console.log('this.workspaceDocumentCount', this.workspaceDocumentCount);
      },
      error => {
        this.loadingScreenService.stopLoading();
        this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }
  getSecondaryWorkspaceData(engagementId: number) {
    this.loadingScreenService.startLoading();
    this.workspaceService.getSecondaryWorkspaceDataByEngId(engagementId).subscribe(
      resultModel => {
        this.secondaryWorkspaceDataList = resultModel.secondaryWorkspaceDataList;
        console.log("secondaryWorkspaceDataList ==>", this.secondaryWorkspaceDataList);
        if (this.secondaryWorkspaceDataList.length > 0) {
          this.secondaryWorkspaceDataList.forEach(workspace => {
            this.allWorkspaceDataList.push(JSON.parse(JSON.stringify(workspace)));
            if (workspace.engagementState === 'Acceptance') {
              this.fillWorkspaceDocumentList(workspace);
            }
          });
        }
      },
      error => {
        this.loadingScreenService.stopLoading();
      });
  }


  getWorkspaceData() {
    this.loadingScreenService.startLoading();

    this.subscribtionList.push(
      this.workspaceService.getWorkspaceByEngagementId(this.engagementId).subscribe(
        result => {
          this.CurrentWorkspaceData = result;
          this.GetWorkspaceDocumentCountbyEngId();
          this.workspaceDocumentList = [];
          this.fillWorkspaceDocumentList(this.CurrentWorkspaceData);
          this.getSecondaryWorkspaceData(this.engagementId);
          setTimeout(() => {
            this.WorkspaceDocumentChange();

          }, 5000);
        },
        error => {
          this.loadingScreenService.stopLoading();
          this.CurrentWorkspaceData = null;
          this.DisplaySystemAlert();
        })
    );

  }

  getReviewNoteEngagementData() {
    this.subscribtionList.push(
      this.engService.GetEngagementDetailsForReviewNotesById(this.engagementId).subscribe(
        result => {
          this.reviewNoteEngagementData = result;
          if (result.ReacceptanceEngagementId > 0 && result.ReacceptanceEngagementId != 0) {
            this.isContinuanceEngagement = true;
          }
          else {
            this.isContinuanceEngagement = false;
          }
        },
        error => {
          this.reviewNoteEngagementData = null;
          this.DisplaySystemAlert();
        })
    );
  }  

  startResize($event, anchors: ResizeAnchorType[], direction: ResizeDirectionType): void {
    $event.preventDefault();
    const mouseX = $event.clientX;
    const lastX = this.position.x;
    const dimensionWidth = this.resizeDivRef.nativeElement.parentNode.offsetWidth;

    const duringResize = (e) => {
      let dw = dimensionWidth;
      if (direction === 'x') {
        if (anchors.includes('left')) {
          dw += (mouseX - e.clientX);
        } else if (anchors.includes('right')) {
          dw -= (mouseX - e.clientX);
        }
      }

      if (anchors.includes('left') && dw <= this.maxSize.w) {
        this.position.x = lastX + e.clientX - mouseX;
        this.size.w = Math.max(dw, this.minSize.w);
      }

      this.lastSize = { ...this.size };
    };

    const finishResize = (e) => {
      this._document.removeEventListener('mousemove', duringResize);
      this._document.removeEventListener('mouseup', finishResize);
    };

    this._document.addEventListener('mousemove', duringResize);
    this._document.addEventListener('mouseup', finishResize);
  }

}
