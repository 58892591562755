import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Engagements } from 'src/app/model/Engagement/Engagements';
import { KeyValue } from 'src/app/model/KeyValue';
import { Clients } from 'src/app/model/clients';
import { EngagementService } from 'src/app/services/EngagementService';
import { ClientService } from 'src/app/services/clientservice';
import { DataService } from 'src/app/services/data.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { of, Observable } from 'rxjs';
import { TeamMemberPermission } from 'src/app/model/Engagement/TeamMemberPermission';
import { TeamMember, ValidationTeamMember } from 'src/app/model/Engagement/TeamMember';
import { RoleNames } from 'src/app/model/Role';
import { AppConfigService } from 'src/app/services/appConfigService';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from 'src/app/services/alerts.service';
import { AppCodes, Applicationmaster } from 'src/app/model/ApplicationMaster';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { MembersList } from 'src/app/model/MembersList';
import { SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { AdUsersService } from 'src/app/services/adusers.service';
import { ChangeOrderService } from 'src/app/services/change-order.service';
import { ChangeOrderModel } from 'src/app/model/change-order/change-order-model';
import { AddWorkspaceDocumentValidationModel, MappingTemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { TemplateMaintenanceService } from 'src/app/services/template-maintenance.service';
import { TemplateMappingModel } from 'src/app/model/TemplateMappingModel';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DocumentService } from 'src/app/services/DocumentService';
declare var $;
@Component({
  selector: 'app-initiate-change-order',
  templateUrl: './initiate-change-order.component.html',
  styleUrls: ['./initiate-change-order.component.css']
})
export class InitiateChangeOrderComponent implements OnInit, OnDestroy {
  changeOrderId: number
  engagementId: number = -1;
  engagementName: string;
  startDateCtrlId: string;
  teamMemberlblCtrlName: string;
  clientId: number = -1;
  editMode: boolean;
  financialUpdate: boolean = false;

  isPrepopulateFromDetails: boolean = false;
  clientkeyValueList: KeyValue[] = [];
  engagementkeyValueList: KeyValue[] = [];
  clients: Clients[];

  userObject: any;
  isLoggedInUserSystemAdmin: boolean;
  isQualityUserLoggedIn: boolean;
  engagement: Engagements;
  engagements: Engagements[];
  changeOrderHistoryList: ChangeOrderModel[];

  teamMemberPermissions: TeamMemberPermission[] = [];
  teamMemberList: Array<TeamMember> = [];
  arrValidationForNewTeamMember: ValidationTeamMember[] = [];
  teamMemberLimit: number;
  teamMemberRoleName: string;
  alert: Alert;
  newTeamMember: any = {};
  validateTM: boolean[] = new Array(15);
  isUserAgentIE: number;
  teamMemberRoleCodes: AppCodes[] = [];
  applicationmasterList: Applicationmaster[] = [];
  applicationmasterListIE: Applicationmaster[] = [];
  teamMemberRoleFromAppMstList: Applicationmaster[] = [];
  keyteamMemberRoleFromAppMstList: Applicationmaster[] = [];
  keyTeamMemberRoleCodes: AppCodes[] = [];
  isAudit: boolean = false;

  teamMemberCtrl: string; // people picker variables 
  teamMemberEmailCtrl: string;
  membersListArrayForTeamMembers: MembersList[];
  selectedTMIndex: string;
  currentTeamMemberIndex: number;
  existingGACheck: boolean;
  TemplateList: MappingTemplatePackageModel[] = [];
  isTemplateMappingFound: boolean;
  SelectedTemp: TemplateMappingModel = new TemplateMappingModel();
  documentCategory: string = 'Create New Engagement';
  SelectedTemplateList: MappingTemplatePackageModel[] = [];
  invalidvalues: string;
  isclientSelected: boolean = true;
  isEngagementSelected: boolean = true;
  isChangeorderTemplateSelected: boolean = true;
  selectedChangeOrderTemplate: string;
  isChangeOrderNameFilled: boolean = true;
  ChangeOrderName: string ;
  isEstimationDateFilled: boolean = true;
  EstimationDate: Date ;
  isIncrementalFeesFilled: boolean = true;
  IncrementalFees: number;
  isGrossMarginFilled: boolean = true;
  Grossmargin: number;
  PlaceholderText: string ="Enter...";
  changeOrderModel: ChangeOrderModel = new ChangeOrderModel();
  workspaceId: string;
  addWorkspace_SelectedData: AddWorkspaceDocumentValidationModel = new AddWorkspaceDocumentValidationModel();

  createChangeorder: boolean = false;

  constructor(private dataService: DataService,
    private loadingService: LoadingScreenService,
    private router: Router,
    private clientService: ClientService,
    private engagementService: EngagementService,
    private activatedRoute: ActivatedRoute,
    private appService: AppConfigService,
    private alertService: AlertsService,
    private applicationMasterService: ApplicationMasterService,
    private renderer: Renderer2,
    private el: ElementRef,
    private adUsersService: AdUsersService,
    private changeOrderService: ChangeOrderService,
    private templateMaintenanceService: TemplateMaintenanceService,
    private configService: ConfigurationService,
    private documentService: DocumentService
  ) {
    const jsonString = localStorage.getItem('currentUser');
    this.userObject = JSON.parse(jsonString);
    this.teamMemberLimit = this.appService.config.DATA.TEAM_MEMEBR_LIMIT;
    this.teamMemberRoleName = RoleNames.GTUSINDUSALL.toString();
    const IEversion = localStorage.getItem('isUserAgentIE');
    this.isUserAgentIE = JSON.parse(IEversion);
  }

  ngOnInit() {
    this.startDateCtrlId = 'EstimateCompletionDate';
    this.teamMemberlblCtrlName = 'team member';
    this.activatedRoute.paramMap.subscribe(params => {
      this.changeOrderId = +params.get('changeOrderId');
      if (this.changeOrderId > 0) {
        this.editMode = true;
      }
    });
    this.checkLoggedinUserSystemAdminRole();
    this.getTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and team member addition
    this.getKeyTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and Key team member addition
    this.getTeamMemberPermissions(); // from appmaster getting all field values to compare on UI and team member addition 
    this.clientService.getMyChangeOrderClients(this.userObject.loggedInUserEmail, this.isLoggedInUserSystemAdmin).subscribe({
      next: (result) => {
        this.clients = result;
        this.clientkeyValueList = this.getKeyValuePair(this.clients, 'clientId', 'clientName');
        this.checkPrefillClient();
      }, error: (err) => {

      }
    })
  }

  ngOnDestroy() { }
  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return of(true);
  }
  checkPrefillClient() {
    let prevPath = this.dataService.previousUrl.getValue();
    if (prevPath?.includes("EngagementDetail") && !(this.changeOrderId > 0)) {
      let pathItems = prevPath.split("/");
      let engagementIdTemp = +pathItems[2];
      this.getEngagementDetailsById(engagementIdTemp);
      this.isPrepopulateFromDetails = true;
    }
  }
  checkLoggedinUserSystemAdminRole() {
    console.log('userObject ==>', this.userObject);
    this.isLoggedInUserSystemAdmin = this.isQualityUserLoggedIn = false;
    if (this.userObject != null && this.userObject.role != null && this.userObject.role.length > 0) {
      for (let i = 0; i < this.userObject.role.length; i++) {
        if (this.userObject.role[i].shortRoleName === 'SA') {
          this.isLoggedInUserSystemAdmin = true;
        }
        if (this.userObject.role[i].shortRoleName === 'QG') {
          this.isQualityUserLoggedIn = true;
        }
      } //End of For  
    }// End of If
  }
  getKeyValuePair(array: any, keyProperty: string, valueProperty) {
    let keyValueList: KeyValue[] = [];
    array.forEach(element => {
      let kv = new KeyValue();
      kv.key = element[keyProperty];
      kv.value = element[valueProperty];
      keyValueList.push(kv);
    });
    return keyValueList;
  }
  getEngagementDetailsById(engId: number) {
    this.loadingService.startLoading();
    return this.engagementService.GetEditEngagementDetailsById(engId)
      .subscribe({
        next: result => {
          this.loadingService.stopLoading();
          this.engagement = result;
          if (this.engagement.status == "Active") {
            this.clientId = this.engagement.clientId;
            if (this.isPrepopulateFromDetails) {
              this.onClientsChange();
              this.isPrepopulateFromDetails = false;
              this.engagementName = this.engagement.engagementName;
            }
            this.engagementId = this.engagement.engagementId;
            this.checkPrimarySolutionCodeIsAudit(this.engagement.parentSolutionCodeId);
            this.workspaceId= this.engagement.workspaceId
          }
        }, error: err => {
          console.error("getEngagementDetailsById ", err);
          this.loadingService.stopLoading();
        }
      })
  }
  onClientsChange() {
    this.isclientSelected = true;
    this.BindEngagementData(this.clientId);
  }
  onEngagementsChange() {
    this.engagementName = this.engagements?.find(x => x.engagementId == this.engagementId).engagementName;
    this.getEngagementDetailsById(this.engagementId)
    this.isEngagementSelected = true;
  }
  BindEngagementData(clientId: number) {
    this.engagementService.getMyChangeOrderEngagements(this.userObject.loggedInUserEmail, clientId, this.isLoggedInUserSystemAdmin).subscribe(result => {
      this.engagements = result;
      this.engagementkeyValueList = this.getKeyValuePair(result, 'engagementId', 'engagementName');
    });
  }
  getTeamMemberRoleList() {
    let applicationName = 'TeamMemberRole';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        //this.applicationmasterList = result;
        result.forEach(element => {
          const teamMemberRoleCode = new AppCodes();
          teamMemberRoleCode.code = element.code;
          teamMemberRoleCode.codeValue = element.applicationMasterId;
          this.teamMemberRoleCodes.push(teamMemberRoleCode);
          this.applicationmasterList.push(element);
          if (element.code !== 'EP' && element.code !== 'C' && element.code !== 'A'
            && element.code !== 'CMSEP' && element.code !== 'PR') {
            this.applicationmasterListIE.push(element);
            this.teamMemberRoleFromAppMstList.push(element);
          }
        });
        console.log(this.teamMemberRoleCodes);
      }
    );
  }
  getKeyTeamMemberRoleList() {
    let applicationName = 'KeyTeamMemberRole';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        result.forEach(element => {
          const keyTeamMemberRoleCode = new AppCodes();
          keyTeamMemberRoleCode.code = element.code;
          keyTeamMemberRoleCode.codeValue = element.applicationMasterId;
          this.keyTeamMemberRoleCodes.push(keyTeamMemberRoleCode);
          //this.applicationmasterListIE.push(element);
          this.keyteamMemberRoleFromAppMstList.push(element);
        });
        this.keyteamMemberRoleFromAppMstList = this.keyteamMemberRoleFromAppMstList.sort((a, b) => Number(a.applicationMasterId) - Number(b.applicationMasterId));
      }
    );
  }
  getTeamMemberPermissions() {
    this.applicationMasterService.GetTeamMemberPermissions().subscribe(
      result => {
        this.teamMemberPermissions = result;
      }
    );
  }
  addTeamMember() {
    $('#clientErrorMessage').text('');
    //set role name for team meber
    // if (this.isContinuanceEngagement && this.engagementCollabData.isIndus && this.engagementCollabData.carryForwadINDUSConsent) { //isindus current engagement collabsitetable == true && carryforwardindus == true && iscontinuous == true
    //   this.teamMemberRoleName = RoleNames.ICANDGIA.toString();
    // } else {
    //   this.teamMemberRoleName = RoleNames.GTUSINDUSALL.toString();
    // }
    // if (this.engagementCollabData.isIndus  || (this.isLoggedInUserSystemAdmin && this.engagement.memberFirmMappingId > 0)) {
    //   this.teamMemberRoleName = this.memberFirmData.indusConsentShortRoleName;
    // } else if (this.engagementCollabData.isIndus) {
    //   this.teamMemberRoleName = RoleNames.INDUSConsent.toString();
    // } else if (this.isMemberFirm || (this.isLoggedInUserSystemAdmin && this.engagement?.memberFirmMappingId > 0)) {
    //   this.teamMemberRoleName = this.memberFirmData?.teamMemberShortRoleName;
    // } else {
    //   this.teamMemberRoleName = RoleNames.StaffMember.toString();
    // }
    this.teamMemberRoleName = RoleNames.StaffMember.toString();
    if (this.teamMemberList != null) {
      if (this.teamMemberList.length < this.teamMemberLimit) {
        //close client team members section
        // this.arrValidationForNewClientTeamMember = [];
        // this.newClientTeamMemberList = [];

        this.alertService.remove(this.alert);
        this.newTeamMember = {
          memberEmailId: '',
          memberConfirmEmail: '',
          memberName: '',
          teamMemberRole: '',
          memeberContactNo: '',
          approvalStatus: '',
          teamMemberPermissionId: 0,
          isKeyTeamMember: false,
          keyTeamMemberRoleId: 0,
          description: ''
        };

        this.teamMemberList.push(this.newTeamMember);
        const ValidNewTeamMember = {
          memberEmailId: '',
          memberConfirmEmail: '',
          memberName: '',
          teamMemberRole: '',
          memeberContactNo: '',
          approvalStatus: '',
          teamMemberPermissionId: 0,
          ishdnValidInputName: true,
          isUserExist: false,
          isNewUserDuplicate: false,
          index: this.arrValidationForNewTeamMember.length
        };
        this.arrValidationForNewTeamMember.push(ValidNewTeamMember);
        console.log('addTeamMember func called', this.teamMemberList, this.arrValidationForNewTeamMember);
      }
      else {
        this.alertServiceFun('You can add only ' + this.teamMemberLimit + ' team members.', 'danger');
      }
    }
  }

  removeTeamMember(index: number, teamMemberRole: string) {
    // if (this.editMode) {
    //   this.loadingService.startLoading();
    //   return this.engagementService.IsDiscussionOrReviewPointExistForUser(this.currentEngagementId, this.teamMemberList[index].memberEmailId).subscribe(
    //     res => {
    //       this.discussionandReviewPointsCount = res;
    //       this.loadingService.stopLoading();
    //       if (this.discussionandReviewPointsCount == 0) {
    //         this.teamMemberList.splice(index, 1);
    //         this.arrValidationForNewTeamMember.splice(index, 1);
    //         this.renameTeammember(index, teamMemberRole);
    //         this.alertService.remove(this.alert);
    //         return true;
    //       }
    //       else {
    //         this.alertServiceFun('Team Member you are deleting has open discussions or review points. You cannot delete this team member.', 'danger');
    //         return false;
    //       }
    //     },
    //     () => {
    //       this.loadingService.stopLoading();
    //       this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    //     });
    // }
    // else
    {
      this.teamMemberList.splice(index, 1);
      this.arrValidationForNewTeamMember.splice(index, 1);
      this.renameTeammember(index, teamMemberRole);
      this.alertService.remove(this.alert);
      return true;
    }
    // }
    // return true;
  }

  selectTeamMember(index: any, member: MembersList) {
    $('#searchTeamMemberName').val(member.displayName);
    $('#tblMemberAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    //this.selectedTM=member;
    this.selectedTMIndex = index;
  }
  deselectTeamMember() { // people picker cancel clicked 
    $('#' + this.teamMemberCtrl).val('');
    $('#' + this.teamMemberCtrl).innerText = '';
    $('#' + this.teamMemberEmailCtrl + ' > #hdnUserEmail').val('');
    //$('#' + this.teamMemberEmailCtrl).innerText = '';
  }



  setTeamMember() {
    let index = this.selectedTMIndex;
    if (Number(index) >= 0) {
      const selectedMemberName = $('#spMemberdisplayName' + index).text();
      const selectedMemberEmail = $('#spMembermail' + index).text();
      const selectedMemberPhone = $('#spMemberbusinessPhones' + index).text();
      $("#TeamMemberErrorMessage").text('');
      var countofAddedTM = 0;
      for (var i in this.teamMemberList) {
        if (this.teamMemberList[i].memberEmailId == selectedMemberEmail) {
          countofAddedTM = countofAddedTM + 1;
        }
      }
      if (countofAddedTM == 0) {
        $('#TeamMemberErrorMessage').text('');
        $('#' + this.teamMemberCtrl).val(selectedMemberName);
        $('#' + this.teamMemberEmailCtrl + ' > #hdnUserEmail').val(selectedMemberEmail);
        this.teamMemberList[this.currentTeamMemberIndex].memberName = selectedMemberName;
        this.teamMemberList[this.currentTeamMemberIndex].memberEmailId = selectedMemberEmail;
        this.teamMemberList[this.currentTeamMemberIndex].memeberContactNo = selectedMemberPhone;
        this.validateTM.forEach((value, memberIndex, array) => array[this.currentTeamMemberIndex] = true);
        console.log('Validate Team Members ', this.validateTM);
      }
      else {
        $('#TeamMemberErrorMessage').text('Selected Team Member is already added');
      }
    }
    this.membersListArrayForTeamMembers = null;
  }

  searchUsers(searchTextBox, tableId: string) {
    const searchText = $('#' + searchTextBox).val();
    // if (tableId === 'tblEPAD' || tableId === 'tblEQCR' || tableId === 'tblGTAgreementOwner') {
    //   this.getEPList(searchText);
    // }

    // if (tableId === 'tblAssAdminAD') {
    //   this.GetAssignmentAdmins(searchText);
    // }

    if (tableId === 'tblMemberAD') {
      this.GetTeamMembers(searchText);
    }

    /*
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
*/
  }


  renameTeammember(index: number, teamMemberRole: string) {
    const rows = this.el.nativeElement.querySelectorAll('#clientUserListContainer');
    if (rows.length > 0) {
      const rowToRemove = rows[index];
      this.renderer.removeChild(this.el.nativeElement, rowToRemove);
      for (let i = index; i < rows.length - 1; i++) {
        const row = rows[i + 1];
        const txtPeoplePicker = row.querySelector('input[type="text"]:first-child');
        const hdnValidInput = row.querySelector('input[type="hidden"][id^="hdnValidInput_' + teamMemberRole + '_"]');
        const hint = row.querySelector('span[id^="hint_' + teamMemberRole + '_"]');

        const loadericon = row.querySelector('em');
        const searchUserList = row.querySelector('ul');
        const UserHiddenProps = row.querySelector('div:nth-child(4)');

        if (hdnValidInput) {
          const newId = 'hdnValidInput_' + teamMemberRole + '_' + i;
          hdnValidInput.setAttribute('id', newId);
        }
        txtPeoplePicker.id = 'txtPeoplePicker_' + teamMemberRole + '_' + i;
        loadericon.id = 'loadericon_' + teamMemberRole + '_' + i;
        searchUserList.id = 'searchUserList_' + teamMemberRole + '_' + i;
        hint.id = 'hint_' + teamMemberRole + '_' + i;

        if (UserHiddenProps) {
          const divId = UserHiddenProps.getAttribute('id');
          if (divId) {
            const newDivId = divId.replace(/_\d+$/, '_' + i);
            UserHiddenProps.setAttribute('id', newDivId);
          }
        }
        const errorDivs = row.querySelectorAll('div.error-color');
        for (let j = 0; j < errorDivs.length; j++) {
          const errorDiv = errorDivs[j];
          const errorDivId = errorDiv.getAttribute('id');
          if (errorDivId) {
            const newErrorDivId = errorDivId.replace(/_\d+$/, '_' + i);
            errorDiv.setAttribute('id', newErrorDivId);
          }
        }

      }
    }

  }

  GetTeamMembers(searchTerm: string) { // function for people picker popup 
    this.loadingService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    // if (this.memberFirmData?.memberFirmMappingId > 0 && this.isLoggedInUserSystemAdmin) {
    //   searchModel.isMemberFirm = true;
    //   searchModel.memberFirmDomain = [];
    //   searchModel.memberFirmDomain.push(this.memberFirmData?.memberDomain)
    // }
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingService.stopLoading();
          this.membersListArrayForTeamMembers = userList;
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForTeamMembers = null;
          this.loadingService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  refreshTeamMemberPopup(index, textboxName: string) { // funtion to refresh team member for people picker popup 
    this.removeRowSelection('tblMemberAD', 'searchTeamMemberName', 'TeamMemberErrorMessage');
    // if (this.EditMode) {
    //   this.loadingScreenService.startLoading();
    //   return this._engService.IsDiscussionOrReviewPointExistForUser(this.currentEngagementId, this.teamMemberList[index].memberEmailId).subscribe(
    //     res => {
    //       this.discussionandReviewPointsCount = res;
    //       this.loadingScreenService.stopLoading();
    //       if (this.discussionandReviewPointsCount == 0) {
    //         this.teamMemberCtrl = textboxName + this.teamMemberRoleName + '_' + index;
    //         this.teamMemberEmailCtrl = 'UserHiddenProps_' + this.teamMemberRoleName + '_' + index;
    //         this.currentTeamMemberIndex = index;
    //         this.selectedTMIndex = null;
    //         this.validateTM.forEach((value, memberIndex, array) => array[index] = false);
    //         console.log('Validate Team Members ', this.validateTM);
    //         return true;
    //       }
    //       else {
    //         $('#modal-TeamMember').modal('hide');
    //         return false;
    //       }
    //     }, () => {
    //       this.loadingScreenService.stopLoading();
    //       this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    //     });
    // }
    // else {
    this.teamMemberCtrl = textboxName + this.teamMemberRoleName + '_' + index;
    this.teamMemberEmailCtrl = 'UserHiddenProps_' + this.teamMemberRoleName + '_' + index;
    this.currentTeamMemberIndex = index;

    this.selectedTMIndex = null;
    this.validateTM.forEach((value, memberIndex, array) => array[index] = false);
    console.log('Validate Team Members ', this.validateTM);
    // }
  }
  removeRowSelection(tableId, textboxId, errorMessageId) {
    $('#' + textboxId).val('');
    $('#' + errorMessageId).text('');
    const searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }

  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }



  alertServiceFun(message, type) {
    this.alertService.remove(this.alert);
    this.alertService.add(message, type, null);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }


  // To check primary solutioncode is audit or not
  checkPrimarySolutionCodeIsAudit(primarySolutioncodeId: number) {
    if (primarySolutioncodeId > 0)
      this.engagementService.GetSolutionCodeMasterById(primarySolutioncodeId).subscribe(result => {
        this.isAudit = result.isAudit;
      });
  }

  getChangeOrderHistory() {
    this.changeOrderService.getChangeOrderHistory(this.engagementId).subscribe({
      next: result => {

      }, error: err => {
        console.error("failed to load change order history", err)
      }
    })

  }
  getTemplateMappingData() {
    this.configService.getSolutionCodeTemplateMappingData(this.documentCategory, this.engagement.parentSolutionCodeId, true).subscribe(result => {
      if (result.length > 0) {
        this.TemplateList = result;
      } else {
        this.TemplateList = [];
        this.SelectedTemplateList = [];
        this.engagement.agreementType = 'undefined';
      }
    });
  }

  getCLMTemplateMaintenanceData() {
    const staticValue = 'noMSAEL'; // Define the static value
    // $("input[type='radio'][name='DocumentTypes']").prop('checked', false);
    this.SelectedTemplateList = [];
    this.existingGACheck = false;
    this.templateMaintenanceService.getTemplateMaintenanceDataByCLMType(staticValue).subscribe(
      result => {
        console.log(result);
        if (result.length > 0) {
          this.SelectedTemplateList = this.TemplateList.filter(o => result.some(({ templatePackageId }) =>
            o.templatePackageId === templatePackageId));
        }
      }, error => {
        this.loadingService.stopLoading();
        this.alertService.add('Something went wrong! Please contact the system administrator.', 'danger', null);
      });
  }

  onTemplateChange() {
    this.getTemplateMappingData();
    this.getCLMTemplateMaintenanceData();
    this.isChangeorderTemplateSelected = true;
  }
  onCreateChangeOrder() {
    console.log("Creating change order");
    this.EstimationDate = $('.EstimateCompletionDate').val();
    this.validateData();
    if(this.createChangeorder){
        this.changeOrderModel.engagementId = this.engagementId;
        this.changeOrderModel.changeOrderName = this.ChangeOrderName;
        this.changeOrderModel.isFinancialUpdate = this.financialUpdate;
        this.changeOrderModel.createdBy = this.userObject.loggedInUserEmail;
        this.changeOrderModel.lastModifiedBy = this.userObject.loggedInUserEmail;
        this.changeOrderModel.workspaceId = this.workspaceId;
        if (!this.changeOrderModel.isFinancialUpdate) {
            this.changeOrderModel.estimateCompletionDate = null;
            this.changeOrderModel.fee = null;
            this.changeOrderModel.grossMargin = null;
        } else {
            this.changeOrderModel.estimateCompletionDate = this.EstimationDate;
            this.changeOrderModel.fee = this.IncrementalFees;
            this.changeOrderModel.grossMargin = this.Grossmargin;
        }
        this.loadingService.startLoading();
        this.changeOrderService.createChangeOrder(this.changeOrderModel).subscribe({
            next: (result: string) => {
                this.loadingService.startLoading();
                if (result === 'Success') {
                    console.log('Change order created successfully.');
                } else {
                    console.error('Unexpected response:', result);
                    this.alertService.add('Unexpected response from server.', 'warning', null);
                }
                this.loadingService.stopLoading();
            },
            error: (error) => {
                console.error('Error creating change order:', error);
                this.alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                this.loadingService.stopLoading();
            }
        });
    }
    this.router.navigate(['ChangeOrderInterviews/' + this.workspaceId]);
}
onInput(event: Event) {
  const input = event.target as HTMLInputElement;
  // Remove any non-numeric characters except for the decimal point
  input.value = input.value.replace(/[^0-9.]/g, '');
}

  setCancelDialog() {
    this.invalidvalues = "";
    this.invalidvalues = "";
    this.RedirectOnCancel();
    this.clearValidationMessages()
  }
  RedirectOnCancel() {
    this.router.navigate(['MyEngagements']);
  }
  
  validateData() {
    this.isclientSelected = this.clientId !== undefined && this.clientId !== -1;
    this.isEngagementSelected = this.engagementId !== undefined && this.engagementId !== -1;
    this.isChangeorderTemplateSelected = this.selectedChangeOrderTemplate?.trim().length > 0;
    this.isChangeOrderNameFilled = this.ChangeOrderName?.trim().length > 0;

    if (this.financialUpdate) {
        this.isIncrementalFeesFilled = this.IncrementalFees !== undefined && this.IncrementalFees !== null;
        this.isGrossMarginFilled = this.Grossmargin !== undefined && this.Grossmargin !== null;
        this.isEstimationDateFilled = this.EstimationDate !== undefined && this.EstimationDate !== null;
    } else {
        this.isIncrementalFeesFilled = true; 
        this.isGrossMarginFilled = true;  
        this.isEstimationDateFilled = true; 
    }
    this.createChangeorder = this.isclientSelected && this.isEngagementSelected && this.isChangeorderTemplateSelected && this.isChangeOrderNameFilled &&
                             (this.financialUpdate ? (this.isIncrementalFeesFilled &&  this.isGrossMarginFilled && this.isEstimationDateFilled) : true);
}

  
 clearValidationMessages() {
  this.isGrossMarginFilled = false;
  this.isEstimationDateFilled = false;
  this.isIncrementalFeesFilled = false;
  this.isChangeOrderNameFilled = false;
  this.isChangeorderTemplateSelected =false;
 }
}
