<div class="d-flex justify-content-between pageTitleParent pb-3 mw-100">
  <div class="text-center">
    <h2 class=" float-md-left pageTitle largerFontSize2">Create change order</h2>
  </div>
</div>
<div>
  <span class="d-inline-block align-middle msg-text">
    This form must be completed for changes in scope resulting in a change order to an existing statement of work
    (“SOW”). A change order is appropriate when there is an extension of time, addition of resources, increase to a
    fixed fee amount, or other similar changes pursuant to the same services associated with an active engagement under
    an existing executed SOW. Note: An addition of resources includes third parties (including INDUS and GTIL member
    firms).
  </span>
</div>

<div class="col-md-12 bg-white pl-0 pr-0 pb-3">
  <div class="row">
    <div class="col-xl-6 col-md-6 pt-3">
      <label><span class="label">Select client </span> <span class="label mandatory">*</span></label>
      <div class="selectdiv">
        <select id="drpselectClientId" name="selectClientId" class="dropdown rounded-0" (change)="onClientsChange()"
          [(ngModel)]="clientId">
          <option [ngValue]="-1">Select client</option>
          <option *ngFor="let clientInfo of clientkeyValueList" [ngValue]=clientInfo.key>
            {{clientInfo.value}}
          </option>
        </select>
      </div>
      <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" 
      *ngIf="!isclientSelected">
      <span>Client is a required field.</span>
    </div>
    </div>
    <div class="col-xl-6 col-md-6 pr-0 pt-3">
      <label><span class="label">Select engagement </span> <span class="label mandatory">*</span></label>
      <div class="selectdiv ">
        <select id="drpselectEngagementId" name="selectEngagementId" class="dropdown rounded-0"
          [(ngModel)]="engagementId" (change)="onEngagementsChange()">
          <option [ngValue]="-1">Select engagement</option>
          <option *ngFor="let engagementInfo of engagementkeyValueList" [ngValue]=engagementInfo.key>
            {{engagementInfo.value}}
          </option>
        </select>
      </div>
      <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" 
      *ngIf="!isEngagementSelected">
      <span>Engagement is a required field.</span>
    </div>
    </div>
  </div>
</div>
<div>
  <div id="engagementDetailHeaderDiv" class="row col-md-12 mt-2">
    <h6 class="form-heading-text">Engagement details</h6>
  </div>
  <div class="row bg-light">
      <div class="pt-2 pl-3 pb-3">
        <div class="mt-1">Selected engagement: </div>
        <div>
          <a class="eng-pointer-cursor" [routerLink]="['/EngagementDetail/',engagementId]" target="_blank">{{engagementName}}</a>
        </div>
        <br>
        <div>Previously approved change order(s):</div>
        <div style="list-style-type: none;">
          <div>
            <a href="#">Timeline Extension</a> - Approved 03/01/2024
          </div>
          <div *ngFor="let order of changeOrderHistoryList">
            <a [routerLink]="['/ChangeOrder/',order.chaneOrderId]">{{ order.name }}</a> - Approved {{ order.approvedDate
            }}
          </div>
      </div>
    </div>
  </div>
</div>
<div>
  <div class="row col-md-12 mt-4">
    <h6 class="form-heading-text">Team members</h6>
  </div>
  <span class="d-inline-block align-middle msg-text">
    If this change order requires additional team members,enter them here.
  </span>

  <div class="col-md-6 mw-100 pl-0">
    <div id="divAddNewTeamMember" *ngIf="teamMemberList.length > 0" class="row ml-0">
      <div id="tblTeamMember" aria-describedby="Team members" class="w-100">
        <div *ngFor="let teamMember of teamMemberList;let i = index" id="clientUserListContainer"
          class="teammemberDataRow row mx-0 pb-3 bg-white" style="justify-content: space-around;">
          <div class="col-xl-4 col-md-11 mt-3 mlm-2">
            <div class="input-group mx-100">
              <app-people-picker class="eng-people-picker" [roleShortName]="teamMemberRoleName"
                [labelCtrlName]="teamMemberlblCtrlName" [isLabelVisible]="false" [InputCtrlIdIndex]="i"
                [PeoplePickerModel]="teamMember.memberName" [userEmail]="teamMember.memberEmailId"
                [IsValidateUserWithADGroup]="validateTM[i]">
              </app-people-picker>

              <div class="input-group-append input-height">
                <button type="button" id="PPIcon_{{peoplepickerIndex}}" data-toggle="modal"
                  data-target="#modal-TeamMember" data-keyboard="false" data-backdrop="static"
                  class="mw-100 people-picker-button text-center"
                  (click)="refreshTeamMemberPopup(i,'txtPeoplePicker_')">
                  <img src="assets/images/EDP/LeftSidebar/picker.svg" alt="Team Member" />
                </button>
              </div>
            </div>
            <div>
              <span id="errorLabelMemberName{{i}}" class="label error-color "
                *ngIf="this.arrValidationForNewTeamMember[i].memberName==null ||this.arrValidationForNewTeamMember[i].memberName==''"
                style="display:none;">Select team member name</span>
              <span id="userExist{{i}}" class="label error-color"
                *ngIf="(this.arrValidationForNewTeamMember[i].isNewUserDuplicate || this.arrValidationForNewTeamMember[i].isUserExist ) && this.arrValidationForNewTeamMember[i].memberName !=null && this.arrValidationForNewTeamMember[i].memberName != ''">User
                already exists!</span>
              <span class="label error-color"
                *ngIf="!this.arrValidationForNewTeamMember[i].ishdnValidInputName && this.arrValidationForNewTeamMember[i].memberName !=null && this.arrValidationForNewTeamMember[i].memberName != '' ">
                Team member should be valid</span>
            </div>
          </div>

          <span class="devider px-0 text-center hide-me"></span>

          <div class="col-xl-3 col-md-5 mt-3">
            <div *ngIf="isUserAgentIE < 0" class="selectdiv">
              <select *ngIf="isAudit; else isTeamMember" id="drpKeyTeamMemberRole_{{i}}" required
                name="KeyTeamMemberRole_{{i}}" class="form-control dropdown rounded-0"
                (change)="OnKeyRoleChange($event,i)">
                <option value="null">Select role</option>
                <option id="drpKeyTeamMembersOptions_{{i}}" *ngFor="let role of keyteamMemberRoleFromAppMstList"
                  [value]=role.applicationMasterId>
                  {{role.value}}
                </option>
              </select>
              <ng-template #isTeamMember>
                <select id="drpTeamMemberRole_{{i}}" required name="TeamMemberRole_{{i}}"
                  class="form-control dropdown rounded-0" (change)="OnRoleChange($event,i)">
                  <option value="null" disabled hidden>Select role</option>
                  <option id="drpTeamMembersOptions_{{i}}" *ngFor="let role of teamMemberRoleFromAppMstList"
                    [value]=role.applicationMasterId selected>
                    {{role.value}}
                  </option>
                </select>
              </ng-template>
            </div>
            <div *ngIf="isUserAgentIE > 0" class="selectdiv">
              <select id="drpTeamMemberRole_{{i}}" required name="TeamMemberRole_{{i}}"
                class="form-control dropdown rounded-0" (change)="OnRoleChange($event,i)">
                <option value="" disabled hidden>Select role</option>
                <option id="drpTeamMembersOptions_{{i}}" *ngFor="let role of applicationmasterListIE"
                  [value]=role.applicationMasterId>
                  {{role.value}}
                </option>
              </select>
            </div>
            <div>
              <span id="errorLabelMemberRole{{i}}" class="label error-color d-none">
                Select team member role</span>
            </div>
          </div>

          <span class="devider px-0 text-center hide-me"></span>

          <div class="col-xl-3 col-md-6 mt-3 mr-4">
            <div class="selectdiv ">
              <select id="drpTeamMemberPermissions_{{i}}" name="TeamMemberPermission_{{i}}"
                class="form-control dropdown rounded-0">
                <option value="-1">Select permissions</option>
                <option id="drpTeamMemberPermissionsOptions_{{i}}" *ngFor="let permission of teamMemberPermissions"
                  [value]=permission.id>
                  {{permission.typeValue}}
                </option>
              </select>
            </div>
            <div>
              <span id="errorLabelMemberPermission{{i}}" class="label error-color d-none">
                Select team member permission</span>
            </div>
          </div>
          <div class="pl-0 delRow mt-3">
            <a id="removeLink_{{i}}" (click)="removeTeamMember(i,teamMemberRoleName)" style="line-height: 38px;">
              <img src="assets/images/EDP/Engagement/remove_circle_outline.svg" alt="Remove Team Member">
            </a>
          </div>
          <div class="col-xl-5"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mx-0 py-2">
    <div class="col-md-3 mw-100 pl-0">
      <div class="bg-white">
        <button class="btn add-teammember-button" type="button" id="addGTTeamMemberCo" (click)="addTeamMember()">
          <img src="assets/images/EDP/Engagement/add_box-blue.svg" alt="Add new team member(s)">
          <span class="d-inline-block pl-2"> Add team member </span>
        </button>
      </div>
    </div>
  </div>
</div>

<div>
  <div id="engagementDetailHeaderDiv" class="row col-md-12 mt-4">
    <h6 class="form-heading-text">Change order details</h6>
  </div>
  <div>
    <span class="d-inline-block align-middle msg-text">
      There are no automatic feeds to oneView or CMS. If this change order impacts financial metrics,update oneView and
      CMS accordingly.
    </span>
  </div>
  <div class="col-md-12 bg-white pl-0 pr-0 pb-3">
    <div class="row">
      <div class="col-xl-6 col-md-6 pr-0 pt-3">
        <label><span class="label">Change order name</span> <span class="label mandatory">*</span></label>
        <input id="changeOrderName" name="changeOrderName" class="edp-font-14 input-height border form-control rounded-0 " maxlength="100"
          type="text"  placeholder="{{PlaceholderText}}" (focus)="this.PlaceholderText=''"
          (blur)="this.PlaceholderText = 'Enter...'"  autocomplete="off" [(ngModel)]="ChangeOrderName" />
          <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" 
          *ngIf="!isChangeOrderNameFilled">
          <span>Change order name is a required field.</span>
        </div>
      </div>
      <div class="col-xl-6 col-md-6 pr-0 pt-3">
        <label><span class="label">Select change order template </span> <span class="label mandatory">*</span></label>
        <div class="selectdiv ">
          <select id="drpchangeOrderTemplate" name="ChangeOrderTemplate" class="dropdown rounded-0"
            (focus)="onTemplateChange()" [(ngModel)]="selectedChangeOrderTemplate">
            <option value="Select...">Select...</option>
            <option *ngFor="let template of SelectedTemplateList" [value]="template.templatePackageId">
              {{ template.templateName }}
            </option>
          </select>
        </div>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" 
          *ngIf="!isChangeorderTemplateSelected">
          <span>Change order template is a required field.</span>
        </div>

      </div>

    </div>
  </div>
  <div class="mt-1">
    <div class="">
      <label for="financialUpdate">Does this change order involve financial updates?</label>
      <div id="financialUpdate">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="yes" name="financialUpdate" class="custom-control-input" [(ngModel)]="financialUpdate"
            [value]="true">
          <label class="custom-control-label" for="yes">Yes</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="no" name="financialUpdate" class="custom-control-input" [(ngModel)]="financialUpdate"
            [value]="false">
          <label class="custom-control-label" for="no">No</label>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="row" *ngIf="financialUpdate">
      <div class="col-xl-4 col-md-6 pr-0 pt-3 periodnew">
        <label for="EstimatedGM">Estimated completion date <span class="mandatory">*</span></label>
        <app-calendercontrol id="EstimateCompletionDate" [txtDate]="startDateCtrlId" class="eng-period-date">
        </app-calendercontrol>
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" 
        *ngIf="!isEstimationDateFilled">
        <span>Estimated completion date is a required field.</span>
      </div>
      </div>
      <div class="col-xl-4 col-md-6 pr-0 pt-3">
        <label><span class="label">Incremental fees </span> <span class="label mandatory">*</span></label>
        <input id="txtnetFees" name="netFees" class=" edp-font-14 border input-height form-control rounded-0 " maxlength="9" type="text"
          placeholder="{{PlaceholderText}}" (focus)="this.PlaceholderText=''"
         (blur)="this.PlaceholderText = 'Enter...'"  pattern="^[0-9]*([.,][0-9]{0,2})?$" (input)="onInput($event)" autocomplete="off" [(ngModel)]="IncrementalFees">
          <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" 
          *ngIf="!isIncrementalFeesFilled">
          <span>Incremental fees is a required field.</span>
        </div>
      </div>
      <div class="col-xl-4 col-md-6 pr-0 pt-3">
        <label for="EstimatedGM">Estimated gross margin % <span class="mandatory">*</span></label>
        <input id="txtGrossMargin" name="txtGrossMargin" class="edp-font-14 border input-height form-control rounded-0 " maxlength="3"
          type="text" placeholder="{{PlaceholderText}}" (focus)="this.PlaceholderText=''"
          (blur)="this.PlaceholderText = 'Enter...'" autocomplete="off"  [(ngModel)]="Grossmargin" />
        <div id="mandatoryMessageForAdministratorsDiv" class="form-control-feedback error-color" 
          *ngIf="!isGrossMarginFilled">
          <span>Estimated gross margin is a required field.</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0">&nbsp;</div>
  <div class="row m-0 text-right">
    <div class="col-xl-3 hide-me">&nbsp;</div>
    <div class="col-xl-3 col-md-4 pl-0"></div>
    <div class="col-xl-3 col-md-4">
      <button class="btn SecondaryButton w-100 text-center" type="button" (click)="setCancelDialog()">
        <span class="d-inline-block align-middle"> Cancel</span>
      </button>
    </div>
    <div class="col-xl-3 col-md-4">
      <button class="btn PrimaryButton w-100 text-center" id="btnNext" type="button" (click)="onCreateChangeOrder()">
        Create change order
        <img class="d-inline-block ml-1" src="../../../assets/images/icons/arrow_forward.svg"
          style="cursor: pointer; margin-bottom: 3px; width: 18px; height: 18px;" alt="Next" />
      </button>
    </div>
  </div>

  <!-- add team memmber popup Start -->

  <div id="modal-TeamMember" class="modal fade modal-window" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">Select team member</span>
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="deselectTeamMember();">
            <span class="close-text mr-1">Close </span>
            <span class="modal-close-btn">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <span id="TeamMemberErrorMessage" class="ErrorFont"></span>
          <div class="input-group nav-searchbox-length pb-2">
            <input type="text" id="searchTeamMemberName"
              (keydown.enter)="searchUsers('searchTeamMemberName','tblMemberAD');"
              class="form-control py-1 rounded-0 nav-seachBox" placeholder="Search for team member" autocomplete="off">
            <span class="input-group-append nav-seachBox">
              <div class="input-group-text" (click)="searchUsers('searchTeamMemberName','tblMemberAD');">

                <img src="../../../assets/images/EDP/Filters/search.svg" alt="Search" class="nav-searchIcon" />
              </div>
            </span>
          </div>
          <span class="span-hint mt-1">Hint: Last name, first name, or email.</span>
          <div class="tableScroll mt-2">
            <table id="tblMemberAD" class="table  table-hover" aria-describedby="Members">
              <thead>
                <tr>
                  <th scope="col"> Name</th>
                  <th scope="col"> Email </th>
                  <th scope="col">Job Title</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Line of Business</th>
                  <th scope="col">Service Line</th>
                  <th scope="col">Location</th>
                </tr>
              </thead>
              <tbody>
                <tr id="trTeamMembers" name="trTeamMembers"
                  *ngFor="let member of membersListArrayForTeamMembers let i=index"
                  (click)='selectTeamMember(i,member)'>
                  <td id="TeamMemberList{{i}}">
                    <span id='spMemberdisplayName{{i}}'>{{member.displayName}}</span>
                  </td>
                  <td id="TeamMemberList{{i}}"> <span id='spMembermail{{i}}'>{{member.mail}}</span>
                  </td>
                  <td>
                    <span id='spjobtitle{{i}}'>{{member.jobTitle}}</span>
                  </td>
                  <td id="TeamMemberList{{i}}"> <span id='spMemberbusinessPhones{{i}}'>{{member.businessPhones}}</span>
                  </td>
                  <td> <span id='splineOfBusiness{{i}}'>{{member.lineOfBusiness}}</span>
                  </td>
                  <td> <span id='spserviceLine{{i}}'>{{member.serviceLine}}</span>
                  </td>
                  <td> <span id='splocation{{i}}'>{{member.location}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="SecondaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
            (click)="deselectTeamMember();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/cancel-blue.svg">
            </span>
            <span class="d-inline-block align-middle">Cancel</span>
          </button>
          <button type="button" class="PrimaryButton" style="width: 150px; text-align: center;" data-dismiss="modal"
            (click)="setTeamMember();">
            <span class="d-inline-block mr-1 align-top">
              <img alt="OK" src="../../../../assets/images/EDP/MessageDialog/delete-white.svg">
            </span>
            <span class="d-inline-block align-middle">OK</span>
          </button>
        </div>
      </div>
    </div>
  </div>


  <!-- add team memmber popup End -->