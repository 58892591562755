import { Component, OnInit, ElementRef, ViewChild, OnDestroy, AfterViewInit, SecurityContext, HostListener, ViewContainerRef, ComponentFactoryResolver, Renderer2 } from '@angular/core';
import { ClientService } from '../../services/clientservice';
import { AdUsersService } from 'src/app/services/adusers.service';
import { EngagementService } from '../../services/EngagementService';
import { SecondarySolutionCode } from 'src/app/model/Engagement/SecondarySolutionCodes';
import { EngagementAssignment, RemoveEngagementAssignmentModel, UpdatePreRequestAssignment } from 'src/app/model/Engagement/EngagementAssignments';
import { TeamMember, AssignmentTeamMember, ValidationTeamMember } from 'src/app/model/Engagement/TeamMember';
import { Engagements, EngagementDialogTitles, EngagementStatus, EngagementStatusRequestModel, AssignmentCodeStatus, DataModificationModuleNames, EngagementCollabData, MemberFirmEngagementPrefillDataRequestModel, MemberFirmEngagementPrefillData } from 'src/app/model/Engagement/Engagements';
import { NewEngagementData, EngagementAssignmentWithoutSCode, PrimaryWorkspaceModel } from 'src/app/model/Engagement/CreateEngagementData';
import { AzureADGroups, AzureADUsersArrayList, SearchGroupUserViewModel } from 'src/app/model/AzureADUsersArrayList';
import { User } from 'src/app/model/User';
import { Alert } from 'src/app/model/Alert';
import { AlertsService } from '../../services/alerts.service';
import { DataService } from 'src/app/services/data.service';
import { Router, ActivatedRoute, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { TeamMemberRole, EnumTeamMemberRoleCodes, EnumKeyTeamMemberRoleCodes } from 'src/app/model/Engagement/TeamMemberRole';
import { EnumRoles, RevievingProfessionalType, RoleNames } from 'src/app/model/Role';
import { AppConfigService } from 'src/app/services/appConfigService';
import { CMSClient, NewClient } from 'src/app/model/Client/NewClient';
import { CreateClientViewModel, OneViewClientOpportunityViewModel, OneViewClientOpportunityViewModelWrapper, OpportunityServiceList, OpportunityServiceMiniModel, OpportunityServiceModel, OpportunityServiceRequestModel, OpportunityServiceRequestModelByEngagementId, SearchOpportunityByEngagementIdRequestModel, SearchOpportunityByOpportunityIdRequestModel, SearchOpportunityByRequestModel } from 'src/app/model/Client/CreateClientViewModel';
import { ClientSmartSearchComponent } from 'src/app/common/component/client-smart-search/client-smart-search.component';
import { TemplateMappingModel } from 'src/app/model/TemplateMappingModel';
import { ClientMemberPermission, ClientMemberPermissionModel, TeamMemberPermission } from 'src/app/model/Engagement/TeamMemberPermission';
import { ApplicationMasterService } from 'src/app/services/application-master.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { KeyValue } from 'src/app/model/KeyValue';
import { BooleanKeyValue } from 'src/app/model/BooleanKeyValue';
import { FieldValidator } from 'src/app/model/Engagement/FieldValidator';
import { WorkspaceDocumentModel } from 'src/app/model/WorkspaceDocumentModel';
import { HDWorkItemDocumentModel, HDWorkItemModel } from 'src/app/model/HDWorkItemModel';
import { HotDocsService } from 'src/app/services/HotDocsService';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { Applicationmaster, AppCodes } from 'src/app/model/ApplicationMaster';
import { forkJoin, of, Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { MembersList } from 'src/app/model/MembersList';
import { WorkspaceModel, WorkspaceInterviewsStatus } from 'src/app/model/WorkspaceModel';
import { DatePipe } from '@angular/common';
import { ContactTypes } from 'src/app/model/Client/ContactTypes';
import { ClientLocation } from 'src/app/model/Client/ClientLocation';
import { LogService } from 'src/app/services/log.service';
import { ClientBillingContact } from 'src/app/model/Client/ClientBillingContact';
import { SharepointSiteModel, SPSiteMetadata, AvepointResponseModel, DecisionsTemplateRequestModel, SiteInputRequest, DecisionsTemplateResponseModel, AvepointResponseListModel, DecisionTemplate } from 'src/app/model/SharePoiint/SharePointSite';
import { SharepointService } from 'src/app/services/sharepoint.service';
import { Employee } from 'src/app/model/Workspace/Employee';
import { ReacceptanceClientsService } from 'src/app/services/reacceptanceClientsService';
import { ContinuanceStatus, ContinuanceEngagementWorkItemModel } from 'src/app/model/ReacceptanceClientListModel';
import { MappingTemplatePackageModel } from 'src/app/model/TemplatePackageModel';
import { ADUser, UpdatePermissionsModel, UpdatePermissionsModelData, UsersListEmailModel } from 'src/app/model/SharePoiint/ActiveDirectory';
import { ActiveDirectoryService } from 'src/app/services/activedirectory.service';
import { ClientLevelDocument, DocumentSubTypesEnum } from 'src/app/model/Client/ClientLevelDocument';
import { TemplateMaintenanceService } from 'src/app/services/template-maintenance.service';
import { DecisionsRPListModel } from 'src/app/model/Workspace/DecisionsRPListModel';
import { DecisionsRequestTypes } from 'src/app/model/Workspace/DecisionsApiLogModel';
import { SharePointMetadataMapping } from '../../model/sharepoint-metadata-mapping';
import { DomSanitizer } from '@angular/platform-browser';
import { DecisionsRuleEngineService } from 'src/app/services/Integration/decisions-rule-engine.service';
import { LoadingScreenService } from 'src/app/services/loading-screen.service';
import { AssignmentRequestList } from 'src/app/model/Workspace/AssignmentRequestList';
import { AssignmentResponseList } from 'src/app/model/Workspace/AssignmentResponse';
import { CMSService } from 'src/app/services/cmsservice.service';
import { GraphService } from 'src/app/services/Integration/graphservice.service';
import { SearchEmployeeModel } from 'src/app/model/Workspace/SearchEmployeeModel';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { DataModificationService } from 'src/app/services/datamodification.service';
import { ApiRequest } from 'src/app/model/Common/api-request.model';
import { ClientTeamMembers } from 'src/app/model/Engagement/ClientTeamMember';
import { ADClientSiteUsersSyncEngageClientMmembers } from 'src/app/model/CollaborationSite';
import { EngageEvent } from 'src/app/model/Engagement/EngageEvent';
import { PowerAutomateRequestModel, TeamMemberEPModel, TeamMembers } from 'src/app/model/Workspace/TeamMembers';
import { ReviewNoteSearchableUser } from 'src/app/model/WorkspaceReviewPoint';
import { WorkspaceReviewPointService } from 'src/app/services/workspaceReviewPointService';
import { ReviewNotesComponent } from '../review/review-notes/review-notes.component';
import { BaseConeveneProjectInitiationModel, ConveneGetProjetcsById, ConveneProjectInsertModel, ConveneProjectsDetails } from 'src/app/model/Engagement/Convene/ConveneBaseModel';
import { EngagementPrerequestPopupComponent } from '../engagement/engagement-prerequest-popups/engagement-prerequest-popup/engagement-prerequest-popup.component';
import { DataModification } from 'src/app/model/data-modification-model';
import { InterchangeableSolutionCode } from 'src/app/model/Engagement/InterchangeableSolutioncodes';
import { CLMService } from 'src/app/services/clm.service';
import { GoverningAgreemenRequest } from 'src/app/model/CLM/governing-agreemen-request';
import { GoverningAgreementResponse } from 'src/app/model/CLM/governing-agreement-response';
import { EditClientViewModel } from 'src/app/model/Client/EditClientViewModel';
import { EditClient } from 'src/app/model/Client/EditClient';
import { MemberFirmService } from 'src/app/services/member-firm.service';
import { MemberFirmData } from 'src/app/model/MemberFirm/member-firm-data';
import { EngagementLinkRequest } from 'src/app/model/CLM/engagement-link-request';
import { CLMResponse } from 'src/app/model/CLM/clm-response';
import { CLMDetails } from 'src/app/model/CLM/clm-details';
import { CLMApiLog } from 'src/app/model/CLM/clm-api-log';
import { CLMApiLogrRequestType } from 'src/app/model/enums/clm-api-log-request-type.enum';
import { EngagementStatusViewModel } from 'src/app/model/Engagement/engagement-status-view-model';
import { ClientConsentRequest } from 'src/app/model/Client/client-consent-request';
import { ClientConsent } from 'src/app/model/Client/client-consent';

declare var $;
declare function clmInitComponent(): any;
declare function handleRadioSelection(): any;
@Component({
  selector: 'app-initiate-engagement',
  templateUrl: './initiate-engagement.component.html',
  styleUrls: ['./initiate-engagement.component.css']
})
export class InitiateEngagementComponent implements OnInit, OnDestroy, AfterViewInit {
  // people picker rolename input variables declaration
  epRoleName: string;
  teamMemberRoleName: string;
  assignAdminRoleName: string;
  eplblCtrlName: string;
  cmslblCtrlName: string;
  teamMemberlblCtrlName: string;
  assignAdminlblCtrlName: string;
  peoplepickerIndex: number = 0;
  //secondarySolutionCodeList: SecondarySolutionCode[] = [];
  secondarySolutionCode: SecondarySolutionCode[] = [];
  solutionCodeListData: SecondarySolutionCode[] = [];
  clientOpportunityList: OneViewClientOpportunityViewModel[] = [];
  oneViewClientOpportunityViewModelWrapper: OneViewClientOpportunityViewModelWrapper = new OneViewClientOpportunityViewModelWrapper();
  validateOpportunityService: boolean = false;
  isPageLoadOnInitiatingContinuanceEngagement: boolean = false;
  EPListarray: AzureADUsersArrayList;
  assignAdminList: AzureADUsersArrayList;
  azureADUsersArrayList: AzureADUsersArrayList = new AzureADUsersArrayList();
  membersListArray: MembersList[];
  membersListArrayForTeamMembers: MembersList[];
  membersListArrayForEP: MembersList[];
  public engagementAssignmentList: Array<EngagementAssignment> = [];
  public engagementAssignmentWithoutSCode: Array<EngagementAssignmentWithoutSCode> = [];
  newEngAssignment: any = {};
  teamMemberList: Array<TeamMember> = [];
  newTeamMember: any = {};
  testnewTeamMember: any = {};
  user: User;
  alert: Alert;
  engagement: Engagements = new Engagements();
  engagementCollabData: EngagementCollabData = new EngagementCollabData();
  createEngagement: NewEngagementData = new NewEngagementData();
  engagementCount: number = 0;
  discussionandReviewPointsCount: number;
  adminNameCtrl: string;
  teamMemberCtrl: string;
  teamMemberEmailCtrl: string;
  currentAssignmentRowIndex: number;
  currentTeamMemberIndex: number;
  EpCtrl: string;
  currentEPIndex: number;
  baseUrl: string;
  currentClientId: string;
  teamMemberRoleList: TeamMemberRole[] = [];
  currentEngagementId: number;
  EditMode: boolean = false;
  engagementAssignmentLimit: number;
  teamMemberLimit: number;
  MappedValue: string;
  SolutionCodeMappedValue: string;
  TeamMemberMappedValue: string;
  isRedirectedFromReacceptance: string;
  selectedclientName: string;
  selectedclientCode: string;
  selectedSolutionCode: string;
  selectedclientShortName: string = null;
  isCShortNameAlter: boolean = false;
  yearEndDateLabel: string;
  beforChangeEngagementName: string;
  checkEngagementExists: boolean = false;
  cmsClientDetails: CMSClient = new CMSClient();
  createClientViewModel: CreateClientViewModel = new CreateClientViewModel();
  client: NewClient = new NewClient();
  searchUserFound: boolean = false;
  maxEngagementNameLimit: any = 25;
  TemplateList: MappingTemplatePackageModel[] = [];
  documentCategory: string = 'Create New Engagement';
  defaultEngAssignment: EngagementAssignment = new EngagementAssignment();
  ActualEngAssignment: EngagementAssignment = new EngagementAssignment();
  isTeamDetailsVisible = false;
  teamMemberPermissions: TeamMemberPermission[] = [];
  assignmentTeamMemberList: AssignmentTeamMember[] = [];
  newAssignmentTeamMember: AssignmentTeamMember = new AssignmentTeamMember();
  newAssignTeamMember: any = {};
  defaultSecondarySolutionCode: any = {};
  @ViewChild(ClientSmartSearchComponent, { static: false })
  private clientSearch: ClientSmartSearchComponent;
  engtypekeyValueList: KeyValue[] = [];
  contingentkeyValueList: BooleanKeyValue[] = [];
  strdefaultcontingentkeyValues: any = {};
  source: string;
  departmentType: string
  arrSecondarySolutionCode: SecondarySolutionCode[] = [];
  arrControlValidation: FieldValidator[] = [];
  strControlValidation: any = {};
  strActualControlValidation: any = {};
  public arrEngagementAssignment: Array<EngagementAssignment>;
  CMSEPCtrl: string;
  ASAdminCtrl: string;
  EQCRCtrl: string;
  IsValidInput: boolean = false;
  cmsDataMatch = false;
  EditAssignMode: boolean = false;
  objectArrdefaultEngAssignment: [string, any][];
  objectArray: [string, any][];
  //isRemovebuttonVisible:boolean =false;
  SelectedTemp: TemplateMappingModel = new TemplateMappingModel();
  engTeamaMembeListrWithoutAssignment: Array<TeamMember> = [];
  interchangeableSolutionCodes: InterchangeableSolutionCode[] = [];
  opportunityId: string;
  opportunityServiceId: string;

  WorkspaceCode: number;
  WorkspaceData: PrimaryWorkspaceModel = new PrimaryWorkspaceModel();
  AddWorkspaceDocumentList: WorkspaceDocumentModel[] = [];
  WorkItemDetails: HDWorkItemModel = new HDWorkItemModel();
  OldWorkItemCreatedDetails: HDWorkItemModel = new HDWorkItemModel();
  currentEngagementName: string = '';
  originalEngagementName: string = '';
  isPeriodRequire: boolean = false;

  isvalidcmsEPPMDName: boolean = true;
  isvalidAassignmentAdministrator: boolean = true;
  isPPMDUserError: boolean = false;
  
  canEnableSaveAssignmentBtnOnValidcmsPPMDCheck: boolean = true;
  canEnableSaveAssignmentBtnOnValidAACheck: boolean = true;

  UserObject: any;
  isEmptyEPPMD: boolean = false;
  isEmptyAdmin: boolean = false;
  newAssignmentObject: any = {};
  HiddenValidInputAdminCtrlName: string;

  arrValidationForEngagement: FieldValidator[] = [];
  newEngTeamMember: any = {};
  validRealization: boolean = true;
  RealizationMessage: string = '';
  validNetfees: boolean = true;
  netFeesMessage: string = '';
  isValidPeriodDate: boolean = false;
  isValidClient: boolean = true;
  isvalidEngEPPMDName: boolean = true;
  isvalidEngAAdministrator: boolean = true;
  arrValidationForNewTeamMember: ValidationTeamMember[] = [];
  private modalNewEngDialogRef: Subscription = null;
  isSaveBtnClicked: boolean = false;
  isVisibleNewAssignmentDIV = false;
  isVisibledefaultAssignmentDiv = true;
  editAssignmentDivId: string;
  isVisibleEditAssignmentDiv = false;
  applicationmasterList: Applicationmaster[] = [];
  applicationmasterListIE: Applicationmaster[] = [];
  teamMemberRoleFromAppMstList: Applicationmaster[] = []
  teamMemberRoleCodes: AppCodes[] = [];
  keyteamMemberRoleFromAppMstList: Applicationmaster[] = []
  keyTeamMemberRoleCodes: AppCodes[] = [];
  newAssignmentData: any = {};
  CurrentInterviewsStatus: any;
  WorkspaceObject: WorkspaceModel = new WorkspaceModel();
  CurrentWorkspaceId: string;
  EditAssignmentIndex: number = 0;
  IsClientAutoPopulate: boolean;
  CurrentWorkspaceStatus: string;

  validateEP: boolean;
  selectedEPIndex: string;
  validateAA: boolean;
  selectedAAIndex: string;
  validateTM: boolean[] = new Array(15);
  selectedTMIndex: string;
  selectedTM: any
  validateCMSEP: boolean = false;
  validateCMSAA: boolean;
  selectedCMSEPIndex: string;
  selectedCMSAAIndex: string;
  oldEngagementData: Engagements = new Engagements();
  isAgreementTypeChanged: boolean = false;
  isEngagementNameChanged: boolean = false;
  isSolutionCodeChanged: boolean = false;
  engPeriodDateCtrlId: string;
  isContinuanceEngagement: boolean = false;
  continuanceEngHD: string = 'Continuance';
  duplicateAssignmentName: boolean = false;
  StartDateCtrlId: string;
  EndDateCtrlId: string;
  ClientContactTypes: ContactTypes[] = [];
  ClientLocations: ClientLocation[] = [];
  defaultClientBillingContact: ClientBillingContact;
  spProvisionEmailIds: any = {};
  sharepointSiteModel: SharepointSiteModel = new SharepointSiteModel();
  SPSiteMetadataArr: SPSiteMetadata[] = [];
  SPSiteMetadataModel: SPSiteMetadata = new SPSiteMetadata();
  AvepointResponseData: AvepointResponseModel = new AvepointResponseModel();
  departmentList: KeyValue[] = [];

  employeeList: Employee[] = [];
  AssignmentCodePending: string = AssignmentCodeStatus.Pending;
  editedAssignmentOldData: EngagementAssignment = new EngagementAssignment();
  DateCompareResult: number;
  validEstimatedGrossMargin: boolean;
  estimatedGrossMarginMessage: string;
  AssignmentCodeRequested: string = AssignmentCodeStatus.RequestSent;
  continuanceWorkItemData: ContinuanceEngagementWorkItemModel = new ContinuanceEngagementWorkItemModel();
  isTemplateMappingFound: boolean = true;
  clientLevelDocuments: ClientLevelDocument[] = [];
  subDocumentTypes: Applicationmaster[] = [];
  isDocPresentElsewhere = false;
  isEffectiveDateEmpty = false;
  isExpriationDateEmpty = false;
  msaEffectiveDateCntrlId = "msaEffectiveDate";
  msaExpirationDateCntrlId = "msaExpirationDate";
  isInvalidMSAEffectiveDate = false;
  isInvalidMSAExpirationDate = false;
  clientLevelDocument: ClientLevelDocument = new ClientLevelDocument();
  SelectedDocumentSubTypeValue: string;
  SelectedDocumentSubType: string;
  selectedCLMRadioButtonValue: string;
  isParentRadioButtonChecked: boolean = false;
  SelectedClientLevelDocId: number;
  DocumentExistsElsewhere = DocumentSubTypesEnum.ExistsElsewhere;
  DocumentNoOptionExists = DocumentSubTypesEnum.NoOptionExists;
  newTemplateData: MappingTemplatePackageModel = new MappingTemplatePackageModel();
  SelectedTemplateList: MappingTemplatePackageModel[] = [];
  isByPass = false;
  UpdatedclientLevelDocument: ClientLevelDocument = new ClientLevelDocument();
  isInvalidSubDocType = false;
  epDisplayUserName = '';
  epDisplayUserEmail = '';
  cmsDisplayUserName = '';
  cmsDisplayUserEmail = '';
  adminDisplayUserName = '';
  adminDisplayUserEmail = '';
  isAssignmentNameExistForClient: boolean = false;
  isValidClientBillingEmail = true;
  localClientCode: string;
  selectContactEmailId: string;
  controlPopoverId: string;
  validAssignmentName = true;
  isUserAgentIE: number;
  redirectUrl: string[] = [];
  notStartedStatus = EngagementStatus.EngNotStarted;
  isAssignmentNotPresent = false;
  isAssignmentNameNotPresent = false;
  validEngagementName = true;
  defaultSelectedsolutionCode: SecondarySolutionCode = new SecondarySolutionCode();
  EQCRRoleName: string;
  EQCRlblCtrlName: string;
  validateEQCR: boolean;
  selectedEQCRIndex: string;
  EQCRNameCtrl: string;
  EQCRDisplayUserName = '';
  EQCRDisplayUserEmail = '';
  isvalidEQCR: boolean = true;
  isValidEQCRBusinessRule: boolean = true;
  isSolutionCodeChangedForDocumentSelection: boolean = false;
  GTAgreementOwnerRoleName: string;
  GTAgreementOwnerlblCtrlName: string;
  validateGTAgreementOwner: boolean;
  selectedGTAgreementOwnerIndex: string;
  GTAgreementOwnerNameCtrl: string;
  isvalidGTAgreementOwner: boolean = true;
  GTAgreementOwnerCtrl: string;
  CustomMSACode: string = DocumentSubTypesEnum.CustomMSACode;
  CreateWorkItemData: HDWorkItemModel = new HDWorkItemModel();
  createNewWorkItem: boolean = false;
  selectedPreRequestForContinuanceValue: string = 'false';
  EngagementSitRequestErrorText: string = EngagementStatus.EngSiteError;
  cmsCodeRequestError: boolean;
  isCMSPreRequestSent: boolean = false;
  isAssignmentFinanceDataEditableBlank: boolean;
  isRemoveCarryForwardedAssignmentClicked: boolean = false;
  isSubmitAssignmentClicked: boolean = false;
  invalidvalues: string;
  invalidvaluesAN: string;
  isvalidESD: boolean = true;
  isvalidEED: boolean = true;
  isvalidPED: boolean = true;
  isvalidEFD: boolean = true;
  isvalidEXD: boolean = true;
  isEPMatchEQCR: boolean = false;
  UnmodifiedEngagementAssignmentData: EngagementAssignment[] = [];
  originalParentSolutionCodeId: number = 0;
  isLoggedInUserSystemAdmin: boolean = false;
  isValidClientBillingName: boolean = true;
  clientMemberPermissions: ClientMemberPermission[] = [];
  clientMemberPermissionModelList: ClientMemberPermissionModel[] = [];
  cmsDataMatchClient: boolean;
  IsValidInputClient: boolean;
  adClientSiteUsersSyncEngageClientMmembers: ADClientSiteUsersSyncEngageClientMmembers = new ADClientSiteUsersSyncEngageClientMmembers();
  isSiteProvisioned: boolean = false;
  oldEqcrName: string = '';
  oldEqcrEmailId: string = '';

  IsUserTM: boolean = false;
  isPureNotificationOnlyProfessional: boolean = false;
  IsUserRP: boolean = false;
  IsUserEP: boolean;
  isConveneIntegrationFlagEnabled: boolean = false;
  engagePreRequestPopupComponentRef: any;
  isPreRequestPopupConfirmed: boolean = false;
  isContinuanceEngExistsPopupOpen: boolean = false;
  isKeyTeamMemberFeatureFlag: boolean;
  existingGACheck: boolean = false;
  engagementNamePlaceholderText: string = "Enter an engagement name";
  skipRedirect: boolean = false;
  //Get Assignment Code status
  public get AssignmentCodeStatus(): typeof AssignmentCodeStatus {
    return AssignmentCodeStatus;
  }

  public get EngagementStatus(): typeof EngagementStatus {
    return EngagementStatus;
  }
  lblCollaborationSiteName: string = 'Awaiting name';
  @ViewChild(NgForm, { static: false }) engagementForm: NgForm;

  unsavedAlertfeature_RequestedNavigationUrl: string = '';
  unsavedAlertfeature_AnyFormFieldChanged: boolean = false;
  isRedirectedFromCreateDoc: boolean;
  unsavedAlertfeature_InitialEngagementObject: Engagements;
  unsavedAlertfeature_IsSaveChangesOrNextBtnClicked: boolean = false;
  unsavedAlertfeature_Component = this;
  unsavedAlertfeature_DefaultAssignmentValues: EngagementAssignment = null;

  reviewInProgressEditEngagementMembers: string = "";
  dataModificationDetails: DataModification = new DataModification();

  isCurrentEngagementIsIndus: boolean = false;
  carryForwadINDUSConsent: boolean = false;
  newClientTeamMemberList: Array<ClientTeamMembers> = [];
  arrValidationForNewClientTeamMember: any = [];
  clientTeamMembersList: Array<ClientTeamMembers> = [];
  newClientTeamMember: any = {};
  defaultClientPermissionId: number = 0;

  //Review note 
  reviewNoteSearchableUsers: ReviewNoteSearchableUser[] = [];
  reviewNoteSearchableUsersFromQualityGroups: ReviewNoteSearchableUser[] = [];
  teamsMembersList: TeamMemberEPModel = new TeamMemberEPModel();
  reviewerList: TeamMembers[] = [];
  reviewNoteEngComponentRef: any;
  workspaceOpenReviewPointsCount: number;
  memberList: TeamMembers[] = [];
  isOpportunityServiceFeatureFlagEnabled: boolean = false;
  selectedsolutionCodeWithDesc: string;
  noOpportunityMsg: string = '';
  primarySelectedSolutionCode: SecondarySolutionCode = new SecondarySolutionCode();
  clientOneViewAccountId: string;
  blankLineAssignmentName: string = '_______________';
  isAssignmentMandatoryFieldsBlank: boolean = false;
  baseConeveneProjectInitiationModel: BaseConeveneProjectInitiationModel = new BaseConeveneProjectInitiationModel();
  conveneProjectDetailsResponse: ConveneProjectsDetails[] = [];
  continuanceEngagementCount: number = 0;
  continuanceDashBoardUrl: string = '/Continuance';
  isvalidCMSEP: boolean = false;
  isvalidAA: boolean = false;
  userDetails: any = {};
  isMemberFirm: boolean = false;
  memberFirmEngagementPrefillData: MemberFirmEngagementPrefillData = new MemberFirmEngagementPrefillData();
  memberFirmData: MemberFirmData;


  IsUserAccessPermission: boolean = false;
  IsMember: boolean = false;
  isQualityUserLoggedIn: boolean = false;
  @ViewChild('clmGoverningAgreementDiv') clmGoverningAgreementDiv: ElementRef;
  clmGoverningAgreementUIState: string = "PAGE_LOAD";
  isCLMFeatureEnabled: boolean = false;
  governingAgreementResponse: GoverningAgreementResponse;
  governingAgreemenRequest: GoverningAgreemenRequest;
  engagementLinkRequest: EngagementLinkRequest;
  linkCLMResponse: CLMResponse;
  isBigScreen: boolean;

  clientConsent: ClientConsent;
  isClientConsentFeatureEnabled: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isBigScreen = window.innerWidth > 1900;
  }
  isAudit: boolean = false;

  constructor(private _clientService: ClientService, private _engService: EngagementService,
    private _alertService: AlertsService, private loadingScreenService: LoadingScreenService
    , private dataService: DataService, private router: Router, private activatedRoute: ActivatedRoute
    , private adUsersService: AdUsersService, private appService: AppConfigService
    , private el: ElementRef,
    private renderer: Renderer2,
    private _configService: ConfigurationService,
    private applicationMasterService: ApplicationMasterService,
    private _hdService: HotDocsService,
    private _workspaceService: WorkspaceService,
    private logService: LogService,
    private datePipe: DatePipe,
    private _sharepointService: SharepointService,
    private _reacceptanceService: ReacceptanceClientsService,
    private _activedirectoryService: ActiveDirectoryService,
    private templateMaintenanceService: TemplateMaintenanceService,
    private domSanitizer: DomSanitizer,
    private decisionRuleEngineService: DecisionsRuleEngineService,
    private workspaceService: WorkspaceService,
    private cmsService: CMSService,
    private graphSerivce: GraphService,
    private dataModification: DataModificationService,
    private vrf: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private workspaceReviewPointService: WorkspaceReviewPointService,
    private clmService: CLMService,
    private memberFirmService: MemberFirmService) {
    this.isBigScreen = window.innerWidth > 1900;
    this.engagementAssignmentList = [];
    this.arrEngagementAssignment = [];
    this.arrControlValidation = [];
    this.engagementAssignmentLimit = this.appService.config.DATA.ENGAGEMENT_ASSIGNMENT_ADMINISTRATOR_LIMIT;
    this.teamMemberLimit = this.appService.config.DATA.TEAM_MEMEBR_LIMIT;
    this.SolutionCodeMappedValue = 'InitiateEngagementSolutionCode';
    this.spProvisionEmailIds = {
      primaryAdmin: this.appService.config.SP_EMAIL_PROVISION.PRIMARY_ADMIN,
      primaryContact: this.appService.config.SP_EMAIL_PROVISION.PRIMARY_CONTACT,
      secondaryContact: this.appService.config.SP_EMAIL_PROVISION.SECONDARY_CONTACT
    }
    const IEversion = localStorage.getItem('isUserAgentIE');
    this.isUserAgentIE = JSON.parse(IEversion);
    this.MappedValue = 'InitiateEngagement';
    this.SolutionCodeMappedValue = 'InitiateEngagementSolutionCode';
    this.TeamMemberMappedValue = 'InitiateEngagementTeamMemberRole';
    this.yearEndDateLabel = 'Fiscal Year End';
    this.source = 'EngagementType';
    this.departmentType = 'Departments';
    const newMemberList: TeamMember[] = [];
    this.newAssignmentTeamMember = {
      assignmentName: '',
      solutionCodeName: '',
      teamMemberList: newMemberList,
      isAssignmentChecked: false,
      isTeamDetailsVisible: false

    };
    this.assignmentTeamMemberList.push(this.newAssignmentTeamMember);
    console.log('team member list' + this.assignmentTeamMemberList);
    const jsonString = localStorage.getItem('currentUser');
    this.UserObject = JSON.parse(jsonString);
    this.IsClientAutoPopulate = false;
    this.validateEP = false;
    this.validateAA = false;
    this.validEstimatedGrossMargin = true;
    this.estimatedGrossMarginMessage = '';
    this.isConveneIntegrationFlagEnabled = false;
  }

  changeDate() {
    setTimeout(() => {
      // this.getEngagementNameOnFYEDateChange();
      const taxYear = $('.engPeriodDate').val();
      this.engagement.taxYear = taxYear;
    }, 500);

  }

  ngOnDestroy() {
    this.ResetIwantToUnsavedFlag();
    this.UpdateDataModification(this.currentEngagementId);
    $('#txtValue' + this.MappedValue + 0).val('');
    $('#hdnKeyCode' + this.MappedValue + 0).val('');
    $('#hdnKey' + this.MappedValue + 0).val(0);
    this.IsClientAutoPopulate = false;
    this._alertService.remove(this.alert);
    this.dataService.setClientIdForEngagement('');
    this.dataService.setClientNameForEngagement('');
    this.dataService.setIsRedirectedFromInitiateReacceptance('false');
    this.modalNewEngDialogRef?.unsubscribe();
    this.reviewNoteEngComponentRef = null;
    this.engagePreRequestPopupComponentRef = null;
  }

  ngOnInit() {

    this._alertService.remove(this.alert);
    this.displayClientOption('hide');
    this.epRoleName = RoleNames.EngagementPartner.toString();
    this.teamMemberRoleName = RoleNames.GTUSINDUSALL.toString();
    this.assignAdminRoleName = RoleNames.AssignmentAdministrator.toString();
    this.eplblCtrlName = 'engagement PPMD';
    this.cmslblCtrlName = 'CMS PPMD';
    this.teamMemberlblCtrlName = 'team member';
    this.assignAdminlblCtrlName = 'assignment administrator';
    this.EQCRRoleName = RoleNames.AssignmentEQCR.toString();
    this.EQCRlblCtrlName = 'assignment EQCR';
    this.GTAgreementOwnerRoleName = RoleNames.GTAgreementOwner;
    this.GTAgreementOwnerlblCtrlName = 'GT agreement owner';
    this._engService.GetOpportunityServiceFeatureFlag().subscribe(result => {
      this.isOpportunityServiceFeatureFlagEnabled = result;
    });
    this.clmService.getCLMFeatureFlag().subscribe(result => {
      this.isCLMFeatureEnabled = result;
    });
    this._clientService.getClientConsentFeatureFlag().subscribe(result => {
      this.isClientConsentFeatureEnabled = result;
    });

    // this.getTemplateMappingData();
    this.MandatoryFieldValidationForEngagement();
    this.activatedRoute.paramMap.subscribe(params => {
      this.currentEngagementId = +params.get('engagementId');
    });
    if (this.currentEngagementId > 0) {
      this.EditMode = true;
      this.getWorkspaceReviewPointsCount();
      this.redirectToCorrectEngagementState(this.currentEngagementId);
    }
    this.getClientMemberPermissions();

    $('#myDatePicker').datepicker({
      format: 'mm/dd/yyyy',
      autoclose: true,
      startDate: new Date(),
      orientation: 'bottom auto',
      onSelect: function () {
        this.changeDate();
      }
    });
    this.GetTeamMemberPermissions();
    this.getTeamMemberRoles();
    this.getTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and team member addition
    this.getKeyTeamMemberRoleList(); // from appmaster getting all field values to compare on UI and Key team member addition
    this.GetContactTypes();
    this.GetClientLocations();
    this.bindObjectInitialization(this.source);
    this.CheckLoggedinUserSystemAdminRole();
    this.baseUrl = this._clientService.getAppEndPoint();

    this.StartDateCtrlId = 'EstimatedStartDate';
    this.EndDateCtrlId = 'EstimatedEndDate';

    if (!this.EditMode) {
      //disable agreement type dropdown on page load only for create mode
      $('#engAgreementType').attr('disabled', true);
      this.IsUserAccessPermission = true;
      this.dataService.currentClientId.subscribe(clientId => this.currentClientId = clientId);
      let retrievedClientName = '';
      this.dataService.currentClientName.subscribe(clientName => retrievedClientName = clientName);
      let isRedirectedFromCreateClient = '';
      this.dataService.lastRedirectedFromCreateClient.subscribe(isReturnFromCreateClient => isReturnFromCreateClient = isRedirectedFromCreateClient);
      setTimeout(() => {
        if (parseInt(this.currentClientId, 10) > 0) {
          this.engagement.clientId = parseInt(this.currentClientId, 10);
          if (retrievedClientName !== null || retrievedClientName !== undefined &&
            isRedirectedFromCreateClient !== '' && isRedirectedFromCreateClient === 'true') {
            this.engagement.clientName = retrievedClientName;
            $('#txtValue' + this.MappedValue + 0).val(this.engagement.clientName);
            $('#hdnKey' + this.MappedValue + 0).val(this.engagement.clientId);
            this.GetClientDetailsById(this.engagement.clientId);
            this.IsClientAutoPopulate = true;
            const clientId = $('#hdnKey' + this.MappedValue + 0).val();
            if (clientId.length > 0) {
              this.getDefaultClientBillingContactDetails(clientId);
            }
          }
        }
      }, 200);

      setTimeout(() => {
        this.setIsMemberFirm();
        if (this.isMemberFirm) {
          this.GetMemberFirmEngagementPrefillData();
        }
      }, 1000);


    } else if (this.EditMode) {
      setTimeout(() => {
        this.CheckUserIsWorkspaceMember(this.currentEngagementId);
      }, 200);
    }


    $('#currency1').inputmask();
    $('#currency2').inputmask();

    this.modalNewEngDialogRef = this.dataService.getModalDetails$.subscribe(
      dialogData => {
        if (dialogData) {
          let data = JSON.parse(dialogData)
          let dailogTitle = data.dialogTitle;
          let btnName = data.dialogButtonName;
          if (dailogTitle === EngagementDialogTitles.UnsavedChangesConfirmation) {
            if (btnName === 'ok') {
              this.router.navigate([this.unsavedAlertfeature_RequestedNavigationUrl]);
            }
            else if (btnName === 'cancel' || btnName === 'close') {
              this.unsavedAlertfeature_RequestedNavigationUrl = '';
            }
            this.ResetIwantToUnsavedFlag();
          } else if ((dailogTitle === WorkspaceInterviewsStatus.ReviewStatus) &&
            btnName === 'ok') {
            this.router.navigate(['MyEngagements']);
          } else if (dailogTitle === EngagementDialogTitles.RemoveAssignment &&
            btnName === 'ok' && this.isRemoveCarryForwardedAssignmentClicked) {
            this.isRemoveCarryForwardedAssignmentClicked = false;
            this.ProcessRemoveCarryForwardedAssignment();
          } else if (dailogTitle === EngagementDialogTitles.SubmitAssignment && btnName === 'ok'
            && this.isSubmitAssignmentClicked) {
            this.isSubmitAssignmentClicked = false;
            this.ProcessPreRequestAssignmentCode();
          } else if (dailogTitle === EngagementDialogTitles.ContinuanceEngExists && btnName === 'ok') {
            if (this.isOpportunityServiceFeatureFlagEnabled)
              this.isContinuanceEngExistsPopupOpen = true;
            else
              this.isContinuanceEngExistsPopupOpen = false;
            this.OpenDefaultAssignmentBox();
          } else if (dailogTitle === EngagementDialogTitles.InterChangeableSolutionCodes) {
            if (btnName === 'ok')
              this.OpportunityServiceClick(this.opportunityId, this.opportunityServiceId, false, 0, 0, false);
            else
              this.OpportunityServiceClick(this.opportunityId, this.opportunityServiceId, true, 0, 0, false);
          }
        }
      });

    this.engPeriodDateCtrlId = 'engPeriodDate';
    for (let index = 0; index < this.validateTM.length; index++) {
      this.validateTM[index] = false;
    }

    this.GetSubDocumentTypeTypes();
    this.dataService.isRedirectedFromReacceptance.subscribe(item => this.isRedirectedFromReacceptance = item);

    this.setUnsavedChangesForIwantTo();
    this._engService.GetKeyTeamMemberFeatureFlag().subscribe(result => {
      this.isKeyTeamMemberFeatureFlag = result;
      console.log('isKeyTeamMemberFeatureFlag' + this.isKeyTeamMemberFeatureFlag);
    });
  } // end init
  CheckUserIsWorkspaceMember(engagementId: number) {
    if (this.UserObject != null) {
      this.CheckLoggedinUserSystemAdminRole();
      return this._engService.CheckUserIsMember(engagementId, this.UserObject.loggedInUserEmail).subscribe(member => {
        if (member) {
          this.IsMember = member;
        }
        this.IsUserAccessPermission = (this.IsMember ? true :
          (this.isLoggedInUserSystemAdmin ? true :
            (this.isQualityUserLoggedIn ? true : false)));
        //this.IsUserAccessPermission=false;
        if (this.IsUserAccessPermission) {
          this.IsInProgressModification(this.currentEngagementId);
          this.StoreDataModification(this.currentEngagementId);
          setTimeout(() => {
            this.getEngagementDetailsById(this.currentEngagementId);
          }, 200);
        }

        else if (!this.IsUserAccessPermission) {

          this._alertService.add('You do not have access to view details !', 'danger', null);
        }
        console.log('IsUserAccessPermission:' + this.IsUserAccessPermission);
      }, (error) => {
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
      });



    }

  }
  CheckLoggedinUserSystemAdminRole() {
    console.log('userObject ==>', this.UserObject);
    if (this.UserObject != null && this.UserObject.role != null && this.UserObject.role.length > 0) {
      for (let i = 0; i < this.UserObject.role.length; i++) {
        if (this.UserObject.role[i].shortRoleName === 'SA') {
          this.isLoggedInUserSystemAdmin = true;
        }
        if (this.UserObject.role[i].shortRoleName === 'QG') {
          this.isQualityUserLoggedIn = true;
        }
      } //End of For  
    }// End of If
  }
  RedirectOnCancel() {
    switch (this.engagement.status) {
      case WorkspaceInterviewsStatus.NotStarted:
        this.router.navigate(['MyEngagements']);
        break;
      case WorkspaceInterviewsStatus.DraftSession:
        this.router.navigate(['Interviews/' + this.engagement.workspaceId]);
        break;
      case EngagementStatus.EngInReview:
        this.router.navigate(['Review/' + this.engagement.workspaceId]);
        break;
      default:
        this.router.navigate(['MyEngagements']);
        break;
    }
  }
  ProcessPreRequestAssignmentCode() {
    this.loadingScreenService.startLoading();
    //First update assignment at DB then send request for code
    this.defaultEngAssignment.engagementId = this.engagement.engagementId;
    let updateAssignmentData: UpdatePreRequestAssignment = new UpdatePreRequestAssignment();
    updateAssignmentData.assignment = this.defaultEngAssignment;
    updateAssignmentData.preRequestForContinuance = this.engagement.preRequestForContinuance;
    updateAssignmentData.loggedInUserName = this.UserObject.loggedInUserFullName;
    updateAssignmentData.assignment.status = 'Update';
    this._engService.UpdateEngagementAssignment(updateAssignmentData).subscribe(updatedAssignmentCodeId => {
      if (updatedAssignmentCodeId != null && updatedAssignmentCodeId != undefined && updatedAssignmentCodeId > 0) {
        this.RequestAssignmentCode(this.WorkspaceObject.workspaceId, updatedAssignmentCodeId, this.defaultEngAssignment.assignmentName);
      }
    }, (error) => {
      this.loadingScreenService.stopLoading();
      this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
    });
  }

  ProcessRemoveCarryForwardedAssignment() {
    this.loadingScreenService.startLoading();
    let removeEngagementAssignmentModel: RemoveEngagementAssignmentModel = new RemoveEngagementAssignmentModel();
    removeEngagementAssignmentModel.engagementId = this.engagement.engagementId;
    removeEngagementAssignmentModel.assignmentCodeId = this.editedAssignmentOldData.assignmentCodeId;
    removeEngagementAssignmentModel.loggedInUser = this.UserObject.loggedInUserEmail;
    removeEngagementAssignmentModel.loggedInUserName = this.UserObject.loggedInUserFullName;
    this._engService.RemoveEngagementAssignment(removeEngagementAssignmentModel).subscribe(
      deleteResult => {
        let index = this.arrEngagementAssignment.findIndex(x => x.assignmentCodeId == this.editedAssignmentOldData.assignmentCodeId);
        this.RemoveSelectedAssignment(index);
        this.loadingScreenService.stopLoading();
      }, (error) => {
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
      });
  }

  defaultSetting() {
    this.showDefaultAssignmentBox();
  }

  bindObjectInitialization(source: string) {

    this.strdefaultcontingentkeyValues = [
      {
        key: true,
        value: 'Yes'
      },
      {
        key: false,
        value: 'No'
      }
    ];
    this.contingentkeyValueList = this.strdefaultcontingentkeyValues;

    this._configService.GetListBySource(source).subscribe(
      result => {
        this.engtypekeyValueList = result;
        console.log('GetListBySource == >', this.engtypekeyValueList);
        if (!this.EditMode) {
          setTimeout(() => {
            this.objectInitialization();
            //this.defaultSetting();
          }, 200);
        }
      },
      error => {
        this.scrollToTop();
      }
    );

    this._configService.GetListBySource(this.departmentType).subscribe(
      result => {
        this.departmentList = result;

      },
      error => {
        this.scrollToTop();
      }
    );


  }

  mandatoryFieldInitialization() {
    this.isValidClientBillingEmail = true;
    this.arrControlValidation = [];
    this.arrControlValidation.push(new FieldValidator('assignmentName', this.defaultEngAssignment.assignmentName, false));
    // if (this.defaultEngAssignment.selectedsolutionCode != undefined) {
    //   this.arrControlValidation.push(new FieldValidator('selectedsolutionCode',
    //     this.defaultEngAssignment.selectedsolutionCode.childSolutionCodeId.toString(), false));
    // } else {
    this.arrControlValidation.push(new FieldValidator('solutionCodeId', this.defaultEngAssignment.solutionCodeId, false));
    //}
    this.arrControlValidation.push(new FieldValidator('selectedengagementType',
      this.defaultEngAssignment.selectedengagementType.value, false));
    this.arrControlValidation.push(new FieldValidator('realization', this.defaultEngAssignment.realization, false));
    this.arrControlValidation.push(new FieldValidator('netFees', this.defaultEngAssignment.netFees, false));
    this.arrControlValidation.push(new FieldValidator('isContingent', this.defaultEngAssignment.isContingent, false));
    this.arrControlValidation.push(new FieldValidator('cmsEPPMDName', this.defaultEngAssignment.cmsEPPMDName, false));
    this.arrControlValidation.push(new FieldValidator('assignmentAdministrator', this.defaultEngAssignment.assignmentAdministrator, false));
    this.arrControlValidation.push(new FieldValidator('clientBillingContactName', this.defaultEngAssignment.clientBillingContactName, false));
    this.arrControlValidation.push(new FieldValidator('clientBillingContactTitleId', this.defaultEngAssignment.clientBillingContactTitleId, false));
    this.arrControlValidation.push(new FieldValidator('clientBillingContactEmail', this.defaultEngAssignment.clientBillingContactEmail, false));
    this.arrControlValidation.push(new FieldValidator('estimatedGrossMargin', this.defaultEngAssignment.estimatedGrossMargin, false));
    this.arrControlValidation.push(new FieldValidator('engagementStartDate', this.defaultEngAssignment.engagementStartDate, false));
    this.arrControlValidation.push(new FieldValidator('engagementEndDate', this.defaultEngAssignment.engagementEndDate, false));
  }

  objectInitialization() {

    const sc = new SecondarySolutionCode();
    sc.secondarySolutionCodeId = 0;
    sc.parentSolutionCodeId = 0;
    sc.childSolutionCodeId = 0;
    sc.solutionCode = '';
    sc.solutionCodeDescription = '';
    sc.solutionCodeLongName = '';
    sc.status = 'A';
    sc.isAudit = false;
    sc.createdBy = null;
    sc.createdDate = new Date();
    sc.lastModifiedBy = null;
    sc.lastModifiedDate = null;
    sc.isGrossMarginRequire = false;

    this.arrSecondarySolutionCode.push(sc);

    //azureADUsersArrayList: AzureADUsersArrayList = new AzureADUsersArrayList();
    this.defaultSelectedsolutionCode = this.arrSecondarySolutionCode[0]; //new SecondarySolutionCode();
    const defaultSelectedengagementType = new KeyValue();
    defaultSelectedengagementType.key = '';
    defaultSelectedengagementType.value = '';

    this.localClientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();

    this.defaultEngAssignment = {
      solutionCodeListData: this.arrSecondarySolutionCode,
      iscontingentData: this.contingentkeyValueList,
      engagementTypeData: this.engtypekeyValueList,
      selectedsolutionCode: this.defaultSelectedsolutionCode,
      selectedengagementType: defaultSelectedengagementType,
      assignmentCodeId: 0,
      engagementId: 0,
      solutionCodeId: null,
      assignmentName: '',
      realization: null,
      netFees: null,
      createdBy: '',
      createdDate: new Date(),
      lastModifiedBy: '',
      lastModifiedDate: new Date(),
      //assignmentCode: '',
      assignmentAdministrator: '',
      adminEmailId: '',
      adminContactNo: '',
      cmsEPPMDName: '',
      cmsEPPMDEmailId: '',
      engagementTypeId: '0',
      isContingent: null,
      isPrimaryAssignment: false,
      netFeesInCurrencyFormat: '',
      collaborationSiteId: 0,
      engagementStartDate: null,
      engagementEndDate: null,
      officeLocationCodeId: 0,
      estimatedGrossMargin: null,
      clientBillingContactName: '',
      clientBillingContactTitleId: 0,
      clientBillingContactEmail: '',
      collaborationSiteName: '',
      officeLocationCodeName: '',
      estimatedGrossMarginInCurrencyFormat: '',
      clientBillingContactTitle: '',
      assignmentCode: null,
      clientCode: this.localClientCode.length > 0 ? this.localClientCode : null,
      assignmentCodeStatus: '',
      isContinuanceCarryFrwdAssignment: false,
      solutionCodeOnSigature: false,
      priorYearAssignmentName: '',
      isGrossMarginRequire: false,
      eqcrName: '',
      eqcrEmailId: '',
      isEditEQCR: false,
      newEQCRName: '',
      newEQCREmailId: '',
      isvalidEQCR: true,
      isValidEQCRBusinessRule: true,
      isEQCRExists: false,
      isAssignmentFinanceDataEditable: 'null',
      solutionCode: '',
      solutionCodeDescription: '',
      isEngagementStartDateRequired: false,
      isEngagementEndDateRequired: false,
      opportunityServiceId: null,
      status: null
      //opportunityServiceOptOut: true
    };
    this.ActualEngAssignment = JSON.parse(JSON.stringify(this.defaultEngAssignment));

    this.mandatoryFieldInitialization();
    this.controlInitialization();
  }

  prefilENName() {
    let rowId = this.arrEngagementAssignment.length + 1;
    if (this.arrEngagementAssignment != null && rowId === 1 && !this.isContinuanceEngagement) {
      this.defaultEngAssignment.assignmentName = this.engagement.engagementName;
      this.invalidCharacterDetectionAssignmentName();
    }
  }
  controlInitialization() {
    this.isvalidcmsEPPMDName = true;
    this.isvalidAassignmentAdministrator = true;
    this.isvalidEQCR = true;
    this.isValidEQCRBusinessRule = true;
    this.isValidClientBillingEmail = true;
    this.isRemoveCarryForwardedAssignmentClicked = false;
    this.isSubmitAssignmentClicked = false;
    this.defaultEngAssignment.isContinuanceCarryFrwdAssignment = false;
    //set default true to make finance fields editable for all new assignments
    this.defaultEngAssignment.isAssignmentFinanceDataEditable = 'true';
    this.defaultEngAssignment.cmsEPPMDName = $('#txtPeoplePicker_EP_0').val();
    this.defaultEngAssignment.cmsEPPMDEmailId = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
    $('#hdnValidInput_AS_AA_0').val(false);
    //$('#drpsolutionCodeId').attr('disabled', true);
    if (this.arrEngagementAssignment != null && this.arrEngagementAssignment.length >= 1) {
      this.defaultEngAssignment.eqcrName = this.arrEngagementAssignment[0].eqcrName ? this.arrEngagementAssignment[0].eqcrName : '';
      this.defaultEngAssignment.eqcrEmailId = this.arrEngagementAssignment[0].eqcrEmailId ? this.arrEngagementAssignment[0].eqcrEmailId : '';
      this.setValidEQCRValue();
    }
    if (this.EditMode) {
      this.defaultEngAssignment.assignmentName = '';
      this.defaultEngAssignment.assignmentCodeId = 0;
      this.defaultEngAssignment.isPrimaryAssignment = false;
      this.defaultEngAssignment.netFees = null;
      this.defaultEngAssignment.realization = null;
      this.defaultEngAssignment.engagementTypeId = '0';
      this.defaultEngAssignment.isContingent = null;
      this.defaultEngAssignment.adminContactNo = '';
      this.defaultEngAssignment.assignmentAdministrator = '';
      this.defaultEngAssignment.engagementStartDate = null;
      this.defaultEngAssignment.engagementEndDate = null;
      this.defaultEngAssignment.clientBillingContactName = '';
      this.defaultEngAssignment.clientBillingContactEmail = '';
      this.defaultEngAssignment.clientBillingContactTitleId = 0;
      this.defaultEngAssignment.officeLocationCodeId = 0;
      this.defaultEngAssignment.estimatedGrossMargin = null;
      this.defaultEngAssignment.solutionCodeId = this.arrEngagementAssignment.length > 0 ? null : this.defaultEngAssignment.selectedsolutionCode.childSolutionCodeId
      const defaultSelectedengagementType = new KeyValue();
      defaultSelectedengagementType.key = '';
      defaultSelectedengagementType.value = '';
      this.defaultEngAssignment.selectedengagementType = defaultSelectedengagementType;
    }
    else {
      this.defaultEngAssignment.solutionCodeId = this.arrEngagementAssignment.length > 0 ? null : this.defaultEngAssignment.selectedsolutionCode.childSolutionCodeId;
    }

    setTimeout(() => {
      $('.EstimatedStartDate').val(this.defaultEngAssignment.engagementStartDate);
      $('.EstimatedEndDate').val(this.defaultEngAssignment.engagementEndDate);
    }, 100);

    this.prefilENName();
    console.log('add new assignment==>', this.defaultEngAssignment);
    this.onEngagementAssignmentsSolutionCodeChange();
    this.setClientBillingContactDetails();
  }

  setValidEQCRValue() {
    if (this.defaultEngAssignment.eqcrEmailId != '' && this.defaultEngAssignment.eqcrEmailId != null &&
      this.defaultEngAssignment.eqcrName != '' && this.defaultEngAssignment.eqcrName != null) {
      this.validateEQCR = true;
    } else {
      this.validateEQCR = false;
      this.defaultEngAssignment.eqcrEmailId = '';
      this.defaultEngAssignment.eqcrName = '';
    }
  }
  getUpdatedValue(id: string) {
    let retval: any = '';

    this.objectArray.forEach(([key, Keyvalue]) => {
      if (key == id) {
        switch (key) {
          case 'selectedengagementType':
            retval = Keyvalue.value;
            break;
          case 'selectedsolutionCode':
            retval = Keyvalue.childSolutionCodeId;
            break;
          case 'solutionCodeId':
            if (Keyvalue == null) {
              retval = Keyvalue;
            } else {
              retval = Keyvalue.value
            }
            break;
          case 'isContingent':
            if (Keyvalue == null) {
              retval = Keyvalue;
            } else {
              retval = Boolean(Keyvalue);
            }
            break;
          default:
            retval = Keyvalue;
            break;
        }
      }
    });
    return retval;
  }

  stringCompare(field1: string, field2: string) {
    let returnVal: boolean = false;

    if (field1 == field2) {
      returnVal = true;
    }
    return returnVal;
  }


  checkRealizationValue(str) {
    const regex = /^((0|[0-9]\d?)|100?)$/gm;
    if (regex.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  checkNumericValue(str) {
    const regex = new RegExp('^[0-9]+(\.[0-9]{1,4})?$');
    if (regex.test(str)) {
      return true;
    } else {
      return false;
    }
  }

  showHideErrorMessageDiv(flag: boolean) {
    const div = 'businessMessageForAdministratorsDiv';
    if (flag) {
      if ($('#' + div).is(':hidden')) {
        $('#' + div).show();
      }
    } else {
      if ($('#' + div).is(':visible')) {
        $('#' + div).hide();
      }
    }
  }

  checkCustomValidation() {
    if (this.defaultEngAssignment.isContingent && parseInt(this.defaultEngAssignment.netFees.toString(), 10) != 0) {
      this.validNetfees = false;
      this.netFeesMessage = 'Estimated net fees should be 0 for contingent assignment';
    } else {
      this.validNetfees = true;
      this.netFeesMessage = '';
    }
  }

  checkBusinessValidations() {
    let result: boolean = false;
    this.checkCustomValidation();

    if (this.checkRealizationValue(this.defaultEngAssignment.realization)) {
      this.validRealization = true;
      this.RealizationMessage = '';
    } else {
      this.validRealization = false;
      this.RealizationMessage = 'Invalid realization value';
    }

    if (this.validNetfees) {
      this.defaultEngAssignment.netFees = parseInt(this.defaultEngAssignment.netFees.toString().replace(',', ''));
      if (this.checkNumericValue(this.defaultEngAssignment.netFees)) {
        this.validNetfees = true;
        this.netFeesMessage = '';
      } else {
        this.validNetfees = false;
        this.netFeesMessage = 'Invalid estimated net fees value';
      }
    }

    if (this.validEstimatedGrossMargin) {
      console.log('insdie validation', this.defaultEngAssignment.estimatedGrossMargin);
      if (this.defaultEngAssignment.estimatedGrossMargin === null ||
        this.defaultEngAssignment.estimatedGrossMargin.toString().length === 0 ||
        this.checkRealizationValue(this.defaultEngAssignment.estimatedGrossMargin)) {
        this.validEstimatedGrossMargin = true;
        this.estimatedGrossMarginMessage = '';
      }
      else {
        this.validEstimatedGrossMargin = false;
        this.estimatedGrossMarginMessage = 'Invalid estimated gross margin value';
      }
    }

    // 1 cmsEPPMDName and assignmentAdministrator should not be the same
    if (this.stringCompare(this.defaultEngAssignment.cmsEPPMDName, this.defaultEngAssignment.assignmentAdministrator)) {
      this.showHideErrorMessageDiv(true);
      result = false;
    } else {
      this.showHideErrorMessageDiv(false);
      result = true;
    }

    // 2. EPPMDName and  AassignmentAdministrator should be valid and with Azure AD
    if (result) {
      this.isvalidcmsEPPMDName = Boolean(JSON.parse($('#hdnValidInput_AS_EP_0').val()));
      this.isvalidAassignmentAdministrator = Boolean(JSON.parse($('#hdnValidInput_AS_AA_0').val()));

      if ((!this.isvalidcmsEPPMDName) ||
        (!this.isvalidAassignmentAdministrator)
      ) {
        result = false;
      } else {
        result = true;
      }
    }

    if ((result !== this.validRealization) || (result !== this.validNetfees) || (result !== this.validEstimatedGrossMargin)) {
      result = false;
    }

    this.DateCompareResult = this.compareDate(new Date(this.defaultEngAssignment.engagementStartDate), new Date(this.defaultEngAssignment.engagementEndDate));

    if (this.DateCompareResult === 0 || this.DateCompareResult > 0) {
      result = false;
    }

    //3. Check for duplicate Assignment Name
    for (let i = 0; i < this.arrEngagementAssignment.length; i++) {
      if (this.EditAssignmentIndex - 1 != i
        && this.defaultEngAssignment.assignmentName.toLowerCase() === this.arrEngagementAssignment[i].assignmentName.toLowerCase()) {
        result = false;
        this.duplicateAssignmentName = true;
        break;
      }
    }
    if (!this.validAssignmentName) {
      result = false;
    }

    //4.IF EQCR then check its valid and is not same as CMSEP nad AA
    if (this.defaultEngAssignment.eqcrName != '' && this.defaultEngAssignment.eqcrName != null &&
      this.defaultEngAssignment.eqcrName != undefined) {
      this.isvalidEQCR = Boolean(JSON.parse($('#hdnValidInput_AS_EQCR_0').val()));

      if (!this.isvalidEQCR) {
        result = false;
      }
      let EPPMDName = $('#txtPeoplePicker_EP_0').val();

      if (this.stringCompare(EPPMDName, this.defaultEngAssignment.eqcrName) ||
        this.stringCompare(this.defaultEngAssignment.cmsEPPMDName, this.defaultEngAssignment.eqcrName) ||
        this.stringCompare(this.defaultEngAssignment.assignmentAdministrator, this.defaultEngAssignment.eqcrName)) {
        this.isValidEQCRBusinessRule = false;
        result = false;
      }
    }

    return result;
  }

  // date comparison method
  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second.
   * -1 if the first date is less than second.
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
 */
  private compareDate(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1); let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }

  checkMandatoryValidations(fieldValidationArray, modalValidateArray, callingFrom: string = '') {
    let retVal: boolean = true;
    // console.log('checkMandatoryValidations func called', fieldValidationArray, modalValidateArray);
    this.objectArray = Object.entries(modalValidateArray);
    for (let i = 0; i < fieldValidationArray.length; i++) {

      fieldValidationArray[i].setvalues(fieldValidationArray[i].fieldId,
        this.getUpdatedValue(fieldValidationArray[i].fieldId), true);
    }

    if (callingFrom === 'engagementAssignment' && fieldValidationArray.length > 3) {  //This is only for Assignmnent
      if (fieldValidationArray[3].fieldId == 'realization' && (fieldValidationArray[3].fieldValue === '0' || fieldValidationArray[3].fieldValue === 0)) {
        fieldValidationArray[3].isvalid = true;
      }
      if (fieldValidationArray[4].fieldId == 'netFees' && (fieldValidationArray[4].fieldValue === '0' || fieldValidationArray[4].fieldValue === 0)) {
        fieldValidationArray[4].isvalid = true;
      }

      if (this.defaultEngAssignment.selectedsolutionCode.isGrossMarginRequire) {
        if (fieldValidationArray[11].fieldId == 'estimatedGrossMargin'
          && (fieldValidationArray[11].fieldValue === null ||
            fieldValidationArray[11].fieldValue === '')) {

          this.arrControlValidation[11].isvalid = false;
          this.arrControlValidation[11].apply = true;
        }
        else {
          this.arrControlValidation[11].isvalid = true;
          this.arrControlValidation[11].apply = true;
        }
      } else {
        this.arrControlValidation[11].isvalid = true;
        this.arrControlValidation[11].apply = true;
      }
      //set engagement start and end date mandatory flag
      if (this.defaultEngAssignment.selectedsolutionCode.isEngagementStartDateRequired) {
        if (fieldValidationArray[12].fieldId == 'engagementStartDate'
          && (fieldValidationArray[12].fieldValue === null ||
            fieldValidationArray[12].fieldValue === undefined ||
            fieldValidationArray[12].fieldValue === '')) {

          this.arrControlValidation[12].isvalid = false;
          this.arrControlValidation[12].apply = true;
        }
        else {
          this.arrControlValidation[12].isvalid = true;
          this.arrControlValidation[12].apply = true;
        }
      } else {
        this.arrControlValidation[12].isvalid = true;
        this.arrControlValidation[12].apply = true;
      }
      if (this.defaultEngAssignment.selectedsolutionCode.isEngagementEndDateRequired) {
        if (fieldValidationArray[13].fieldId == 'engagementEndDate'
          && (fieldValidationArray[13].fieldValue === null ||
            fieldValidationArray[13].fieldValue === undefined ||
            fieldValidationArray[13].fieldValue === '')) {

          this.arrControlValidation[13].isvalid = false;
          this.arrControlValidation[13].apply = true;
        }
        else {
          this.arrControlValidation[13].isvalid = true;
          this.arrControlValidation[13].apply = true;
        }
      } else {
        this.arrControlValidation[13].isvalid = true;
        this.arrControlValidation[13].apply = true;
      }

      //Set Finance data true for pre request Continuance engagment if budget is not approved
      if (this.isContinuanceEngagement && this.engagement.preRequestForContinuance && !this.defaultEngAssignment.budgetApproved) {
        if (fieldValidationArray[3].fieldId == 'realization') {
          fieldValidationArray[3].isvalid = true;
        }
        if (fieldValidationArray[4].fieldId == 'netFees') {
          fieldValidationArray[4].isvalid = true;
        }
        if (fieldValidationArray[5].fieldId == 'isContingent') {
          fieldValidationArray[5].isvalid = true;
        }
        if (fieldValidationArray[11].fieldId == 'estimatedGrossMargin') {
          fieldValidationArray[11].isvalid = true;
        }
      }

      if (this.isContinuanceEngagement && this.defaultEngAssignment.assignmentCodeStatus != this.AssignmentCodePending && this.defaultEngAssignment.isContinuanceCarryFrwdAssignment) {
        if (fieldValidationArray[12].fieldId == 'engagementStartDate') {
          fieldValidationArray[12].isvalid = true;
        }
        if (fieldValidationArray[13].fieldId == 'engagementEndDate') {
          fieldValidationArray[13].isvalid = true;
        }
      }
    }

    for (let i = 0; i < fieldValidationArray.length; i++) {
      if (!fieldValidationArray[i].isvalid) {
        if (this.isMemberFirm && (fieldValidationArray[i].fieldId == 'clientBillingContactName' || fieldValidationArray[i].fieldId == 'clientBillingContactTitleId' || fieldValidationArray[i].fieldId == 'clientBillingContactEmail'))
          break;
        retVal = false;
        break;
      }
    }

    // console.log('after checkMandatoryValidations func called', fieldValidationArray, modalValidateArray);
    return retVal;
  }

  empty(e) {
    switch (e) {
      case '':
      case 0:
      case '0':
      case null:
      case false:
      case typeof (e) == 'undefined':
        return true;
      default:
        return false;
    }
  }

  setAssignmentValues() {
    this.validRealization = true;
    this.validNetfees = false;
    //this.validEstimatedGrossMargin=false;
    this.netFeesMessage = '';
    this.RealizationMessage = '';

    this.defaultEngAssignment.solutionCodeId = $('#drpsolutionCodeId').val();
    const selectedengagementTypeId: string = $('#drpengagementTypeId').val();
    if (this.defaultEngAssignment.isContingent !== null) {
      this.defaultEngAssignment.isContingent = this.getBoolean(this.defaultEngAssignment.isContingent);
    }

    // this.defaultEngAssignment.cmsEPPMDName = $('#txtPeoplePicker_AS_EP_0').val();
    // this.defaultEngAssignment.cmsEPPMDEmailId = $('#UserHiddenProps_AS_EP_0 #hdnUserEmail').val();    
    // this.defaultEngAssignment.assignmentAdministrator = $('#txtPeoplePicker_AS_AA_0').val();
    // this.defaultEngAssignment.adminEmailId = $('#UserHiddenProps_AS_AA_0 #hdnUserEmail').val();
    // this.defaultEngAssignment.adminContactNo = $('#UserHiddenProps_AS_AA_0 #hdnUserPhone').val();

    this.defaultEngAssignment.cmsEPPMDName = this.userDetails?.CMSEP?.displayName;
    this.defaultEngAssignment.cmsEPPMDEmailId = this.userDetails?.CMSEP?.mail;
    console.log('CMSEP Name :' + this.defaultEngAssignment.cmsEPPMDName + ' CMSEP Email :' + this.defaultEngAssignment.cmsEPPMDEmailId);
    this.defaultEngAssignment.assignmentAdministrator = this.userDetails?.AA?.displayName;
    this.defaultEngAssignment.adminEmailId = this.userDetails?.AA?.mail;
    this.defaultEngAssignment.adminContactNo = this.userDetails?.AA?.businessPhones;
    console.log('AA Name :' + this.defaultEngAssignment.assignmentAdministrator + ' AA Email :' + this.defaultEngAssignment.adminEmailId);

    this.defaultEngAssignment.engagementStartDate = $('.EstimatedStartDate').val();
    this.defaultEngAssignment.engagementEndDate = $('.EstimatedEndDate').val();
    this.defaultEngAssignment.officeLocationCodeId = $('#ddClosestGTOffice').val();
    let locationName = $('#ddClosestGTOffice option:selected').text();
    this.defaultEngAssignment.officeLocationCodeName = locationName === 'Select closest GT office' ? '' : locationName;
    this.defaultEngAssignment.clientBillingContactTitleId = $('#ddClientBillingTitle').val();
    this.defaultEngAssignment.clientBillingContactTitle = $('#ddClientBillingTitle option:selected').text();

    this.defaultEngAssignment.eqcrName = $('#txtPeoplePicker_AS_EQCR_0').val();
    this.defaultEngAssignment.eqcrEmailId = $('#UserHiddenProps_AS_EQCR_0 #hdnUserEmail').val();

    if (!this.empty(this.defaultEngAssignment.solutionCodeId)) {
      this.defaultEngAssignment.selectedsolutionCode =
        this.defaultEngAssignment.solutionCodeListData.find(element =>
          element.childSolutionCodeId == this.defaultEngAssignment.solutionCodeId);

      //to set solution code details for display
      this.defaultEngAssignment.solutionCode = this.defaultEngAssignment.selectedsolutionCode?.solutionCode;
      this.defaultEngAssignment.solutionCodeDescription = this.defaultEngAssignment.selectedsolutionCode?.solutionCodeDescription;
    } else {
      this.defaultEngAssignment.solutionCodeId = null;
    }

    if (!this.empty(selectedengagementTypeId)) {
      this.defaultEngAssignment.selectedengagementType =
        this.defaultEngAssignment.engagementTypeData.find(element => element.key == selectedengagementTypeId);
    }
    if (this.defaultEngAssignment.eqcrEmailId != '' && this.defaultEngAssignment.eqcrEmailId != null &&
      this.defaultEngAssignment.eqcrName != '' && this.defaultEngAssignment.eqcrName != null) {
      this.validateEQCR = true;
    } else {
      this.defaultEngAssignment.eqcrEmailId = '';
      this.defaultEngAssignment.eqcrName = '';
      this.validateEQCR = false;
    }
    //set Assignment buget approved data 
  }

  saveAssignment(assignmentrowid: string) {
    if (this.EditMode) {
      this.mandatoryFieldInitialization();
    }
    let isValidEPPMD: boolean = true;
    this.isvalidEED = true;
    this.isvalidESD = true;
    this.duplicateAssignmentName = false;
    this.isValidClientBillingEmail = true;
    this.isValidEQCRBusinessRule = true;
    this.DateCompareResult = null;
    this.isAssignmentFinanceDataEditableBlank = false;
    this.isSubmitAssignmentClicked = false;
    this.isValidClientBillingName = true;
    this.setAssignmentValues();
    // isValidEPPMD = Boolean(JSON.parse($('#hdnValidInput_EP_0').val()));  
    let EPPMDName = $('#txtPeoplePicker_EP_0').val();
    let EPPMDEmail = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
    console.log('saveAssignment func called ', this.defaultEngAssignment);
    this.validateDate(this.defaultEngAssignment.engagementStartDate, 'ESD');
    this.validateDate(this.defaultEngAssignment.engagementEndDate, 'EED');
    if (this.defaultEngAssignment.cmsEPPMDName === EPPMDName)
      this.defaultEngAssignment.cmsEPPMDEmailId = EPPMDEmail;
    if (EPPMDEmail.length === 0 || EPPMDName.length === 0) {
      isValidEPPMD = false;
      if (this.isSaveBtnClicked) {
        $('.epMandatoryCheckForAssignment').removeClass('d-none');
        $('.epMandatoryCheckForAssignment').addClass('d-block');
      } else {
        this.arrValidationForEngagement[3].isvalid = false;
        this.arrValidationForEngagement[3].apply = true;
      }
    } else {
      isValidEPPMD = true;
      if (this.isSaveBtnClicked) {
        $('.epMandatoryCheckForAssignment').removeClass('d-block');
        $('.epMandatoryCheckForAssignment').addClass('d-none');
      } else {
        this.arrValidationForEngagement[3].isvalid = true;
        this.arrValidationForEngagement[3].apply = false;
      }
    }
    if (this.engagement.clientId > 0) {
      this.arrValidationForEngagement[0].isvalid = true;
      this.arrValidationForEngagement[0].apply = false;

      if (this.engagement.preRequestForContinuance &&
        this.isContinuanceEngagement &&
        this.defaultEngAssignment.isAssignmentFinanceDataEditable === undefined &&
        this.defaultEngAssignment.isAssignmentFinanceDataEditable === null &&
        this.defaultEngAssignment.isAssignmentFinanceDataEditable === '') {
        this.isAssignmentFinanceDataEditableBlank = true;
      }
      if (this.isMemberFirm) {
        if (this.defaultEngAssignment?.clientBillingContactEmail?.length > 0)
          this.isValidClientBillingEmail = this.validateEmail(this.defaultEngAssignment.clientBillingContactEmail);
        else
          this.isValidClientBillingEmail = true;
        if (this.defaultEngAssignment?.clientBillingContactName?.length > 0)
          this.isValidClientBillingName = this.validateClientBillingName(this.defaultEngAssignment.clientBillingContactName);
        else
          this.isValidClientBillingName = true;
      }
      else {
        this.isValidClientBillingEmail = this.validateEmail(this.defaultEngAssignment.clientBillingContactEmail);
        if (this.defaultEngAssignment.clientBillingContactName != null &&
          this.defaultEngAssignment.clientBillingContactName != undefined &&
          this.defaultEngAssignment?.clientBillingContactName != '') {
          this.isValidClientBillingName = this.validateClientBillingName(this.defaultEngAssignment.clientBillingContactName);
        }
      }
      if (this.checkMandatoryValidations(this.arrControlValidation, this.defaultEngAssignment, 'engagementAssignment') &&
        isValidEPPMD && this.isValidClientBillingEmail && !this.isAssignmentFinanceDataEditableBlank && this.isvalidESD &&
        this.isvalidEED && this.isValidClientBillingName) {
        //Check first valid assignment name
        if (this.validateAssignmentName(this.defaultEngAssignment.assignmentName)) {
          this.validAssignmentName = true;
          this._engService.GetAssignmentNameCountByClientId(this.defaultEngAssignment.assignmentName, this.engagement.clientId).subscribe(count => {
            if (count > 0 && !this.EditAssignMode) {
              this.isAssignmentNameExistForClient = true;
            } else if (count > 0 && !this.EditMode &&
              (this.engagement.opportunityId != '' && this.engagement.opportunityId != undefined)) {
              this.isAssignmentNameExistForClient = true;
            }
            else if (count > 1 && this.EditAssignMode) {
              this.isAssignmentNameExistForClient = true;
            } else {
              this.isAssignmentNameExistForClient = false;
            }
            //When primary solution code changes that time allow user to add new assignment with old assignment name
            if (this.EditMode && this.isSolutionCodeChanged &&
              this.UnmodifiedEngagementAssignmentData != null &&
              this.UnmodifiedEngagementAssignmentData.length > 0) {
              let assignmentNameFoundIndex = this.UnmodifiedEngagementAssignmentData.findIndex(x => x.assignmentName == this.defaultEngAssignment.assignmentName);
              if (assignmentNameFoundIndex >= 0) {
                this.isAssignmentNameExistForClient = false;
              }
            }
            if (this.checkBusinessValidations() && !this.isAssignmentNameExistForClient) {
              this.setAssignmentValuesBeforeSave();
              if (!this.EditAssignMode) {
                this.InsertAssignment();
                this.EditAssignMode = false;
                this.EditAssignmentIndex = 0;
                this.addAssignMembers();
                this.ProcessResetAssignmentModel();
              } else {
                if (this.isContinuanceEngagement && this.engagement.preRequestForContinuance && this.defaultEngAssignment.isContinuanceCarryFrwdAssignment) {
                  this.isSubmitAssignmentClicked = true;
                  this.setDialogContent('warning', EngagementDialogTitles.SubmitAssignment);
                } else {
                  this.updateAssignment();
                  this.EditAssignmentTeamMembers(this.EditAssignmentIndex - 1);
                  this.ProcessResetAssignmentModel();
                }
              }
              if (this.EditMode) {
                if (assignmentrowid !== null && assignmentrowid !== '') {
                  $('#row_' + assignmentrowid).show();
                }
              }
            }
          });
        } else {
          this.validAssignmentName = false;
        }
      }
    } else {
      this.scrollToTop();
      this.arrValidationForEngagement[0].isvalid = false;
      this.arrValidationForEngagement[0].apply = true;
    }
  }
  ProcessResetAssignmentModel() {
    this.resetAssignmentModel();
    this.adminDisplayUserName = '';
    this.adminDisplayUserEmail = '';
    this.validateCMSAA = false;
    this.cmsDisplayUserName = $('#txtPeoplePicker_EP_0').val();
    this.cmsDisplayUserEmail = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
  }
  validateEmail(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
    if (regex.test(email)) {
      return true;
    } else {
      return false;
    }
  }
  validateConfirmEmail(clientEmailId: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/gm;
    if (regex.test(clientEmailId)) {
      return true;
    } else {
      return false;
    }
  }

  validateAssignmentName(name: string) {
    const regex = /^[A-Za-z0-9()',&._-\s]*$/gm;
    if (regex.test(name)) {
      return true;
    } else {
      return false;
    }
  }
  validateClientBillingName(name: string) {
    const regex = /^(?![0-9]*$)[a-zA-Z0-9',_.\s]+$/gm;
    if (regex.test(name)) {
      return true;
    } else {
      return false;
    }
  }

  validateDate(date: any, dateName: string) {
    let isSpace = false;
    console.log("passed date is =====" + date);
    if (date === "") { isSpace = true; } else { isSpace = false; }
    let date1 = new Date(date);
    if (isNaN(date1.getTime()) && !isSpace) {
      if (dateName === 'ESD') {
        this.isvalidESD = false;
      }
      if (dateName === 'EED') {
        this.isvalidEED = false;
      }
      if (dateName === 'PED') {
        this.isvalidPED = false;
      }
      if (dateName === 'EFD') {
        this.isvalidEFD = false;
      }
      if (dateName === 'EXD') {
        this.isvalidEXD = false;
      }
    }
  }

  setAssignmentValuesBeforeSave() {
    this.defaultEngAssignment.netFees = parseInt(this.defaultEngAssignment.netFees.toString().replace(',', ''));
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    })
    this.defaultEngAssignment.netFeesInCurrencyFormat = formatter.format(Number(this.defaultEngAssignment.netFees));
    this.defaultEngAssignment.estimatedGrossMarginInCurrencyFormat =
      (this.defaultEngAssignment.estimatedGrossMargin === null || this.defaultEngAssignment.estimatedGrossMargin.toString().length === 0)
        ? ''
        : formatter.format(Number(this.defaultEngAssignment.estimatedGrossMargin));
  }

  restartAssignment() {
    // clear Assignment
    this.engagement.opportunityId = undefined;
    this.arrEngagementAssignment = [];
    this.isAssignmentNameExistForClient = false;
    this.duplicateAssignmentName = false;
    this.RefreshAssignmentModel();

    // clear Team member
    this.assignmentTeamMemberList = [];
    this.engTeamaMembeListrWithoutAssignment = [];
    const newMemberList: TeamMember[] = [];
    this.newAssignmentTeamMember = {
      assignmentName: '',
      solutionCodeName: '',
      teamMemberList: newMemberList,
      isAssignmentChecked: false,
      isTeamDetailsVisible: false
    };
    this.assignmentTeamMemberList.push(this.newAssignmentTeamMember);
  }

  InsertAssignment() {
    this.unsavedAlertfeature_AnyFormFieldChanged = true;
    this.setUnsavedChangesForIwantTo();
    let rowid: number;
    this.defaultEngAssignment.isContinuanceCarryFrwdAssignment = false;
    this.defaultEngAssignment.assignmentCodeId = 0;
    rowid = this.arrEngagementAssignment.length + 1;
    this.defaultEngAssignment.assignmentCode = null;
    this.defaultEngAssignment.status = 'Add';
    this.defaultEngAssignment.assignmentCodeStatus = this.AssignmentCodeStatus.Pending;
    if (rowid == 1) {
      this.defaultEngAssignment.isPrimaryAssignment = true;
    }

    this.arrEngagementAssignment.push(JSON.parse(JSON.stringify(this.defaultEngAssignment)));
  }

  updateAssignment() {
    let assignmentRowIndex = this.EditAssignmentIndex - 1;
    if (this.arrEngagementAssignment.length > 0) {
      this.unsavedAlertfeature_AnyFormFieldChanged = true;
      this.setUnsavedChangesForIwantTo();
      // if (this.engagement.preRequestForContinuance == false || this.engagement.preRequestForContinuance == undefined) {
      this.defaultEngAssignment.status = this.defaultEngAssignment.assignmentCodeId == 0 ? 'Add' : 'Update'
      //}
      //Get Updated CMSEPPMD and Admin
      this.editedAssignmentOldData = this.arrEngagementAssignment[assignmentRowIndex];
      this.arrEngagementAssignment[this.EditAssignmentIndex - 1] = this.defaultEngAssignment;

      //Retain opportunityId for continuance even if opportunityServiceId is not mapped.
      if (this.isContinuanceEngagement == false) {
        let isMappedOpportunityServiceExists = this.arrEngagementAssignment.find(assignment =>
          ((assignment.opportunityServiceId != '' && assignment.opportunityServiceId != undefined ? assignment : null) != null));
        //Remove opportunity binding if none of the EngagementAssignment having OpportunityService bind      
        if (isMappedOpportunityServiceExists == undefined) {
          this.engagement.opportunityId = undefined;
        }
      }
    }//end if

    //});
  }

  editAssignment(index: number) {
    this.isvalidESD = true;
    this.isvalidEED = true;
    this.editAssignmentDivId = "editAssignmentDiv_" + index;
    $('.editAssignmentWhiteForm').show();
    console.log('editAssignment func called', index, JSON.stringify(this.arrEngagementAssignment[index]));
    this.defaultEngAssignment = JSON.parse(JSON.stringify(this.arrEngagementAssignment[index]));
    if (this.defaultEngAssignment.opportunityServiceId != null && !this.defaultEngAssignment.isPrimaryAssignment) {
      if (this.defaultEngAssignment.assignmentName == this.blankLineAssignmentName) {
        this.defaultEngAssignment.assignmentName = '';
      }
    }
    this.editAssignmentDefaultValuesCheck(this.defaultEngAssignment);

    if (this.defaultEngAssignment.selectedsolutionCode != undefined) {
      this.defaultEngAssignment.solutionCodeId = this.defaultEngAssignment.selectedsolutionCode.childSolutionCodeId;
      this.defaultEngAssignment.solutionCodeListData = [];
      const activeSolutionCodeList = this.secondarySolutionCode.filter(solutionCode => solutionCode.status == 'A');
      this.defaultEngAssignment.solutionCodeListData = activeSolutionCodeList;
      const selectedAssignmentSolutionCode = this.secondarySolutionCode.find(element => element.childSolutionCodeId == this.defaultEngAssignment.solutionCodeId);
      //Add if Not Exists
      this.defaultEngAssignment.solutionCodeListData.indexOf(selectedAssignmentSolutionCode) === -1 ? this.defaultEngAssignment.solutionCodeListData.push(selectedAssignmentSolutionCode) : console.log("This item already exists");
    }

    this.DateCompareResult = null;
    this.isVisibledefaultAssignmentDiv = false;
    this.isVisibleNewAssignmentDIV = false;
    this.isVisibleEditAssignmentDiv = true;
    $('#row_' + index).hide();
    this.EditAssignMode = false;
    this.isvalidcmsEPPMDName = true;
    this.isvalidAassignmentAdministrator = true;
    this.adminDisplayUserName = this.defaultEngAssignment.assignmentAdministrator;
    this.adminDisplayUserEmail = this.defaultEngAssignment.adminEmailId;
    this.userDetails.AA = JSON.parse(JSON.stringify({ displayName: this.defaultEngAssignment.assignmentAdministrator, mail: this.defaultEngAssignment.adminEmailId, businessPhones: this.defaultEngAssignment.adminContactNo }));
    console.log('Assignment Administrator', this.userDetails.AA);
    this.cmsDisplayUserEmail = this.defaultEngAssignment.cmsEPPMDEmailId;
    this.cmsDisplayUserName = this.defaultEngAssignment.cmsEPPMDName;
    this.validateCMSEP = true;
    this.validateCMSAA = true;
    this.isvalidEQCR = true;
    this.isvalidCMSEP = true;
    this.isvalidAA = true;
    this.isValidEQCRBusinessRule = true;
    this.isAssignmentFinanceDataEditableBlank = false;
    this.setValidEQCRValue();
    this.SetAssignFinanceDataDisplayMode();
    setTimeout(() => {
      $('#UserHiddenProps_AS_AA_0 #hdnUserPhone').val(this.defaultEngAssignment.adminContactNo);
      //$('.EstimatedStartDate').val(this.defaultEngAssignment.engagementStartDate);
      $('.EstimatedStartDate').val(this.datePipe.transform(this.defaultEngAssignment.engagementStartDate, 'MM/dd/yyyy'));
      //$('.EstimatedEndDate').val(this.defaultEngAssignment.engagementEndDate);
      $('.EstimatedEndDate').val(this.datePipe.transform(this.defaultEngAssignment.engagementEndDate, 'MM/dd/yyyy'));

      if (this.EditMode) {
        // console.log('Inside Edit Mode', this.defaultEngAssignment.engagementStartDate, this.datePipe.transform(this.defaultEngAssignment.engagementStartDate,"MM/dd/yyyy"));
        $('.EstimatedStartDate').val(this.datePipe.transform(this.defaultEngAssignment.engagementStartDate, 'MM/dd/yyyy'));
        $('.EstimatedEndDate').val(this.datePipe.transform(this.defaultEngAssignment.engagementEndDate, 'MM/dd/yyyy'));
      }

      if (this.isMemberFirm) {
        this.disableMemberFirmAssignmentData();
      }
    }, 100);
    this.EditAssignMode = true;
    this.EditAssignmentIndex = index + 1;
    this.mandatoryFieldInitialization();

  }

  SetAssignFinanceDataDisplayMode() {
    //set finance field editable flag
    if (this.isContinuanceEngagement && this.engagement.preRequestForContinuance && this.defaultEngAssignment.isContinuanceCarryFrwdAssignment) {
      switch (this.defaultEngAssignment.budgetApproved) {
        case false:
        case undefined:
        case null:
          this.defaultEngAssignment.isAssignmentFinanceDataEditable = 'false';
          break;
        case true:
          this.defaultEngAssignment.isAssignmentFinanceDataEditable = 'true';
          break;
        default:
          break;
      }
      this.onBudgetApprovedChange();
    } else {
      this.defaultEngAssignment.isAssignmentFinanceDataEditable = 'true';
    }
  }

  removeAssignment(index) {
    this.isRemoveCarryForwardedAssignmentClicked = false;
    if (index > -1) {
      this.editedAssignmentOldData = this.arrEngagementAssignment[index];
      if (this.isContinuanceEngagement && this.editedAssignmentOldData.isContinuanceCarryFrwdAssignment) {
        this.isRemoveCarryForwardedAssignmentClicked = true;
        this.setDialogContent('warning', EngagementDialogTitles.RemoveAssignment);
      } else {
        this.RemoveSelectedAssignment(index);
      }

    }
  }
  RemoveSelectedAssignment(index: number) {
    if (index > -1) {
      this.unsavedAlertfeature_AnyFormFieldChanged = true;
      this.setUnsavedChangesForIwantTo();
      this.arrEngagementAssignment.splice(index, 1);
      //Retain opportunityId for continuance even if opportunityServiceId is not mapped.
      if (this.isContinuanceEngagement == false) {
        let isMappedOpportunityServiceExists = this.arrEngagementAssignment.find(assignment =>
          ((assignment.opportunityServiceId != '' && assignment.opportunityServiceId != undefined ? assignment : null) != null));
        //Rmove opportunity binding if none of the EngagementAssignment having OpportunityService bind      
        if (isMappedOpportunityServiceExists == undefined) {
          this.engagement.opportunityId = undefined;
        }
      }
      this.resetDefaultAssignment();
      //Set Always AdminIndex and CMSEPPMDIndex as index in case of remove assignment.
      this.removeEngAssignmetTeammemberData(index, index, index);
    }
  }
  resetAssignmentModel() {
    this.hideDefaultAssignmentBox();
    this.objectInitialization();
    this.setPrePopullatedControls();
  }

  RefreshAssignmentModel() {
    this.objectInitialization();
    this.setPrePopullatedControls();
    //this.showDefaultAssignmentBox();
  }

  resetDefaultAssignment() {
    this.resetAssignmentModel();
  }

  cancelAssignment(assignmentrowid: string) {
    this.EditAssignMode = false;
    this.EditAssignmentIndex = 0;
    this.adminDisplayUserName = '';
    this.adminDisplayUserEmail = '';
    this.validateCMSAA = false;
    this.cmsDisplayUserName = $('#txtPeoplePicker_EP_0').val();
    this.cmsDisplayUserEmail = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
    if (this.arrEngagementAssignment != null && this.arrEngagementAssignment.length >= 1) {

      this.defaultEngAssignment.eqcrName = this.arrEngagementAssignment[0].eqcrName ? this.arrEngagementAssignment[0].eqcrName : '';
      this.defaultEngAssignment.eqcrEmailId = this.arrEngagementAssignment[0].eqcrEmailId ? this.arrEngagementAssignment[0].eqcrEmailId : '';
      this.setValidEQCRValue();
    }
    if (assignmentrowid !== null && assignmentrowid !== '') {
      $('#row_' + assignmentrowid).show();
    }
    this.resetDefaultAssignment();
  }

  setPrePopullatedControls() {
    // this.solution code drop down
    if (this.secondarySolutionCode != null) {
      if (this.secondarySolutionCode.length > 0) {
        const activeSolutionCodeList = this.secondarySolutionCode.filter(solutionCode => solutionCode.status == 'A');
        this.defaultEngAssignment.solutionCodeListData = activeSolutionCodeList
        if (activeSolutionCodeList != null && activeSolutionCodeList != undefined && activeSolutionCodeList.length > 0) {
          this.defaultEngAssignment.solutionCodeId = this.secondarySolutionCode[0].parentSolutionCodeId;
        }
      }
    }
  }

  BuildNFetchClientOpportunityByOpportunityId() {
    let clientName = $('#txtValue' + this.MappedValue + 0).val();
    const clientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();
    if (clientCode.length > 0) {
      clientName = clientName.replace(' (' + clientCode + ')', '');
    }
    this.selectedclientName = clientName;
    this.selectedclientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();
    this.clientOneViewAccountId = $('#hdnclientOneViewAccountId' + this.MappedValue + 0).val();
    // if (this.primarySelectedSolutionCode != undefined) {
    //   this.selectedSolutionCode = this.primarySelectedSolutionCode.solutionCode;
    //   this.selectedsolutionCodeWithDesc = this.primarySelectedSolutionCode.solutionCode + ' - ' + this.primarySelectedSolutionCode.solutionCodeDescription;
    // }
    this.selectedsolutionCodeWithDesc = $('#txtValue' + this.SolutionCodeMappedValue + 0).val();

    let searchOpportunityByOpportunityIdRequestModel: SearchOpportunityByOpportunityIdRequestModel = new SearchOpportunityByOpportunityIdRequestModel();
    searchOpportunityByOpportunityIdRequestModel.opportunityId = this.engagement.opportunityId;
    searchOpportunityByOpportunityIdRequestModel.primarySolutionCode = this.primarySelectedSolutionCode.solutionCode;
    let opportunityServiceMiniModelList: OpportunityServiceMiniModel[] = [];
    this.arrEngagementAssignment.filter(model => model.opportunityServiceId != '').forEach(item => {
      let opportunityServiceMiniModel: OpportunityServiceMiniModel = new OpportunityServiceMiniModel();
      opportunityServiceMiniModel.OpportunityServiceId = item.opportunityServiceId;
      opportunityServiceMiniModel.isPrimary = item.isPrimaryAssignment;
      opportunityServiceMiniModel.isPartOfSecondary = true;
      opportunityServiceMiniModelList.push(opportunityServiceMiniModel);
    });
    searchOpportunityByOpportunityIdRequestModel.opportunityServiceMiniModelList = opportunityServiceMiniModelList;

    let secSolutionCodeList: string[] = [];
    if (this.secondarySolutionCode != undefined) {
      this.secondarySolutionCode.filter(code => code.seq != 0).forEach(item => {
        secSolutionCodeList.push(item.solutionCode);
      });
    }

    searchOpportunityByOpportunityIdRequestModel.secSolutionCodeList = secSolutionCodeList;
    $("#modal-Opportunity").modal('show');
    this.GetClientOpportunityByOpportunityId(searchOpportunityByOpportunityIdRequestModel);
  }

  BuildNFetchClientOpportunityByEngagementId() {
    let clientName = $('#txtValue' + this.MappedValue + 0).val();
    const clientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();
    if (clientCode.length > 0) {
      clientName = clientName.replace(' (' + clientCode + ')', '');
    }
    this.selectedclientName = clientName;
    this.selectedclientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();
    this.clientOneViewAccountId = $('#hdnclientOneViewAccountId' + this.MappedValue + 0).val();
    // if (this.primarySelectedSolutionCode != undefined) {
    //   this.selectedSolutionCode = this.primarySelectedSolutionCode.solutionCode;
    //   this.selectedsolutionCodeWithDesc = this.primarySelectedSolutionCode.solutionCode + ' - ' + this.primarySelectedSolutionCode.solutionCodeDescription;
    // }
    this.selectedsolutionCodeWithDesc = $('#txtValue' + this.SolutionCodeMappedValue + 0).val();

    let searchOpportunityByEngagementIdRequestModel: SearchOpportunityByEngagementIdRequestModel = new SearchOpportunityByEngagementIdRequestModel();
    searchOpportunityByEngagementIdRequestModel.engagementId = this.engagement.engagementId;
    searchOpportunityByEngagementIdRequestModel.primarySolutionCode = this.primarySelectedSolutionCode.solutionCode;
    let opportunityServiceMiniModelList: OpportunityServiceMiniModel[] = [];
    this.arrEngagementAssignment.filter(model => model.opportunityServiceId != '').forEach(item => {
      let opportunityServiceMiniModel: OpportunityServiceMiniModel = new OpportunityServiceMiniModel();
      opportunityServiceMiniModel.OpportunityServiceId = item.opportunityServiceId;
      opportunityServiceMiniModel.isPrimary = item.isPrimaryAssignment;
      opportunityServiceMiniModel.isPartOfSecondary = true;
      opportunityServiceMiniModelList.push(opportunityServiceMiniModel);
    });
    searchOpportunityByEngagementIdRequestModel.opportunityServiceMiniModelList = opportunityServiceMiniModelList;

    let secSolutionCodeList: string[] = [];
    if (this.secondarySolutionCode != undefined) {
      this.secondarySolutionCode.filter(code => code.seq != 0).forEach(item => {
        secSolutionCodeList.push(item.solutionCode);
      });
    }

    searchOpportunityByEngagementIdRequestModel.secSolutionCodeList = secSolutionCodeList;
     $("#modal-Opportunity").modal('show');
    this.GetClientOpportunityByEngagementId(searchOpportunityByEngagementIdRequestModel);
  }

  PopulateDefaultAssignmentModel() {
    this.isVisibledefaultAssignmentDiv = true;
    this.isVisibleNewAssignmentDIV = false;
    //this.defaultEngAssignment.solutionCodeId = null;
    this.isvalidCMSEP = Boolean(JSON.parse($('#hdnValidInput_EP_0').val()));
    this.EditAssignMode = false;
    this.EditAssignmentIndex = 0;
    if (this.isMemberFirm) {
      this.prefillMemberFirmEngagementAssignmentDetails();
      $('.isMemberFirm').addClass('custom-radioDisabled');
      $('#engAgreementType').attr('disabled', true);
    }
    else {
      $('.isMemberFirm').removeClass('custom-radioDisabled');
      $('#engAgreementType').attr('disabled', false);
    }
    setTimeout(() => {
      this.controlInitialization();
    }, 100);
  }

  showDefaultAssignmentBox() {
    this.PopulateDefaultAssignmentModel();
  }

  openAssignmentModelWithOneViewOpportunity(pageLoadOnInitiatingContinuanceEngagement) {
    $('#clientErrorMessage').text('');
    if (this.isOpportunityServiceFeatureFlagEnabled) {
      this.isPageLoadOnInitiatingContinuanceEngagement = pageLoadOnInitiatingContinuanceEngagement;
      // if (pageLoadOnInitiatingContinuanceEngagement == true || (this.engagement.opportunityId != '' && this.engagement.opportunityId != undefined)) {
      //   this.BuildNFetchClientOpportunityByOpportunityId();
      // } else
       if (this.engagement.engagementId != 0 && this.engagement.engagementId != undefined) {
        this.BuildNFetchClientOpportunityByEngagementId();
      } else {
        this.SetOpportunityModelValuesAndOpenPopUp();
      }
    }
    else {
      this.defaultEngAssignment.solutionCodeId = null;
      this.PopulateDefaultAssignmentModel();
    }
  }

  hideDefaultAssignmentBox() {
    this.isVisibledefaultAssignmentDiv = false;
    this.isVisibleEditAssignmentDiv = false;
    this.isVisibleNewAssignmentDIV = true;
    $('.editAssignmentWhiteForm').show();
  }

  getEngagementDetailsById(engId: number) {
    this.loadingScreenService.startLoading();
    return this._engService.GetEditEngagementDetailsById(engId)
      .subscribe(
        result => {
          this.engagement = result;
          if (this.engagement?.memberFirmMappingId > 0) //if engagement is member firm then load member firm details
            this.memberFirmService.getMemberFirmDetailsById(this.engagement?.memberFirmMappingId).subscribe(memberFirmData => {
              this.memberFirmData = memberFirmData
            })
          this.CheckPrimarySolutionCodeIsAudit(this.engagement.parentSolutionCodeId);
          if (this.EditMode) {
            this.dataService.setClientIdForPrePopulate(this.engagement.clientId);
          }

          setTimeout(() => {
            this.setIsMemberFirm();
            if (this.isMemberFirm) {
              this.disableMemberFirmEngagementData();
              $('.isMemberFirm').addClass('custom-radioDisabled');
            }
            else {
              $('.isMemberFirm').removeClass('custom-radioDisabled');
            }
          }, 1000);

          this.unsavedAlertfeature_InitialEngagementObject = JSON.parse(JSON.stringify(this.engagement));
          this.originalParentSolutionCodeId = result.parentSolutionCodeId;
          this.originalEngagementName = this.engagement.engagementName;
          console.log('originalEngagementName', this.originalEngagementName);
          if (this.engagement.engagementName.indexOf('&') >= 0) {
            this.engagement.engagementName = this.domSanitizer.sanitize(SecurityContext.HTML, this.engagement.engagementName);
            this.engagement.engagementName = this.engagement.engagementName.replace('&amp;', '&');
          }
          else {
            this.engagement.engagementName = this.domSanitizer.sanitize(SecurityContext.HTML, this.engagement.engagementName);
          }
          if (this.engagement.reacceptanceEngagementId > 0 && this.engagement.reacceptanceEngagementId != null) {
            this.isContinuanceEngagement = true;
          } else {
            this.isContinuanceEngagement = false;
          }
          this._engService.GetConveneIntegrationFeatureFlag().subscribe(result => {
            this.isConveneIntegrationFlagEnabled = result;
            if (this.isConveneIntegrationFlagEnabled && this.isContinuanceEngagement) {
              this.baseConeveneProjectInitiationModel.dueDates = "Rollforward";
              this.baseConeveneProjectInitiationModel.isUsers = false;
              this.baseConeveneProjectInitiationModel.isCloneProject = true;
              this.baseConeveneProjectInitiationModel.statusOfTheProject = "Draft";
              this.baseConeveneProjectInitiationModel.cloneProjectid = null;
              this.getConveneProjectDetailsByEngagementId();
            }
          });
          this._engService.GetEditEngagementCollabDetailsById(engId).subscribe(collab => {
            this.engagementCollabData = collab;
            this.isCurrentEngagementIsIndus = this.engagementCollabData.isIndus;
            this.carryForwadINDUSConsent = this.engagementCollabData.carryForwadINDUSConsent;
            console.log("Edit engagement collab data ===" + JSON.stringify(this.engagementCollabData));
            if (this.engagementCollabData.siteRequestStatus === 'Completed') {
              this.isSiteProvisioned = true;
            }

            this._workspaceService.getWorkspaceByEngagementId(this.currentEngagementId).subscribe(
              workspace => {
                this.WorkspaceObject = workspace;
                this.CurrentWorkspaceStatus = workspace.status;
                console.log('Workspace details on Engagement :', this.WorkspaceObject);
                this.SetBasicEngDataforEditMode();
                // to get review notes count
                this.getWorkspaceReviewPoints();
                //this.controlInitialization();
                // this.getSecondarySolutionCodeById(this.engagement.parentSolutionCodeId);
                this._engService.getSecondarySolutionCodeById(this.engagement.parentSolutionCodeId)
                  .subscribe(
                    res => {
                      this.secondarySolutionCode = res;
                      // this.secondarySolutionCodeList = res;
                      if (this.secondarySolutionCode.length > 0) {
                        this.primarySelectedSolutionCode = this.secondarySolutionCode.find(c => c.seq == 0);
                        const activeSolutionCodeList = this.secondarySolutionCode.filter(solutionCode => solutionCode.status == 'A');
                        if (activeSolutionCodeList != null && activeSolutionCodeList != undefined && activeSolutionCodeList.length > 0) {
                          this.defaultEngAssignment.solutionCodeListData = activeSolutionCodeList;
                          this.defaultEngAssignment.solutionCodeId = activeSolutionCodeList[0].parentSolutionCodeId;
                          this.defaultEngAssignment.selectedsolutionCode =
                            this.defaultEngAssignment.solutionCodeListData.find(element =>
                              element.childSolutionCodeId == this.defaultEngAssignment.solutionCodeId);
                        }
                      } else {
                        this.primarySelectedSolutionCode = new SecondarySolutionCode();
                        this.primarySelectedSolutionCode.parentSolutionCodeId = this.engagement.parentSolutionCodeId;
                      }
                      //to set client contact billing details                    
                      this.setClientBillingContactDetails();
                      this.getEngagementAssignmentsById(this.currentEngagementId);
                      this.CheckEngagementNameLimit();
                      this.ValidateClientData();
                      this.OpenOpportunityPopupOnInitiatingContinuanceEngagement();
                    },
                    () => {
                      this.secondarySolutionCode = null;
                      this.loadingScreenService.stopLoading();
                      this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
                    });
              },
              () => {
                this.WorkspaceObject = null;
                this.loadingScreenService.stopLoading();
                this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
              }
            );
          },
            () => {
              this.engagementCollabData = null;
              this.loadingScreenService.stopLoading();
              this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
            });
        },
        () => {
          this.engagement = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );
  }

  getEngagementAssignmentsById(engId: number) {
    this.loadingScreenService.startLoading();
    this.arrEngagementAssignment = [];
    this._engService.GetEngagementAssignmentsById(engId, this.EditMode)
      .subscribe(
        result => {
          this.UnmodifiedEngagementAssignmentData = result;
          if (this.UnmodifiedEngagementAssignmentData.length > 0) {
            const engTeamMembers = this._engService.GetEngagementTeamMembersById(engId, this.EditMode);
            const clientTeamMembers = this._engService.GetClientTeamMembersByEngagementId(engId);
            forkJoin([engTeamMembers, clientTeamMembers]).subscribe(
              responses => {
                let newTeamMember: TeamMember[] = [];
                newTeamMember = responses[0];
                this.clientTeamMembersList = responses[1];
                if (result.length <= 0) {
                  this.prefilENName();
                  this.defaultEngAssignment.engagementTypeData = this.engtypekeyValueList;
                }
                else {
                  this.hideDefaultAssignmentBox();
                  console.log('Engagement  assignment list on edit ', result);
                  //set team members list to UI
                  this.engTeamaMembeListrWithoutAssignment = newTeamMember;
                  this.CheckAndUpdateKeyTeamMemberRoleData();
                  //to set @members for revierw notes 
                  this.getWorkspaceMembers();
                  this.SetAssignmentMemberforEditMode(result, newTeamMember);
                  console.log('team member data on edit mode', newTeamMember);
                }
              });
          }
          else {
            this.defaultEngAssignment.engagementTypeData = this.engtypekeyValueList;
            this.loadingScreenService.stopLoading();
          }

        },
        () => {
          this.arrEngagementAssignment = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );


  }

  getEPList(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArrayForEP = userList;
          if(userList.length){
            this.isPPMDUserError = false;
          }
          else{
            this.isPPMDUserError = true;
          }
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForEP = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  OnSolutionCodeChange() {
    this.engagement.parentSolutionCodeId = parseInt($('#hdnKey' + this.SolutionCodeMappedValue + 0).val());
    this.CheckPrimarySolutionCodeIsAudit(this.engagement.parentSolutionCodeId); // To check primary solutioncode is audit or not
    this.isSolutionCodeChanged = true;
    this.restartAssignment();
    this.AddSecondarySolutionCode();
    this.prefilENName();
    this.LoadGoverningAgreementsFromCLM();
  }

  onEngagementAssignmentsSolutionCodeChange() {
    console.log(" from onEngagementAssignmentsSolutionCodeChange => solutionCodeListData ==> ", this.defaultEngAssignment.solutionCodeListData)
    if (this.defaultEngAssignment.solutionCodeListData != undefined) {
      if (!this.empty(this.defaultEngAssignment.solutionCodeId)) {
        this.defaultEngAssignment.selectedsolutionCode =
          this.defaultEngAssignment.solutionCodeListData.find(element =>
            element.childSolutionCodeId == this.defaultEngAssignment.solutionCodeId && element.status == 'A');

        //When changing secondary solution code while editing assignment, need to reset opportunityServiceId.
        this.defaultEngAssignment.opportunityServiceId = '';
      }
    }
  }
  AddSecondarySolutionCode() {
    $('#EngNamediv').removeClass('hide');
    const selectedsolutionCode = $('#txtValue' + this.SolutionCodeMappedValue + 0).val();
    const selectedSolutionCodehdnKeyValue = $('#hdnKey' + this.SolutionCodeMappedValue + 0).val().split('-!');
    this.engagement.parentSolutionCodeId = selectedSolutionCodehdnKeyValue[0];
    this.isPeriodRequire = this.getBoolean(selectedSolutionCodehdnKeyValue[2]);
    // if (this.originalParentSolutionCodeId != this.engagement.parentSolutionCodeId) {
    //   this.originalParentSolutionCodeId = this.engagement.parentSolutionCodeId;
    if (selectedsolutionCode != '') {
      if (this.engagement.parentSolutionCodeId > 0) {
        //set true when called from onsolutioncode change
        this.getTemplateMappingData(true);
        this.getSecondarySolutionCodeById(this.engagement.parentSolutionCodeId);
      }
    }
    // }
  }

  getBoolean(value) {
    switch (value) {
      case true:
      case 'true':
      case 1:
      case '1':
      case 'on':
      case 'yes':
        return true;
      default:
        return false;
    }
  }


  getDefaultClientBillingContactDetails(clientId: number) {
    return this._clientService.getDefaultClientBillingContactDetails(clientId)
      .subscribe(
        result => {
          this.defaultClientBillingContact = result;
          console.log('getDefaultClientBillingContactDetails func called ', this.engagementAssignmentList, this.engagementAssignmentList.length, this.defaultClientBillingContact);
          let localSecondarySolutioncode: SecondarySolutionCode[] = [];
          if (this.engagementAssignmentList.length >= 1) {
            localSecondarySolutioncode = this.engagementAssignmentList[0].solutionCodeListData;
          } else {
            this.engagementAssignmentList = [];
          }
          if (localSecondarySolutioncode != null && localSecondarySolutioncode.length > 0) {
            this.defaultEngAssignment.solutionCodeListData = localSecondarySolutioncode;
            this.defaultEngAssignment.solutionCodeId = localSecondarySolutioncode[0].parentSolutionCodeId;
          }
          //to set client contact billing details
          this.setClientBillingContactDetails();
          if (this.engagement.parentSolutionCodeId > 0) {
            this.getClientLevelDocuments(this.engagement.clientId, false);
          }
        },
        error => {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );
  }

  getSecondarySolutionCodeById(solutionCodeId: number) {
    return this._engService.getSecondarySolutionCodeById(solutionCodeId)
      .subscribe(
        res => {
          this.secondarySolutionCode = res;
          if (this.secondarySolutionCode.length > 0) {
            this.primarySelectedSolutionCode = this.secondarySolutionCode.find(c => c.seq == 0);
            const activeSolutionCodeList = this.secondarySolutionCode.filter(solutionCode => solutionCode.status == 'A');
            if (activeSolutionCodeList != null && activeSolutionCodeList != undefined && activeSolutionCodeList.length > 0) {
              this.defaultEngAssignment.solutionCodeListData = activeSolutionCodeList;
              this.defaultEngAssignment.solutionCodeId = activeSolutionCodeList[0].parentSolutionCodeId;
              this.defaultEngAssignment.selectedsolutionCode =
                this.defaultEngAssignment.solutionCodeListData.find(element =>
                  element.childSolutionCodeId == this.defaultEngAssignment.solutionCodeId);
            }
          } else {
            this.primarySelectedSolutionCode = new SecondarySolutionCode();
            this.primarySelectedSolutionCode.parentSolutionCodeId = solutionCodeId;
          }
          //to set client contact billing details
          this.setClientBillingContactDetails();

          //check IsContinuanceEngagementExists before Set Opportunity Model Values 
          if (this.originalParentSolutionCodeId != Number(this.engagement.parentSolutionCodeId)) {
            this.originalParentSolutionCodeId = this.engagement.parentSolutionCodeId;
            if ($('#txtValue' + this.SolutionCodeMappedValue + 0).val() != '') {
              this._engService.IsContinuanceEngagementExists(this.engagement.clientId, this.engagement.parentSolutionCodeId).subscribe(
                res => {
                  this.continuanceEngagementCount = res;
                  if (this.continuanceEngagementCount === 0) {
                    this.OpenDefaultAssignmentBox();
                  } else {
                    this.isContinuanceEngExistsPopupOpen = true;
                    this.setDialogContent('warning', EngagementDialogTitles.ContinuanceEngExists);
                  }
                },
                error => {
                  this.loadingScreenService.stopLoading();
                  this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                }

              );
            }
          }
        },
        () => {
          this.secondarySolutionCode = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  OpenDefaultAssignmentBox() {
    if (this.isOpportunityServiceFeatureFlagEnabled) {
      this.isPageLoadOnInitiatingContinuanceEngagement = false;
      this.SetOpportunityModelValuesAndOpenPopUp();
    } else {
      //Default Engagement Assignment
      this.showDefaultAssignmentBox();
    }
  }

  removeEngAssignmentRow(index) {
    if (this.engagementAssignmentList.length == 1) {
      this.alertServiceFun('Cannot delete the row when there is only one row.', 'danger');
    } else {
      this.engagementAssignmentList.splice(index, 1);
      this.removeTeamMemberWhichIsAdmin();
      this.addAssignmentAdminAsTeamMember();
      return true;
    }
  }


  resetEngForm() {
    $('#txtPeoplePicker_EP_0').val('');
    $('#txtPeoplePicker_AA_0').val('');
    $('#UserHiddenProps_EP_0 #hdnUserName').val('');
    $('#UserHiddenProps_EP_0 #hdnUserEmail').val('');
    $('#UserHiddenProps_AA_0 #hdnUserEmail').val('');
    $('#UserHiddenProps_AA_0 #hdnUserName').val('');
    $('#UserHiddenProps_EP_0 #hdnUserPhone').val('');
    $('#hdnKey' + this.SolutionCodeMappedValue + 0).val('');
    $('#hdnKey' + this.MappedValue + 0).val('');
    $('#txtValue' + this.MappedValue + 0).val('');
    $('#txtKeyCode' + this.MappedValue + 0).val('');
    $('.engPeriodDate').val('');
    $('#engagementName').val('');
    $('#txtValue' + this.SolutionCodeMappedValue + 0).val('');
    $('#engAgreementType').val('undefined');
    // $('#engagement-solcode-description').text('Solution code description will appear here when selected');
    $('#drpsolutionCodeId').val('');
    this.secondarySolutionCode = [];
    this.restartAssignment();
    this.CheckEngagementNameLimit();
    this.scrollToTop();
  }

  // async isEngagementExists(isValueDifferent: boolean) {
  //   this.loadingScreenService.startLoading();
  //   this._engService.isEngagementExists(this.engagement.clientId, this.engagement.engagementName)
  //     .subscribe(
  //       res => {
  //         this.loadingScreenService.stopLoading();
  //         this.engagementCount = res;
  //         if (this.engagementCount > 0 && isValueDifferent) {
  //           this.checkEngagementExists = true;
  //         }
  //         else {
  //           this.checkEngagementExists = false;
  //         }
  //         console.log(this.engagementCount);
  //       },
  //       () => {
  //         this.checkEngagementExists = false;
  //         this.loadingScreenService.stopLoading();
  //         this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
  //       });
  //   return this.checkEngagementExists;
  // }

  refreshAssignmentAdminPopup(textboxName: string) {
    this.removeRowSelection('tblAssAdminAD', 'searchAssAdminName', 'AssignmentAdminErrorMessage');
    let lastIndex = this.peoplepickerIndex;
    this.adminNameCtrl = textboxName + this.assignAdminRoleName + '_' + this.peoplepickerIndex;
    this.ASAdminCtrl = textboxName + 'AS_' + this.assignAdminRoleName + '_' + this.peoplepickerIndex;
    this.currentAssignmentRowIndex = lastIndex;
    this.selectedAAIndex = null;
    this.validateAA = false;
    if ($('#' + this.adminNameCtrl).hasClass('text-danger')) {
      $('#' + this.adminNameCtrl).removeClass('text-danger');
    }
  }

  refreshCMSAssignmentAdminPopup(index, textboxName: string) {
    this.removeRowSelection('tblAssAdminAD', 'searchEQCRName', 'EQCRErrorMessage');
    index = this.peoplepickerIndex;
    this.adminNameCtrl = '';
    this.ASAdminCtrl = textboxName + 'AS_' + this.assignAdminRoleName + '_' + this.peoplepickerIndex;
    this.currentAssignmentRowIndex = index;
    this.validateCMSAA = false;
    if ($('#' + this.ASAdminCtrl).hasClass('text-danger')) {
      $('#' + this.ASAdminCtrl).removeClass('text-danger');
    }
  }

  removeRowSelection(tableId, textboxId, errorMessageId) {
    $('#' + textboxId).val('');
    $('#' + errorMessageId).text('');
    const searchText = $('#' + textboxId).val().toLowerCase().trim();
    this.searchResult(tableId, searchText);
    $('#' + tableId + ' tr').each(function () {
      if ($(this).hasClass('highlight')) {
        $(this).removeClass('highlight');
      }
    });
  }

  searchResult(tableId, searchText) {
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
  }

  GetAssignmentAdmins(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArray = userList.filter(
            item => item.lineOfBusiness != this.appService.config.DATA.LINE_OF_BUSINESS_ID_PS
          );
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArray = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  searchUsers(searchTextBox, tableId: string) {
    console.log('searchUsers func called', searchTextBox, tableId);
    const searchText = $('#' + searchTextBox).val();
    if (tableId === 'tblEPAD' || tableId === 'tblEQCR' || tableId === 'tblGTAgreementOwner') {
      this.getEPList(searchText);
    }

    if (tableId === 'tblAssAdminAD') {
      this.GetAssignmentAdmins(searchText);
    }

    if (tableId === 'tblMemberAD') {
      this.GetTeamMembers(searchText);
    }

    /*
    $('#' + tableId + ' tr').each(function (index) {
      if (!index) return;
      $(this).find('td').each(function () {
        var id = $(this).text().toLowerCase().trim();
        var not_found = (id.indexOf(searchText) === -1);
        $(this).closest('tr').toggle(!not_found);
        return not_found;
      });
    });
*/
  }

  setAdmin() {
    let index = this.selectedAAIndex;
    if (Number(index) >= 0) {
      const selectedAdminName = $('#spAdmindisplayName' + index).text();
      const selectedAdminEmail = $('#spAdminmail' + index).text();
      const selectedAdminPhone = $('#spAdminbusinessPhones' + index).text();
      this.adminDisplayUserName = selectedAdminName;
      this.adminDisplayUserEmail = selectedAdminEmail;
      this.validateCMSAA = true;
      this.validateAA = true;
      $("#AssignmentAdminErrorMessage").text('');
      var countofAddedAssignmentAdmin = 0;
      for (var i in this.engagementAssignmentList) {
        if (this.engagementAssignmentList[i].adminEmailId == selectedAdminEmail) {
          countofAddedAssignmentAdmin = countofAddedAssignmentAdmin + 1;
        }
      }
      if (countofAddedAssignmentAdmin == 0) {
        $('#AssignmentAdminErrorMessage').text('');
        if (this.adminNameCtrl !== '') {
          $('#' + this.adminNameCtrl).val(selectedAdminName);
          $('#UserHiddenProps_AA_0 #hdnUserName').val(selectedAdminName);
          $('#UserHiddenProps_AA_0 #hdnUserEmail').val(selectedAdminEmail);
          $('#UserHiddenProps_AA_0 #hdnUserPhone').val(selectedAdminPhone);
          $('#hdnValidInput_AA_0').val(true);
        }
        $('#' + this.ASAdminCtrl).val(selectedAdminName);
        $('#UserHiddenProps_AS_AA_0 #hdnUserName').val(selectedAdminName);
        $('#UserHiddenProps_AS_AA_0 #hdnUserEmail').val(selectedAdminEmail);
        $('#UserHiddenProps_AS_AA_0 #hdnUserPhone').val(selectedAdminPhone);
        $('#hdnValidInput_AS_AA_0').val(true);
        this.validateCMSAA = true;

        if (this.adminNameCtrl !== '') {
          this.membersListArray = null;
          this.engagementAssignmentList[this.currentAssignmentRowIndex].assignmentAdministrator = selectedAdminName;
          this.engagementAssignmentList[this.currentAssignmentRowIndex].adminEmailId = selectedAdminEmail;
          this.engagementAssignmentList[this.currentAssignmentRowIndex].adminContactNo = selectedAdminPhone;
        }
        this.membersListArray = null;
        $('#modal-AssignmentAdministrator').modal('hide');
      }

    }
    else {
      $('#AssignmentAdminErrorMessage').text('Please Select User');
    }
    $('#searchAssAdminName').val('');
    //this.membersListArray = null;
  }

  selectAdmin(index) {
    $('#tblAssAdminAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedAAIndex = index;
  }

  removeTeamMemberWhichIsAdmin() {
    this.teamMemberList.forEach((item, i) => {
      if (item.memberEmailId == '') {
        this.teamMemberList.splice(i, 1);
      }
      if (item.teamMemberRole == 86) {
        this.teamMemberList.splice(i, 1);
        this.removeTeamMemberWhichIsAdmin();
      }
    });

  }
  addAssignmentAdminAsTeamMember() {
    for (var i = 0, l = this.engagementAssignmentList.length; i < l; i++) {
      if (this.engagementAssignmentList[i].adminEmailId != '') {
        this.testnewTeamMember = {
          memberEmailId: this.engagementAssignmentList[i].adminEmailId,
          memberName: this.engagementAssignmentList[i].assignmentAdministrator,
          teamMemberRole: 86,
          memeberContactNo: this.engagementAssignmentList[i].adminContactNo,
          approvalStatus: 'Pending',
        }
        this.teamMemberList.push(this.testnewTeamMember);
      }
    }
  }

  deselectAdmin() {
    this.membersListArray = null;
    $('#modal-AssignmentAdministrator').modal('hide');
    $('#searchAssAdminName').val('');
    //$('#' + this.adminNameCtrl).val('');
    $('#' + this.adminNameCtrl).innerText = '';
    //$('#' + this.ASAdminCtrl).val('');
    $('#' + this.ASAdminCtrl).innerText = '';
  }

  addTeamMember() {
    $('#clientErrorMessage').text('');
    //set role name for team meber
    // if (this.isContinuanceEngagement && this.engagementCollabData.isIndus && this.engagementCollabData.carryForwadINDUSConsent) { //isindus current engagement collabsitetable == true && carryforwardindus == true && iscontinuous == true
    //   this.teamMemberRoleName = RoleNames.ICANDGIA.toString();
    // } else {
    //   this.teamMemberRoleName = RoleNames.GTUSINDUSALL.toString();
    // }
    if (this.engagementCollabData.isIndus && this.isMemberFirm || (this.isLoggedInUserSystemAdmin && this.engagement.memberFirmMappingId > 0)) {
      this.teamMemberRoleName = this.memberFirmData.indusConsentShortRoleName;
    } else if (this.engagementCollabData.isIndus) {
      this.teamMemberRoleName = RoleNames.INDUSConsent.toString();
    } else if (this.isMemberFirm || (this.isLoggedInUserSystemAdmin && this.engagement?.memberFirmMappingId > 0)) {
      this.teamMemberRoleName = this.memberFirmData?.teamMemberShortRoleName;
    } else {
      this.teamMemberRoleName = RoleNames.StaffMember.toString();
    }
    if (this.teamMemberList != null) {
      if (this.teamMemberList.length < this.teamMemberLimit) {
        //close client team members section
        // this.arrValidationForNewClientTeamMember = [];
        // this.newClientTeamMemberList = [];

        this._alertService.remove(this.alert);
        this.newTeamMember = {
          memberEmailId: '',
          memberConfirmEmail: '',
          memberName: '',
          teamMemberRole: '',
          memeberContactNo: '',
          approvalStatus: '',
          teamMemberPermissionId: 0,
          isKeyTeamMember: false,
          keyTeamMemberRoleId: 0,
          description: ''
        };

        this.teamMemberList.push(this.newTeamMember);
        const ValidNewTeamMember = {
          memberEmailId: '',
          memberConfirmEmail: '',
          memberName: '',
          teamMemberRole: '',
          memeberContactNo: '',
          approvalStatus: '',
          teamMemberPermissionId: 0,
          ishdnValidInputName: true,
          isUserExist: false,
          isNewUserDuplicate: false,
          index: this.arrValidationForNewTeamMember.length
        };
        this.arrValidationForNewTeamMember.push(ValidNewTeamMember);
        console.log('addTeamMember func called', this.teamMemberList, this.arrValidationForNewTeamMember);
      }
      else {
        this.alertServiceFun('You can add only ' + this.teamMemberLimit + ' team members.', 'danger');
      }
    }
  }

  removeTeamMember(index: number, teamMemberRole: string) {
    if (this.EditMode) {
      this.loadingScreenService.startLoading();
      return this._engService.IsDiscussionOrReviewPointExistForUser(this.currentEngagementId, this.teamMemberList[index].memberEmailId).subscribe(
        res => {
          this.discussionandReviewPointsCount = res;
          this.loadingScreenService.stopLoading();
          if (this.discussionandReviewPointsCount == 0) {
            this.teamMemberList.splice(index, 1);
            this.arrValidationForNewTeamMember.splice(index, 1);
            this.renameTeammember(index, teamMemberRole);
            this._alertService.remove(this.alert);
            return true;
          }
          else {

            this.alertServiceFun('Team Member you are deleting has open discussions or review points. You cannot delete this team member.', 'danger');

            return false;
          }
        },
        () => {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
    }
    else {
      this.teamMemberList.splice(index, 1);
      this.arrValidationForNewTeamMember.splice(index, 1);
      this.renameTeammember(index, teamMemberRole);
      this._alertService.remove(this.alert);
      return true;
    }


    // }
    // return true;

  }

  renameTeammember(index: number, teamMemberRole: string) {
    const rows = this.el.nativeElement.querySelectorAll('#clientUserListContainer');
    if (rows.length > 0) {
      const rowToRemove = rows[index];
      this.renderer.removeChild(this.el.nativeElement, rowToRemove);
      for (let i = index; i < rows.length - 1; i++) {
        const row = rows[i + 1];
        const txtPeoplePicker = row.querySelector('input[type="text"]:first-child');
        const hdnValidInput = row.querySelector('input[type="hidden"][id^="hdnValidInput_' + teamMemberRole + '_"]');
        const hint = row.querySelector('span[id^="hint_' + teamMemberRole + '_"]');

        const loadericon = row.querySelector('em');
        const searchUserList = row.querySelector('ul');
        const UserHiddenProps = row.querySelector('div:nth-child(4)');

        if (hdnValidInput) {
          const newId = 'hdnValidInput_' + teamMemberRole + '_' + i;
          hdnValidInput.setAttribute('id', newId);
        }
        txtPeoplePicker.id = 'txtPeoplePicker_' + teamMemberRole + '_' + i;
        loadericon.id = 'loadericon_' + teamMemberRole + '_' + i;
        searchUserList.id = 'searchUserList_' + teamMemberRole + '_' + i;
        hint.id = 'hint_' + teamMemberRole + '_' + i;

        if (UserHiddenProps) {
          const divId = UserHiddenProps.getAttribute('id');
          if (divId) {
            const newDivId = divId.replace(/_\d+$/, '_' + i);
            UserHiddenProps.setAttribute('id', newDivId);
          }
        }
        const errorDivs = row.querySelectorAll('div.error-color');
        for (let j = 0; j < errorDivs.length; j++) {
          const errorDiv = errorDivs[j];
          const errorDivId = errorDiv.getAttribute('id');
          if (errorDivId) {
            const newErrorDivId = errorDivId.replace(/_\d+$/, '_' + i);
            errorDiv.setAttribute('id', newErrorDivId);
          }
        }

      }
    }

  }

  removeAssignmentTeamMember(index: number) {
    if (index >= 0) {
      this.unsavedAlertfeature_AnyFormFieldChanged = true;
      this.setUnsavedChangesForIwantTo();

      this.engTeamaMembeListrWithoutAssignment.splice(index, 1);
      this.ConfigureReviewNoteSearch();
    }
  }


  IsDiscussionOrReviewPointExistForUser() {

  }
  refreshTeamMemberPopup(index, textboxName: string) {
    this.removeRowSelection('tblMemberAD', 'searchTeamMemberName', 'TeamMemberErrorMessage');
    if (this.EditMode) {
      this.loadingScreenService.startLoading();
      return this._engService.IsDiscussionOrReviewPointExistForUser(this.currentEngagementId, this.teamMemberList[index].memberEmailId).subscribe(
        res => {
          this.discussionandReviewPointsCount = res;
          this.loadingScreenService.stopLoading();
          if (this.discussionandReviewPointsCount == 0) {
            this.teamMemberCtrl = textboxName + this.teamMemberRoleName + '_' + index;
            this.teamMemberEmailCtrl = 'UserHiddenProps_' + this.teamMemberRoleName + '_' + index;
            this.currentTeamMemberIndex = index;
            this.selectedTMIndex = null;
            this.validateTM.forEach((value, memberIndex, array) => array[index] = false);
            console.log('Validate Team Members ', this.validateTM);
            return true;
          }
          else {
            $('#modal-TeamMember').modal('hide');
            return false;
          }
        }, () => {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
    }
    else {
      this.teamMemberCtrl = textboxName + this.teamMemberRoleName + '_' + index;
      this.teamMemberEmailCtrl = 'UserHiddenProps_' + this.teamMemberRoleName + '_' + index;
      this.currentTeamMemberIndex = index;

      this.selectedTMIndex = null;
      this.validateTM.forEach((value, memberIndex, array) => array[index] = false);
      console.log('Validate Team Members ', this.validateTM);
    }
  }

  GetTeamMembers(searchTerm: string) {
    this.loadingScreenService.startLoading();
    let searchModel = new SearchGroupUserViewModel();
    searchModel.user = new MembersList();
    searchModel.user.displayName = searchTerm;
    if (this.memberFirmData?.memberFirmMappingId > 0 && this.isLoggedInUserSystemAdmin) {
      searchModel.isMemberFirm = true;
      searchModel.memberFirmDomain = [];
      searchModel.memberFirmDomain.push(this.memberFirmData?.memberDomain)
    }
    this.adUsersService.GetUserInformationByDisplayName(searchModel)
      .subscribe(
        userList => {
          this.loadingScreenService.stopLoading();
          this.membersListArrayForTeamMembers = userList;
        },
        (error) => {
          console.log('Error getting result ', error);
          this.membersListArrayForTeamMembers = null;
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        });
  }

  searchTeamMembers(event) {

    $('#' + event.currentTarget.id).keyup(function () {
      const searchText = this.value.toLowerCase().trim();

      $('#tblMemberAD tr').each(function (index) {
        if (!index) return;
        $(this).find('td').each(function () {
          var id = $(this).text().toLowerCase().trim();
          var not_found = (id.indexOf(searchText) === -1);
          $(this).closest('tr').toggle(!not_found);
          return not_found;
        });
      });
    });
  }

  setTeamMember() {
    let index = this.selectedTMIndex;
    if (Number(index) >= 0) {
      const selectedMemberName = $('#spMemberdisplayName' + index).text();
      const selectedMemberEmail = $('#spMembermail' + index).text();
      const selectedMemberPhone = $('#spMemberbusinessPhones' + index).text();
      console.log('setTeamMember func called', selectedMemberName, selectedMemberEmail, selectedMemberPhone, this.teamMemberEmailCtrl);
      $("#TeamMemberErrorMessage").text('');
      var countofAddedTM = 0;
      for (var i in this.teamMemberList) {
        if (this.teamMemberList[i].memberEmailId == selectedMemberEmail) {
          countofAddedTM = countofAddedTM + 1;
        }
      }
      if (countofAddedTM == 0) {
        $('#TeamMemberErrorMessage').text('');
        $('#' + this.teamMemberCtrl).val(selectedMemberName);
        $('#' + this.teamMemberEmailCtrl + ' > #hdnUserEmail').val(selectedMemberEmail);
        this.teamMemberList[this.currentTeamMemberIndex].memberName = selectedMemberName;
        this.teamMemberList[this.currentTeamMemberIndex].memberEmailId = selectedMemberEmail;
        this.teamMemberList[this.currentTeamMemberIndex].memeberContactNo = selectedMemberPhone;
        this.validateTM.forEach((value, memberIndex, array) => array[this.currentTeamMemberIndex] = true);
        console.log('Validate Team Members ', this.validateTM);
      }
      else {
        $('#TeamMemberErrorMessage').text('Selected Team Member is already added');
      }
    }
    this.membersListArrayForTeamMembers = null;
  }

  selectTeamMember(index: any, member: MembersList) {
    $('#searchTeamMemberName').val(member.displayName);
    $('#tblMemberAD').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    //this.selectedTM=member;

    this.selectedTMIndex = index;
  }

  deselectTeamMember() {
    $('#' + this.teamMemberCtrl).val('');
    $('#' + this.teamMemberCtrl).innerText = '';
    $('#' + this.teamMemberEmailCtrl + ' > #hdnUserEmail').val('');
    //$('#' + this.teamMemberEmailCtrl).innerText = '';
  }

  refreshEPPopup(textboxName: string) {
    this.removeRowSelection('tblEPAD', 'searchEPName', 'EPErrorMessage');
    let lastIndex = this.peoplepickerIndex;
    this.EpCtrl = textboxName + this.epRoleName + '_' + this.peoplepickerIndex;
    this.CMSEPCtrl = textboxName + 'AS_' + this.epRoleName + '_' + this.peoplepickerIndex;
    this.currentEPIndex = lastIndex;
    this.selectedEPIndex = null;
    this.validateEP = false;
    this.membersListArrayForEP = null;
    if ($('#' + this.EpCtrl).hasClass('text-danger')) {
      $('#' + this.EpCtrl).removeClass('text-danger');
    }
  }

  refreshCMSEPPopup(index, textboxName: string) {
    this.removeRowSelection('tblEPAD', 'searchEPName', 'EPErrorMessage');
    index = this.peoplepickerIndex;
    this.EpCtrl = '';
    this.CMSEPCtrl = textboxName + 'AS_' + this.epRoleName + '_' + this.peoplepickerIndex;
    this.currentEPIndex = index;
    this.validateCMSEP = false;
    this.selectedEPIndex = null;
    this.membersListArrayForEP = null;
    if ($('#' + this.CMSEPCtrl).hasClass('text-danger')) {
      $('#' + this.CMSEPCtrl).removeClass('text-danger');
    }
  }

  // Function not in use.
  searchEP() {
    // Declare variables
    var input, filter, ul, li, i, txtValue;
    input = document.getElementById('searchEPName');
    filter = input.value.toUpperCase();
    ul = document.getElementById('ulEP');
    li = ul.getElementsByTagName('li');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      txtValue = li[i].textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = '';
      } else {
        li[i].style.display = 'none';
      }
    }
  }

  setEP() {
    console.log('setEP func called ', this.selectedEPIndex, Number(this.selectedEPIndex) >= 0, this.EpCtrl, this.CMSEPCtrl);
    let index = this.selectedEPIndex;
    if (Number(index) >= 0) {
      const selectedEPName = $('#spEPdisplayName' + index).text();
      const selectedEPEmail = $('#spEPmail' + index).text();
      const selectedEPPhone = $('#spEPbusinessPhones' + index).text();
      if (selectedEPEmail !== '') {
        if (this.EpCtrl !== '') {
          $('#' + this.EpCtrl).val(selectedEPName);
          $('#UserHiddenProps_EP_0 #hdnUserName').val(selectedEPName);
          $('#UserHiddenProps_EP_0 #hdnUserEmail').val(selectedEPEmail);
          this.epDisplayUserName = selectedEPName;
          this.epDisplayUserEmail = selectedEPEmail;
          //$('#hdnValidInput_EP' + '_' + this.peoplepickerIndex).val(true);
          this.validateEP = true;
        }
        $('#' + this.CMSEPCtrl).val(selectedEPName);
        $('#UserHiddenProps_AS_EP_0 #hdnUserName').val(selectedEPName);
        $('#UserHiddenProps_AS_EP_0 #hdnUserEmail').val(selectedEPEmail);
        $('#hdnValidInput_AS_EP' + '_' + this.peoplepickerIndex).val(true);
        $("#EPErrorMessage").text('');
        this.cmsDisplayUserEmail = selectedEPEmail;
        this.cmsDisplayUserName = selectedEPName;
        if (this.EpCtrl === '' && this.CMSEPCtrl !== '') {
          this.validateCMSEP = true;
          $('#modal-CMSEP').modal('hide');
        }
        if (this.engagement.engagementPartner !== selectedEPEmail && this.EpCtrl !== '') {
          $('#EPErrorMessage').text('');
          $('#modal-EP').modal('hide');
          this.membersListArrayForEP = null;
          this.engagement.engagementPartner = selectedEPEmail.trim();
          this.engagement.engagementPartnerName = selectedEPName.trim();
          this.engagement.epContactno = selectedEPPhone.trim();
        }
        else {
          $('#EPErrorMessage').text('Selected EP is already added');
        }
      }
      else {
        $('#EPErrorMessage').text('Please Select User');
      }
    }
    $('#searchCMSEPName').val('');
    //this.membersListArrayForEP = null;
  }

  selectEP(index, text: string) {
    if (text == 'EP') {
      $('#tblEPAD').on('click', 'tbody tr', function () {
        $(this).addClass('highlight').siblings().removeClass('highlight');
      });
    }
    if (text == 'CMSEP') {
      $('#tblEPADCMS').on('click', 'tbody tr', function () {
        $(this).addClass('highlight').siblings().removeClass('highlight');
      });
    }
    this.selectedEPIndex = index;
    console.log('selectEP func called ', this.selectedEPIndex);
  }

  deselectEP() {
    this.membersListArrayForEP = null;
    $('#modal-EP').modal('hide');
    $('#modal-CMSEP').modal('hide');
    $('#searchCMSEPName').val('');
    //$('#' + this.EpCtrl).val('');
    $('#' + this.EpCtrl).innerText = '';
    //$('#' + this.CMSEPCtrl).val('');
    $('#' + this.CMSEPCtrl).innerText = '';
  }

  getTeamMemberRoles() {
    return this._engService.GetTeamMemberRoles().subscribe(
      res => {
        this.teamMemberRoleList = res;
      },
      () => {
        this.loadingScreenService.stopLoading();
        this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
      });

  }

  formatDate(date: any) {
    if ((date == null) || (date == undefined)) {
      // $('.engPeriodDate').val('');
      this.engagement.taxYear = null;
      return true;
    } else {
      const formattedDate = new Date(date);
      const d = formattedDate.getDate();
      let m = formattedDate.getMonth();
      m += 1;  // JavaScript months are 0-11
      const y = formattedDate.getFullYear();
      const finalFormattedDate = (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
      if (finalFormattedDate === '1/1/1' || finalFormattedDate === '1/1/2001' ||
        finalFormattedDate == '0/0/0' || finalFormattedDate.length <= 0 || y <= 1990
        || isNaN(d) == true || isNaN(m) == true || isNaN(y) == true) {
        this.engagement.taxYear = null;
        //  $('#txttaxYear').val('');
        return true;
      } else {
        // $('.engPeriodDate').val(finalFormattedDate);
        date = this.datePipe.transform(date, 'MM/dd/yyyy');
        this.engagement.taxYear = date;
        return false;
      }
    }


  }

  checkCharCodeForNumber(event) {
    if (((event.keyCode >= 48) && (event.keyCode <= 57) || (event.keyCode === 40) || (event.keyCode === 41) || (event.keyCode === 45))) {
      return true;
    }
    return false;
  }

  checkSpaceChartBeginInTableRow(event, i, recordListName: string) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      if (recordListName === 'engagementAssignmentList') {
        this.engagementAssignmentList[i].assignmentName = null;
        return false;
      }
      if (recordListName === 'teamMemberList') {
        this.teamMemberList[i].memeberContactNo = null;
        return false;
      }
      if (recordListName === 'defaultEngAssignment') {
        this.defaultEngAssignment.assignmentName = null;
        return false;
      }
      if (recordListName === ('txtClientEmail_' + i)) {
        $('txtClientEmail_' + i).val('');
        return false;
      }
    }
    else if (text.length > 0) {
      if (recordListName === 'engagementAssignmentList') {
        this.engagementAssignmentList[i].assignmentName = text;
        return false;
      }
      if (recordListName === 'defaultEngAssignment') {
        this.defaultEngAssignment.assignmentName = text;
        return false;
      }
      if (recordListName === ('txtClientEmail_' + i)) {
        $('txtClientEmail_' + i).val(text);
        return true;
      }
    }
  }
  alertServiceFun(message, type) {
    this._alertService.remove(this.alert);
    this._alertService.add(message, type, null);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  CreateEngagement() {
    this.loadingScreenService.startLoading();
    this.createEngagement.teamMember = this.engTeamaMembeListrWithoutAssignment;
    this.createEngagement.engagementAssignment = this.engagementAssignmentWithoutSCode;
    this.createEngagement.engagements = this.engagement;
    this.createEngagement.workspaceData = this.WorkspaceData;
    this.createEngagement.workspaceData.hdTemplateName = $('#engAgreementType :selected').text();
    this.createEngagement.workspaceDocuments = this.AddWorkspaceDocumentList;
    this.createEngagement.loggedInUserFullName = this.UserObject.loggedInUserFullName;
    this.createEngagement.clientLevelDocument = this.UpdatedclientLevelDocument;
    this.createEngagement.defaultIndusConsent = this.ComputeDefaultIndusFlag();
    this.createEngagement.clientMembers = this.clientTeamMembersList;

    let solutionCodeArr = this.createEngagement.engagements.solutionCode.split('-');
    let siteInputRequestData: SiteInputRequest[] = [];
    let engagementSiteInputRequest: SiteInputRequest = new SiteInputRequest();
    engagementSiteInputRequest.siteClassification = 'engagement';
    engagementSiteInputRequest.clientCode = this.localClientCode;
    engagementSiteInputRequest.solutionCode = solutionCodeArr[0].trim();
    siteInputRequestData.push(engagementSiteInputRequest);

    let clientSiteInputRequest: SiteInputRequest = new SiteInputRequest();
    clientSiteInputRequest.siteClassification = 'client';
    clientSiteInputRequest.clientCode = this.localClientCode;
    clientSiteInputRequest.solutionCode = solutionCodeArr[0].trim();
    siteInputRequestData.push(clientSiteInputRequest);

    let decisionsTemplateRequest: DecisionsTemplateRequestModel = new DecisionsTemplateRequestModel();
    decisionsTemplateRequest.templateRequest = siteInputRequestData;

    let engagementDecisionTemplate: DecisionTemplate = new DecisionTemplate();
    let clientDecisionTemplate: DecisionTemplate = new DecisionTemplate();
    const decisionTemplateArr: DecisionTemplate[] = [];

    this._sharepointService.GetDecisionsTemplate(decisionsTemplateRequest)
      .subscribe(result => {
        let tempplateResponse = result;
        if (tempplateResponse.templateSelection != null && tempplateResponse.templateSelection.length > 1) {

          engagementDecisionTemplate.templateName = tempplateResponse.templateSelection[0].templateName;
          engagementDecisionTemplate.policyID = tempplateResponse.templateSelection[0].policyID;
          engagementDecisionTemplate.policyName = tempplateResponse.templateSelection[0].policyName;
          engagementDecisionTemplate.acceptanceFolderPath = tempplateResponse.templateSelection[0].acceptanceFolderPath;
          engagementDecisionTemplate.supplementalFolderPath = tempplateResponse.templateSelection[0].supplementalFolderPath;
          this.createEngagement.acceptanceFolderPath = engagementDecisionTemplate.acceptanceFolderPath;
          this.createEngagement.supplementalFolderPath = engagementDecisionTemplate.supplementalFolderPath;
          decisionTemplateArr.push(engagementDecisionTemplate);

          clientDecisionTemplate.templateName = tempplateResponse.templateSelection[1].templateName;
          clientDecisionTemplate.policyID = tempplateResponse.templateSelection[1].policyID;
          clientDecisionTemplate.policyName = tempplateResponse.templateSelection[1].policyName;
          clientDecisionTemplate.acceptanceFolderPath = tempplateResponse.templateSelection[1].acceptanceFolderPath;
          clientDecisionTemplate.supplementalFolderPath = tempplateResponse.templateSelection[1].supplementalFolderPath;
          decisionTemplateArr.push(clientDecisionTemplate);
        }

        this._engService.insertEngagement(this.createEngagement)
          .subscribe(
            res => {
              this.unsavedAlertfeature_IsSaveChangesOrNextBtnClicked = true;
              this._alertService.remove(this.alert);
              let insertEngagementResultArr = res.split(':');
              this.insertCLMDetailsDB(+insertEngagementResultArr[1].trim());
              this.updateLinkEngagementtoCLM(+insertEngagementResultArr[1].trim());
              this.insertCLMApiLog(+insertEngagementResultArr[1].trim());
              if ((!this.isSaveBtnClicked) && insertEngagementResultArr.length > 0 && insertEngagementResultArr[3] !== 'null') {
                this.redirectUrl = ['Interviews/' + insertEngagementResultArr[3]];
                let siteMembersGroups = this.createEngagement.teamMember.reduce((r, a) => {
                  r[a.teamMemberPermissionId] = [...r[a.teamMemberPermissionId] || [], a];
                  return r;
                }, {});

                console.log("siteMembersGroups", siteMembersGroups);
                let onwwerList: TeamMember[] = [];
                let memberList: TeamMember[] = [];

                let siteGroupTypes = Object.keys(siteMembersGroups);

                siteGroupTypes.forEach(type => {
                  if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
                    onwwerList = this.createEngagement.teamMember.filter(member =>
                      Number(member.teamMemberPermissionId) === Number(type));
                  } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
                    memberList = this.createEngagement.teamMember.filter(member =>
                      Number(member.teamMemberPermissionId) === Number(type));
                  }
                });

                // Site Onwers Email List
                let siteOwnerEmailIds: string[] = [];
                onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); });

                //Site Member Email List
                let siteMemberEmailIds: string[] = [];
                memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); });
                this.DecisionTemplateApiLogs(tempplateResponse.isSuccess, insertEngagementResultArr[3], decisionsTemplateRequest, tempplateResponse);

                this.initinateSharepointSiteProvision(insertEngagementResultArr[1], siteOwnerEmailIds, siteMemberEmailIds,
                  this.createEngagement.engagements, parseInt(insertEngagementResultArr[5]), decisionTemplateArr);


              } else if (insertEngagementResultArr.length > 0 && this.isSaveBtnClicked) {
                // this.redirectUrl = ['MyEngagements'];
                this.redirectUrl = ['EditEngagement/' + insertEngagementResultArr[1]];
                this.loadingScreenService.stopLoading();
                this.router.navigate(this.redirectUrl);
              }
            },
            error => {
              this.loadingScreenService.stopLoading();
              this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
            });

      },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });


  }

  initinateSharepointSiteProvision(engagementId, siteOwnerEmailIds: string[], siteMemberEmailIds: string[],
    engagement: Engagements, collaborationSiteId: number, decisionTemplateArr: DecisionTemplate[] = []) {
    engagement.engagementId = engagementId;

    let siteVisitorEmailIds: string[] = [];
    this._engService.getMetadataList().subscribe((metadata: SPSiteMetadata[]) => {
      const sharepointMetadata: SPSiteMetadata[] = [];
      this._engService.getSharePointMetadataMapping(parseInt(engagementId, 10), collaborationSiteId)
        .subscribe((engagementSharePointData: SharePointMetadataMapping) => {
          engagementSharePointData.propertiesMapping.forEach(item => {
            metadata.forEach(element => {
              if (item.value === element.name) {
                element.value = engagementSharePointData.metadata[item.key];
                sharepointMetadata.push(element);
              }
            });
          });
          this.sharepointSiteModel.siteType = 'Engage';
          this.sharepointSiteModel.engagementId = engagement.engagementId;
          this.sharepointSiteModel.siteUniqueID = engagement.siteUniqueID;
          this.sharepointSiteModel.department = this.departmentList[0].value;
          this.sharepointSiteModel.siteTitle = engagement.engagementName;
          this.sharepointSiteModel.primaryAdmin = this.spProvisionEmailIds.primaryAdmin;
          this.sharepointSiteModel.primaryContact = this.spProvisionEmailIds.primaryContact;
          this.sharepointSiteModel.secondaryContact = this.spProvisionEmailIds.secondaryContact;

          this.sharepointSiteModel.siteOwnerEmailIds = siteOwnerEmailIds;
          this.sharepointSiteModel.siteMemberEmailIds = siteMemberEmailIds;
          this.sharepointSiteModel.siteVisitorEmailIds = siteVisitorEmailIds;
          this.sharepointSiteModel.metadata = sharepointMetadata;
          this.sharepointSiteModel.clientName = engagement.clientName;
          this.sharepointSiteModel.collaborationSiteId = collaborationSiteId;
          this.sharepointSiteModel.primarySiteUniqueId = engagement.siteUniqueID;
          this.sharepointSiteModel.clientSiteDetails = this.GenerateClientSiteUpdatePermissionModel();

          console.log('sharepointSiteModel', this.sharepointSiteModel);

          this.sharepointSiteModel.decisionTemplate = decisionTemplateArr;

          if (this.sharepointSiteModel.metadata.find(m => m.name === 'Site Template Name') !== null
            && this.sharepointSiteModel.metadata.find(m => m.name === 'Site Template Name') !== undefined) {
            this.sharepointSiteModel.metadata.find(m => m.name === 'Site Template Name').value = this.sharepointSiteModel.decisionTemplate[0].templateName;
          }
          console.log('Final sharepointSiteModel ==>', this.sharepointSiteModel);
          // Once get templateName from Decision then procide for sharepoint site provision
          this._sharepointService.CreateProvisionSite(this.sharepointSiteModel).subscribe(
            result => {
              this.AvepointResponseData = result;
              if (this.AvepointResponseData !== null && this.AvepointResponseData !== undefined) {
                if (this.AvepointResponseData.responseStatus === 'Success') {
                  var aepointResponseListModel: AvepointResponseListModel = new AvepointResponseListModel();

                  aepointResponseListModel.avepointResponseArray = this.AvepointResponseData.avepointResponseArray;

                  return this._engService.updateEngagementSiteRequestData(aepointResponseListModel)
                    .subscribe(
                      updateResult => {
                        this.loadingScreenService.stopLoading();
                        this.router.navigate(this.redirectUrl);
                      },
                      error => {
                        this.loadingScreenService.stopLoading();
                        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                      });
                }
                else {
                  var engagementStatusRequestModel: EngagementStatusRequestModel = new EngagementStatusRequestModel();
                  engagementStatusRequestModel.engagementId = engagement.engagementId;
                  engagementStatusRequestModel.status = EngagementStatus.EngSiteError;
                  this._engService.updateEngagementStatusWithError(engagementStatusRequestModel)
                    .subscribe(
                      ErrorResult => {
                        this.loadingScreenService.stopLoading();
                        this.engagement.status = EngagementStatus.EngSiteError;
                        this._alertService.add(this.AvepointResponseData.errorMessage + ' Please contact system administrator.', 'danger', null);
                      },
                      error => {
                        this.loadingScreenService.stopLoading();
                        this._alertService.add(this.AvepointResponseData.errorMessage + ' Please contact system administrator.', 'danger', null);
                      });
                }
              }
            },
            error => {
              this.loadingScreenService.stopLoading();
              this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
            });

        });
    });
  }

  DecisionTemplateApiLogs(isSuccess: boolean, currentWorkspaceId: string, request: DecisionsTemplateRequestModel, response: DecisionsTemplateResponseModel) {
    var decisionRPListModel: DecisionsRPListModel = new DecisionsRPListModel();
    decisionRPListModel.isSuccess = isSuccess;
    decisionRPListModel.workspaceId = currentWorkspaceId;
    decisionRPListModel.decisionsApiLogModel.workspaceId = currentWorkspaceId;
    decisionRPListModel.decisionsApiLogModel.loggedInUser = this.UserObject.loggedInUserEmail;
    decisionRPListModel.decisionsApiLogModel.requestType = DecisionsRequestTypes.TemplateDetermination;
    decisionRPListModel.decisionsApiLogModel.requestPayload = JSON.stringify(request);
    decisionRPListModel.decisionsApiLogModel.responseBody = JSON.stringify(response);
    console.log('decisionRPListModel', decisionRPListModel);

    this._workspaceService.SaveDecisionDetails(decisionRPListModel).subscribe((count: number) => {
      if (count > 0) {
        console.log('Save Decision Details captured successfully !');
      }
    },
      err => {
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      })
  }
  invalidCharacterDetectionAssignmentName() {
    this.invalidvaluesAN = "";
    var value = this.defaultEngAssignment.assignmentName;
    const validvalues = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890&-_()',. ";
    for (let char of value) {
      if (validvalues.indexOf(char) === -1 && this.invalidvaluesAN.indexOf(char) === -1) {
        this.invalidvaluesAN += char;
      }
    }
  }

  invalidCharacterDetectionEngagementName() {
    this.invalidvalues = "";
    var value = this.engagement.engagementName;
    const validvalues = "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890&-_()',. ";
    for (let char of value) {
      if (validvalues.indexOf(char) === -1 && this.invalidvalues.indexOf(char) === -1) {
        this.invalidvalues += char;
      }
    }
  }


  CheckEngagementNameLimit() {
    this.invalidCharacterDetectionEngagementName();
    let engName = $('.engagement-name').val();
    var engagementNameLength = engName?.length;
    var engagementRemainingLimit = this.maxEngagementNameLimit - engagementNameLength;
    $('#engagement-remaining-chars').text(engagementRemainingLimit);
    if (this.EditMode) {
      this.lblCollaborationSiteName = engName;
    }
    if (this.engagement.engagementName != null && this.engagement.engagementName !== '') {
      this.engagement.engagementName = this.engagement.engagementName.replace('<', '');
    }
  }

  ClientValidation(event) {
    console.log('ClientValidation func called ', event);
    var clientValidationDiv = $('#clientValidationMessage');
    clientValidationDiv.removeClass('d-none');
    clientValidationDiv.text(event);
    this.isValidClient = false;
  }

  onSearch(search: string) {
    this.clientSearch.onSearch(search);
  }

  clientChange() {
    var clientValidationText = $('#clientValidationMessage').text();
    this.clientOneViewAccountId = '';
    if (clientValidationText.length === 0) {
      this.isValidClient = true;
      const clientId = $('#hdnKey' + this.MappedValue + 0).val();
      const clientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();
      this.clientOneViewAccountId = $('#hdnclientOneViewAccountId' + this.MappedValue + 0).val();
      if (clientId.length > 0) {
        this.unsavedAlertfeature_AnyFormFieldChanged = true;
        this.setUnsavedChangesForIwantTo();

        this.getDefaultClientBillingContactDetails(clientId);
        this.engagement.clientId = clientId;
        if (this.EditMode) {
          this.dataService.setClientIdForPrePopulate(this.engagement.clientId);
        }
        this.restartAssignment();
        this.CancelOpportunityPopUp();
        this.LoadGoverningAgreementsFromCLM();
        if (this.isClientConsentFeatureEnabled) {
          this.getClientConsent(clientCode, this.clientOneViewAccountId);
        }
      }
    }
  }

  displayClientOption(event) {
    if (event === 'hide') {
      $('#CreateNewClient').hide();
      $('#CreateNewClientBtn').hide();
    } else {
      $('#CreateNewClient').show();
      $('#CreateNewClientBtn').show();
    }
  }

  NavigateToCreateClient() {
    this.dataService.setReturnUrlForCreateClient(this.router.url);
    this.dataService.setNewClientName($('#txtValue' + this.MappedValue + 0).val());
    this.router.navigate(['/NewClient']);
  }

  getTemplateMappingData(isSolutionCodeChanged: boolean) {
    this._configService.getSolutionCodeTemplateMappingData(this.documentCategory, this.engagement.parentSolutionCodeId, true).subscribe(result => {
      if (result.length > 0) {
        this.TemplateList = result;
        if (this.engagement.clientId > 0) {
          this.getClientLevelDocuments(this.engagement.clientId, isSolutionCodeChanged);
        }
      } else {
        this.TemplateList = [];
        this.SelectedTemplateList = [];
        this.engagement.agreementType = 'undefined';
        this.ValidateTemplateMapping();
      }
    });
  }

  expandTeamDetails(index, isVisible: boolean) {
    $('#teamdetails_' + index).toggle(500);
    this.assignmentTeamMemberList[index].isTeamDetailsVisible = isVisible;
  }

  GetTeamMemberPermissions() {
    this.applicationMasterService.GetTeamMemberPermissions().subscribe(
      result => {
        this.teamMemberPermissions = result;
      }
    );
  }

  addAssignMembers() {
    const EPPMDEmailId = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
    //First check if current user is present in list
    let EPPMDIndex = this.getTeamMemberListIndex(EPPMDEmailId);

    //Add EPPMD to list ifnot present
    if (EPPMDIndex < 0) {
      this.addNewTeamMember(null, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP));
    }

    //Add EQCR
    this.updateEQCR();
    //process other team members to add
    let CMSEPPMDIndex = this.getTeamMemberListIndex(this.defaultEngAssignment.cmsEPPMDEmailId);
    let AdminIndex = this.getTeamMemberListIndex(this.defaultEngAssignment.adminEmailId);

    //Add CMS EPPMSD to list if its different than EPPMD
    if (CMSEPPMDIndex < 0 && EPPMDEmailId != this.defaultEngAssignment.cmsEPPMDEmailId) {
      this.addNewTeamMember(this.defaultEngAssignment, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP));
    }
    //Add Admin Data to list if its different than EPPMD
    if (AdminIndex < 0 && EPPMDEmailId != this.defaultEngAssignment.adminEmailId) {
      this.addNewTeamMember(this.defaultEngAssignment, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A));
    }
    //Add Creator to list if not present
    this.addTeammemberCreatorData();

  } // end addAssignMembers

  ValidateTeamMemberInExistingAssignment() {
    this.cmsDataMatch = false;
    this.IsValidInput = true;
    for (let t = 0; t < this.arrValidationForNewTeamMember.length; t++) {
      this.arrValidationForNewTeamMember[t].memberName = $('#txtPeoplePicker_' + this.teamMemberRoleName + '_' + t).val();
      this.arrValidationForNewTeamMember[t].teamMemberRole = this.isAudit ? $('#drpKeyTeamMemberRole_' + t).val() : $('#drpTeamMemberRole_' + t).val();
      this.arrValidationForNewTeamMember[t].teamMemberPermissionId = Number($('#drpTeamMemberPermissions_' + t).val());
      this.arrValidationForNewTeamMember[t].ishdnValidInputName = (/true/i).test($('#hdnValidInput_' + this.teamMemberRoleName + '_' + t).val());
      this.arrValidationForNewTeamMember[t].isNewUserDuplicate = false;
      this.arrValidationForNewTeamMember[t].isUserExist = false;
      this.arrValidationForNewTeamMember[t].memberEmailId = $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserEmail').val();

      if (this.arrValidationForNewTeamMember[t].memberName === '' ||
        this.arrValidationForNewTeamMember[t].memberName === null ||
        this.arrValidationForNewTeamMember[t].memberName === undefined) {
        $('#errorLabelMemberName' + t).removeClass('d-none');
        $('#errorLabelMemberName' + t).addClass('d-block');
      } else {
        $('#errorLabelMemberName' + t).removeClass('d-block');
        $('#errorLabelMemberName' + t).addClass('d-none');
      }
      if (this.arrValidationForNewTeamMember[t].teamMemberRole === null ||
        this.arrValidationForNewTeamMember[t].teamMemberRole === '' ||
        this.arrValidationForNewTeamMember[t].teamMemberRole === undefined ||
        this.arrValidationForNewTeamMember[t].teamMemberRole === "null") {
        $('#errorLabelMemberRole' + t).removeClass('d-none');
        $('#errorLabelMemberRole' + t).addClass('d-block');
        this.arrValidationForNewTeamMember[t].teamMemberRole = null;
      } else {
        $('#errorLabelMemberRole' + t).removeClass('d-block');
        $('#errorLabelMemberRole' + t).addClass('d-none');
      }
      if (this.arrValidationForNewTeamMember[t].teamMemberPermissionId === null ||
        this.arrValidationForNewTeamMember[t].teamMemberPermissionId === undefined ||
        this.arrValidationForNewTeamMember[t].teamMemberPermissionId === -1) {
        $('#errorLabelMemberPermission' + t).removeClass('d-none');
        $('#errorLabelMemberPermission' + t).addClass('d-block');
        this.arrValidationForNewTeamMember[t].teamMemberPermissionId = null;
      } else {
        $('#errorLabelMemberPermission' + t).removeClass('d-block');

        $('#errorLabelMemberPermission' + t).addClass('d-none');
      }
      if (this.arrValidationForNewTeamMember[t].memberName === '' ||
        this.arrValidationForNewTeamMember[t].teamMemberRole === null ||
        this.arrValidationForNewTeamMember[t].teamMemberPermissionId === null ||
        this.arrValidationForNewTeamMember[t].ishdnValidInputName === false ||
        this.arrValidationForNewTeamMember[t].memberEmailId === '' ||
        this.arrValidationForNewTeamMember[t].memberEmailId === null ||
        this.arrValidationForNewTeamMember[t].memberEmailId === undefined) {
        this.IsValidInput = false;
      }
    }
    // for loop to check duplicate
    for (let t = 0; t < this.arrValidationForNewTeamMember.length; t++) {
      if (this.CheckDuplicateElement(this.arrValidationForNewTeamMember, 'memberEmailId', this.arrValidationForNewTeamMember[t].memberEmailId)) {
        this.arrValidationForNewTeamMember[t].isNewUserDuplicate = true;
      }
    }
    for (let j = 0; j < this.engTeamaMembeListrWithoutAssignment.length; j++) {
      for (let t = 0; t < this.arrValidationForNewTeamMember.length; t++) {
        if (this.arrValidationForNewTeamMember[t].memberEmailId.toLowerCase() ===
          this.engTeamaMembeListrWithoutAssignment[j].memberEmailId.toLowerCase() || this.arrValidationForNewTeamMember[t].isNewUserDuplicate) {
          this.cmsDataMatch = true;
          this.arrValidationForNewTeamMember[t].isUserExist = true;
          $('#userExist' + t).removeClass('d-none');
          $('#userExist' + t).attr('style', 'display:block');
          break;
        }
        // }
        if (this.cmsDataMatch === false) {
          $('#userExist' + t).attr('style', 'display:none');
        }
      }
    }

    console.log('Multiple Team Member Validation ', this.IsValidInput);

  } // end UpdateTeamMemberInExistingAssignment()

  ValidateKeyTeamMemberRolesInManageTeam() {
    let isValidKeyTeamMember = true;
    if (this.isAudit) {
      for (let t = 0; t < this.engTeamaMembeListrWithoutAssignment.length; t++) {
        if (this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole !== this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP) &&
          this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole !== this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR) &&
          this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole !== this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A) &&
          this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole !== this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP)
        ) {
          if (this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId === null ||
            this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId.toString() === "null" ||
            this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId === undefined ||
            this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId.toString() === "") {
            $('#errorLabelkeyTeamMemberRole' + t).removeClass('d-none');
            $('#errorLabelkeyTeamMemberRole' + t).addClass('d-contents');
            isValidKeyTeamMember = false;
          } else {
            $('#errorLabelkeyTeamMemberRole' + t).removeClass('d-contents');
            $('#errorLabelkeyTeamMemberRole' + t).addClass('d-none');
          }
        }
      }
      if (!isValidKeyTeamMember) {
        this.expandAllTeamDetails();
      }
    }
    return isValidKeyTeamMember;
  }

  updateEPPMDinTeamMemberListIfNotExists() {
    let EProleId = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP);
    let index = this.engTeamaMembeListrWithoutAssignment.findIndex(str => str.teamMemberRole === EProleId);
    if (index < 0) {
      this.addNewTeamMember(null, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP));
    }
  }

  EditAssignmentTeamMembers(AssignmentIndex) { // Assignment index
    //Add EQCR
    this.updateEQCR();

    //Add EPPMD if not exists
    this.updateEPPMDinTeamMemberListIfNotExists();

    //Update other team members
    let CMSEPPMDIndex = this.getTeamMemberListIndex(this.arrEngagementAssignment[AssignmentIndex].cmsEPPMDEmailId);

    //Add CMS EPPMSD to list if its different than EPPMD
    if (CMSEPPMDIndex < 0 && !this.checkEPPMDMatch(this.arrEngagementAssignment[AssignmentIndex].cmsEPPMDEmailId)) {
      this.addNewTeamMember(this.defaultEngAssignment, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP));
      CMSEPPMDIndex = this.getTeamMemberListIndex(this.arrEngagementAssignment[AssignmentIndex].cmsEPPMDEmailId);
    }

    //Add Admin Data to list if its different than EPPMD
    let AdminIndex = this.getTeamMemberListIndex(this.arrEngagementAssignment[AssignmentIndex].adminEmailId);

    if (AdminIndex < 0 && !this.checkEPPMDMatch(this.arrEngagementAssignment[AssignmentIndex].adminEmailId)) {
      this.addNewTeamMember(this.defaultEngAssignment, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A));
      AdminIndex = this.getTeamMemberListIndex(this.arrEngagementAssignment[AssignmentIndex].adminEmailId);
    }

    this.removeEngAssignmetTeammemberData(AdminIndex, CMSEPPMDIndex, -1);
  }

  FilterAssignmentList() {
    this.engagementAssignmentWithoutSCode = [];
    for (let i = 0, length = this.arrEngagementAssignment.length; i < length; i++) {
      let tempAssignmentName: string = '';
      if (this.arrEngagementAssignment[i].opportunityServiceId != null && !this.arrEngagementAssignment[i].isPrimaryAssignment) {
        if (this.arrEngagementAssignment[i].assignmentName == this.blankLineAssignmentName) {
          tempAssignmentName = '';
        } else {
          tempAssignmentName = this.arrEngagementAssignment[i].assignmentName;
        }
      } else {
        tempAssignmentName = this.arrEngagementAssignment[i].assignmentName;
      }
      this.newEngAssignment = {
        assignmentCodeId: this.arrEngagementAssignment[i].assignmentCodeId,
        engagementId: this.arrEngagementAssignment[i].engagementId,
        solutionCodeId: this.arrEngagementAssignment[i].solutionCodeId,
        assignmentName: tempAssignmentName,
        assignmentAdministrator: this.arrEngagementAssignment[i].assignmentAdministrator,
        realization: this.arrEngagementAssignment[i].realization == null ? 0 : this.arrEngagementAssignment[i].realization,
        netFees: this.arrEngagementAssignment[i].netFees,
        createdBy: this.arrEngagementAssignment[i].createdBy,
        createdDate: this.arrEngagementAssignment[i].createdDate,
        lastModifiedBy: this.arrEngagementAssignment[i].lastModifiedBy,
        lastModifiedDate: this.arrEngagementAssignment[i].lastModifiedDate,
        adminEmailId: this.arrEngagementAssignment[i].adminEmailId,
        adminContactNo: this.arrEngagementAssignment[i].adminContactNo,
        assignmentCode: this.arrEngagementAssignment[i].assignmentCode != null ? this.arrEngagementAssignment[i].assignmentCode : '0000',
        cmsEPPMDName: this.arrEngagementAssignment[i].cmsEPPMDName,
        cmsEPPMDEmailId: this.arrEngagementAssignment[i].cmsEPPMDEmailId,
        engagementTypeId: this.arrEngagementAssignment[i].engagementTypeId,
        isContingent: this.arrEngagementAssignment[i].isContingent == null ? false : this.arrEngagementAssignment[i].isContingent,
        isPrimaryAssignment: this.arrEngagementAssignment[i].isPrimaryAssignment,
        engagementStartDate: this.arrEngagementAssignment[i].engagementStartDate,
        engagementEndDate: this.arrEngagementAssignment[i].engagementEndDate,
        estimatedGrossMargin: this.arrEngagementAssignment[i].estimatedGrossMargin,
        officeLocationCodeId: this.arrEngagementAssignment[i].officeLocationCodeId,
        clientBillingContactName: this.arrEngagementAssignment[i].clientBillingContactName,
        clientBillingContactTitleId: this.arrEngagementAssignment[i].clientBillingContactTitleId,
        clientBillingContactEmail: this.arrEngagementAssignment[i].clientBillingContactEmail,
        clientCode: this.arrEngagementAssignment[i].clientCode != null ? this.arrEngagementAssignment[i].clientCode : '000',
        eqcrName: this.arrEngagementAssignment[i].eqcrName,
        eqcrEmailId: this.arrEngagementAssignment[i].eqcrEmailId,
        opportunityServiceId: this.arrEngagementAssignment[i].opportunityServiceId,
        status: this.arrEngagementAssignment[i].status
      };
      this.engagementAssignmentWithoutSCode.push(this.newEngAssignment);
    }
  }

  engagementNextClick() {
    $('#clientErrorMessage').text('');
    this.isvalidPED = true;
    this.isvalidEFD = true;
    this.isvalidEXD = true;
    this._alertService.remove(this.alert);
    this.isSaveBtnClicked = false;
    this.MandatoryFieldValidationForEngagement();
    this.FilterAssignmentList();
    this.SetEngagementValues();
    this.setClientLevelDocuments();
    var PeriodDate = new Date($('.engPeriodDate').val());
    let periodDate = $('.engPeriodDate').val();
    let effectiveDate = $('.' + this.msaEffectiveDateCntrlId).val();
    let expirationDate = $('.' + this.msaExpirationDateCntrlId).val();
    this.validateDate(periodDate, 'PED');
    this.validateDate(effectiveDate, 'EFD');
    this.validateDate(expirationDate, 'EXD');
    if (this.isPeriodRequire === true && (this.formatDate(PeriodDate))) {
      console.log('dateValidation:', this.formatDate(PeriodDate));
      this.isValidPeriodDate = true;
    } else {
      this.isValidPeriodDate = false;
    }
    this.isvalidEngEPPMDName = Boolean(JSON.parse($('#hdnValidInput_EP_0').val()));
    if (this.checkMandatoryValidations(this.arrValidationForEngagement, this.engagement, '') &&
      this.ValidateKeyTeamMemberRolesInManageTeam() &&
      this.isValidClient && this.isvalidPED && this.isvalidEFD && this.isvalidEXD &&
      this.isvalidEngEPPMDName &&
      (!this.isValidPeriodDate) &&
      (this.ValidateSubDocumentTypeSection()) &&
      !this.isEPMatchEQCR &&
      !(this.conveneProjectDetailsResponse == null && this.baseConeveneProjectInitiationModel.isCloneProject
        && this.engagement.status == EngagementStatus.EngNotStarted && this.isConveneIntegrationFlagEnabled && this.isContinuanceEngagement)
    ) {
      this._engService.isEngagementExists(this.engagement.clientId, this.engagement.engagementName)
        .subscribe(
          res => {
            this.engagementCount = res;
            if (this.engagementCount > 0 && !this.EditMode) {
              this.checkEngagementExists = true;
            }
            else if (this.engagementCount > 0 &&
              this.originalEngagementName.toLowerCase() != this.engagement.engagementName.toLowerCase() && this.EditMode) {
              this.checkEngagementExists = true;
            }
            else {
              this.checkEngagementExists = false;
            }
            console.log(this.engagementCount);

            if (!this.BusinessValidationCheckForEngagement()
              && !this.checkTeammemberOwnerCountBusinessValidation()
              && this.validateEngagementName()
              && !this.checkEngagementExists
              && this.ValidatePreRequestPopupConfirmation()) {
              this.loadingScreenService.startLoading();
              console.log("Site Unique Id:")
              if (!this.EditMode) {
                this.GenerateSiteUniqueId();
              }
              else if (this.EditMode) {
                this.insertCLMDetailsDB(this.currentEngagementId);
                this.updateLinkEngagementtoCLM(this.currentEngagementId);
                return this._engService.GetEditEngagementDetailsById(this.currentEngagementId)
                  .subscribe(
                    result => {
                      this.oldEngagementData = result;
                      if (this.oldEngagementData?.memberFirmMappingId > 0) //if engagement is member firm then load member firm details
                        this.memberFirmService.getMemberFirmDetailsById(this.oldEngagementData?.memberFirmMappingId).subscribe(memberFirmData => {
                          this.memberFirmData = memberFirmData
                        })
                      console.log('OLD ENGAGEMENT OBJECT:', this.oldEngagementData);
                      if (this.oldEngagementData.agreementType === this.engagement.agreementType) {
                        this.isAgreementTypeChanged = false;
                      } else {
                        this.isAgreementTypeChanged = true;
                      }

                      if (this.oldEngagementData.engagementName === this.engagement.engagementName) {
                        this.isEngagementNameChanged = false;
                      } else {
                        this.isEngagementNameChanged = true;
                      }

                      if (this.oldEngagementData.solutionCode === this.engagement.solutionCode) {
                        this.isSolutionCodeChanged = false;
                      } else {
                        this.isSolutionCodeChanged = true;
                      }

                      if (this.isValidWorkspaceStatus()) {
                        if (this.oldEngagementData.agreementType == null ||
                          this.oldEngagementData.agreementType === '' ||
                          this.oldEngagementData.agreementType === 'undefined' ||
                          (this.isContinuanceEngagement && this.WorkspaceObject.status === WorkspaceInterviewsStatus.NotStarted)) {
                          this.createNewWorkItem = true;
                        } else if (this.isAgreementTypeChanged && this.oldEngagementData.agreementType != null) {
                          this.CreateWorkItemData.isDelete = true;
                          this.CreateWorkItemData.id = this.WorkspaceObject.hdWorkItemId;
                          this.createNewWorkItem = true;
                        }
                        this.CreateWorkItem();
                      } else {
                        this.loadingScreenService.stopLoading();
                        if (this.oldEngagementData.status === EngagementStatus.EngRPRuleEngineError) {
                          this.setDialogContent('danger', WorkspaceInterviewsStatus.EngRPRuleEngineError);
                        }
                        else {
                          this.setDialogContent('danger', WorkspaceInterviewsStatus.ReviewStatus);
                        }
                      }
                    },
                    error => {
                      this.loadingScreenService.stopLoading();
                      this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
                    });
              } // end else if edit mode

            }//end if businessvalidation
            else {
              this.scrollToTop();
            }
          },
          () => {
            this.checkEngagementExists = false;
            this.loadingScreenService.stopLoading();
            this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
          });

    } else {
      this.scrollToTop();
    }//end of else
  }

  TemplateChange(value) {
    this.SelectedTemp.templatePackageId = value;
    this.SelectedTemp.templateName = $('#engAgreementType :selected').text();
    console.log('Selected Template on Change', this.SelectedTemp.templatePackageId);
  }
  EngNameChange(event) {
    this.currentEngagementName = event.currentTarget.value.trim();
    this.lblCollaborationSiteName = this.currentEngagementName;
    this.prefilENName();
    this.invalidCharacterDetectionEngagementName();
    this.LoadGoverningAgreementsFromCLM();
  }

  CreateWorkItem() {
    this.CreateWorkItemData.templatePackageId = $('#engAgreementType').val();
    let flag: string;
    if (this.createNewWorkItem) {
      this._workspaceService.getMaxWorkspaceId().subscribe(
        result => {
          if (result <= 0) {
            result = 1;
          }
          this.CreateWorkItemData.workspaceCode = result;
          this.CreateWorkItemData.siteUniqueId = this.engagement.siteUniqueID;
          if (this.OldWorkItemCreatedDetails != undefined && this.OldWorkItemCreatedDetails != null) {
            this.CreateWorkItemData.originalWorkItemId = this.OldWorkItemCreatedDetails.id != null ? this.OldWorkItemCreatedDetails.id : "";
          }
          this.ProcessWorkItemCreation();
        },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });

    } else {
      this.WorkspaceData.hdWorkItemId = this.WorkspaceObject.hdWorkItemId;
      this.WorkspaceData.hdWorkGroupId = this.WorkspaceObject.hdWorkGroupId;
      this.WorkspaceData.hdTemplatePackageId = this.WorkspaceObject.hdTemplatePackageId;
      this.WorkspaceData.hdCurrentTempVersion = this.WorkspaceObject.hdCurrentTempVersion;
      this.WorkspaceData.workspaceId = this.WorkspaceObject.workspaceId;
      this.WorkspaceData.status = this.WorkspaceObject.status;
      this.EditEngagement();
    }
  }

  ProcessWorkItemCreation() {
    this._hdService.GetCreateWorkItemDocumentData(this.CreateWorkItemData).subscribe(
      workItemResult => {
        this.OldWorkItemCreatedDetails = workItemResult.hdWorkItem;
        this.WorkItemDetails = workItemResult.hdWorkItem;
        this.WorkspaceData.hdWorkGroupId = this.WorkItemDetails.workGroupId;
        this.WorkspaceData.hdWorkItemId = this.WorkItemDetails.id;
        this.WorkspaceData.hdTemplatePackageId = this.WorkItemDetails.templatePackageId;
        this.WorkspaceData.hdCurrentTempVersion = this.WorkItemDetails.templateCurrentVersion;
        if (this.EditMode) {
          this.WorkspaceData.workspaceId = this.WorkspaceObject.workspaceId;
          this.WorkspaceData.status = this.WorkspaceObject.status;
        } else {
          if (this.isSaveBtnClicked)
            this.WorkspaceData.status = 'Not started';
          else {
            this.WorkspaceData.status = 'Draft';
          }
        }
        this.WorkspaceData.hdTemplatePackageVersionId = this.WorkItemDetails.templatePackageVersionId;

        this.getDocumentListtoPreview(workItemResult);
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });

  }

  getDocumentListtoPreview(workItemResult: HDWorkItemDocumentModel) {
    if (workItemResult.hdWorkItem.id != null) {
      this.AddWorkspaceDocumentList = [];
      var DocumentList = new WorkspaceDocumentModel();
      workItemResult.hdWorkspaceDocumentList.forEach(x => {
        DocumentList.hdDocumentId = x.documentId;
        DocumentList.hdDocumentName = x.name;
        DocumentList.hdMimeType = x.mimeType;
        DocumentList.hdAssemblySession = x.assemblySessionId;
        DocumentList.isActive = true;
        DocumentList.tileName = this.documentCategory;
        DocumentList.hdTemplatePackageVersionId = this.WorkItemDetails.templatePackageVersionId;
        DocumentList.engagementState = "Acceptance";
        this.AddWorkspaceDocumentList.push(DocumentList);
      });
      if (this.EditMode) {
        this.EditEngagement();
      } else {
        console.log('Create engagement called');
        this.CreateEngagement();
      }
    }
  }

  SetEngagementValues() {
    var date = $('.engPeriodDate').val();
    let periodDate;
    if (date === null || date === 'undefined' || date == '') {
      this.engagement.taxYear = null;
    } else {
      periodDate = new Date(date);
      this.formatDate(periodDate);
    }
    this.engagement.engagementPartnerName = $('#UserHiddenProps_EP_0 #hdnUserName').val();
    this.engagement.engagementPartner = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
    this.engagement.epContactno = $('#UserHiddenProps_EP_0 #hdnUserPhone').val();
    this.engagement.ePPMDOfficeLocation = $('#UserHiddenProps_EP_0 #hdnOfficeLocation').val();
    this.engagement.parentSolutionCodeId = parseInt($('#hdnKey' + this.SolutionCodeMappedValue + 0).val());

    var clientValidationText = $('#clientValidationMessage').text();
    if (clientValidationText.length === 0) {
      this.engagement.clientId = parseInt($('#hdnKey' + this.MappedValue + 0).val().split('-')[0]);
      let clientName = $('#txtValue' + this.MappedValue + 0).val();
      const clientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();
      if (clientCode.length > 0) {
        clientName = clientName.replace(' (' + clientCode + ')', '');
      }
      this.engagement.clientName = clientName;
    } else {
      this.engagement.clientId = 0;
      this.engagement.clientName = "";
    }

    this.engagement.engagementName = this.currentEngagementName;
    this.engagement.solutionCode = $('#txtValue' + this.SolutionCodeMappedValue + 0).val();
    this.engagement.agreementType = $('#engAgreementType').val();
    this.engagement.createdBy = this.UserObject.loggedInUserEmail;
    this.engagement.lastModifiedBy = this.UserObject.loggedInUserEmail;
    if (!this.EditMode) {
      if (this.isSaveBtnClicked)
        this.engagement.status = EngagementStatus.EngNotStarted;
      else
        this.engagement.status = EngagementStatus.EngDraft;
    }
    this.ValidateClientData();
    if (this.EditMode) {
      if (this.engagement.engagementPartner.length > 0 && this.engagement.engagementPartnerName.length > 0)
        $('#hdnValidInput_EP_0').val(true);
    }
    this.engagement.isByPass = this.isByPass;
    this.engagement.clientLevelDocumentId = this.SelectedClientLevelDocId;
  }

  getTeamMemberRoleList() {
    let applicationName = 'TeamMemberRole';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        //this.applicationmasterList = result;
        result.forEach(element => {
          const teamMemberRoleCode = new AppCodes();
          teamMemberRoleCode.code = element.code;
          teamMemberRoleCode.codeValue = element.applicationMasterId;
          this.teamMemberRoleCodes.push(teamMemberRoleCode);
          this.applicationmasterList.push(element);
          if (element.code !== 'EP' && element.code !== 'C' && element.code !== 'A'
            && element.code !== 'CMSEP' && element.code !== 'PR') {
            this.applicationmasterListIE.push(element);
            this.teamMemberRoleFromAppMstList.push(element);
          }
        });
        console.log(this.teamMemberRoleCodes);
      }
    );
  }

  getKeyTeamMemberRoleList() {
    let applicationName = 'KeyTeamMemberRole';
    this.applicationMasterService.GetAllTeamMemberRoles(applicationName).subscribe(
      result => {
        result.forEach(element => {
          const keyTeamMemberRoleCode = new AppCodes();
          keyTeamMemberRoleCode.code = element.code;
          keyTeamMemberRoleCode.codeValue = element.applicationMasterId;
          this.keyTeamMemberRoleCodes.push(keyTeamMemberRoleCode);
          //this.applicationmasterListIE.push(element);
          this.keyteamMemberRoleFromAppMstList.push(element);
        });
        this.keyteamMemberRoleFromAppMstList = this.keyteamMemberRoleFromAppMstList.sort((a, b) => Number(a.applicationMasterId) - Number(b.applicationMasterId));
        console.log('Key team member : ' + result);
      }
    );
  }

  getTeamMemberRoleIdByCode(Code: string): number {
    return this.teamMemberRoleCodes.find(c => c.code === Code).codeValue;
  }

  getKeyTeamMemberRoleIdByCode(Code: string): number {
    return this.keyTeamMemberRoleCodes.find(c => c.code === Code).codeValue;
  }

  collapseAllTeamDetails() {
    $('.teamdetailsAll').fadeOut(500);
  }

  expandAllTeamDetails() {
    $('.teamdetailsAll').toggle(500);
  }

  MandatoryFieldValidationForEngagement() {
    this.arrValidationForEngagement = [];
    if (this.isSaveBtnClicked) {
      this.arrValidationForEngagement.push(new FieldValidator('clientName', this.engagement.clientName, false));
      this.arrValidationForEngagement.push(new FieldValidator('engagementName', this.engagement.engagementName, false));
      this.arrValidationForEngagement.push(new FieldValidator('solutionCode', this.engagement.solutionCode, false));
    } else {
      this.arrValidationForEngagement.push(new FieldValidator('clientName', this.engagement.clientName, false));
      this.arrValidationForEngagement.push(new FieldValidator('engagementName', this.engagement.engagementName, false));
      this.arrValidationForEngagement.push(new FieldValidator('solutionCode', this.engagement.solutionCode, false));
      this.arrValidationForEngagement.push(new FieldValidator('engagementPartnerName', this.engagement.engagementPartnerName, false));
      this.arrValidationForEngagement.push(new FieldValidator('agreementType', this.engagement.agreementType, false));
    }
  }

  BusinessValidationCheckForEngagement() {
    let result: boolean = false;
    this.isAssignmentNotPresent = false;
    this.isAssignmentNameNotPresent = false;
    if (this.engagementAssignmentWithoutSCode.length <= 0) {
      this.isAssignmentNotPresent = true;
      this.setDialogContent('danger', EngagementDialogTitles.CreateEng);
      result = true;
    } else if (this.CheckContinuanceAssignmentName() && this.isContinuanceEngagement) {
      this.isAssignmentNameNotPresent = true;
      this.setDialogContent('danger', EngagementDialogTitles.CreateEng);
      result = true;
    } else if (this.CheckBlankAssignmentNameExistsList() && (this.engagement.opportunityId != '' || this.engagement.opportunityId != undefined)) {
      this.isAssignmentNameNotPresent = true;
      this.setDialogContent('danger', EngagementDialogTitles.CreateEng);
      result = true;
    }
    else if (!this.CheckAssignmentMandatoryFieldsInist() && (this.engagement.opportunityId != '' || this.engagement.opportunityId != undefined)) {
      this.isAssignmentMandatoryFieldsBlank = true;
      this.setDialogContent('danger', EngagementDialogTitles.CreateEng);
      result = true;
    }
    return result;
  }

  scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  displayDialog(type: string, title: string, description: string, button: number, bigTitle: boolean, isContinuanceEngExists: boolean = false) {
    var modalObj = {
      dialogType: type,
      dialogTitle: title,
      dialogDescription: description,
      dialogRedirectUrl: '',
      dialogButton: button,
      modalDisplay: true,
      dialogBigTitle: bigTitle,
      dialogisContinuanceEngagementExistsModel: isContinuanceEngExists,
      dialogcontinuanceDashBoardUrl: isContinuanceEngExists ? '/Continuance' : ''
    };
    var modalJSON = JSON.stringify(modalObj);
    console.log(' showDialog func called information');
    this.dataService.displayDialogNotification(modalJSON);
  }

  setCancelDialog() {
    this.invalidvalues = "";
    this.invalidvaluesAN = "";
    this.RedirectOnCancel();
    $('#clientErrorMessage').text('');
  }

  setDialogContent(type: string, title: string) {
    let description: string;
    let button: number = 2;
    let bigTitle = false;
    switch (title) {
      case EngagementDialogTitles.CreateEng:
        if (this.isAssignmentNotPresent) {
          description = 'At least one assignment is required to create a new engagement.';
        } else if (this.isAssignmentNameNotPresent) {
          description = 'Assignment name is missing for one or more engagement assignments.';
        } else if (this.isAssignmentMandatoryFieldsBlank) {
          description = 'Assignment mandatory field information is missing for one or more engagement assignment(s).';
        }
        button = 1;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      case WorkspaceInterviewsStatus.ReviewStatus:
        description = 'The status of this engagement changed during your review.  Click OK to view the most current information.';
        button = 1;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      case WorkspaceInterviewsStatus.EngRPRuleEngineError:
        description = 'Engagement has RP rule engine error. Please contact system administrator.';
        button = 1;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.RemoveAssignment:
        description = 'Are you sure you want to remove this assignment and the ability to request this assignment code now?';
        button = 2;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.SubmitAssignment:
        description = 'You are about to request your CMS assignment code(s). Once requested, any updates to the code information must be made via the Centralized Billing Share tool. Do you want to proceed?';
        button = 2;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.UnsavedChangesConfirmation:
        description = 'You have changes that have not been saved. Click "Cancel" to return to the page to update or save your changes, or click "OK" to continue to leave the page without saving your changes.';
        button = 2;
        bigTitle = true;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.SaveEngagement:
        description = "To save changes to this draft, the following fields must be completed: client, engagement name, and primary solution code.";
        button = 1;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      case EngagementDialogTitles.ContinuanceEngExists:
        description = '';
        button = 1;
        console.log('ContinuanceEngExists==');
        this.displayDialog(type, title, description, button, bigTitle, (this.continuanceEngagementCount > 0 ? true : false));
        break;
      case EngagementDialogTitles.InterChangeableSolutionCodes:
        description = 'You are selecting an alternate solution code that will result in a change in the linked opportunity in oneView.';
        button = 2;
        this.displayDialog(type, title, description, button, bigTitle);
        break;
      default:
        break;
    }
  }

  saveEngagement() {
    this.isSaveBtnClicked = true;
    this.isvalidPED = true;
    this.isvalidEXD = true;
    this.isvalidEFD = true;
    let PeriodDate = $('.engPeriodDate').val();
    let effectiveDate = $('.' + this.msaEffectiveDateCntrlId).val();
    let expirationDate = $('.' + this.msaExpirationDateCntrlId).val();
    this.MandatoryFieldValidationForEngagement();
    this.FilterAssignmentList();
    this.SetEngagementValues();
    this.setClientLevelDocuments();
    this.validateDate(PeriodDate, 'PED');
    this.validateDate(effectiveDate, 'EFD');
    this.validateDate(expirationDate, 'EXD');
    setTimeout(() => {
      if (this.checkMandatoryValidations(this.arrValidationForEngagement, this.engagement) && this.isvalidPED &&
        this.isvalidEFD && this.isvalidEXD && !this.isEPMatchEQCR) {
        if (this.validateEngagementName()) {
          this.loadingScreenService.startLoading();
          this._engService.isEngagementExists(this.engagement.clientId, this.engagement.engagementName)
            .subscribe(
              res => {
                this.engagementCount = res;
                if (this.engagementCount > 0) {
                  this.loadingScreenService.stopLoading();
                  this.checkEngagementExists = true;
                }
                else {
                  this.checkEngagementExists = false;
                  //Generate SitUniqueId in DB while creating engagement only
                  this._engService.GenerateSiteUniqueId(this.engagement).subscribe(
                    siteId => {
                      this.engagement.siteUniqueID = siteId;
                      console.log("site unique id : " + siteId);
                      if (this.engagement.agreementType != null && this.engagement.agreementType != '') {
                        this.createNewWorkItem = true;
                        this.CreateWorkItem();
                      } else {
                        console.log('Create engagement called on next');
                        this.CreateEngagement();
                      }
                      console.log('save engagement mandatory check succeeded');
                    });
                }
                console.log(this.engagementCount);
                this.loadingScreenService.stopLoading();
              },
              () => {
                this.checkEngagementExists = false;
                this.loadingScreenService.stopLoading();
                this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
              });

        } else {
          this.scrollToTop();
        }
      } else {
        this.setDialogContent('warning', EngagementDialogTitles.SaveEngagement);
      }
    }, 150);
  }

  private CheckDuplicateElement(array, property, value) {
    let foundElements = [];
    let elCount = 0;
    let isDuplicate = false;
    for (let element of array) {
      if (element[property] === value) {
        elCount = elCount + 1;
        if (elCount === 1) {
          foundElements.push(element);
        } else if (elCount > 1) {
          isDuplicate = true;
          break;
        }
      }
    }
    return isDuplicate;
  }

  SetAssignmentMemberforEditMode(assignmentData: EngagementAssignment[], teamMemberData: TeamMember[]) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    this.assignmentTeamMemberList = [];
    for (let i = 0; i < assignmentData.length; i++) {
      this.defaultEngAssignment.solutionCodeId = assignmentData[i].solutionCodeId;
      // refer bux #603886 -- secondary assignment we set with default parentSolutioncode
      this.defaultEngAssignment.selectedsolutionCode =
        this.defaultEngAssignment.solutionCodeListData.find(element =>
          element.childSolutionCodeId == this.defaultEngAssignment.solutionCodeId);
      if (this.isContinuanceEngagement && this.engagement.status === this.notStartedStatus
        && assignmentData[i].isContinuanceCarryFrwdAssignment && assignmentData[i].assignmentCodeStatus === this.AssignmentCodePending) {
        this.defaultEngAssignment.priorYearAssignmentName = assignmentData[i].assignmentName;
        this.defaultEngAssignment.assignmentName = '';
      } else {
        this.defaultEngAssignment.priorYearAssignmentName = '';
        this.defaultEngAssignment.assignmentName = assignmentData[i].assignmentName;
      }
      this.defaultEngAssignment.isContingent = Boolean(assignmentData[i].isContingent);
      this.defaultEngAssignment.cmsEPPMDName = assignmentData[i].cmsEPPMDName;
      this.defaultEngAssignment.cmsEPPMDEmailId = assignmentData[i].cmsEPPMDEmailId;
      this.defaultEngAssignment.assignmentAdministrator = assignmentData[i].assignmentAdministrator;
      this.defaultEngAssignment.adminEmailId = assignmentData[i].adminEmailId;
      this.defaultEngAssignment.adminContactNo = assignmentData[i].adminContactNo;
      this.defaultEngAssignment.solutionCode = assignmentData[i].solutionCode;
      this.defaultEngAssignment.solutionCodeDescription = assignmentData[i].solutionCodeDescription;
      this.defaultEngAssignment.selectedengagementType =
        this.engtypekeyValueList.find(element => element.key == assignmentData[i].engagementTypeId.toString());
      this.defaultEngAssignment.assignmentCodeId = assignmentData[i].assignmentCodeId;
      this.defaultEngAssignment.realization = assignmentData[i].realization;
      this.defaultEngAssignment.isPrimaryAssignment = assignmentData[i].isPrimaryAssignment;
      this.defaultEngAssignment.netFeesInCurrencyFormat = formatter.format(Number(assignmentData[i].netFees));
      this.defaultEngAssignment.netFees = assignmentData[i].netFees;
      this.defaultEngAssignment.engagementTypeId = assignmentData[i].engagementTypeId;
      this.defaultEngAssignment.engagementTypeData = this.engtypekeyValueList;
      this.defaultEngAssignment.iscontingentData = this.contingentkeyValueList;
      this.defaultEngAssignment.assignmentCodeStatus = assignmentData[i].assignmentCodeStatus;
      this.defaultEngAssignment.engagementStartDate = assignmentData[i].engagementStartDate;
      this.defaultEngAssignment.engagementEndDate = assignmentData[i].engagementEndDate;
      this.defaultEngAssignment.estimatedGrossMarginInCurrencyFormat =
        (assignmentData[i].estimatedGrossMargin === null || assignmentData[i].estimatedGrossMargin.toString().length === 0)
          ? ''
          : formatter.format(Number(assignmentData[i].estimatedGrossMargin));
      this.defaultEngAssignment.estimatedGrossMargin = assignmentData[i].estimatedGrossMargin;
      this.defaultEngAssignment.officeLocationCodeId = assignmentData[i].officeLocationCodeId;
      if (assignmentData[i].officeLocationCodeId !== 0) {
        const clientLocation = this.ClientLocations.find(element =>
          element.id === assignmentData[i].officeLocationCodeId);
        if (clientLocation) {
          this.defaultEngAssignment.officeLocationCodeName = clientLocation.typeValue;
        }
      }
      this.defaultEngAssignment.clientBillingContactName = assignmentData[i].clientBillingContactName;
      this.defaultEngAssignment.clientBillingContactTitleId = assignmentData[i].clientBillingContactTitleId;
      if (assignmentData[i].clientBillingContactTitleId !== 0) {
        const contactTypeLookup = this.ClientContactTypes.find(element =>
          element.id === assignmentData[i].clientBillingContactTitleId);
        if (contactTypeLookup) {
          this.defaultEngAssignment.clientBillingContactTitle = contactTypeLookup.typeValue;
        }
      }
      this.defaultEngAssignment.clientBillingContactEmail = assignmentData[i].clientBillingContactEmail;
      this.defaultEngAssignment.isContinuanceCarryFrwdAssignment = assignmentData[i].isContinuanceCarryFrwdAssignment;
      this.defaultEngAssignment.clientCode = assignmentData[i].clientCode;
      this.defaultEngAssignment.assignmentCode = assignmentData[i].assignmentCode;
      this.defaultEngAssignment.eqcrEmailId = assignmentData[i].eqcrEmailId;
      this.defaultEngAssignment.eqcrName = assignmentData[i].eqcrName;
      this.defaultEngAssignment.budgetApproved = assignmentData[i].budgetApproved;
      if (this.defaultEngAssignment.budgetApproved != undefined && this.defaultEngAssignment.budgetApproved != null) {
        this.defaultEngAssignment.isAssignmentFinanceDataEditable = String(this.defaultEngAssignment.budgetApproved);
      }
      this.defaultEngAssignment.isEngagementStartDateRequired = assignmentData[i].isEngagementStartDateRequired;
      this.defaultEngAssignment.isEngagementEndDateRequired = assignmentData[i].isEngagementEndDateRequired;

      if (this.defaultEngAssignment.selectedengagementType == undefined) {
        const defaultSelectedengagementType = new KeyValue();
        defaultSelectedengagementType.key = '';
        defaultSelectedengagementType.value = '';
        this.defaultEngAssignment.selectedengagementType = defaultSelectedengagementType;
      }
      this.defaultEngAssignment.opportunityServiceId = assignmentData[i].opportunityServiceId;
      this.arrEngagementAssignment.push(JSON.parse(JSON.stringify(this.defaultEngAssignment)));

      //set team member data
      this.newAssignmentData = {
        assignmentName: this.defaultEngAssignment.assignmentName,
        solutionCodeName: this.defaultEngAssignment.selectedsolutionCode.solutionCode + ' - ' + this.defaultEngAssignment.selectedsolutionCode.solutionCodeDescription,
        teamMemberList: teamMemberData,
        isAssignmentChecked: false,
        isTeamDetailsVisible: false
      };

      this.assignmentTeamMemberList.push(this.newAssignmentData);
    }
    this.isLoggedInUserIsPureNotificationOnlyProfesional();

    //disable Pre-request flag if any of the request is sent to cms on edit mode for continuance engagement
    if (this.isContinuanceEngagement && this.EditMode) {
      this.isCMSPreRequestSent = this.ComputeCMSRequestSentFlag();
    }
  }

  SetBasicEngDataforEditMode() {
    this.isPeriodRequire = this.engagement.isPeriodEndDateRequired;
    // this.formatDate(this.engagement.taxYear);
    this.currentEngagementName = this.engagement.engagementName.trim();
    this.epDisplayUserEmail = this.engagement.engagementPartner;
    this.epDisplayUserName = this.engagement.engagementPartnerName;
    this.validateEP = true;
    $('#hdnKey' + this.MappedValue + 0).val(this.engagement.clientId);
    $('#txtValue' + this.MappedValue + 0).val(this.engagement.clientName);
    $('#hdnKeyCode' + this.MappedValue + 0).val(this.engagement.clientCode);
    $('#UserHiddenProps_EP_0 #hdnUserName').val(this.engagement.engagementPartnerName);
    $('#UserHiddenProps_EP_0 #hdnUserEmail').val(this.engagement.engagementPartner);
    $('#UserHiddenProps_AA_0 #hdnUserEmail').val(this.engagement.administratorEmailId);
    $('#UserHiddenProps_AA_0 #hdnUserName').val(this.engagement.assignmentAdministratorName);
    $('#txtPeoplePicker_AA_0').val(this.engagement.assignmentAdministratorName);
    $('#UserHiddenProps_EP_0 #hdnUserPhone').val(this.engagement.epContactno);
    $('#hdnKey' + this.SolutionCodeMappedValue + 0).val(this.engagement.parentSolutionCodeId);
    $('#txtValue' + this.SolutionCodeMappedValue + 0).val(this.engagement.solutionCode);
    $('#engAgreementType').val(this.engagement.agreementType);
    $('#engagementName').val(this.currentEngagementName);
    this.IsClientAutoPopulate = true;
    const clientId = $('#hdnKey' + this.MappedValue + 0).val();
    if (clientId != undefined) {
      if (clientId.length > 0) {
        this.getDefaultClientBillingContactDetails(clientId);
      }

    }
    if (this.isContinuanceEngagement) {
      $('#txtValue' + this.MappedValue + 0).attr('disabled', true);
      $('#txtValue' + this.SolutionCodeMappedValue + 0).attr('disabled', true);
      $('.autocomplete-dropdown-btn').attr('disabled', true);
      $('#btnClientSearch').attr('disabled', true);
    }

    //get template mapping for solution code
    if (this.engagement.parentSolutionCodeId > 0) {
      this.getTemplateMappingData(false);
    }

    //set  pre request data for continuance engagement
    if (this.isContinuanceEngagement && this.engagement.preRequestDuringContinuance) {
      //set default to false if it is not selected
      switch (this.engagement.preRequestForContinuance) {
        case undefined:
        case null:
        case false:
          this.selectedPreRequestForContinuanceValue = 'false';
          break;
        case true:
          this.selectedPreRequestForContinuanceValue = 'true';
          break;
        default:
          break;
      }
      this.onPreRequestFlagChange();
    }

    //set user isEP 
    this.IsUserEP = (this.UserObject.userName.toLowerCase() === this.engagement.engagementPartner.toLowerCase());

  }


  getWorkspaceDetails() {
    this._workspaceService.getWorkspaceByEngagementId(this.currentEngagementId).subscribe(
      workspace => {
        this.WorkspaceObject = workspace;
        this.CurrentWorkspaceStatus = workspace.status;
        console.log('Workspace details on Engagement :', this.WorkspaceObject);
      });
  }
  ValidateClientData() {
    if (this.engagement.clientName.length > 0 && this.engagement.clientId > 0)
      this.isValidClient = true;
    else
      this.isValidClient = false;
  }

  isValidWorkspaceStatus() {
    if (this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.Abandoned ||
      this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.Declined ||
      this.CurrentWorkspaceStatus === WorkspaceInterviewsStatus.Approved ||
      this.engagement.status === EngagementStatus.EngAbandoned ||
      this.engagement.status === EngagementStatus.EngDeclined ||
      this.engagement.status === EngagementStatus.EngActive ||
      this.engagement.status === EngagementStatus.EngClosed ||
      this.engagement.status === EngagementStatus.EngPendingCMSCode ||
      this.engagement.status === EngagementStatus.EngCmsCodeError ||
      this.engagement.status === EngagementStatus.EngSiteError ||
      this.engagement.status === EngagementStatus.EngRPRuleEngineError ||
      this.engagement.status === EngagementStatus.EngVoided) {
      return false;
    } else {
      return true;
    }
  }


  createAndUpdatePermissionsModel(editedTeamMembers: TeamMember[], siteUniqueID: string) {

    // Get List of Groups based on Permission Drop down(GROUP By) //
    let siteMembersGroups = editedTeamMembers.reduce((r, a) => {
      r[a.teamMemberPermissionId] = [...r[a.teamMemberPermissionId] || [], a];
      return r;
    }, {});

    console.log("siteMembersGroups", siteMembersGroups);
    let onwwerList: TeamMember[] = [];
    let memberList: TeamMember[] = [];
    let siteGroupTypes = Object.keys(siteMembersGroups);

    siteGroupTypes.forEach(type => {
      if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
        onwwerList = editedTeamMembers.filter(member =>
          Number(member.teamMemberPermissionId) === Number(type));
      } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
        memberList = editedTeamMembers.filter(member =>
          Number(member.teamMemberPermissionId) === Number(type));
      }
    })
    // Collect site onwer email List //
    let siteOwnerEmailIds: string[] = [];
    onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); });

    // Collect site member email List //
    let siteMemberEmailIds: string[] = [];
    memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); });

    let EngagementSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
    let OwnerListEmailModel: UsersListEmailModel = new UsersListEmailModel();
    let MemberListEmailModel: UsersListEmailModel = new UsersListEmailModel();
    let updatePermissionsModelList: UpdatePermissionsModel[] = []

    OwnerListEmailModel.groupType = 'Owner';
    OwnerListEmailModel.groupUsersEmail = [];
    siteOwnerEmailIds.forEach(emailid => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = '';
      adUser.lastName = '';
      adUser.userEmailId = emailid;
      OwnerListEmailModel.groupUsersEmail.push(adUser);
    })

    MemberListEmailModel.groupType = 'Member';
    MemberListEmailModel.groupUsersEmail = [];
    siteMemberEmailIds.forEach(emailid => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = '';
      adUser.lastName = '';
      adUser.userEmailId = emailid;
      MemberListEmailModel.groupUsersEmail.push(adUser);
    })

    EngagementSiteUsersUpdatePermissionModel.siteType = "EngagementSite";
    EngagementSiteUsersUpdatePermissionModel.siteUniqueID = siteUniqueID;
    EngagementSiteUsersUpdatePermissionModel.usersListEmail.push(OwnerListEmailModel);
    EngagementSiteUsersUpdatePermissionModel.usersListEmail.push(MemberListEmailModel);
    updatePermissionsModelList.push(EngagementSiteUsersUpdatePermissionModel);
    //Add client users
    updatePermissionsModelList.push(this.GenerateClientSiteUpdatePermissionModel());
    console.log('updatePermissionsModelList ==>', updatePermissionsModelList);
    //1. Final Call Call Active Directory API to update latest collaborationSite Member Ids
    this.UpdatePermissions(updatePermissionsModelList);
    //redirect call only for edit engagement site member modification only.
    this.dataService.setRedirectedFromEditEngagement(true);
    this.loadingScreenService.stopLoading();
    this.unsavedAlertfeature_IsSaveChangesOrNextBtnClicked = true;
    this.router.navigate(this.redirectUrl);
  }

  UpdatePermissions(updatePermissionsModeList: UpdatePermissionsModel[]) {
    let updatePermissionsModelData: UpdatePermissionsModelData = new UpdatePermissionsModelData();
    updatePermissionsModelData.updatePermissionsModel = updatePermissionsModeList;

    this._activedirectoryService.UpdatePermissions(updatePermissionsModelData).subscribe(
      result => {
        //this.resultText = result;
      },
      error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  EditEngagement() {
    this.loadingScreenService.startLoading();
    this.createEngagement.teamMember = this.engTeamaMembeListrWithoutAssignment;
    this.createEngagement.engagementAssignment = this.engagementAssignmentWithoutSCode;
    this.createEngagement.engagements = this.engagement;
    this.createEngagement.workspaceData = this.WorkspaceData;
    this.createEngagement.workspaceData.hdTemplateName = $('#engAgreementType :selected').text();
    this.createEngagement.workspaceDocuments = this.AddWorkspaceDocumentList;
    this.createEngagement.loggedInUserFullName = this.UserObject.loggedInUserFullName;
    this.createEngagement.clientLevelDocument = this.UpdatedclientLevelDocument;
    this.createEngagement.defaultIndusConsent = this.ComputeDefaultIndusFlag();
    this.createEngagement.IsLoggedInUserSystemAdmin = this.isLoggedInUserSystemAdmin;
    this.createEngagement.clientMembers = this.clientTeamMembersList;
    const decisionTemplateArr: DecisionTemplate[] = [];

    if (this.EditMode &&
      this.oldEngagementData.status == WorkspaceInterviewsStatus.NotStarted) {

      let solutionCodeArr = this.createEngagement.engagements.solutionCode.split('-');
      let siteInputRequestData: SiteInputRequest[] = [];
      let engagementSiteInputRequest: SiteInputRequest = new SiteInputRequest();
      engagementSiteInputRequest.siteClassification = 'engagement';
      engagementSiteInputRequest.clientCode = this.createEngagement.engagements.clientCode;
      engagementSiteInputRequest.solutionCode = solutionCodeArr[0].trim();
      siteInputRequestData.push(engagementSiteInputRequest);

      let clientSiteInputRequest: SiteInputRequest = new SiteInputRequest();
      clientSiteInputRequest.siteClassification = 'client';
      clientSiteInputRequest.clientCode = this.createEngagement.engagements.clientCode
      clientSiteInputRequest.solutionCode = solutionCodeArr[0].trim();
      siteInputRequestData.push(clientSiteInputRequest);

      let decisionsTemplateRequest: DecisionsTemplateRequestModel = new DecisionsTemplateRequestModel();
      decisionsTemplateRequest.templateRequest = siteInputRequestData;

      let engagementDecisionTemplate: DecisionTemplate = new DecisionTemplate();
      let clientDecisionTemplate: DecisionTemplate = new DecisionTemplate();

      this._sharepointService.GetDecisionsTemplate(decisionsTemplateRequest)
        .subscribe(result => {
          let tempplateResponse = result;
          if (tempplateResponse.templateSelection != null && tempplateResponse.templateSelection.length > 1) {

            engagementDecisionTemplate.templateName = tempplateResponse.templateSelection[0].templateName;
            engagementDecisionTemplate.policyID = tempplateResponse.templateSelection[0].policyID;
            engagementDecisionTemplate.policyName = tempplateResponse.templateSelection[0].policyName;
            engagementDecisionTemplate.acceptanceFolderPath = tempplateResponse.templateSelection[0].acceptanceFolderPath;
            engagementDecisionTemplate.supplementalFolderPath = tempplateResponse.templateSelection[0].supplementalFolderPath;

            this.createEngagement.acceptanceFolderPath = engagementDecisionTemplate.acceptanceFolderPath;
            this.createEngagement.supplementalFolderPath = engagementDecisionTemplate.supplementalFolderPath;
            decisionTemplateArr.push(engagementDecisionTemplate);

            clientDecisionTemplate.templateName = tempplateResponse.templateSelection[1].templateName;
            clientDecisionTemplate.policyID = tempplateResponse.templateSelection[1].policyID;
            clientDecisionTemplate.policyName = tempplateResponse.templateSelection[1].policyName;
            clientDecisionTemplate.acceptanceFolderPath = tempplateResponse.templateSelection[1].acceptanceFolderPath;
            clientDecisionTemplate.supplementalFolderPath = tempplateResponse.templateSelection[1].supplementalFolderPath;
            decisionTemplateArr.push(clientDecisionTemplate);
          }
          this.DecisionTemplateApiLogs(tempplateResponse.isSuccess, this.createEngagement.engagements.workspaceId, decisionsTemplateRequest, tempplateResponse);
          this.CallUpdateEngagement(decisionTemplateArr);
        },
          error => {
            this.loadingScreenService.stopLoading();
            this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
          });
    }
    else {
      this.CallUpdateEngagement(decisionTemplateArr);
    }
  }

  CallUpdateEngagement(decisionTemplateArr: DecisionTemplate[] = []) {
    this._engService.UpdateEngagement(this.createEngagement)
      .subscribe(
        UpdateEngagementResult => {
          if (this.isEngagementNameChanged) {
            const apiRequest = new ApiRequest();
            apiRequest.title = this.engagement.clientName + ' | ' + this.engagement.engagementName;
            apiRequest.siteUrl = this.engagement.siteURL;
            this._engService.UpdateEngagementTitle(apiRequest).subscribe(result => {
            });
          }
          this.unsavedAlertfeature_IsSaveChangesOrNextBtnClicked = true;
          this._alertService.remove(this.alert);
          if (this.isConveneIntegrationFlagEnabled && this.isContinuanceEngagement && this.engagement.status == EngagementStatus.EngNotStarted) {
            this.AddUpdateConveneProjectDetails();
          }

          if (this.createEngagement.engagements.engagementId > 0 &&
            // this.isContinuanceEngagement &&
            this.oldEngagementData.status === EngagementStatus.EngNotStarted) {
            this.getContinuanceData(this.createEngagement.engagements.engagementId, decisionTemplateArr);
          }
          else {
            // Update available list of team members in sharepoint site
            // Taking siteUniqueID from old "oldEngagementData"
            // which is nothing but latest engagment data callin on Next button Method 'engagementNextClick'
            this.redirectUrl = ['/Interviews/' + UpdateEngagementResult];
            if (this.EditMode && this.isSiteProvisioned) {
              this._engService.GetClientTeamMembersByEngagementId(this.createEngagement.engagements.engagementId).subscribe(
                updatedClientMembers => {
                  var newClientMembers = updatedClientMembers.filter(x => x.status === 'Added');
                  if (newClientMembers != null && newClientMembers.length > 0) {
                    this.callPowerAutomateToProcessClientMemberNotification();
                  }
                });
            }
            this.createAndUpdatePermissionsModel(this.createEngagement.teamMember, this.oldEngagementData.siteUniqueID);
          }
        },
        error => {
          this.loadingScreenService.stopLoading();
          this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
        });
  }

  GetContactTypes() {
    let excludedType = 'Billing Contact';
    this.applicationMasterService.GetContactTypes().subscribe(
      contactTypes => {
        contactTypes.forEach(contact => {
          if (contact.typeValue !== excludedType) {
            this.ClientContactTypes = contactTypes;
          }
        });
      },
      error => {
        this.logService.log('GetContactTypes in InitiateNewEngagement class ', error);
      }
    );
  }

  GetClientLocations() {
    this.applicationMasterService.GetClientLocations().subscribe(
      result => {
        this.ClientLocations = result;
      },
      error => {
        this.logService.log('GetClientLocations in InitiateNewEngagement class ', error);
      }
    );
  }

  updateTeamMembersList(event, roleShortName) {
    let memberJSON = new MembersList();
    // if (event == null || event == undefined && this.isContinuanceEngagement) {
    //   memberJSON.displayName = this.engagement?.engagementPartnerName
    //   memberJSON.mail = this.engagement?.engagementPartner;
    // } else {
    memberJSON = JSON.parse(event);
    // }
    this.epDisplayUserEmail = memberJSON.mail;
    this.epDisplayUserName = memberJSON.displayName;
    //Check EP matches with any of the EQCR
    let EQCRList: EngagementAssignment[] = [];
    EQCRList = this.getDistinctEQCRList();
    let isEQCRFoundIndex = EQCRList.findIndex(e => e.eqcrEmailId.toLowerCase() === this.epDisplayUserEmail.toLowerCase());
    if (isEQCRFoundIndex >= 0) {
      this.isEPMatchEQCR = true;
      this.validateEP = false;
    } else {
      this.isEPMatchEQCR = false;
      console.log('Selected User details', event, roleShortName);
      this.userDetails.CMSEP = memberJSON;
      $('#txtPeoplePicker_AS_EP_0').val(this.userDetails.CMSEP.displayName);
      $('#UserHiddenProps_AS_EP_0 #hdnUserName').val(this.userDetails.CMSEP.displayName);
      $('#UserHiddenProps_AS_EP_0 #hdnUserEmail').val(this.userDetails.CMSEP.mail);
      $('#UserHiddenProps_AS_EP_0 #hdnUserPhone').val(this.userDetails.CMSEP.businessPhones);
      $('#UserHiddenProps_AS_EP_0 #hdnJobTitle').val(this.userDetails.CMSEP.jobTitle);
      $('#hdnValidInput_AS_EP_0').val(true);
      if ($('#txtPeoplePicker_AS_EP_0').hasClass('text-danger')) {
        $('#txtPeoplePicker_AS_EP_0').removeClass('text-danger');
      }
      this.cmsDisplayUserEmail = memberJSON.mail;
      this.cmsDisplayUserName = memberJSON.displayName;
      this.validateCMSEP = true;
      this.canEnableSaveAssignmentBtnOnValidcmsPPMDCheck = true;
      this.enableDisableSaveAssignmentButton();
      //this.isvalidCMSEP = true;
      //update engagement partner on EP change
      this.updateEPinTeammemberList(memberJSON);
      this.LoadGoverningAgreementsFromCLM();
    }
  }
  checkEPPMDMatch(email: string): boolean {
    const EPPMDEmailId = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
    if (EPPMDEmailId === email)
      return true;
    else
      return false;
  }

  removeEngAssignmetTeammemberData(AdminIndex: number, CMSEPPMDIndex: number, EQCRIndex: number) {
    //Remove team member data
    if (AdminIndex >= 0) {
      this.callRemoveOldMembersInDistinctList(this.editedAssignmentOldData.adminEmailId);
    }
    if (CMSEPPMDIndex >= 0) {
      this.callRemoveOldMembersInDistinctList(this.editedAssignmentOldData.cmsEPPMDEmailId);
    }
    if (EQCRIndex >= 0) {
      this.callRemoveOldMembersInDistinctList(this.editedAssignmentOldData.eqcrEmailId);
    }
    this.addTeammemberCreatorData();
  }
  callRemoveOldMembersInDistinctList(email: string) {
    let distinctCMSEPPMDList: EngagementAssignment[] = [];
    let distinctAdminList: EngagementAssignment[] = [];
    let distinctEQCRList: EngagementAssignment[] = [];
    distinctAdminList = this.getDistinctAdminList();
    distinctCMSEPPMDList = this.getDistinctCMSEPPMDList();
    distinctEQCRList = this.getDistinctEQCRList();

    let AdminIndexInDistictList = distinctAdminList.findIndex(x => x.adminEmailId?.toLowerCase() === email?.toLowerCase());

    let CMSEPIndexInDistictList = distinctCMSEPPMDList.findIndex(x => x.cmsEPPMDEmailId?.toLowerCase() === email?.toLowerCase());
    let EQCRIndexInDistictList = distinctEQCRList.findIndex(x => x.eqcrEmailId?.toLowerCase() === email?.toLowerCase());
    if (AdminIndexInDistictList < 0 && CMSEPIndexInDistictList < 0 && EQCRIndexInDistictList < 0) {
      this.removeOldTeamMembersfromList(email);
    }
  }

  removeOldTeamMembersfromList(email: string) {
    let EPRoleId = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP);
    let CreatorRoleId = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.C);
    //remove item from team member list
    let memberListIndexOfCMSEPOldData = this.getTeamMemberListIndex(email);
    if (memberListIndexOfCMSEPOldData >= 0 && this.engTeamaMembeListrWithoutAssignment[memberListIndexOfCMSEPOldData].teamMemberRole != EPRoleId
      && this.engTeamaMembeListrWithoutAssignment[memberListIndexOfCMSEPOldData].teamMemberRole != CreatorRoleId) {
      this.engTeamaMembeListrWithoutAssignment.splice(memberListIndexOfCMSEPOldData, 1);
      this.ConfigureReviewNoteSearch();
    }
  }

  updateEPinTeammemberList(memberJSON) {
    let distinctCMSEPPMDList: EngagementAssignment[] = [];
    let distinctAdminList: EngagementAssignment[] = [];
    let distinctEQCRList: EngagementAssignment[] = [];

    if (this.engTeamaMembeListrWithoutAssignment.length > 0) {
      let EProleId = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP);
      let index = this.engTeamaMembeListrWithoutAssignment.findIndex(str => str.teamMemberRole === EProleId);
      var oldEPData = this.engTeamaMembeListrWithoutAssignment[index];
      if (index >= 0) {
        this.newAssignTeamMember = {
          memberName: memberJSON.displayName,
          memberEmailId: memberJSON.mail,
          teamMemberRole: EProleId,
          teamMemberPermissionId: this.teamMemberPermissions.find(x => x.typeValue === 'Site owner').id,
          isKeyTeamMember: (this.isKeyTeamMemberFeatureFlag && this.isAudit) ? true : false, //By default EPPMD will be key member for audit sol code.
          keyTeamMemberRoleId: EProleId
        };
        this.engTeamaMembeListrWithoutAssignment[index] = this.newAssignTeamMember;
      }
      //remove data if matches to EP
      for (let i = 0; i < this.engTeamaMembeListrWithoutAssignment.length; i++) {
        if (this.engTeamaMembeListrWithoutAssignment[i].memberEmailId === memberJSON.mail
          && this.engTeamaMembeListrWithoutAssignment[i].teamMemberRole != EProleId)
          this.engTeamaMembeListrWithoutAssignment.splice(i, 1);
      }
      //Add removed data if present assignment list and not in tem member list
      distinctCMSEPPMDList = this.getDistinctCMSEPPMDList();
      distinctAdminList = this.getDistinctAdminList();
      distinctEQCRList = this.getDistinctEQCRList();
      let isFoundMemberIndex = this.getTeamMemberListIndex(oldEPData.memberEmailId);
      let isFoundAA = distinctAdminList.findIndex(x => x.adminEmailId.toLowerCase() === oldEPData.memberEmailId.toLowerCase());
      let isAddedFlag: boolean = false;
      let isFoundCMSEP = distinctCMSEPPMDList.findIndex(x => x.cmsEPPMDEmailId.toLowerCase() === oldEPData.memberEmailId.toLowerCase());
      let isFoundEQCR = distinctCMSEPPMDList.findIndex(e => e.eqcrEmailId.toLowerCase() === oldEPData.memberEmailId.toLowerCase());
      if (isFoundCMSEP >= 0 && isFoundMemberIndex < 0 && !isAddedFlag) {
        this.addNewTeamMember(distinctCMSEPPMDList[isFoundCMSEP], this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP));
        isAddedFlag = true;
      }

      //Add Admin Data to list if its different than EPPMD
      if (isFoundAA >= 0 && isFoundMemberIndex < 0 && !isAddedFlag) {
        this.addNewTeamMember(distinctAdminList[isFoundAA], this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A));
        isAddedFlag = true;
      }

      //Add EQCR Data to list if its different than EPPMD
      if (isFoundAA >= 0 && isFoundMemberIndex < 0 && !isAddedFlag) {
        this.addNewTeamMember(distinctEQCRList[isFoundEQCR], this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR));
      }

      this.addTeammemberCreatorData();
      this.ConfigureReviewNoteSearch();
    }
  }
  addTeammemberCreatorData() {
    //Add creator if not present
    let creatorIndex = this.getTeamMemberListIndex(this.UserObject.loggedInUserEmail);
    //Add Creator to list if not present
    if (creatorIndex < 0 && !this.checkEPPMDMatch(this.UserObject.loggedInUserEmail)) {
      this.addNewTeamMember(null, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.C));
    }
  }
  resetEstimatedGrossMarginFlag() {
    this.validEstimatedGrossMargin = true;
  }

  addNewTeamMember(memberData: EngagementAssignment, teamMemberRole: number) {
    let memberName: string;
    let memberEmail: string;
    let keyTeamMemberRole: number;
    let isKeyTeamMember: boolean = false;
    //Set default role to team member except EP
    let role = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P);
    this.isAudit ? keyTeamMemberRole = role : keyTeamMemberRole = 0;
    if (teamMemberRole > 0) {
      switch (teamMemberRole) {
        case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP):
          memberName = $('#txtPeoplePicker_EP_0').val();
          memberEmail = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
          role = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP);
          isKeyTeamMember = true;
          this.isAudit ? keyTeamMemberRole = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP) : keyTeamMemberRole = 0;
          break;
        case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP):
          if (memberData != null) {
            memberEmail = memberData.cmsEPPMDEmailId;
            memberName = memberData.cmsEPPMDName;
            //this.isAudit ? keyTeamMemberRole = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP) : keyTeamMemberRole = 0;
            this.isAudit ? keyTeamMemberRole = this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH) : keyTeamMemberRole = 0;
          }
          break;
        case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A):
          if (memberData != null) {
            memberEmail = memberData.adminEmailId;
            memberName = memberData.assignmentAdministrator;
            //this.isAudit ? keyTeamMemberRole = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A) : keyTeamMemberRole = 0;
            this.isAudit ? keyTeamMemberRole = this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH) : keyTeamMemberRole = 0;
          }
          break;
        case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.C):
          memberEmail = this.UserObject.loggedInUserEmail;
          memberName = this.UserObject.loggedInUserFullName;
          break;
        case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR):
          memberEmail = memberData.eqcrEmailId;
          memberName = memberData.eqcrName;

          role = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR);
          this.isAudit ? keyTeamMemberRole = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR) : keyTeamMemberRole = 0;
          break;
        default:
          break;
      }
      if (memberEmail != null && memberEmail != '') {
        this.newAssignTeamMember = {
          memberName: memberName,
          memberEmailId: memberEmail,
          teamMemberRole: role,
          teamMemberPermissionId: this.teamMemberPermissions.find(x => x.typeValue === 'Site owner').id,
          isKeyTeamMember: (this.isKeyTeamMemberFeatureFlag && this.isAudit) ? isKeyTeamMember : false,
          keyTeamMemberRoleId: (this.isAudit && keyTeamMemberRole == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P)) ?
            this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH) : keyTeamMemberRole,
          description: ''
        };
        this.engTeamaMembeListrWithoutAssignment.push(this.newAssignTeamMember);
        console.log('Team member list : ' + this.engTeamaMembeListrWithoutAssignment);
      }
    }
  }
  getTeamMemberListIndex(email: string): number {
    return this.engTeamaMembeListrWithoutAssignment.findIndex(x => x.memberEmailId?.toLowerCase() === email?.toLowerCase());
  }
  getDistinctCMSEPPMDList(): EngagementAssignment[] {
    return this.arrEngagementAssignment.filter((thing, i, arr) => {
      return arr.indexOf(arr.find(t => t.cmsEPPMDEmailId === thing.cmsEPPMDEmailId)) === i;
    });
  }
  getDistinctAdminList(): EngagementAssignment[] {
    return this.arrEngagementAssignment.filter((thing, i, arr) => {
      return arr.indexOf(arr.find(t => t.adminEmailId === thing.adminEmailId)) === i;
    });
  }
  getDistinctEQCRList(): EngagementAssignment[] {
    return this.arrEngagementAssignment.filter((thing, i, arr) => {
      if (thing.eqcrEmailId != '' && thing.eqcrEmailId != null) {
        return arr.indexOf(arr.find(t => t.eqcrEmailId === thing.eqcrEmailId)) === i;
      }
    });
  }

  getContinuanceData(engagementId: number, decisionTemplateArr: DecisionTemplate[] = []) {
    this._reacceptanceService.getContinuanceEngWorkItemData(engagementId).subscribe(
      result => {
        this.continuanceWorkItemData = result;

        this.redirectUrl = ['/Interviews/' + this.createEngagement.workspaceData.workspaceId];
        this.dataService.setRedirectedFromEditEngagement(true);
        console.log('Continuance eng workitem data', this.continuanceWorkItemData);
        //Create share point site for first time continuance engagagement
        if (
          (this.continuanceWorkItemData.continuanceStatus == ContinuanceStatus.Draft
            && this.continuanceWorkItemData.reacceptanceEngagementId > 0
            && this.isContinuanceEngagement && this.EditMode
            && this.oldEngagementData.status == WorkspaceInterviewsStatus.NotStarted
            && this.continuanceWorkItemData.isSiteRequestSent === false
          ) ||
          (this.EditMode
            && this.oldEngagementData.status == WorkspaceInterviewsStatus.NotStarted
            && this.continuanceWorkItemData.isSiteRequestSent === false)) {

          let siteMembersGroups = this.createEngagement.teamMember.reduce((r, a) => {
            r[a.teamMemberPermissionId] = [...r[a.teamMemberPermissionId] || [], a];
            return r;
          }, {});

          console.log("siteMembersGroups", siteMembersGroups);
          let onwwerList: TeamMember[] = [];
          let memberList: TeamMember[] = [];

          let siteGroupTypes = Object.keys(siteMembersGroups);

          siteGroupTypes.forEach(type => {
            if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site owner') {
              onwwerList = this.createEngagement.teamMember.filter(member =>
                Number(member.teamMemberPermissionId) === Number(type));
            } else if ((this.teamMemberPermissions.filter(pr => pr.id == Number(type))[0].typeValue) === 'Site member') {
              memberList = this.createEngagement.teamMember.filter(member =>
                Number(member.teamMemberPermissionId) === Number(type));
            }
          })

          // Site Onwers Email List
          let siteOwnerEmailIds: string[] = [];
          onwwerList.forEach(onwer => { siteOwnerEmailIds.push(onwer.memberEmailId); })

          //Site Member Email List
          let siteMemberEmailIds: string[] = [];
          memberList.forEach(member => { siteMemberEmailIds.push(member.memberEmailId); });
          if (this.createEngagement.engagements.clientCode != null) {
            this.createEngagement.engagements.clientName =
              this.createEngagement.engagements.clientName.replace(' (' + this.createEngagement.engagements.clientCode + ')', '');
          }
          this.initinateSharepointSiteProvision(engagementId, siteOwnerEmailIds, siteMemberEmailIds,
            this.createEngagement.engagements, this.continuanceWorkItemData.collaborationSiteid, decisionTemplateArr);

        } else {
          //update permission once continuance engagement status is completed
          this.createAndUpdatePermissionsModel(this.createEngagement.teamMember, this.oldEngagementData.siteUniqueID);
        }

        this.GenerateIndusConsentEvent(this.continuanceWorkItemData.isIndus ? 'Yes' : 'No');
      }, error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }
  getClientLevelDocuments(clientId, isSolutionCodeChanged: boolean) {
    this._clientService.GetClientLevelDocuments(clientId).subscribe(
      result => {
        if (result.length > 0) {
          this.clientLevelDocuments = result;
        }
        if (this.EditMode && !isSolutionCodeChanged) {
          this.isSolutionCodeChangedForDocumentSelection = false;
          this.setClientLevelDocumentForEditMode();
        } else {
          this.isSolutionCodeChangedForDocumentSelection = true;
          this.onDocumentSelectionChange();
        }
      }, error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  GetSubDocumentTypeTypes() {
    this.applicationMasterService.GetSubDocumentTypes().subscribe(result => {
      this.subDocumentTypes = result;
      // this.subDocumentTypes.splice(this.subDocumentTypes.findIndex(x => x.code === 'CMSA'), 1);
    },
      err => {
        this.logService.error('Failed to load subDocumentTypes');
      });
  }

  ValidateSubDocumentTypeSection() {
    let status = true;
    this.isInvalidSubDocType = false;
    this.isInvalidMSAExpirationDate = false;
    this.isvalidGTAgreementOwner = true;
    if (this.isDocPresentElsewhere) {
      this.isEffectiveDateEmpty = $('.' + this.msaEffectiveDateCntrlId).val() === '';
      this.isExpriationDateEmpty = $('.' + this.msaExpirationDateCntrlId).val() === '';
      if (!this.isEffectiveDateEmpty) {
        this.ValidateMSAEffectiveDate();
      }
      if (!this.isExpriationDateEmpty) {
        this.ValidateMSAExpirationDate();
      }
      if (this.clientLevelDocument.documentSubTypeId == undefined || this.clientLevelDocument.documentSubTypeId <= 0) {
        this.isInvalidSubDocType = true;
      }
      if ((this.clientLevelDocument.gtAgreementOwnerName != '' && this.clientLevelDocument.gtAgreementOwnerName != null) &&
        (this.clientLevelDocument.gtAgreementOwnerEmail == '' || this.clientLevelDocument.gtAgreementOwnerEmail == null)) {
        this.isvalidGTAgreementOwner = false;
        this.UpdatedclientLevelDocument.gtAgreementOwnerName = '';
        this.UpdatedclientLevelDocument.gtAgreementOwnerEmail = '';
      }
      status = !this.isInvalidSubDocType && !this.isEffectiveDateEmpty && !this.isInvalidMSAEffectiveDate && !this.isInvalidMSAExpirationDate && this.isvalidGTAgreementOwner;
    }
    return status;
  }

  ValidateMSAEffectiveDate() {
    this.isInvalidMSAEffectiveDate = this.clientLevelDocument.effectiveDate > this.clientLevelDocument.expirationDate;
  }

  ValidateMSAExpirationDate() {
    let rawDate = new Date();
    let today = new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate());
    this.isInvalidMSAExpirationDate = (this.UpdatedclientLevelDocument.expirationDate < today || this.UpdatedclientLevelDocument.expirationDate < this.UpdatedclientLevelDocument.effectiveDate);
  }
  GetDateFromString(date) {
    //format date to  mm/dd/yy
    let strDateArray = date.split('/');
    let dateObject = new Date(strDateArray[2], parseInt(strDateArray[0]) - 1, strDateArray[1]);
    return dateObject;
  }

  onDocumentSelectionChange() {
    if (this.isMemberFirm) {
      if ($("input[type='radio'][name='DocumentTypes']:checked").attr('ng-reflect-value') == 'Create new engagement letter o') {
        this.SelectedDocumentSubTypeValue = DocumentSubTypesEnum.NoOptionExists + ':0';
      }
    }
    $("input[type='radio'][name='radioGroup']").prop('checked', false);
    console.log(this.SelectedDocumentSubTypeValue);
    this.isDocPresentElsewhere = false;
    this.isByPass = false;
    if (this.SelectedDocumentSubTypeValue != undefined) {
      let selectedValues = this.SelectedDocumentSubTypeValue.split(':');
      this.SelectedDocumentSubType = selectedValues[0];
      this.SelectedClientLevelDocId = parseInt(selectedValues[1]);
      if (this.SelectedDocumentSubType != DocumentSubTypesEnum.ExistsElsewhere) {
        this.resetClientLevelDocumentData();
      }
      switch (this.SelectedDocumentSubType) {
        case DocumentSubTypesEnum.NoOptionExists:
          $('.docExistsElsewhereContentDiv').fadeOut(500);
          this.SelectedDocumentSubType = DocumentSubTypesEnum.ByPassCode;
          this.isByPass = true;
          this.getTemplateMaintenanceData();
          break;
        case DocumentSubTypesEnum.ExistsElsewhere:
          this.isDocPresentElsewhere = true;
          $('.docExistsElsewhereContentDiv').show(500);
          if (this.isSolutionCodeChangedForDocumentSelection) {
            this.onSubDocumentTypeChange();
          }
          break;
        default:
          $('.docExistsElsewhereContentDiv').fadeOut(500);
          this.getTemplateMaintenanceData();
          break;
      }
    }
  }

  ProcessCustomMSAType(clientLeveDocument: ClientLevelDocument) {
    this.SelectedTemplateList = [];
    var template = this.TemplateList.find(x => x.templatePackageId === clientLeveDocument.templatePackageId);
    if (template == null) {
      this.newTemplateData = {
        templateName: clientLeveDocument.templateName,
        templatePackageId: clientLeveDocument.templatePackageId,
        templateVersion: clientLeveDocument.templateVersion,
        documentTypeId: 0,
        subDocumentTypeId: clientLeveDocument.documentSubTypeId,
        ischecked: false,
        createdBy: clientLeveDocument.createdBy,
        createdOn: clientLeveDocument.createdOn,
        lastModifiedBy: clientLeveDocument.updatedBy,
        lastModifiedOn: clientLeveDocument.updatedOn,
        templateMappingId: 0,
        solutionCodeId: this.engagement.parentSolutionCodeId
      };
      this.SelectedTemplateList.push(this.newTemplateData);
    } else {
      this.SelectedTemplateList.push(template);
    }
    this.ValidateTemplateMapping();
  }
  getTemplateMaintenanceData() {
    this.SelectedTemplateList = [];
    this.templateMaintenanceService.getTemplateMaintenanceDataByDocSubType(this.SelectedDocumentSubType).subscribe(
      result => {
        console.log(result);
        if (result.length > 0) {
          this.SelectedTemplateList = this.TemplateList.filter(o => result.some(({ templatePackageId }) =>
            o.templatePackageId === templatePackageId));
        }
        this.ValidateTemplateMapping();
      }, error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }
  getCLMTemplateMaintenanceDataByParent(event) {
    this.selectedCLMRadioButtonValue = event.target.labels[0].textContent;
    this.engagement.agreementType = undefined;
    $('#engAgreementType').val('undefined');
    this.getCLMTemplateMaintenanceData(this.selectedCLMRadioButtonValue);
    this.injectCLMCloseButtonDynamically();
  }
  injectCLMCloseButtonDynamically() {// inject CLM close buttton if secof and third popup clicked
    setTimeout(() => { // wating  to load clm popup html
      var subject = document.querySelector(".clmmodal-body");
      const elc = document.getElementById('clmpopupCloseButton')
      if (elc == undefined) {
        subject.insertAdjacentHTML(
          "afterbegin",
          `<button id="clmpopupCloseButton" type="button" (click)="clmPopupClosebuttonClicked()"  class="close clm-close"><span  class="close-text mr-1">Close </span><span  class="modal-close-btn">×</span></button>`,
        );
        setTimeout(() => { // wating  to load clm popup lose button html
          const el = document.getElementById('clmpopupCloseButton');
          el.addEventListener('click', this.clmPopupClosebuttonClicked.bind(this))
        }, 50);
      }
    }, 100);
  }

  clmPopupClosebuttonClicked(event) { //close butto clicked of clm Popup
    const parentRadioGroup = document.querySelector('input[name="radioGroup"]:checked')?.id;
    if (parentRadioGroup != "existingGA") {
      $('input[name="radioGroup1"]').prop('checked', false);
      $('input[name="radioGroup"]').prop('checked', false);
      $('idTextField').val("");
    }
    document.getElementById('myModal').style.display = 'none';
  }

  getCLMTemplateMaintenanceDataByChild(event) {
    const parentRadioGroup = document.querySelectorAll('input[name="radioGroup"]');
    const parentFirstRadioButton = parentRadioGroup[0] as HTMLInputElement
    this.existingGACheck = false;
    if (this.selectedCLMRadioButtonValue !== parentFirstRadioButton?.labels[0].textContent) {
      this.selectedCLMRadioButtonValue = parentFirstRadioButton?.labels[0].textContent;
      this.getCLMTemplateMaintenanceData(this.selectedCLMRadioButtonValue);
    }
  }
  getCLMTemplateMaintenanceData(selectedRadioLabel: string) {
    $("input[type='radio'][name='DocumentTypes']").prop('checked', false);
    this.SelectedTemplateList = [];
    this.existingGACheck = false;
    this.templateMaintenanceService.getTemplateMaintenanceDataByCLMType(selectedRadioLabel).subscribe(
      result => {
        console.log(result);
        if (result.length > 0) {
          this.SelectedTemplateList = this.TemplateList.filter(o => result.some(({ templatePackageId }) =>
            o.templatePackageId === templatePackageId));
        }
        this.ValidateTemplateMapping();
      }, error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  onSubDocumentTypeChange() {
    console.log(this.clientLevelDocument.documentSubTypeId);
    this.subDocumentTypes.forEach((x) => {
      if (x.applicationMasterId == this.clientLevelDocument.documentSubTypeId) {
        this.SelectedDocumentSubType = x.code;
      }
    });
    this.getTemplateMaintenanceData();
  }
  ValidateTemplateMapping() {
    if (this.SelectedTemplateList.length > 0) {
      $('#engAgreementType').attr('disabled', false);
      this.isTemplateMappingFound = true;
    } else {
      $('#engAgreementType').attr('disabled', true);
      this.isTemplateMappingFound = false;
    }
  }
  setClientLevelDocuments() {
    var selectedDocumentTpeDetails = this.subDocumentTypes.find(x => x.code === this.SelectedDocumentSubType);
    if (selectedDocumentTpeDetails != null && this.SelectedClientLevelDocId <= 0 && this.isDocPresentElsewhere) {
      this.UpdatedclientLevelDocument.documentSubTypeId = selectedDocumentTpeDetails.applicationMasterId;
      this.UpdatedclientLevelDocument.documentDescription = this.clientLevelDocument.documentDescription;
      this.UpdatedclientLevelDocument.documentSubTypeValue = selectedDocumentTpeDetails.value;
      this.UpdatedclientLevelDocument.documentSubTypeCode = selectedDocumentTpeDetails.code;

      this.SelectedTemplateList.forEach(temp => {
        if (temp.templatePackageId === this.clientLevelDocument.templatePackageId) {
          this.UpdatedclientLevelDocument.templateName = temp.templateName;
        }
      });
      let effectiveDate = $('.' + this.msaEffectiveDateCntrlId).val();
      this.UpdatedclientLevelDocument.effectiveDate = this.GetDateFromString(effectiveDate);
      let expirationDate = $('.' + this.msaExpirationDateCntrlId).val();
      this.UpdatedclientLevelDocument.expirationDate = this.GetDateFromString(expirationDate);
    }
    if (this.isDocPresentElsewhere) {
      this.clientLevelDocument.gtAgreementOwnerName = $('#txtPeoplePicker_AO_0').val();
      this.clientLevelDocument.gtAgreementOwnerEmail = $('#UserHiddenProps_AO_0 #hdnUserEmail').val();
      if (this.clientLevelDocument.gtAgreementOwnerName != '' && this.clientLevelDocument.gtAgreementOwnerName != null &&
        this.clientLevelDocument.gtAgreementOwnerEmail != '' && this.clientLevelDocument.gtAgreementOwnerEmail != null) {
        this.validateGTAgreementOwner = true;
        this.UpdatedclientLevelDocument.gtAgreementOwnerEmail = this.clientLevelDocument.gtAgreementOwnerEmail;
        this.UpdatedclientLevelDocument.gtAgreementOwnerName = this.clientLevelDocument.gtAgreementOwnerName;
      } else {
        this.validateGTAgreementOwner = false;
      }
      if (this.TemplateList != null) {
        this.TemplateList.forEach(temp => {
          if (temp.templatePackageId === this.clientLevelDocument.templatePackageId) {
            this.UpdatedclientLevelDocument.templateName = temp.templateName;
          }
        });
      }
    }
    if (this.SelectedClientLevelDocId > 0) {
      this.UpdatedclientLevelDocument = this.clientLevelDocuments.find(x => x.clientLevelDocumentId == this.SelectedClientLevelDocId);
    }
  }
  setClientLevelDocumentForEditMode() {
    if (this.engagement.isByPass) {
      this.SelectedDocumentSubType = DocumentSubTypesEnum.NoOptionExists;
    } else {
      this.clientLevelDocuments.forEach((x) => {
        if (x.clientLevelDocumentId == this.engagement.clientLevelDocumentId) {
          this.SelectedDocumentSubType = x.documentSubTypeCode;
        }
      });
    }
    this.SelectedClientLevelDocId = this.engagement.clientLevelDocumentId;
    if (this.SelectedDocumentSubType != undefined) {
      this.SelectedDocumentSubTypeValue = this.SelectedDocumentSubType + ':' + this.SelectedClientLevelDocId;
    }
    this.onDocumentSelectionChange();
    if (this.engagement.agreementType == "") {
      $('#engAgreementType').attr('disabled', false);
      $('#engAgreementType').val('undefined');
    } else {
      $('#engAgreementType').val(this.engagement.agreementType);
    }
  }
  resetClientLevelDocumentData() {
    $('.' + this.msaEffectiveDateCntrlId).val('');
    $('.' + this.msaExpirationDateCntrlId).val('');
    // $('#eng-documentSubType').val('undefined');
    this.clientLevelDocument.documentSubTypeId = undefined;
    this.clientLevelDocument.documentSubTypeCode = '';
    this.clientLevelDocument.documentSubTypeValue = '';
    this.clientLevelDocument.documentDescription = '';
    this.clientLevelDocument.expirationDate = null;
    this.clientLevelDocument.effectiveDate = null;
    this.clientLevelDocument.templateName = '';
    $('#txtPeoplePicker_AO_0').val('');
    $('#UserHiddenProps_AO_0 #hdnUserEmail').val('');
    this.clientLevelDocument.gtAgreementOwnerEmail = '';
    this.clientLevelDocument.gtAgreementOwnerName = '';

  }

  checkTeammemberOwnerCountBusinessValidation() {
    let result = false;
    let onwwerList: TeamMember[] = [];

    onwwerList = this.engTeamaMembeListrWithoutAssignment.filter(member =>
      Number(member.teamMemberPermissionId) == Number(this.teamMemberPermissions.find(x => x.typeValue === 'Site owner').id));

    if (onwwerList.length < 2) {
      this._alertService.remove(this.alert);
      this.loadingScreenService.stopLoading();
      this._alertService.add('Two site owners are mandatory', 'danger', null);
      result = true;
    }
    return result;
  }

  setClientBillingContactDetails() {
    // console.log('---> Client Billing Contact Detail ', JSON.stringify(this.defaultClientBillingContact), JSON.stringify(this.defaultEngAssignment));
    //Client Billing contact details
    if (this.engagement.clientId > 0) {
      if (this.defaultClientBillingContact !== undefined) {
        if ((this.defaultClientBillingContact.clientBillingEmail !== undefined ||
          this.defaultClientBillingContact.clientBillingEmail !== null) ||
          (this.defaultClientBillingContact.clientBillingName !== undefined ||
            this.defaultClientBillingContact.clientBillingName !== null)) {
          this.defaultEngAssignment.clientBillingContactEmail = this.defaultClientBillingContact.clientBillingEmail;
          this.defaultEngAssignment.clientBillingContactName = this.defaultClientBillingContact.clientBillingName;

        }
        if (this.defaultClientBillingContact.clientBillingTitleId > 0) {
          this.defaultEngAssignment.clientBillingContactTitleId = this.defaultClientBillingContact.clientBillingTitleId;
        }
      }
    }
  }

  displayContactPopover(emailId: string, popoverControlId: string) {
    this.selectContactEmailId = emailId;
    this.controlPopoverId = popoverControlId;
    this.hidePopUp();
    this.bindContactPopup();
    this.checkForContactContent('contact-popup');
    $('#' + this.controlPopoverId).popover('show');

  }
  checkForContactContent(contact) {
    const timer = setTimeout(() => {
      const contactHtml = $('#' + contact).html();
      console.log('after 5 second', contact, contactHtml);
      if (contactHtml) {
        $('.popover-body').html(contactHtml);
        clearTimeout(timer);

      } else {
        this.checkForContactContent(contact);
      }
    }, 900);
  }

  hidePopUp() {
    $('.contactUser').popover('hide');
  }

  bindContactPopup() {
    $('.contactUser').popover({
      container: 'body',
      html: true,
      placement: 'top',
      trigger: 'manual',
      boundary: 'viewport',
      content: function () {
        return '<div class="mx-auto" style="width: 200px;">Loading ...</div>';
      }
    });
  }
  ngAfterViewInit() {
    $('html').on('click', function (e) {
      if (typeof $(e.target).data('original-title') == 'undefined' &&
        !$(e.target).parents().is('.popover.in')) {
        $('.contactUser').popover('hide');
      }
    });

    $('body').on('click', (e) => {
      //did not click a popover toggle or popover
      let target = e.target;
      let parent = target.parentElement;
      if ($(target).is('.contact-close') || $(parent).is('.contact-close')) {
        if ($(e.target).data('toggle') !== 'popover'
          && $(e.target).parents('.popover.in').length === 0) {
          $('.contactUser').popover('hide');

        }
      }
    });
    this.onDocumentSelectionChange();

    this.unsavedAlertfeature_InitialEngagementObject = JSON.parse(JSON.stringify(this.engagement));

  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  blockScriptTags(event, isEngagementName: boolean): boolean {
    this.invalidCharacterDetectionAssignmentName();
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 60 || charCode == 62) {
      return false;
    }
    if (!isEngagementName && this.defaultEngAssignment.assignmentName != null &&
      this.defaultEngAssignment.assignmentName !== '') {
      this.defaultEngAssignment.assignmentName = this.defaultEngAssignment.assignmentName.toString().replace('<', '');
    }
    return true;
  }
  CheckContinuanceAssignmentName() {
    let result = false;
    for (const assignment of this.engagementAssignmentWithoutSCode) {
      if (assignment.assignmentCodeId > 0 && (assignment.assignmentName === undefined ||
        assignment.assignmentName === null || assignment.assignmentName === '')) {
        result = true;
        break;
      }
    }
    return result;
  }

  CheckAssignmentMandatoryFieldsInist() {
    let result = true;
    if (this.arrEngagementAssignment != undefined) {
      for (const assignment of this.arrEngagementAssignment) {
        if (result) {
          this.defaultEngAssignment = assignment;
          this.isValidClientBillingEmail = true;
          this.arrControlValidation = [];
          this.arrControlValidation.push(new FieldValidator('assignmentName', this.defaultEngAssignment.assignmentName, false));
          this.arrControlValidation.push(new FieldValidator('solutionCodeId', this.defaultEngAssignment.solutionCodeId, false));

          this.arrControlValidation.push(new FieldValidator('selectedengagementType',
            this.defaultEngAssignment.selectedengagementType.value, false));
          this.arrControlValidation.push(new FieldValidator('realization', this.defaultEngAssignment.realization, false));
          this.arrControlValidation.push(new FieldValidator('netFees', this.defaultEngAssignment.netFees, false));
          this.arrControlValidation.push(new FieldValidator('isContingent', this.defaultEngAssignment.isContingent, false));
          this.arrControlValidation.push(new FieldValidator('cmsEPPMDName', this.defaultEngAssignment.cmsEPPMDName, false));
          this.arrControlValidation.push(new FieldValidator('assignmentAdministrator', this.defaultEngAssignment.assignmentAdministrator, false));
          this.arrControlValidation.push(new FieldValidator('clientBillingContactName', this.defaultEngAssignment.clientBillingContactName, false));
          this.arrControlValidation.push(new FieldValidator('clientBillingContactTitleId', this.defaultEngAssignment.clientBillingContactTitleId, false));
          this.arrControlValidation.push(new FieldValidator('clientBillingContactEmail', this.defaultEngAssignment.clientBillingContactEmail, false));
          this.arrControlValidation.push(new FieldValidator('estimatedGrossMargin', this.defaultEngAssignment.estimatedGrossMargin, false));
          this.arrControlValidation.push(new FieldValidator('engagementStartDate', this.defaultEngAssignment.engagementStartDate, false));
          this.arrControlValidation.push(new FieldValidator('engagementEndDate', this.defaultEngAssignment.engagementEndDate, false));
          result = this.checkMandatoryValidations(this.arrControlValidation, this.defaultEngAssignment, 'engagementAssignment');
        }
      }
    }
    return result;
  }

  CheckBlankAssignmentNameExistsList() {
    let result = false;
    let isBlankAssignmentNameExists = this.arrEngagementAssignment.find(assignment =>
      assignment.assignmentName === undefined ||
      assignment.assignmentName === null ||
      assignment.assignmentName === '' ||
      assignment.assignmentName === this.blankLineAssignmentName)
    if (isBlankAssignmentNameExists == undefined) {
      result = false;
    }
    else {
      result = true;
    }
    return result;
  }

  validateEngagementName() {
    this.validEngagementName = true;
    if (this.validateAssignmentName(this.engagement.engagementName)) {
      this.validEngagementName = true;
    } else {
      this.validEngagementName = false;
    }
    return this.validEngagementName;
  }

  GenerateSiteUniqueId() {
    //if not edit mode on next click then generate uniqueid first 
    this._engService.GenerateSiteUniqueId(this.engagement).subscribe(
      siteId => {
        this.engagement.siteUniqueID = siteId;
        console.log("site unique id : " + siteId);
        this.createNewWorkItem = true;
        this.CreateWorkItem();
      }, error => {
        this.loadingScreenService.stopLoading();
        this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
      });
  }

  deselectEQCR() {
    this.membersListArrayForEP = null;
    $('#modal-AssignmentEQCR').modal('hide');
    $('#searchEQCRName').val('')
    //$('#' + this.EQCRNameCtrl).val('');
    $('#' + this.EQCRNameCtrl).innerText = '';
    //$('#' + this.EQCRCtrl).val('');
    $('#' + this.EQCRCtrl).innerText = '';
  }
  refreshAssignmentEQCRPopup(textboxName: string) {
    this.removeRowSelection('tblEQCR', 'searchAssAdminName', 'AssignmentAdminErrorMessage');
    let lastIndex = this.peoplepickerIndex;
    this.EQCRNameCtrl = textboxName + this.EQCRRoleName + '_' + this.peoplepickerIndex;
    this.EQCRCtrl = textboxName + 'AS_' + this.EQCRRoleName + '_' + this.peoplepickerIndex;
    this.currentAssignmentRowIndex = lastIndex;
    this.selectedEQCRIndex = null;
    this.validateEQCR = false;
    if ($('#' + this.EQCRNameCtrl).hasClass('text-danger')) {
      $('#' + this.EQCRNameCtrl).removeClass('text-danger');
    }
  }

  setEQCR() {
    let index = this.selectedEQCRIndex;
    if (Number(index) >= 0) {
      const selectedEQCRName = $('#spEQCRdisplayName' + index).text();
      const selectedEQCREmail = $('#spEQCRmail' + index).text();
      const selectedEQCRPhone = $('#spEQCRbusinessPhones' + index).text();
      this.defaultEngAssignment.eqcrEmailId = selectedEQCREmail;
      this.defaultEngAssignment.eqcrName = selectedEQCRName;
      this.validateEQCR = true;
      $("#EQCRErrorMessage").text('');
      if (selectedEQCREmail !== '') {
        if (this.EQCRNameCtrl !== '') {
          $('#' + this.EQCRNameCtrl).val(selectedEQCRName);
          $('#UserHiddenProps_EQCR_0 #hdnUserName').val(selectedEQCRName);
          $('#UserHiddenProps_EQCR_0 #hdnUserEmail').val(selectedEQCREmail);
          $('#UserHiddenProps_EQCR_0 #hdnUserPhone').val(selectedEQCRPhone);
          $('#hdnValidInput_EQCR_0').val(true);
        }
        $('#' + this.EQCRCtrl).val(selectedEQCRName);
        $('#UserHiddenProps_AS_EQCR_0 #hdnUserName').val(selectedEQCRName);
        $('#UserHiddenProps_AS_EQCR_0 #hdnUserEmail').val(selectedEQCREmail);
        $('#UserHiddenProps_AS_EQCR_0 #hdnUserPhone').val(selectedEQCRPhone);
        $('#hdnValidInput_AS_EQCR_0').val(true);
        this.validateEQCR = true;

        if (this.EQCRNameCtrl !== '') {
          this.membersListArrayForEP = null;
          $('#modal-AssignmentEQCR').modal('hide');
          this.engagementAssignmentList[this.currentAssignmentRowIndex].eqcrName = selectedEQCRName;
          this.engagementAssignmentList[this.currentAssignmentRowIndex].eqcrEmailId = selectedEQCREmail;
        }
      }
      else {
        $('#EQCRErrorMessage').text('Please Select User');
      }

    }
    $('#searchEQCRName').val('');
    //this.membersListArrayForEP = null;
  }


  selectEQCR(index) {
    $('#tblEQCR').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedEQCRIndex = index;
    console.log('selectEQCR func called ', this.selectedEQCRIndex);
  }
  updateEQCR() {
    this.ProcessEqcrUpdate(this.defaultEngAssignment.eqcrEmailId, this.editedAssignmentOldData.eqcrEmailId);

    //Add OR Remove old EQCR and TM data if not matching with old data
    if (this.EditAssignMode) {
      //add Old CMSEP and AA to Team member if different than old data and not present as team member
      if (this.defaultEngAssignment.cmsEPPMDEmailId?.toLowerCase() != this.editedAssignmentOldData.cmsEPPMDEmailId?.toLowerCase()) {
        let cmsEPMatchIndex = this.getTeamMemberListIndex(this.editedAssignmentOldData.cmsEPPMDEmailId);
        if (cmsEPMatchIndex < 0) {
          this.addNewTeamMember(this.editedAssignmentOldData, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP));
        }
      }
      if (this.defaultEngAssignment.adminEmailId?.toLowerCase() != this.editedAssignmentOldData.adminEmailId?.toLowerCase()) {
        let adminMatchIndex = this.getTeamMemberListIndex(this.editedAssignmentOldData.adminEmailId);
        if (adminMatchIndex < 0) {
          this.addNewTeamMember(this.editedAssignmentOldData, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A));
        }
      }
    }// End of Edit Assignment Check
  }

  ComputeDefaultIndusFlag(): boolean {
    let indusFlag: boolean = false;
    //functionality got changed for default indus flag no longer using default consent flag
    return indusFlag;
  }

  OnRoleChange(event, id: number) {
    let memberRoleId: number;
    memberRoleId = Number(event.target.value);
    if (memberRoleId == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P)) {
      $('#drpTeamMemberPermissions_' + id).prop("disabled", false);
    } else if (memberRoleId == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.S)) {
      $('#drpTeamMemberPermissions_' + id).prop('selectedIndex', 1);
      $('#drpTeamMemberPermissions_' + id).attr('disabled', true);
    }
  }

  OnKeyRoleChange(event, id: number) {
    let memberRoleId: number;
    memberRoleId = Number(event.target.value);
    if (this.teamMemberList[id].isKeyTeamMember == true &&
      memberRoleId == this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) {
      $('#keyTeamMemberDescription_' + id).css('display', 'block');
    } else {
      this.teamMemberList[id].description = '';
      $('#keyTeamMemberDescription_' + id).css('display', 'none');
    }
  }

  OnKeyTeamMemberChkboxChecked(event, id: number) {
    (event.target.checked && $('#drpKeyTeamMemberRole_' + id).val() == this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) ?
      $('#keyTeamMemberDescription_' + id).css('display', 'block') : $('#keyTeamMemberDescription_' + id).css('display', 'none');
  }

  OnRoleChangeForExistingMember(memberRoleId: string, id: number) {
    if (this.isAudit) {
      $('#errorLabelkeyTeamMemberRole' + id).removeClass('d-contents');
      $('#errorLabelkeyTeamMemberRole' + id).addClass('d-none');
    }

    if (Number(memberRoleId) != this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) {
      this.engTeamaMembeListrWithoutAssignment[id].description = '';
    }
    if (Number(memberRoleId) == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P)) {
      $('#teamMemberPermissions_Row_' + id).prop("disabled", false);
    } else if (Number(memberRoleId) == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.S)) {
      this.engTeamaMembeListrWithoutAssignment[id].teamMemberPermissionId = this.getTeamMemberPermissionsIdByValue('Site member');
      $('#teamMemberPermissions_Row_' + id).prop('selectedIndex', 1);
      $('#teamMemberPermissions_Row_' + id).attr('disabled', true);
    }
  }

  getTeamMemberPermissionsIdByValue(value: string): number {
    return this.teamMemberPermissions.find(c => c.typeValue === value).id;
  }

  deselectGTAgreementOwner() {
    $('#modal-GTAgreementOwner').modal('hide');
    $('#' + this.GTAgreementOwnerNameCtrl).val('');
    $('#' + this.GTAgreementOwnerNameCtrl).innerText = '';
    $('#' + this.GTAgreementOwnerCtrl).val('');
    $('#' + this.GTAgreementOwnerCtrl).innerText = '';
  }
  selectGTAgreementOwner(index) {
    $('#tblGTAgreementOwner').on('click', 'tbody tr', function () {
      $(this).addClass('highlight').siblings().removeClass('highlight');
    });
    this.selectedGTAgreementOwnerIndex = index;
    console.log('selectGTAgreementOwner func called ', this.selectedGTAgreementOwnerIndex);
  }
  setGTAgreementOwner() {
    let index = this.selectedGTAgreementOwnerIndex;
    if (Number(index) >= 0) {
      const selectedGTAgreementOwnerName = $('#spGTAgreementOwnerdisplayName' + index).text();
      const selectedGTAgreementOwnerEmail = $('#spGTAgreementOwnermail' + index).text();
      const selectedEGTAgreementOwnerPhone = $('#spGTAgreementOwnerbusinessPhones' + index).text();
      this.clientLevelDocument.gtAgreementOwnerEmail = selectedGTAgreementOwnerEmail;
      this.clientLevelDocument.gtAgreementOwnerName = selectedGTAgreementOwnerName;
      this.validateGTAgreementOwner = true;
      $("#GTAgreementOwnerErrorMessage").text('');
      if (this.GTAgreementOwnerNameCtrl !== '') {
        $('#' + this.GTAgreementOwnerNameCtrl).val(selectedGTAgreementOwnerName);
        $('#UserHiddenProps_AO_0 #hdnUserName').val(selectedGTAgreementOwnerName);
        $('#UserHiddenProps_AO_0 #hdnUserEmail').val(selectedGTAgreementOwnerEmail);
        $('#UserHiddenProps_AO_0 #hdnUserPhone').val(selectedEGTAgreementOwnerPhone);
        $('#hdnValidInput_AO_0').val(true);
      }

      if (this.GTAgreementOwnerNameCtrl !== '') {
        $('#modal-GTAgreementOwner').modal('hide');
        this.clientLevelDocument.gtAgreementOwnerName = selectedGTAgreementOwnerName;
        this.clientLevelDocument.gtAgreementOwnerEmail = selectedGTAgreementOwnerEmail;

      }
    }
    else {
      $("#GTAgreementOwnerErrorMessage").text('Please Select User');
    }
    this.membersListArray = null;
  }
  refreshGTAgreementOwnerPopup(textboxName: string) {
    this.removeRowSelection('tblGTAgreementOwner', 'searchAsGTAgreementOwnerName', 'GTAgreementOwnerErrorMessage');
    this.GTAgreementOwnerCtrl = textboxName + this.GTAgreementOwnerRoleName + '_' + this.peoplepickerIndex;
    this.selectedGTAgreementOwnerIndex = null;
    this.validateGTAgreementOwner = false;
    this.membersListArrayForEP = null;
    if ($('#' + this.GTAgreementOwnerCtrl).hasClass('text-danger')) {
      $('#' + this.GTAgreementOwnerCtrl).removeClass('text-danger');
    }
  }
  updateClientLevelDocumentList(event: ClientLevelDocument[]) {
    this.clientLevelDocuments = event;
  }
  SetGoverningAgreementSelection(selectedAgreement: ClientLevelDocument) {
    if (selectedAgreement != null && selectedAgreement != undefined) {
      switch (selectedAgreement.selectedActionName) {
        case 'Remove':
          this.SelectedDocumentSubType = null;
          this.engagement.clientLevelDocumentId = 0;
          this.engagement.isByPass = false;
          this.SelectedClientLevelDocId = 0;
          this.engagement.agreementType = undefined;
          this.SelectedTemplateList = [];
          $('#engAgreementType').val('undefined');
          break;
        case 'Edit':
          this.SelectedDocumentSubType = selectedAgreement.documentSubTypeCode;
          this.SelectedClientLevelDocId = selectedAgreement.clientLevelDocumentId;
          this.SelectedDocumentSubTypeValue = this.SelectedDocumentSubType + ':' + this.SelectedClientLevelDocId;
          this.onDocumentSelectionChange();
          break;
        default:
          break;
      }
    }
  }
  onPreRequestFlagChange() {
    this.unsavedAlertfeature_AnyFormFieldChanged = true;
    this.setUnsavedChangesForIwantTo();

    this.ProcessResetAssignmentModel();
    this.engagement.preRequestForContinuance = this.getBoolean(this.selectedPreRequestForContinuanceValue);
  }
  onBudgetApprovedChange() {
    this.setAssignmentValuesBeforeSave();
    this.defaultEngAssignment.budgetApproved = this.getBoolean(this.defaultEngAssignment.isAssignmentFinanceDataEditable);
  }
  ComputeCMSRequestSentFlag(): boolean {
    let requestSent: boolean = false;
    for (let x of this.arrEngagementAssignment) {
      if (x.assignmentCodeStatus != undefined &&
        x.assignmentCodeStatus != null &&
        x.assignmentCodeStatus != this.AssignmentCodePending) {
        requestSent = true;
        break;
      } else if (x.assignmentCodeStatus === this.AssignmentCodePending) {
        requestSent = false;
      }
    }
    return requestSent;
  }
  // request Assignment Code from cms
  RequestAssignmentCode(workspaceId: string, assignmentCodeId: number, newAssignmentName: string) {
    // this.loadingScreenService.startLoading();
    let processAssignmentFlag = false;
    console.log(workspaceId, assignmentCodeId, newAssignmentName);
    if (workspaceId === null && assignmentCodeId === null) {
      console.log('Cannot process the CMS Request as no valid values found.');
      this.cmsCodeRequestError = true;
      this.loadingScreenService.stopLoading();
    } else {
      let _workspaceId = workspaceId;
      let _assignmentCodeId = assignmentCodeId;
      let _assignmentName = newAssignmentName;

      this.workspaceService.getPrimaryAssignment(_workspaceId, _assignmentCodeId, _assignmentName).subscribe(
        assignmentRequestList => {

          const filterredAssignmentRequestList = assignmentRequestList.filter(x =>
            Number(x.engagement_Solution_Assignment_Code_Id) == _assignmentCodeId);
          console.log('filtered assignment list', filterredAssignmentRequestList);

          for (const assignmentRequest of filterredAssignmentRequestList) {
            console.log('data from getPrimaryAssignment ', assignmentRequest);

            if (!this.checkUserExistance(assignmentRequest.initiated_By_Email)) {
              this.employeeList.push(new Employee('0', assignmentRequest.initiated_By_Email));
            }
            if (!this.checkUserExistance(assignmentRequest.client_Administrator_Email)) {
              this.employeeList.push(new Employee('0', assignmentRequest.client_Administrator_Email));
            }
            if (!this.checkUserExistance(assignmentRequest.assignment_Administrator_Email)) {
              this.employeeList.push(new Employee('0', assignmentRequest.assignment_Administrator_Email));
            }
            if (!this.checkUserExistance(assignmentRequest.engagement_Partner_Email)) {
              this.employeeList.push(new Employee('0', assignmentRequest.engagement_Partner_Email));
            }
          }

          console.log('To get the employee ids for given user list ', this.employeeList);
          const searchEmployee = new SearchEmployeeModel();
          searchEmployee.employees = this.employeeList;

          this.graphSerivce.GetEmployeeIdForUserList(searchEmployee).subscribe(
            finalEmployeeList => {

              console.log('API response for employee ids for given user list ', finalEmployeeList);
              for (const assignmentRequestInner of filterredAssignmentRequestList) {
                assignmentRequestInner.initiated_By
                  = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.initiated_By_Email)
                    .employeeId;
                assignmentRequestInner.client_Administrator
                  = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.client_Administrator_Email)
                    .employeeId;
                assignmentRequestInner.assignment_Administrator
                  = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.assignment_Administrator_Email)
                    .employeeId;
                assignmentRequestInner.engagement_Partner
                  = finalEmployeeList.find(item => item.employeeEmailId === assignmentRequestInner.engagement_Partner_Email)
                    .employeeId;

                console.log('Updated Assignment Request ', filterredAssignmentRequestList);
              }
              const request = new AssignmentRequestList();
              request.list = filterredAssignmentRequestList;
              this.cmsService.PostPrimaryAssignment(request).subscribe(
                assignmentResponse => {
                  let responseList = new AssignmentResponseList();
                  responseList.list = assignmentResponse;
                  console.log('Successfully submission of CMS Primary Assignment.', assignmentResponse);
                  this.workspaceService.storeCMSLog(responseList).subscribe(
                    logResult => {
                      for (let i = 0; i < assignmentResponse.length; i++) {
                        this.workspaceService
                          .UpdateContinuanceAssignmentStatus(assignmentResponse[i].responseStatus, assignmentResponse[i].assignmentCodeId)
                          .subscribe(
                            statusResult => { console.log(statusResult); }
                          );
                      }
                      this.loadingScreenService.stopLoading();
                      this.defaultEngAssignment.assignmentCodeStatus = this.AssignmentCodeStatus.RequestSent;
                      this.updateAssignment();
                      this.EditAssignmentTeamMembers(this.EditAssignmentIndex - 1);
                      this.isCMSPreRequestSent = this.ComputeCMSRequestSentFlag();
                      //Close assignment div
                      this.ProcessResetAssignmentModel();
                    },
                    logError => {
                      this.loadingScreenService.stopLoading();
                      console.error('Error in getting the response in storeCMSLog in Engage API ', logError);
                    }
                  );
                },
                error => {
                  this.loadingScreenService.stopLoading();
                  console.error('Error in getting the response in PostPrimaryAssignment in CMS Integration API ', error);
                }
              );
            },
            error => {
              this.loadingScreenService.stopLoading();
              console.error('Error in getting the response in GetEmployeeIdForUserList in Graph API ', error);
            }
          );
        },
        error => {
          this.loadingScreenService.stopLoading();
          console.error('Error in getting the response in getPrimaryAssignment in API ', error);
        }
      );
    }
  }
  // end request assignment code from cms

  checkUserExistance(email: string): boolean {
    return this.employeeList.some(r => r.employeeEmailId === email);
  }

  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.IsUserAccessPermission) {
      this.unsavedAlertfeature_RequestedNavigationUrl = nextState.url;
      return of(true);
    }
    if ((this.unsavedAlertfeature_IsSaveChangesOrNextBtnClicked)
      || (!this.unsavedAlertfeature_AnyFormFieldChanged && !this.AnyPendingUnsavedField() && !this.engagementForm.dirty)
      || (this.unsavedAlertfeature_RequestedNavigationUrl !== '')) {
      this.unsavedAlertfeature_RequestedNavigationUrl = '';
      return of(true);
    }
    else {
      this.isRedirectedFromCreateDoc = this.dataService.getUnsavedDialogVisibility();
      if (this.isRedirectedFromCreateDoc) {
        this.unsavedAlertfeature_RequestedNavigationUrl = nextState.url;
        return of(true);
      }
      if
        (
        (this.unsavedAlertfeature_AnyFormFieldChanged || this.AnyPendingUnsavedField() || this.engagementForm.dirty)
        && !(currentState.url == '/NewEngagement' && nextState.url == '/Continuance' && this.isContinuanceEngExistsPopupOpen)) {
        this.unsavedAlertfeature_RequestedNavigationUrl = nextState.url;
        let oldurl = this.dataService.previousUrl.getValue();
        if (oldurl != undefined) {
          this.setDialogContent("information", EngagementDialogTitles.UnsavedChangesConfirmation);
          return of(false);
        }
        return of(true);
      }
      else if (currentState.url == '/NewEngagement' && nextState.url == '/Continuance') {
        this.hidePopupModal();
        $("#modal-Opportunity").modal('hide');
        this.unsavedAlertfeature_RequestedNavigationUrl = '';
        return of(true);
      }
      else {
        this.unsavedAlertfeature_RequestedNavigationUrl = '';
        return of(true);
      }
    }
  }

  hidePopupModal(title: string = '', buttonName: string = '') {
    const modalObj = {
      dialogType: '',
      dialogTitle: '',
      dialogDescription: '',
      dialogRedirectUrl: '',
      dialogButton: 0,
      modalDisplay: false,
      declineReason: '',
      selectAccountId: ''
    };
    const modalJSON = JSON.stringify(modalObj);
    this.dataService.displayDialogNotification(modalJSON);
    const modalData = {
      dialogTitle: title,
      dialogButtonName: buttonName,
      dialogInput: ''
    };
    const dialogJSON = JSON.stringify(modalData);
    this.dataService.setDialogDetails(dialogJSON);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    $event.returnValue = 'You have changes that have not been saved. Click "Cancel" to return to the page to update or save your changes, or click "OK" to continue to leave the page without saving your changes.';
  }

  editAssignmentDefaultValuesCheck(engagementAssignment: EngagementAssignment) {
    this.unsavedAlertfeature_DefaultAssignmentValues = JSON.parse(JSON.stringify(engagementAssignment));
  }

  AnyPendingUnsavedField() {

    let anyUnsavedField: boolean = false;
    if (this.EditMode) {
      if (this.unsavedAlertfeature_AnyFormFieldChanged || JSON.stringify(this.unsavedAlertfeature_InitialEngagementObject) !== JSON.stringify(this.engagement)
        || (this.defaultEngAssignment.solutionCodeId !== this.unsavedAlertfeature_InitialEngagementObject.parentSolutionCodeId)
        || new Date($('.engPeriodDate').val()).toDateString() != new Date(this.unsavedAlertfeature_InitialEngagementObject.taxYear).toDateString()
        || (this.unsavedAlertfeature_DefaultAssignmentValues &&
          ($('#txtPeoplePicker_AS_' + this.unsavedAlertfeature_Component.EQCRRoleName + '_' + this.unsavedAlertfeature_Component.peoplepickerIndex).val() !== this.unsavedAlertfeature_DefaultAssignmentValues.eqcrName
            || $('#txtPeoplePicker_AS_' + this.unsavedAlertfeature_Component.epRoleName + '_' + this.unsavedAlertfeature_Component.peoplepickerIndex).val() !== this.unsavedAlertfeature_DefaultAssignmentValues.cmsEPPMDName
            || $('#txtPeoplePicker_AS_' + this.unsavedAlertfeature_Component.assignAdminRoleName + '_' + this.unsavedAlertfeature_Component.peoplepickerIndex).val() !== this.unsavedAlertfeature_DefaultAssignmentValues.assignmentAdministrator
          ))
        || ($('#txtPeoplePicker_' + this.unsavedAlertfeature_Component.teamMemberRoleName + '_0').val() && $('#txtPeoplePicker_' + this.unsavedAlertfeature_Component.teamMemberRoleName + '_0').val() !== '')
        || ($('#drpTeamMemberPermissions_0').val() && $('#drpTeamMemberPermissions_0').val() !== '')
        || ($('#drpTeamMemberRole_0').val() && $('#drpTeamMemberRole_0').val() !== '')
        || $('#txtPeoplePicker_' + this.unsavedAlertfeature_Component.epRoleName + '_' + this.unsavedAlertfeature_Component.peoplepickerIndex).val() !== this.unsavedAlertfeature_InitialEngagementObject.engagementPartnerName) {
        anyUnsavedField = true;
      }
    }
    else {
      if (this.unsavedAlertfeature_AnyFormFieldChanged || this.defaultEngAssignment.solutionCodeId > 0
        || ($('#txtPeoplePicker_AS_' + this.unsavedAlertfeature_Component.EQCRRoleName + '_' + this.unsavedAlertfeature_Component.peoplepickerIndex).val() !== ''
          || $('#txtPeoplePicker_AS_' + this.unsavedAlertfeature_Component.epRoleName + '_' + this.unsavedAlertfeature_Component.peoplepickerIndex).val() !== ''
          || $('#txtPeoplePicker_AS_' + this.unsavedAlertfeature_Component.assignAdminRoleName + '_' + this.unsavedAlertfeature_Component.peoplepickerIndex).val() !== ''
          || $('#txtPeoplePicker_' + this.unsavedAlertfeature_Component.epRoleName + '_' + this.unsavedAlertfeature_Component.peoplepickerIndex).val() !== '')
        || ($('#txtPeoplePicker_' + this.unsavedAlertfeature_Component.teamMemberRoleName + '_0').val() && $('#txtPeoplePicker_' + this.unsavedAlertfeature_Component.teamMemberRoleName + '_0').val() !== '')
        || ($('#drpTeamMemberPermissions_0').val() && $('#drpTeamMemberPermissions_0').val() !== '')
        || ($('#drpTeamMemberRole_0').val() && $('#drpTeamMemberRole_0').val() !== '')
        || $('.engPeriodDate').val() != '') {
        anyUnsavedField = true;
      }
    }

    return anyUnsavedField;
  }

  StoreDataModification(engagementId: number) {
    this.dataModification.StoreDataModification(engagementId, DataModificationModuleNames.EditEngagement).subscribe(
      result => {
        console.log('Store Engagement Id ', engagementId, ' Modification Status ', result);
      }, error => {
        console.error('StoreDataModification error ', error);
      }
    );
  }

  UpdateDataModification(engagementId: number) {
    if (engagementId > 0)
      this.dataModification.UpdateDataModification(engagementId, DataModificationModuleNames.EditEngagement).subscribe(
        result => {
          console.log('Update Engagement Id ', engagementId, ' Modification Status ', result);
        }, error => {
          console.error('UpdateDataModification error ', error);
        }
      );
  }

  IsInProgressModification(engagementId: number) {
    this.dataModification.IsInProgressDataModificationExists(engagementId, DataModificationModuleNames.EditEngagement).subscribe(
      notificationResult => {
        this.dataModificationDetails = notificationResult;
        if (this.dataModificationDetails.editStatus == true) {
          this.reviewInProgressEditEngagementMembers = this.dataModificationDetails.editingMembers?.join(", ");
          if (this.dataModificationDetails.editingMembers?.length == 1) {
            this.skipRedirect = true;
            this.reviewInProgressEditEngagementMembers = this.reviewInProgressEditEngagementMembers + '.';
          } else {
            this.reviewInProgressEditEngagementMembers = this.reviewInProgressEditEngagementMembers + '....';
          }
        }
      }, error => {
        console.error('IsInProgressModification error ', error);
      }
    );
  }

  removeCollabClientTeamMember(index: number) {
    if (index > -1) {
      this.clientTeamMembersList.splice(index, 1);
    }
  }
  addClientTeamMember(index: number) {
    if (!(this.engagement?.status !== EngagementStatus.EngNotStarted && this.EditMode && !this.isSiteProvisioned)) {
      if (this.newClientTeamMemberList != null) {
        //close client team members section
        // this.arrValidationForNewTeamMember = [];
        // this.teamMemberList = [];
        $('#clientErrorMessage').text("")
        this._alertService.remove(this.alert);
        this.newClientTeamMember = {
          engagementId: this.engagement.engagementId,
          collaborationSiteId: this.engagementCollabData.collaborationSiteId,
          firstName: '',
          lastName: '',
          emailId: '',
          title: '',
          permissionId: this.defaultClientPermissionId
        };

        this.newClientTeamMemberList.push(this.newClientTeamMember);
        //this.newClientTeamMemberList = this.newClientTeamMemberList;
        const ValidNewClientTeamMember = {
          firstName: '',
          lastName: '',
          emailId: '',
          title: '',
          permissionId: 0,
          collaborationSiteId: 0,
          isNewUserDuplicate: false,
          isInvalidClientTeamMemberEmail: false,
          isInvalidClientTeamMemberConfirmEmail: false,
          cmsDataMatchClient: false,
          index: this.arrValidationForNewClientTeamMember.length,
          isValidClientMember: false
        };
        this.arrValidationForNewClientTeamMember.push(ValidNewClientTeamMember);
      }
    }
    if (this.engagement?.status !== EngagementStatus.EngNotStarted && this.EditMode && !this.isSiteProvisioned) {
      //$('#addClientTeamMember')
      $('#clientErrorMessage').text("Site provisioning in progress.");
    }
    //console.log('this.collaborationSiteViewModelList ==>', this.collaborationSiteViewModelList);
  }
  getClientMemberPermissions() {
    this.applicationMasterService.GetClientMemberPermissions().subscribe(
      result => {
        this.clientMemberPermissions = result;
        this.clientMemberPermissionModelList = [];
        this.clientMemberPermissions.forEach(pr => {
          let codeValueArr = pr.typeValue.split('|');
          let tempModel: ClientMemberPermissionModel = new ClientMemberPermissionModel();
          tempModel.id = pr.id;
          tempModel.code = codeValueArr[0];
          tempModel.typeValue = codeValueArr[1];
          this.clientMemberPermissionModelList.push(tempModel);
          if (codeValueArr[0] === 'R') {
            this.defaultClientPermissionId = pr.id;
          }
        });

        console.log('clientMemberPermissionModelList', this.clientMemberPermissionModelList);
      }
    );
  }
  btnUpdateTeamBtnClick() {
    this.ValidateClientTeamMemberData();
    this.ValidateTeamMemberInExistingAssignment();
    if (this.IsValidInput && !this.cmsDataMatch && this.IsValidInputClient && !this.cmsDataMatchClient) {
      if (this.engTeamaMembeListrWithoutAssignment.length > 0 && this.teamMemberList != null
        && this.teamMemberList.length > 0) {
        for (let t = 0; t < this.teamMemberList.length; t++) {
          let selectedPermissionId = 0;
          if ($('#drpTeamMemberPermissions_' + t + ' option:selected').val() != null) {
            selectedPermissionId = parseInt($('#drpTeamMemberPermissions_' + t + ' option:selected').val());
          }
          //var iskeyTeamMember = $('#chkKeyTeamMember_' + t).val();
          this.newAssignTeamMember = {

            memberName: $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserName').val(),
            memberEmailId: $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserEmail').val(),
            memeberContactNo: $('#UserHiddenProps_' + this.teamMemberRoleName + '_' + t + ' #hdnUserPhone').val(),
            //teamMemberRole: $('#drpTeamMemberRole_' + t + ' option:selected').val(),
            teamMemberRole: this.isAudit ? this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P) : $('#drpTeamMemberRole_' + t + ' option:selected').val(),
            teamMemberPermissionId: selectedPermissionId,
            isKeyTeamMember: this.isAudit ? this.teamMemberList[t].isKeyTeamMember : false,
            keyTeamMemberRoleId: this.isAudit ? $('#drpKeyTeamMemberRole_' + t + ' option:selected').val() : '',
            description: (this.isAudit && this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH)) ? this.teamMemberList[t].description : ''
          };
          this.engTeamaMembeListrWithoutAssignment.push(this.newAssignTeamMember);
          this.unsavedAlertfeature_AnyFormFieldChanged = true;
          this.setUnsavedChangesForIwantTo();

          this.ConfigureReviewNoteSearch();
        }
        this.teamMemberList = [];
        this.arrValidationForNewTeamMember = [];
      }
      // if (this.IsValidInputClient && !this.cmsDataMatchClient && this.IsValidInputClient && !this.cmsDataMatchClient) {
      if (this.newClientTeamMemberList != null && this.newClientTeamMemberList.length > 0) {
        for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
          let selectedPermissionId = 0;
          if ($('#drpClientTeamMemberPermissions_' + t + ' option:selected').val() != null) {
            selectedPermissionId = parseInt($('#drpClientTeamMemberPermissions_' + t + ' option:selected').val());
          }
          let newClientMember: ClientTeamMembers = new ClientTeamMembers();
          newClientMember.engagementId = this.adClientSiteUsersSyncEngageClientMmembers.engagementId;
          newClientMember.collaborationSiteId = this.adClientSiteUsersSyncEngageClientMmembers.collaborationSiteId;
          newClientMember.firstName = $('#txtFirstName_' + t).val();
          newClientMember.lastName = $('#txtLastName_' + t).val();
          newClientMember.emailId = $('#txtClientEmail_' + t).val();
          newClientMember.clientEmailId = $('#txtconfirmClientEmail_' + t).val();
          newClientMember.title = this.newClientTeamMemberList[t].title;
          newClientMember.permissionId = selectedPermissionId;
          this.clientTeamMembersList.push(newClientMember);
        }
        this.newClientTeamMemberList = [];
        this.arrValidationForNewClientTeamMember = [];
      }
    } // end check for cmsmatchdata and valid input

  }
  ValidateClientTeamMemberData() {
    // <===== Client Team Member Validation Process Start =====>
    //this.TeamMemberManipulationTrigger = true;
    this.cmsDataMatchClient = false;
    this.IsValidInputClient = true;
    if (this.newClientTeamMemberList.length > 0) {
      // Check Control Validations
      for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
        this.arrValidationForNewClientTeamMember[t].firstName = $('#txtFirstName_' + t).val();
        this.arrValidationForNewClientTeamMember[t].lastName = $('#txtLastName_' + t).val();
        this.arrValidationForNewClientTeamMember[t].emailId = $('#txtClientEmail_' + t).val();
        this.arrValidationForNewClientTeamMember[t].clientEmailId = $('#txtconfirmClientEmail_' + t).val();
        this.arrValidationForNewClientTeamMember[t].title = $('#txtClientTitle_' + t).val();
        this.arrValidationForNewClientTeamMember[t].permissionId = Number($('#drpClientTeamMemberPermissions_' + t).val());
        this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = false;
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
        this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;


        if (this.arrValidationForNewClientTeamMember[t].firstName == undefined || this.arrValidationForNewClientTeamMember[t].firstName === '') {
          $('#errorLabelFirstName' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelFirstName' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewClientTeamMember[t].lastName === '' || this.arrValidationForNewClientTeamMember[t].lastName === undefined) {
          $('#errorLabelLastName' + t).attr('style', 'display:block');
        } else {
          $('#errorLabelLastName' + t).attr('style', 'display:none');
        }
        if (this.arrValidationForNewClientTeamMember[t].emailId === '' || this.arrValidationForNewClientTeamMember[t].emailId === undefined) {
          $('#errorLabelEmail' + t).attr('style', 'display:block');
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
        } else {
          $('#errorLabelEmail' + t).attr('style', 'display:none');
        }

        if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
          $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
          if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
            this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
            this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
            $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
            $('#userExistClient' + t).attr('style', 'display:none');

          } else {
            this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
            if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
              $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
              $('#userExistClient' + t).attr('style', 'display:none');
              this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
            }
            else {
              $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
              $('#userExistClient' + t).attr('style', 'display:none');
            }
          }
        }
        else {
          $('#errorLabelConfirmEmail' + t).attr('style', 'display:block');
          $('#userExistClient' + t).attr('style', 'display:none');
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        }




        if (this.arrValidationForNewClientTeamMember[t]?.permissionId > 0) {
          $('#errorLabelClientTeamMemberPermission' + t).attr('style', 'display:none');
        } else {
          $('#errorLabelClientTeamMemberPermission' + t).attr('style', 'display:block');
        }

        if (this.arrValidationForNewClientTeamMember[t]?.firstName?.length > 0
          && this.arrValidationForNewClientTeamMember[t]?.lastName?.length > 0
          && this.arrValidationForNewClientTeamMember[t]?.emailId?.length > 0
          && this.arrValidationForNewClientTeamMember[t]?.clientEmailId?.length > 0
          && (this.arrValidationForNewClientTeamMember[t]?.emailId == this.arrValidationForNewClientTeamMember[t]?.clientEmailId)
          && this.arrValidationForNewClientTeamMember[t]?.permissionId > 0) {
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = true;
        } else {
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        }

        if (!this.validateEmail(this.arrValidationForNewClientTeamMember[t].emailId)) {
          this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = true;
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        } else {
          this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = false;
        }
      }
      // check duplicate emailid Recrods
      for (let t = 0; t < this.arrValidationForNewClientTeamMember.length; t++) {
        if (this.CheckDuplicateElement(this.arrValidationForNewClientTeamMember, 'emailId',
          this.arrValidationForNewClientTeamMember[t].emailId)) {
          this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = true;
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        } else {
          this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
        }
      }

      // check if new client member email is matching with existing  client team member email.
      if (this.clientTeamMembersList != undefined) {

        for (let j = 0; j < this.clientTeamMembersList.length; j++) {
          for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
            if ($('#txtClientEmail_' + t).val().toLowerCase() ===
              this.clientTeamMembersList[j].emailId.toLowerCase()) {
              this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = true;
              this.cmsDataMatchClient = true;
              $('#userExistClient' + t).attr('style', 'display:block');
              break;
            }
            if (this.cmsDataMatchClient === false || this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient === false) {
              $('#userExistClient' + t).attr('style', 'display:none');
            }
          } //newClientTeamMemberList
        }//clientTeamMemberList
      }

      //Check all memners are valid 
      for (let i = 0; i < this.arrValidationForNewClientTeamMember.length; i++) {
        if (this.arrValidationForNewClientTeamMember[i].isValidClientMember == false) {
          this.IsValidInputClient = false;
          break;
        } else {
          this.IsValidInputClient = true;
        }
      }



    } // Top if newClientTeamMemberList.length > 0
    //  <===== Client Team Member Validation Process End  =====>
  }
  removeNewCollabClientTeamMember(index: number) {
    if (index > -1) {
      this.newClientTeamMemberList.splice(index, 1);
      this.arrValidationForNewClientTeamMember.splice(index, 1);
    }
  }
  GenerateClientSiteUpdatePermissionModel(): UpdatePermissionsModel {
    // Get List of Groups based on Permission Drop down(R/RW/RWD) //
    let clientSiteMembersGroups = this.clientTeamMembersList.reduce((r, a) => {
      r[a.permissionId] = [...r[a.permissionId] || [], a];
      return r;
    }, {});

    console.log("clientSiteMembersGroups", clientSiteMembersGroups);
    let RPermissionUserList: ClientTeamMembers[] = [];
    let RWPermissionUserList: ClientTeamMembers[] = [];
    let RWDPermissionUserList: ClientTeamMembers[] = [];

    let clientSiteGroupTypes = Object.keys(clientSiteMembersGroups);
    clientSiteGroupTypes.forEach(type => {
      if ((this.clientMemberPermissionModelList.filter(permission => permission.id == Number(type))[0].code) === 'R') {
        RPermissionUserList = this.clientTeamMembersList.filter(member =>
          Number(member.permissionId) === Number(type));
      } else if ((this.clientMemberPermissionModelList.filter(pr => pr.id == Number(type))[0].code) === 'RW') {
        RWPermissionUserList = this.clientTeamMembersList.filter(member =>
          Number(member.permissionId) === Number(type));
      } else if ((this.clientMemberPermissionModelList.filter(pr => pr.id == Number(type))[0].code) === 'RWD') {
        RWDPermissionUserList = this.clientTeamMembersList.filter(member =>
          Number(member.permissionId) === Number(type));
      }
    })

    // Assemble UpdatePermissionModel  //
    let clientSiteUsersUpdatePermissionModel: UpdatePermissionsModel = new UpdatePermissionsModel();
    let RUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();
    let RWUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();
    let RWDUserEmailIdModel: UsersListEmailModel = new UsersListEmailModel();

    // Collect client site READ Permission User Email ID List //
    RUserEmailIdModel.groupType = 'R';
    RUserEmailIdModel.groupUsersEmail = [];
    RPermissionUserList.forEach(rUser => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = rUser.firstName;
      adUser.lastName = rUser.lastName;
      adUser.userEmailId = rUser.emailId;
      RUserEmailIdModel.groupUsersEmail.push(adUser);
    })

    // Collect client site READ/WRITE Permission User Email ID List //
    RWUserEmailIdModel.groupType = 'RW';
    RWUserEmailIdModel.groupUsersEmail = [];
    RWPermissionUserList.forEach(rwUser => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = rwUser.firstName;
      adUser.lastName = rwUser.lastName;
      adUser.userEmailId = rwUser.emailId;
      RWUserEmailIdModel.groupUsersEmail.push(adUser);
    })

    // Collect client site READ/WRITE/DELETE Permission User Email ID List //
    RWDUserEmailIdModel.groupType = 'RWD';
    RWDUserEmailIdModel.groupUsersEmail = [];
    RWDPermissionUserList.forEach(rwdUser => {
      let adUser: ADUser = new ADUser();
      adUser.firstName = rwdUser.firstName;
      adUser.lastName = rwdUser.lastName;
      adUser.userEmailId = rwdUser.emailId;
      RWDUserEmailIdModel.groupUsersEmail.push(adUser);
    })

    clientSiteUsersUpdatePermissionModel.siteType = 'ClientSite';
    clientSiteUsersUpdatePermissionModel.siteUniqueID = 'CS-' + this.engagement.siteUniqueID;
    clientSiteUsersUpdatePermissionModel.usersListEmail.push(RUserEmailIdModel);
    clientSiteUsersUpdatePermissionModel.usersListEmail.push(RWUserEmailIdModel);
    clientSiteUsersUpdatePermissionModel.usersListEmail.push(RWDUserEmailIdModel);
    return clientSiteUsersUpdatePermissionModel;
  }
  checkSpaceChartBegin(event, index: number) {
    const text = event.currentTarget.value.trim();
    if (text.length === 0) {
      if (event.target.id === 'txtClientTitle_' + index) {
        this.newClientTeamMemberList[index].title = null;
      }
      return false;
    } else if (text.length > 0) {
      if (event.target.id === 'txtClientTitle_' + index) {
        this.newClientTeamMemberList[index].title = text;
      }
    } else { return true; }
  }

  GenerateIndusConsentEvent(consent: string) {
    this.logService.info('Indus Consent ', consent);

    if (consent.length > 0) {
      let indusConsentEvent = new EngageEvent();
      indusConsentEvent.engageEventType = 'IndusConsent';
      indusConsentEvent.engagementId = this.oldEngagementData.engagementId;
      indusConsentEvent.collaborationSiteId = this.engagementCollabData.collaborationSiteId;
      indusConsentEvent.engagementName = this.oldEngagementData.engagementName;
      indusConsentEvent.createdBy = this.UserObject.loggedInUserEmail;
      indusConsentEvent.indusConsent = consent;
      this.logService.info('Event data ', indusConsentEvent);

      this._engService.GenerateEventInEventGrid(indusConsentEvent).subscribe(
        result => {
          this.logService.info('Indus Consent sent to Event Grid.', result);
        }, error => {
          this.logService.error('Error in sending Indus Consent event to Event Grid.', error);
        }
      );
    }
  }

  callPowerAutomateToProcessClientMemberNotification() {
    let powerAutomateRequestModel = new PowerAutomateRequestModel();
    powerAutomateRequestModel.engageEnviornment = "";
    powerAutomateRequestModel.collaborationSiteId = this.engagementCollabData.collaborationSiteId;

    this._engService.GeneratePowerAutomateTriggerOnClientUserCreated(powerAutomateRequestModel)
      .subscribe(
        resultString => {
          console.log('GeneratePowerAutomateTriggerOnClientUserCreated=>', resultString);
        },
        (error) => {
          this.loadingScreenService.stopLoading();
          this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
        }
      );
  }

  addEditEQCR(assignmentIndex: number) {
    this.defaultEngAssignment = this.arrEngagementAssignment[assignmentIndex];
    this.arrEngagementAssignment[assignmentIndex].isEditEQCR = true;
    this.arrEngagementAssignment[assignmentIndex].isValidEQCRBusinessRule = true;
    this.arrEngagementAssignment[assignmentIndex].isvalidEQCR = true;
    this.arrEngagementAssignment[assignmentIndex].isEQCRExists = false;
    this.arrEngagementAssignment[assignmentIndex].newEQCREmailId = this.arrEngagementAssignment[assignmentIndex].eqcrEmailId;
    this.arrEngagementAssignment[assignmentIndex].newEQCRName = this.arrEngagementAssignment[assignmentIndex].eqcrName;
  }

  cancelEQCR(assignmentIndex: number) {
    this.arrEngagementAssignment[assignmentIndex].isEditEQCR = false;
    this.arrEngagementAssignment[assignmentIndex].newEQCRName = '';
    this.arrEngagementAssignment[assignmentIndex].newEQCREmailId = '';
    this.arrEngagementAssignment[assignmentIndex].isvalidEQCR = false;
    this.arrEngagementAssignment[assignmentIndex].isValidEQCRBusinessRule = true;
    this.arrEngagementAssignment[assignmentIndex].isEQCRExists = false;
    this.EditAssignMode = false;
  }

  updateEngagementEqcr(assignmentIndex: number) {
    let isSelectedEQCRValid: boolean = false;
    let isValidData: boolean = true;
    let EQCRName = $('#txtPeoplePicker_AS_EQCR_' + assignmentIndex).val();
    let selectedEQCREmail = $('#UserHiddenProps_AS_EQCR_' + assignmentIndex + ' #hdnUserEmail').val();
    isSelectedEQCRValid = Boolean(JSON.parse($('#hdnValidInput_AS_EQCR_' + assignmentIndex).val()));
    let EPPMDEmail = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
    if (EQCRName === "" || EQCRName === null || EQCRName === undefined) {
      this.defaultEngAssignment.eqcrEmailId = '';
      this.defaultEngAssignment.eqcrName = '';
      selectedEQCREmail = '';
    }
    this.defaultEngAssignment.isvalidEQCR = isSelectedEQCRValid;
    if (this.defaultEngAssignment.isvalidEQCR &&
      selectedEQCREmail.toLocaleLowerCase() === this.arrEngagementAssignment[assignmentIndex].newEQCREmailId.toLowerCase()) {
      this.arrEngagementAssignment[assignmentIndex].isEQCRExists = true;
      isValidData = false;
    }
    else if (this.defaultEngAssignment.isvalidEQCR &&
      (selectedEQCREmail.toLowerCase() === this.arrEngagementAssignment[assignmentIndex].cmsEPPMDEmailId.toLowerCase() ||
        selectedEQCREmail.toLowerCase() === this.arrEngagementAssignment[assignmentIndex].adminEmailId.toLowerCase() ||
        selectedEQCREmail.toLowerCase() === EPPMDEmail.toLowerCase())) {
      this.arrEngagementAssignment[assignmentIndex].isValidEQCRBusinessRule = false;
      isValidData = false;
    }

    if (!this.defaultEngAssignment.isvalidEQCR && (EQCRName === "" ||
      EQCRName === null || EQCRName === undefined)) {
      isValidData = true;
    } else if (!this.defaultEngAssignment.isvalidEQCR && EQCRName != "" &&
      EQCRName != null && EQCRName != undefined) {
      isValidData = false;
    }

    if (isValidData) {
      this.defaultEngAssignment.eqcrEmailId = selectedEQCREmail;
      this.defaultEngAssignment.eqcrName = EQCRName;

      this.EditAssignMode = true;

      this.arrEngagementAssignment[assignmentIndex].eqcrEmailId = this.defaultEngAssignment.eqcrEmailId;
      this.arrEngagementAssignment[assignmentIndex].eqcrName = this.defaultEngAssignment.eqcrName;
      this.ProcessEqcrUpdate(this.defaultEngAssignment.eqcrEmailId, this.arrEngagementAssignment[assignmentIndex].newEQCREmailId);
      this.arrEngagementAssignment[assignmentIndex].status = 'Update';
      this.ProcessResetAssignmentModel();
      this.cancelEQCR(assignmentIndex);
    } else {
      this.arrEngagementAssignment[assignmentIndex].eqcrName = this.defaultEngAssignment.newEQCRName;
      this.arrEngagementAssignment[assignmentIndex].eqcrEmailId = this.defaultEngAssignment.newEQCREmailId;
      this.arrEngagementAssignment[assignmentIndex].status = 'Update';
    }

  }
  ProcessEqcrUpdate(selectedEQCR: string, oldEQCR: string) {
    let distinctEQCRList: EngagementAssignment[] = [];
    distinctEQCRList = this.getDistinctEQCRList();
    let EQCRAddFlag: boolean = false;
    let addMemberFlag: boolean = true;
    if (this.defaultEngAssignment.eqcrName === '' ||
      this.defaultEngAssignment.eqcrName === null ||
      this.defaultEngAssignment.eqcrName === undefined) {
      this.defaultEngAssignment.eqcrEmailId = '';
      EQCRAddFlag = false;
    } else {
      EQCRAddFlag = true;
    }

    //remove New EQCR if present as team member
    this.engTeamaMembeListrWithoutAssignment.forEach((x, index) => {
      if (x.memberEmailId.toLowerCase() === this.defaultEngAssignment.eqcrEmailId.toLowerCase() &&
        x.teamMemberRole != this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP) &&
        x.teamMemberRole != this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR)) {
        this.engTeamaMembeListrWithoutAssignment.splice(index, 1);
      }
    });


    //Add New EQCR if EQCR not present
    let EQCRIndex = this.getTeamMemberListIndex(this.defaultEngAssignment.eqcrEmailId);

    if (EQCRIndex < 0 && EQCRAddFlag && !this.checkEPPMDMatch(this.defaultEngAssignment.eqcrEmailId)) {
      this.addNewTeamMember(this.defaultEngAssignment, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR));

    }
    //Add OR Remove old EQCR and TM data if not matching with old data
    if (this.EditAssignMode) {
      if (selectedEQCR?.toLowerCase() != oldEQCR?.toLowerCase()) {
        //remove EQCR if not present in list
        let eqcrMatchIndex = this.getTeamMemberListIndex(oldEQCR);
        let eqcrDistinctIndex = distinctEQCRList.findIndex(eqcr => eqcr.eqcrEmailId?.toLowerCase() === oldEQCR?.toLowerCase());


        if (eqcrMatchIndex > 0 && eqcrDistinctIndex < 0) {

          this.engTeamaMembeListrWithoutAssignment.splice(eqcrMatchIndex, 1);
          //add Old EQCR to Team member if present as team member
          this.arrEngagementAssignment.forEach(x => {
            if (addMemberFlag && x.cmsEPPMDEmailId?.toLowerCase() === oldEQCR?.toLowerCase()) {
              this.addNewTeamMember(x, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP));
              addMemberFlag = false;
              return;
            }
            else if (addMemberFlag && x.adminEmailId?.toLowerCase() === oldEQCR?.toLowerCase()) {
              this.addNewTeamMember(x, this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A));
              addMemberFlag = false;
              return;
            }

          });

        }

      }

    }


    this.ConfigureReviewNoteSearch();
  }

  //#region floating review note code 
  ConfigureReviewNoteSearch() {
    this.reviewNoteSearchableUsers = [];

    setTimeout(() => {

      if (this.reviewerList && this.reviewerList.length > 0) {
        this.reviewerList.forEach(user => {
          if (user.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional && this.reviewNoteSearchableUsers.filter(item => item.email == user.memberEmailId).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.memberName, value: user.memberName, email: user.memberEmailId });
          }
        })
      }

      if (this.engTeamaMembeListrWithoutAssignment && this.engTeamaMembeListrWithoutAssignment.length > 0) {
        this.engTeamaMembeListrWithoutAssignment.forEach(user => {
          if (user.memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional && this.reviewNoteSearchableUsers.filter(item => item.email == user.memberEmailId).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.memberName, value: user.memberName, email: user.memberEmailId });
          }
        })
      }


      if (this.reviewNoteSearchableUsersFromQualityGroups && this.reviewNoteSearchableUsersFromQualityGroups.length > 0) {
        this.reviewNoteSearchableUsersFromQualityGroups.forEach(user => {
          if (this.reviewNoteSearchableUsers.filter(item => item.email == user.email).length == 0) {
            this.reviewNoteSearchableUsers.push({ key: user.key, value: user.value, email: user.email });
          }
        })
      }
    }, 1000);
  }

  getWorkspaceMembers() {
    const getRps = this.workspaceService.getReviewers(this.engagement.workspaceId);
    const getQualityADGroups = this.adUsersService.GetQaulityADGroupsByWorkspaceId(this.engagement.workspaceId);
    forkJoin([getRps, getQualityADGroups]).subscribe(
      responses => {
        this.reviewerList = responses[0];
        this.isLoggedInUserIsPureNotificationOnlyProfesional();
        let qaulityGroups: AzureADGroups = new AzureADGroups();
        if (responses[1] && responses[1].length > 0) {
          qaulityGroups.azureADUsers = responses[1];
          this.graphSerivce.getAzureAdUsersByGroups(qaulityGroups, RoleNames.QualityGroup).subscribe(
            qaulityUsers => {
              if (qaulityUsers && qaulityUsers.length > 0) {
                qaulityUsers.forEach(user => {
                  if (this.reviewNoteSearchableUsersFromQualityGroups.filter(addedUser => addedUser.email == user.mail).length == 0) {
                    this.reviewNoteSearchableUsersFromQualityGroups.push({ key: user.displayName, value: user.displayName, email: user.mail });
                  }
                });
                this.ConfigureReviewNoteSearch();
              }
              this.loadingScreenService.stopLoading();
            },
            error => {
              this.DisplaySystemAlert();
            });
        } else {
          this.ConfigureReviewNoteSearch();
          this.loadingScreenService.stopLoading();
        }
      },
      error => {
        this.DisplaySystemAlert();
      });
  }
  async DisplayReviewNotes() {
    //Load review notes component
    //this.dataService.setCollapsibleLeftNav(true);
    this.loadingScreenService.startLoading();
    setTimeout(() => {
      const componentFactorya = this.cfr.resolveComponentFactory(ReviewNotesComponent);
      this.reviewNoteEngComponentRef = this.vrf.createComponent(componentFactorya);
      this.reviewNoteEngComponentRef.instance.isUserEP = this.IsUserEP;
      this.reviewNoteEngComponentRef.instance.isUserRP = this.IsUserRP;
      this.reviewNoteEngComponentRef.instance.isPureNotificationOnlyProfessional = this.isPureNotificationOnlyProfessional;
      this.reviewNoteEngComponentRef.instance.isUserTM = this.IsUserTM;
      this.reviewNoteEngComponentRef.instance.reviewId = this.currentEngagementId;
      this.reviewNoteEngComponentRef.instance.reviewNoteSearchableUsers = this.reviewNoteSearchableUsers;
      this.reviewNoteEngComponentRef.instance.selectedWorkspaceDocumentId = '';
      this.reviewNoteEngComponentRef.instance.showReviewNoteModal();
      this.loadingScreenService.stopLoading();
      this.reviewNoteEngComponentRef.instance.closeReviewNotesModal.subscribe(isTrue => {
        this.loadingScreenService.stopLoading();
        this.vrf.clear();
      });
    }, 1000);

  }

  getWorkspaceReviewPointsCount() {
    this.workspaceReviewPointService.getReviewNotesCount().subscribe(points => {
      this.workspaceOpenReviewPointsCount = points;
    });
  }
  isLoggedInUserIsPureNotificationOnlyProfesional() {
    var i = 0, j = 0;
    if (this.engTeamaMembeListrWithoutAssignment && this.engTeamaMembeListrWithoutAssignment.length > 0) {
      for (i = 0; i < this.engTeamaMembeListrWithoutAssignment.length; i++) {
        if (this.engTeamaMembeListrWithoutAssignment[i].memberEmailId.toLowerCase() == this.UserObject.userName.toLowerCase()) {
          this.IsUserTM = true;
          break;
        }
      }
    }
    if (this.reviewerList && this.reviewerList.length > 0) {
      for (j = 0; j < this.reviewerList.length; j++) {
        if (this.reviewerList[j].memberEmailId.toLowerCase() == this.UserObject.userName.toLowerCase()
          && this.reviewerList[j].teamMemberRole != EnumRoles.NotificationOnlyProfessional
          && this.reviewerList[j].memberType != RevievingProfessionalType.AutoNotificationOnlyProfessional) {
          this.IsUserRP = true;
        }
      }
    }
    this.isPureNotificationOnlyProfessional = false;
  }
  getWorkspaceReviewPoints() {
    return this.workspaceReviewPointService
      .getReviewPointsWithComments(this.engagement.workspaceId, false)
      .subscribe(
        result => {
          var list = result.reviewPointList.filter(x => x.status == 1);
          this.workspaceReviewPointService.setReviewNotesCount(list.length);
          this.getWorkspaceReviewPointsCount();
        },
        error => {
          this.DisplaySystemAlert();
        }
      );
  }
  DisplaySystemAlert() {
    this.loadingScreenService.stopLoading();
    this._alertService.add('Something went wrong! Please contact system administrator.', 'danger', null);
  }
  //#endregion

  UpdateValidationArrayForNewClientTeamMember() {
    for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
      this.arrValidationForNewClientTeamMember[t].firstName = $('#txtFirstName_' + t).val();
      this.arrValidationForNewClientTeamMember[t].lastName = $('#txtLastName_' + t).val();
      this.arrValidationForNewClientTeamMember[t].emailId = $('#txtClientEmail_' + t).val();
      this.arrValidationForNewClientTeamMember[t].clientEmailId = $('#txtconfirmClientEmail_' + t).val();
      this.arrValidationForNewClientTeamMember[t].title = $('#txtClientTitle_' + t).val();
      this.arrValidationForNewClientTeamMember[t].permissionId = Number($('#drpClientTeamMemberPermissions_' + t).val());
      this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = false;
      this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
      this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
    }
  }

  ValidateClientEmailField(t, siteId) {
    this.UpdateValidationArrayForNewClientTeamMember();

    if (this.arrValidationForNewClientTeamMember[t].emailId === '') {
      $('#errorLabelEmail' + t).attr('style', 'display:block');
      $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
    } else {
      $('#errorLabelEmail' + t).attr('style', 'display:none');
    }

    if (!this.validateEmail(this.arrValidationForNewClientTeamMember[t].emailId)) {
      this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = true;
      this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
    } else {
      this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberEmail = false;
    }

    this.ValidateClientEmailAlreadyExist();

    //Confirm email check
    if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
      if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
        this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');

      } else {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
        if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        }
        else {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
        }
      }
    }
  }

  ValidateClientEmailAlreadyExist() {
    // check if new client member email is matching with existing  client team member email.
    if (this.clientTeamMembersList != undefined) {

      for (let j = 0; j < this.clientTeamMembersList.length; j++) {
        for (let t = 0; t < this.newClientTeamMemberList.length; t++) {
          if ($('#txtClientEmail_' + t).val().toLowerCase() ===
            this.clientTeamMembersList[j].emailId.toLowerCase()) {
            this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient = true;
            this.cmsDataMatchClient = true;
            $('#userExistClient' + t).attr('style', 'display:block');
            break;
          }
          if (this.cmsDataMatchClient === false || this.arrValidationForNewClientTeamMember[t].cmsDataMatchClient === false) {
            $('#userExistClient' + t).attr('style', 'display:none');
          }
        } //newClientTeamMemberList
      }//clientTeamMemberList
    }

    // check duplicate emailid Recrods
    for (let t = 0; t < this.arrValidationForNewClientTeamMember.length; t++) {
      if (this.CheckDuplicateElement(this.arrValidationForNewClientTeamMember, 'emailId',
        this.arrValidationForNewClientTeamMember[t].emailId)) {
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = true;
        this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
      } else {
        this.arrValidationForNewClientTeamMember[t].isNewUserDuplicate = false;
      }
    }
  }

  ValidateConfirmClientEmailField(t) {
    this.UpdateValidationArrayForNewClientTeamMember();
    if (this.arrValidationForNewClientTeamMember[t].clientEmailId != '') {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:none');
      if (!this.validateConfirmEmail(this.arrValidationForNewClientTeamMember[t].clientEmailId)) {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = true;
        this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
        $('#userExistClient' + t).attr('style', 'display:none');

      } else {
        this.arrValidationForNewClientTeamMember[t].isInvalidClientTeamMemberConfirmEmail = false;
        if (this.arrValidationForNewClientTeamMember[t].emailId != this.arrValidationForNewClientTeamMember[t].clientEmailId) {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:block');
          $('#userExistClient' + t).attr('style', 'display:none');
          this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
        }
        else {
          $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
          $('#userExistClient' + t).attr('style', 'display:none');
        }
      }
    }
    else {
      $('#errorLabelConfirmEmail' + t).attr('style', 'display:block');
      $('#userExistClient' + t).attr('style', 'display:none');
      $('#errorLabelConfirmEmailMatch' + t).attr('style', 'display:none');
      this.arrValidationForNewClientTeamMember[t].isValidClientMember = false;
    }

    this.ValidateClientEmailAlreadyExist();
  }

  SetOpportunityModelValuesAndOpenPopUp() {
    this.validateOpportunityService = false;
    let clientName = $('#txtValue' + this.MappedValue + 0).val();
    const clientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();
    if (clientCode.length > 0) {
      clientName = clientName.replace(' (' + clientCode + ')', '');
    }
    this.selectedclientName = clientName;
    this.selectedclientCode = $('#hdnKeyCode' + this.MappedValue + 0).val();
    this.clientOneViewAccountId = $('#hdnclientOneViewAccountId' + this.MappedValue + 0).val();

    // if (this.primarySelectedSolutionCode != undefined) {
    //   this.selectedSolutionCode = this.primarySelectedSolutionCode.solutionCode;
    //   this.selectedsolutionCodeWithDesc = this.primarySelectedSolutionCode.solutionCode + ' - ' + this.primarySelectedSolutionCode.solutionCodeDescription;
    // }
    this.selectedsolutionCodeWithDesc = $('#txtValue' + this.SolutionCodeMappedValue + 0).val();

    if ((this.selectedclientCode != '' || this.clientOneViewAccountId != '')
      && this.selectedSolutionCode != '') {
      let secSolutionCodeList: string[] = [];
      if (this.secondarySolutionCode != undefined) {
        this.secondarySolutionCode.filter(code => code.seq != 0).forEach(item => {
          secSolutionCodeList.push(item.solutionCode);
        });
      }
      this._engService.GetInterchangeableSolutionCodes(this.engagement.parentSolutionCodeId).subscribe(result => {
        this.interchangeableSolutionCodes = result;
        let interchangeableSolutionCodeList: string[] = [];
        if (this.interchangeableSolutionCodes != undefined) {
          this.interchangeableSolutionCodes.forEach(item => {
            interchangeableSolutionCodeList.push(item.solutionCode);
          });
        }
        console.log('Interchangable solutioncode ids:' + interchangeableSolutionCodeList);

        let searchOpportunityByRequestModel: SearchOpportunityByRequestModel =
          new SearchOpportunityByRequestModel(String(this.primarySelectedSolutionCode.solutionCode),
            this.selectedclientCode,
            secSolutionCodeList,
            this.clientOneViewAccountId,
            this.primarySelectedSolutionCode.isAllAdvisory,
            this.primarySelectedSolutionCode.isAllAudit,
            this.primarySelectedSolutionCode.isAllTax,
            this.primarySelectedSolutionCode.solutionCodeType, interchangeableSolutionCodeList);
        //   let secSolutionCodeList1: string[] = [];
        //   secSolutionCodeList1.push('AAS10111');
        //  let searchOpportunityByRequestModel: SearchOpportunityByRequestModel = new SearchOpportunityByRequestModel("AAS1011", "0161024", secSolutionCodeList1);      
        $("#modal-Opportunity").modal('show');
        this.GetClientOpportunity(searchOpportunityByRequestModel);
      });
    }
    else //If Client code and One view Account id BLANK then open default Assignemnt section    
    {
      this.showDefaultAssignmentBox();
    }
  }

  GetClientOpportunity(searchOpportunityByRequestModel: SearchOpportunityByRequestModel) {
    this.loadingScreenService.startLoading();
    this.clientOpportunityList = [];
    this.noOpportunityMsg = '';
    this._clientService.GetClientOpportunity(searchOpportunityByRequestModel).subscribe(result => {
      this.oneViewClientOpportunityViewModelWrapper = result.response;
      this.clientOpportunityList = this.oneViewClientOpportunityViewModelWrapper.oneViewClientOpportunityViewModelList.filter(opp => opp.opportunityId != null);

      if (this.clientOpportunityList.length > 0 &&
        !this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
        this.noOpportunityMsg = 'No opportunity services match the primary solution code; only related service offerings can be linked to Engage. Select any that are to be included along with the primary solution code assignment.';
      } else if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary) {
        this.noOpportunityMsg = 'There are no opportunities related to this solution code in oneView. An opportunity will automatically be created in oneView once the engagement is created.';
      }
      else {
        this.noOpportunityMsg = '';
      }
      let opportunityServiceRequestModel: OpportunityServiceRequestModelByEngagementId = new OpportunityServiceRequestModelByEngagementId();
     // let opportunityServiceList : OpportunityServiceList=new OpportunityServiceList();
      opportunityServiceRequestModel.clientCode = searchOpportunityByRequestModel.clientCode;
      opportunityServiceRequestModel.engagementId = this.engagement.engagementId;

      //Fetch all opportunityServiceIds      
      this.clientOpportunityList.forEach(oopElement => {
        oopElement.opportunityServiceList.forEach(s => {
            if (s.opportunityServiceId != null) {
                // Create a new opportunityServiceList object for each iteration
                let opportunityServiceList = {
                    opportunityServiceId: s.opportunityServiceId,
                    osaCount: s.osaCount != null ? s.osaCount : undefined
                };
     
                // Push the new opportunityServiceList object to opportunityServiceRequestModel
                opportunityServiceRequestModel.opportunityServiceList.push(opportunityServiceList);
            }
        });
    });
      if (opportunityServiceRequestModel.opportunityServiceList.length > 0) {
       // this._engService.CheckClientOpportunityServiceAssignedByEngagementID(opportunityServiceRequestModel).subscribe(result1 => {
          //let updatedServicelist = result1.opportunityServiceList;
          let updatedServicelist = opportunityServiceRequestModel.opportunityServiceList;
          console.log('updatedServicelist after CheckClientOpportunityServiceAssigned ==>', updatedServicelist);

          //Assigned updated value for isAssigned
          if (updatedServicelist != null) {
            this.clientOpportunityList.forEach(oopElement => {
              oopElement.opportunityServiceList.forEach(service => {
                updatedServicelist.forEach(item => {
                  if (item.opportunityServiceId == service.opportunityServiceId) {
                     service.isAssigned = item.osaCount > 0;
                      
                  }
                })
              })
            });
          }
          console.log('After update clientOpportunityList ==>', this.clientOpportunityList);
       // }, (error) => {
       //  this.loadingScreenService.stopLoading();
       //  this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
     //  });
      }

      console.log('Pop up clientOpportunityList ==>', this.clientOpportunityList);
      this.loadingScreenService.stopLoading();
    },
     (error) => {
       this.loadingScreenService.stopLoading();
       console.log('Error while getting response GetClientOpportunity func ', error);
     });
  }


  GetClientOpportunityByOpportunityId(searchOpportunityByOpportunityIdRequestModel: SearchOpportunityByOpportunityIdRequestModel) {
    this.loadingScreenService.startLoading();
    this.clientOpportunityList = [];
    this.noOpportunityMsg = '';
    this._clientService.GetClientOpportunityByOpportunityId(searchOpportunityByOpportunityIdRequestModel).subscribe(result => {
      this.oneViewClientOpportunityViewModelWrapper = result.response;
      if (this.oneViewClientOpportunityViewModelWrapper != null) {
        this.clientOpportunityList = this.oneViewClientOpportunityViewModelWrapper.oneViewClientOpportunityViewModelList.filter(opp => opp.opportunityId != null);
        if (this.clientOpportunityList.length > 0 &&
          !this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
          this.noOpportunityMsg = 'No opportunity services match the primary solution code; only related service offerings can be linked to Engage. Select any that are to be included along with the primary solution code assignment.';
        } else if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary) {
          this.noOpportunityMsg = 'There are no opportunities related to this solution code in oneView. An opportunity will automatically be created in oneView once the engagement is created.';
        }
        else {
          this.noOpportunityMsg = '';
        }
        let opportunityServiceRequestModel: OpportunityServiceRequestModel = new OpportunityServiceRequestModel();
        opportunityServiceRequestModel.clientCode = this.selectedclientCode;
        opportunityServiceRequestModel.engagementId = this.engagement.engagementId;
        //Fetch all opportunityServiceIds      
        this.clientOpportunityList.forEach(oopElement => {
          oopElement.opportunityServiceList.forEach(s => {
            if (s.opportunityServiceId != null) {
              opportunityServiceRequestModel.opportunityServiceList.push(s.opportunityServiceId)
            }
          })
        });

        if (opportunityServiceRequestModel.opportunityServiceList.length > 0) {
          this._engService.CheckClientOpportunityServiceAssigned(opportunityServiceRequestModel).subscribe(result1 => {
            let updatedServicelist = result1.opportunityServiceList;
            console.log('updatedServicelist after CheckClientOpportunityServiceAssigned ==>', updatedServicelist);
            //Assigned updated value for isAssigned
            if (updatedServicelist != null) {
              this.clientOpportunityList.forEach(oopElement => {
                oopElement.opportunityServiceList.forEach(service => {
                  updatedServicelist.forEach(item => {
                    if (item.opportunityServiceId == service.opportunityServiceId) {
                      service.isAssigned = item.isAssigned;
                      service.isContinuanceCarryFrwdAssignment = item.isContinuanceCarryFrwdAssignment;
                    }
                    if (this.arrEngagementAssignment.find(ea => ea.opportunityServiceId == service.opportunityServiceId) != undefined)
                      service.isAssigned = true;
                  })
                })
              });
            }
            console.log('After update clientOpportunityList ==>', this.clientOpportunityList);
          }, (error) => {
            this.loadingScreenService.stopLoading();
            this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
          });
        }

        console.log('Pop up clientOpportunityList ==>', this.clientOpportunityList);
        this.loadingScreenService.stopLoading();
      }
      else {
        console.log('Error while fatching opportunityService ==>', result.status);
        this.loadingScreenService.stopLoading();
      }
    },
      (error) => {
        this.loadingScreenService.stopLoading();
        console.log('Error while getting response GetClientOpportunity func ', error);
      });
  }

  GetClientOpportunityByEngagementId(searchOpportunityByEngagementIdRequestModel: SearchOpportunityByEngagementIdRequestModel) {
    this.loadingScreenService.startLoading();
    this.clientOpportunityList = [];
    this.noOpportunityMsg = '';
    this._clientService.GetClientOpportunityByEngagementId(searchOpportunityByEngagementIdRequestModel).subscribe(result => {
      this.oneViewClientOpportunityViewModelWrapper = result.response;
      if (this.oneViewClientOpportunityViewModelWrapper != null) {
        this.clientOpportunityList = this.oneViewClientOpportunityViewModelWrapper.oneViewClientOpportunityViewModelList.filter(opp => opp.opportunityId != null);

      if (this.clientOpportunityList.length > 0 &&
            !this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
            this.noOpportunityMsg = 'No opportunity services match the primary solution code; only related service offerings can be linked to Engage. Select any that are to be included along with the primary solution code assignment.';
          } else if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimaryNSecondary) {
            this.noOpportunityMsg = 'There are no opportunities related to this solution code in oneView. An opportunity will automatically be created in oneView once the engagement is created.';
          }
          else {
            this.noOpportunityMsg = '';
          }
          let opportunityServiceRequestModel: OpportunityServiceRequestModelByEngagementId=new OpportunityServiceRequestModelByEngagementId(); 
          opportunityServiceRequestModel.clientCode = this.selectedclientCode;
          opportunityServiceRequestModel.engagementId = this.engagement.engagementId;
         let opportunityServiceList : OpportunityServiceList = new OpportunityServiceList();    
          //Fetch all opportunityServiceIds      
          this.clientOpportunityList.forEach(oopElement => {
            oopElement.opportunityServiceList.forEach(s => {
                if (s.opportunityServiceId != null) {
                    // Create a new opportunityServiceList object for each iteration
                    let opportunityServiceList = {
                        opportunityServiceId: s.opportunityServiceId,
                        osaCount: s.osaCount != null ? s.osaCount : undefined
                    };
         
                    // Push the new opportunityServiceList object to opportunityServiceRequestModel
                    opportunityServiceRequestModel.opportunityServiceList.push(opportunityServiceList);
                }
            });
        });
          if (opportunityServiceRequestModel.opportunityServiceList.length > 0) {
            //this._engService.CheckClientOpportunityServiceAssignedByEngagementID(opportunityServiceRequestModel).subscribe(result1 => {
            // let updatedServicelist = result1.opportunityServiceList;
            let updatedServicelist = opportunityServiceRequestModel.opportunityServiceList;
              console.log('updatedServicelist after CheckClientOpportunityServiceAssignedByEngagementID ==>', updatedServicelist);
              //Assigned updated value for isAssigned
              if (updatedServicelist != null) {
                this.clientOpportunityList.forEach(oopElement => {
                  oopElement.opportunityServiceList.forEach(service => {
                    updatedServicelist.forEach(item => {
                      if (item.opportunityServiceId == service.opportunityServiceId) {
                        // service.isAssigned = item.isAssigned;
                        service.isAssigned = item.osaCount > 0;
                        service.isContinuanceCarryFrwdAssignment = false;
                      }
                    })
                  })
                });
              }
              console.log('After update clientOpportunityList ==>', this.clientOpportunityList);
            // }, (error) => {
            //   this.loadingScreenService.stopLoading();
            //   this.alertServiceFun('Something went wrong! Please contact system administrator.', 'danger');
            // });
          
        }

        console.log('Pop up clientOpportunityList ==>', this.clientOpportunityList);
        this.loadingScreenService.stopLoading();
      }
      else {
        console.log('Error while fatching opportunityService ==>', result.status);
        this.PopulateDefaultAssignmentModel();
        this.loadingScreenService.stopLoading();
      }
    },
      (error) => {
        this.PopulateDefaultAssignmentModel();
        this.loadingScreenService.stopLoading();
        console.log('Error while getting response GetClientOpportunity func ', error);
      });
  }

  OpportunityServiceClick(opportunityId: string, opportunityServiceId: string, isChecked: boolean, row: number, col: number, showPopup: boolean) {

    //Interchangeable solutioncode confirmation popup
    if (this.clientOpportunityList.find(i => i.opportunityId == opportunityId).
      opportunityServiceList.find(s => s.opportunityServiceId == opportunityServiceId).interchangeableSolutionCodeFlag
      && showPopup && !isChecked) {
      this.opportunityId = opportunityId;
      this.opportunityServiceId = opportunityServiceId;
      this.setDialogContent("information", EngagementDialogTitles.InterChangeableSolutionCodes);
    }

    //check/uncheck selected service
    this.clientOpportunityList.find(o => o.opportunityId == opportunityId).
      opportunityServiceList.find(s => s.opportunityServiceId == opportunityServiceId).isChecked = !isChecked;

    //unselect isOpportunityServicelisted check box if anyone opportunityService selected
    if (this.clientOpportunityList.find(o => o.opportunityId == opportunityId).
      opportunityServiceList.find(s => s.opportunityServiceId == opportunityServiceId).isChecked) {
      this.oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted = false;
    }
    if (this.engagement.opportunityId == '' || this.engagement.opportunityId == undefined) {
      this.clientOpportunityList.filter(o => o.opportunityId == opportunityId).forEach(oopElement => {
        oopElement.opportunityServiceList.forEach(os => {
          if (!os.isPrimary && this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
            if (this.secondarySolutionCode.find(item => item.solutionCode == os.solutionCode) != undefined) {
              var primaryServiceSelectedCount = oopElement.opportunityServiceList.filter(x => (x.isPrimary || x.interchangeableSolutionCodeFlag) && x.isChecked).length;
              if (os.isChecked) {
                os.isChecked = primaryServiceSelectedCount > 0 ? true : false;
              }
              ///This is to enable/disable check box for selected service
              os.isEnabled = (primaryServiceSelectedCount > 0 || os.interchangeableSolutionCodeFlag) ? true : false;
            }
          }

          var isSelectedServicePrimary = oopElement.opportunityServiceList.filter(x => (x.isPrimary || x.interchangeableSolutionCodeFlag) && x.opportunityServiceId == opportunityServiceId).length;
          if (isSelectedServicePrimary > 0 && os.opportunityServiceId != opportunityServiceId) {
            os.isChecked = false;
          }
        }
        )
      });

      //uncheck unselected service
      let exceptSelectedclientOpportunityList = this.clientOpportunityList.filter(e => e.opportunityId != opportunityId);
      exceptSelectedclientOpportunityList.forEach(oopElement => {
        oopElement.opportunityServiceList.forEach(e => {
          e.isChecked = false;
          if ((!e.isPrimary && !e.interchangeableSolutionCodeFlag) && this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
            e.isEnabled = false;
          }
        }
        )
      });
    }
    console.log('clientOpportunityList after check click==>', this.clientOpportunityList);
  }

  OpportunityServiceNotListed(isOpportunityServicelisted: boolean) {
    this.oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted = !isOpportunityServicelisted;
    //unselect if any opportunityService selected
    this.clientOpportunityList.forEach(oopElement => {
      oopElement.opportunityServiceList.forEach(e => {
        if (e.isChecked) {
          e.isChecked = false;
        }
        if (this.engagement.opportunityId == undefined) {
          if (!e.isPrimary && this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {
            e.isEnabled = false;
          }
        }
      }
      )
    });
  }

  CancelOpportunityPopUp() {
    this.isContinuanceEngExistsPopupOpen = false;
    this.originalParentSolutionCodeId = 0;
    if (!this.isMemberFirm) {
      if ((this.engagement.opportunityId == '' || this.engagement.opportunityId == undefined) &&
        this.arrEngagementAssignment.length == 0) {
        this.engagement.opportunityId = undefined;
        //this.engagement.opportunityOptOut = true;
        this.clientOpportunityList = [];
        this.arrEngagementAssignment = [];
        this.validateOpportunityService = false;
        this.noOpportunityMsg = '';
        $('#hdnKey' + this.SolutionCodeMappedValue + 0).val('')
        $('#txtValue' + this.SolutionCodeMappedValue + 0).val('');
        this.LoadGoverningAgreementsFromCLM();
        this.restartAssignment();
        this.isVisibledefaultAssignmentDiv = false;
      } else {
        $("#modal-Opportunity").modal('hide');
      }
    }
    else {
      this.engagement.opportunityId = undefined;
      this.validateOpportunityService = false;
      this.noOpportunityMsg = '';
      $("#modal-Opportunity").modal('hide');

      //For RCGT: Don't open assignment box in edit mode for continuance engagement.
      if (this.isContinuanceEngagement == false) {
        this.PopulateDefaultAssignmentModel();
      }
    }
  }

  CheckOpportunityValidation() {
    // if (this.engagement.opportunityId == '' || this.engagement.opportunityId == undefined) {
    if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted) {
      this.validateOpportunityService = true;
      let selecteOpportunity = this.clientOpportunityList.find(o => o.opportunityServiceList.find(s => s.isChecked))
      console.log('selecteOpportunity ', selecteOpportunity);

      if (selecteOpportunity == undefined) {
        this.oneViewClientOpportunityViewModelWrapper.isOpportunitySelected = false;
        if (this.isPageLoadOnInitiatingContinuanceEngagement) {
          //Dont show error message in popup when continunce engagement is initiated and we show popup in edit engagement screen for first time.
          this.validateOpportunityService = false;
          return true;
        }
        //this.engagement.opportunityOptOut = (selecteOpportunity.opportunityId != undefined)? false: true;         
      } else {
        this.validateOpportunityService = false;
        this.engagement.opportunityId = selecteOpportunity.opportunityId;
        // this.engagement.opportunityOptOut = (selecteOpportunity.opportunityId != undefined)? false: true;                
        return true;
      }
    } else {
      this.validateOpportunityService = false;
      return true;
    }
    // } else {
    //   return true;
    // }
  }

  OkOpportunityPopUp() {
    this.isContinuanceEngExistsPopupOpen = false;
    if (this.CheckOpportunityValidation()) {
      $("#modal-Opportunity").modal('hide');
      if (this.engagement.opportunityId != '' && this.engagement.opportunityId != undefined) {
        this.BuildAssignmentWithSelecetdOpportunityService();
      }
      else {
        // if (this.arrEngagementAssignment.length > 0)
        // {
        // this.objectInitialization();         
        if (this.isPageLoadOnInitiatingContinuanceEngagement == false) {
          this.PopulateDefaultAssignmentModel();
        }
        // setTimeout(() => {
        //   this.setPrePopullatedControls();
        // }, 100);
        // }       
      }
    }
  }


  BuildAssignmentWithSelecetdOpportunityService() {
    //this.arrEngagementAssignment = [];
    if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServicelisted) {
      this.isVisibledefaultAssignmentDiv = false;
      let filiterSelectedOpportunityServices: OpportunityServiceModel[] = [];
      this.clientOpportunityList.forEach(oopElement => {
        oopElement.opportunityServiceList.forEach(service => {
          if (service.isChecked) {
            filiterSelectedOpportunityServices.push(service);
          }
        })
      });

      console.log('filiterSelectedOpportunityServices ==>', filiterSelectedOpportunityServices);
      let filiterSelectedOpportunityServicesLength: number = filiterSelectedOpportunityServices.length;

      if (filiterSelectedOpportunityServicesLength > 0) {
        if (!this.oneViewClientOpportunityViewModelWrapper.isOpportunityServiceMatchWithPrimary) {

          let isPrimaryAssignmentExists = this.arrEngagementAssignment.find(item => item.isPrimaryAssignment);
          if (isPrimaryAssignmentExists == undefined) {
            let defaultService = this.buildDefaultPrimaryOportunityServices();
            this.LoadAssignmentWithOpportunityService(defaultService, false, '', '', '');
          }
        }

        for (let index = 0; index < filiterSelectedOpportunityServicesLength; index++) {
          let isLastElement: boolean = false;
          if (index == filiterSelectedOpportunityServicesLength - 1) {
            isLastElement = true;
          }
          let expectedStartDate = this.clientOpportunityList.find(o => o.opportunityServiceList.find(s => s.opportunityServiceId == filiterSelectedOpportunityServices[index].opportunityServiceId)).expectedStartDate;
          let estCloseDate = this.clientOpportunityList.find(o => o.opportunityServiceList.find(s => s.opportunityServiceId == filiterSelectedOpportunityServices[index].opportunityServiceId)).estCloseDate;
          let gtOfficeLocation = this.clientOpportunityList.find(o => o.opportunityServiceList.find(s => s.opportunityServiceId == filiterSelectedOpportunityServices[index].opportunityServiceId)).gtOfficeLocation;
          this.LoadAssignmentWithOpportunityService(filiterSelectedOpportunityServices[index], isLastElement, expectedStartDate, estCloseDate, gtOfficeLocation);
        }
        // Hide Default Assignment mode and Show lin 'Create new Assignment'
        this.hideDefaultAssignmentBox();
      }
    } else {
      // this.isVisibledefaultAssignmentDiv = true;
      // this.isVisibleNewAssignmentDIV = false;
      this.objectInitialization();
      this.setPrePopullatedControls();
      this.PopulateDefaultAssignmentModel();
    }
  }

  buildDefaultPrimaryOportunityServices() {
    let defaultService: OpportunityServiceModel = new OpportunityServiceModel();
    defaultService.opportunityServiceId = null;
    defaultService.solutionCode = this.primarySelectedSolutionCode.solutionCode;
    defaultService.solutionDescription = this.primarySelectedSolutionCode.solutionCodeDescription;
    defaultService.PriceAmt = 0;
    defaultService.PriceAmtFormattedValue = '0,00';
    defaultService.isPrimary = true;
    defaultService.isChecked = false;
    defaultService.isAssigned = false;
    defaultService.isPartOfSecondary = true;
    defaultService.isEnabled = true;
    return defaultService;
  }

  LoadAssignmentWithOpportunityService(oopService: OpportunityServiceModel, isLastElement: boolean, expectedStartDate: string, estCloseDate: string, gtOfficeLocation) {
    this.defaultEngAssignment = null;
    const defaultSelectedengagementType = new KeyValue();
    defaultSelectedengagementType.key = '';
    defaultSelectedengagementType.value = '';
    let selectedsolutionCode = this.secondarySolutionCode.find(s => s.solutionCode == (oopService.interchangeableSolutionCodeFlag ?
      this.primarySelectedSolutionCode.solutionCode : oopService.solutionCode));

    let evaluatedIsPrimary: boolean = false;
    if (this.arrEngagementAssignment != undefined) {
      if (this.arrEngagementAssignment.length > 0) {
        let isPrimaryAssignmentExists = this.arrEngagementAssignment.find(item => item.isPrimaryAssignment);
        if (isPrimaryAssignmentExists != undefined) {
          evaluatedIsPrimary = false;
        }
        else {
          evaluatedIsPrimary = oopService.interchangeableSolutionCodeFlag ? true : oopService.isPrimary;
        }
      }
      else {
        evaluatedIsPrimary = oopService.interchangeableSolutionCodeFlag ? true : oopService.isPrimary;
      }
    }
    else {
      evaluatedIsPrimary = oopService.interchangeableSolutionCodeFlag ? true : oopService.isPrimary;
    }

    const clientLocation = this.ClientLocations.find(element => element.typeValue === gtOfficeLocation);
    this.defaultEngAssignment = {
      solutionCodeListData: this.secondarySolutionCode.filter(solutionCode => solutionCode.status == 'A'),
      solutionCode: oopService.interchangeableSolutionCodeFlag ? this.primarySelectedSolutionCode.solutionCode : oopService.solutionCode,
      selectedsolutionCode: selectedsolutionCode,
      solutionCodeId: selectedsolutionCode.childSolutionCodeId,
      solutionCodeDescription: selectedsolutionCode.solutionCodeDescription,
      iscontingentData: this.contingentkeyValueList,
      engagementTypeData: this.engtypekeyValueList,
      selectedengagementType: defaultSelectedengagementType,
      assignmentCodeId: 0,
      engagementId: 0,
      assignmentName: (evaluatedIsPrimary) ? $('.engagement-name').val().trim() : this.blankLineAssignmentName,
      realization: null,
      netFees: oopService.PriceAmt,
      createdBy: '',
      createdDate: new Date(),
      lastModifiedBy: '',
      lastModifiedDate: new Date(),
      assignmentAdministrator: '',
      adminEmailId: '',
      adminContactNo: '',
      cmsEPPMDName: $('#txtPeoplePicker_EP_0').val(),
      cmsEPPMDEmailId: $('#UserHiddenProps_EP_0 #hdnUserEmail').val(),
      engagementTypeId: '0',
      isContingent: null,
      isPrimaryAssignment: evaluatedIsPrimary,
      netFeesInCurrencyFormat: oopService.PriceAmtFormattedValue,
      collaborationSiteId: 0,
      engagementStartDate: (expectedStartDate != undefined || expectedStartDate != '') ? expectedStartDate : null,
      engagementEndDate: (estCloseDate != undefined || estCloseDate != '') ? estCloseDate : null,
      officeLocationCodeId: (clientLocation != undefined) ? clientLocation.id : 0,
      officeLocationCodeName: (clientLocation != undefined) ? clientLocation.typeValue : '',
      estimatedGrossMargin: null,
      clientBillingContactName: '',
      clientBillingContactTitleId: 0,
      clientBillingContactEmail: '',
      collaborationSiteName: '',
      estimatedGrossMarginInCurrencyFormat: '',
      clientBillingContactTitle: '',
      assignmentCode: null,
      clientCode: this.localClientCode.length > 0 ? this.localClientCode : null,
      assignmentCodeStatus: '',
      isContinuanceCarryFrwdAssignment: false,
      solutionCodeOnSigature: false,
      priorYearAssignmentName: '',
      isGrossMarginRequire: false,
      eqcrName: '',
      eqcrEmailId: '',
      isEditEQCR: false,
      newEQCRName: '',
      newEQCREmailId: '',
      isvalidEQCR: true,
      isValidEQCRBusinessRule: true,
      isEQCRExists: false,
      isAssignmentFinanceDataEditable: 'null',
      isEngagementStartDateRequired: false,
      isEngagementEndDateRequired: false,
      opportunityServiceId: oopService.opportunityServiceId,
      status: 'Add'
      //opportunityServiceOptOut: (oopService.opportunityServiceId != null || oopService.opportunityServiceId !=undefined)? false: true
    };

    if (this.engagement.clientId > 0) {
      if (this.defaultClientBillingContact !== undefined) {
        if ((this.defaultClientBillingContact.clientBillingEmail !== undefined ||
          this.defaultClientBillingContact.clientBillingEmail !== null) ||
          (this.defaultClientBillingContact.clientBillingName !== undefined ||
            this.defaultClientBillingContact.clientBillingName !== null)) {
          this.defaultEngAssignment.clientBillingContactEmail = this.defaultClientBillingContact.clientBillingEmail;
          this.defaultEngAssignment.clientBillingContactName = this.defaultClientBillingContact.clientBillingName;

        }
        if (this.defaultClientBillingContact.clientBillingTitleId > 0) {
          this.defaultEngAssignment.clientBillingContactTitleId = this.defaultClientBillingContact.clientBillingTitleId;
        }
      }
    }

    this.arrEngagementAssignment.push(JSON.parse(JSON.stringify(this.defaultEngAssignment)));
    if (isLastElement) {
      this.arrEngagementAssignment = this.arrEngagementAssignment.sort((a, b) => Number(b.isPrimaryAssignment) - Number(a.isPrimaryAssignment));
      console.log('arrEngagementAssignment ==>', this.arrEngagementAssignment);
    }
  }

  assignUserDetailsForCMSEP(event) {
    event = JSON.parse(event);
    if (event.status == true) {
      if (event.roleType == 'EP') {
        this.userDetails.CMSEP = event.data;
      }
      this.canEnableSaveAssignmentBtnOnValidcmsPPMDCheck = true;
    } else {
      this.isvalidcmsEPPMDName = true;
      this.canEnableSaveAssignmentBtnOnValidcmsPPMDCheck = false;
    }

    this.enableDisableSaveAssignmentButton();
    console.log('CMSEP details :', this.userDetails.CMSEP);
  }

  assignUserDetailsForAA(event) {
    event = JSON.parse(event);
    if (event.status == true) {
      if (event.roleType == 'AA') {
        this.userDetails.AA = event.data;
      }
      this.canEnableSaveAssignmentBtnOnValidAACheck = true;
    } else {
      this.isvalidAassignmentAdministrator = true;
      this.canEnableSaveAssignmentBtnOnValidAACheck = false;
    }

    this.enableDisableSaveAssignmentButton();
    console.log('AA details :', this.userDetails.AA);
  }

  enableDisableSaveAssignmentButton() {
    if (this.canEnableSaveAssignmentBtnOnValidcmsPPMDCheck == true &&
      this.canEnableSaveAssignmentBtnOnValidAACheck == true) {
      $('#btnSaveAssignment').attr('disabled', false);
    } else {
      $('#btnSaveAssignment').attr('disabled', true);
    }
  }

  // saveAssignmentbtnhandler(event) {
  //   if (event == true) {
  //     $('#btnSaveAssignment').attr('disabled', false);
  //   } else {
  //     console.log(event);
  //     $('#btnSaveAssignment').attr('disabled', true);
  //   }
  // }

  CloneConveneProjectSetup(text: string) {
    if (text == 'true') {
      this.baseConeveneProjectInitiationModel.isCloneProject = true;
      this.getConveneProjectDetailsByEngagementId();
    }
    else {
      this.baseConeveneProjectInitiationModel.isCloneProject = false;
    }

  }

  StatusConveneProjectSetup(status: string) {

    this.baseConeveneProjectInitiationModel.statusOfTheProject = status;

  }

  UsersConveneProjectSetup(text: string) {
    if (text == 'true') {
      this.baseConeveneProjectInitiationModel.isUsers = true;
    }
    else {
      this.baseConeveneProjectInitiationModel.isUsers = false;
    }

  }

  DueDatesConveneProjectSetup(text: string) {

    this.baseConeveneProjectInitiationModel.dueDates = text;
  }

  getConveneProjectDetailsByEngagementId() {
    let requestModel: ConveneGetProjetcsById = new ConveneGetProjetcsById();

    requestModel.engagementId = this.engagement.priorYearEngagementId;
    requestModel.userEmailId = this.UserObject.userName;

    this._engService.GetConveneProjectsByEngagementId(requestModel).subscribe(res => {
      if (res.status) {
        this.conveneProjectDetailsResponse = res.data;
        if (this.conveneProjectDetailsResponse != null) {
          this.baseConeveneProjectInitiationModel.cloneProjectid = this.conveneProjectDetailsResponse[0].projectId;
        }
      }


    });


  }

  AddUpdateConveneProjectDetails() {
    var conveneInsertModel = new ConveneProjectInsertModel();
    conveneInsertModel.engagementId = this.engagement.engagementId;
    conveneInsertModel.continuanceEngagementId = this.engagement.priorYearEngagementId;
    conveneInsertModel.isCloneProject = this.baseConeveneProjectInitiationModel.isCloneProject;
    conveneInsertModel.cloneProjectId = this.baseConeveneProjectInitiationModel.cloneProjectid == null ? '' : this.baseConeveneProjectInitiationModel.cloneProjectid;
    conveneInsertModel.status = this.baseConeveneProjectInitiationModel.statusOfTheProject;
    conveneInsertModel.dueDates = this.baseConeveneProjectInitiationModel.dueDates;
    conveneInsertModel.conveneProjectId = '';
    conveneInsertModel.users = this.baseConeveneProjectInitiationModel.isUsers;
    conveneInsertModel.userEmail = this.UserObject.userName;

    this._engService.AddUpdateConveneProjectDetails(conveneInsertModel).subscribe(result => {
      if (result == 'Success') {
        this.logService.log("Conevene project details stored successfully for engagementId : " + this.engagement.engagementId);
      }
      else {
        this.logService.log("Conevene project details storing failed for engagementId : " + this.engagement.engagementId);
      }
    }, error => {
      this.loadingScreenService.stopLoading();
      this.logService.log("Error occured while storing Conevene project details  for engagementId : " + this.engagement.engagementId);
      console.log("Error occured while storing Conevene project details  for engagementId : " + this.engagement.engagementId);

    });
  }
  GetConveneProjectDetails() {
    this._engService.GetConveneProjectDetails(this.currentEngagementId).subscribe(result => {
      if (result != null && result != undefined) {
        this.baseConeveneProjectInitiationModel.cloneProjectid = result.cloneProjectId == "" ? null : result.cloneProjectId;
        this.baseConeveneProjectInitiationModel.dueDates = result.dueDates;
        this.baseConeveneProjectInitiationModel.isUsers = result.users;
        this.baseConeveneProjectInitiationModel.isCloneProject = result.isCloneProject;
        this.baseConeveneProjectInitiationModel.statusOfTheProject = result.status;
        this.logService.log("Conevene project details fetched successfully for engagementId : " + this.engagement.engagementId);
      }
      else {
        this.baseConeveneProjectInitiationModel.dueDates = "Rollforward";
        this.baseConeveneProjectInitiationModel.isUsers = false;
        this.baseConeveneProjectInitiationModel.isCloneProject = true;
        this.baseConeveneProjectInitiationModel.statusOfTheProject = "Draft";
        this.baseConeveneProjectInitiationModel.cloneProjectid = null;
        this.logService.log("Conevene project details fetching failed for engagementId : " + this.engagement.engagementId);
      }
    }, error => {
      this.loadingScreenService.stopLoading();
      this.logService.log("Error occured while fetching Conevene project details  for engagementId : " + this.engagement.engagementId);
      console.log("Error occured while fetching Conevene project details  for engagementId : " + this.engagement.engagementId);

    });
  }
  async DisplayContinuancePreRequestPopUp() {
    //Load review notes component
    this.loadingScreenService.startLoading();
    this.isPreRequestPopupConfirmed = false;

    setTimeout(() => {
      const componentFactorya = this.cfr.resolveComponentFactory(EngagementPrerequestPopupComponent);
      this.engagePreRequestPopupComponentRef = this.vrf.createComponent(componentFactorya);
      this.engagePreRequestPopupComponentRef.instance.isPreRequest = this.getBoolean(this.selectedPreRequestForContinuanceValue);
      this.engagePreRequestPopupComponentRef.instance.showEngagementDuedateExtensionComponentModal();
      this.loadingScreenService.stopLoading();
      this.engagePreRequestPopupComponentRef.instance.prequestPopupButtonClick.subscribe(result => {
        switch (result) {
          case 'ClosePopup':
            this.isPreRequestPopupConfirmed = false;
            this.selectedPreRequestForContinuanceValue = 'false';
            this.onPreRequestFlagChange();
            break;
          case 'NonPrequestCancel':
            this.isPreRequestPopupConfirmed = false;
            break;
          case 'NonPrequestOk':
            this.isPreRequestPopupConfirmed = true;
            this.engagementNextClick();
            break;
          case 'PrequestCancel':
            this.isPreRequestPopupConfirmed = false;
            this.selectedPreRequestForContinuanceValue = 'false';
            this.onPreRequestFlagChange();
            break;
          case 'PrequestOk':
            this.isPreRequestPopupConfirmed = true;
            this.onPreRequestFlagChange();
            this.RevertThePriorYearAssignments();
            break;
        }
        this.loadingScreenService.stopLoading();
        this.vrf.clear();
      });

    }, 1000);

  }

  OnPreRequestFlagSelected() {
    if (this.selectedPreRequestForContinuanceValue == 'false') {
      this.isPreRequestPopupConfirmed = false;
      this.onPreRequestFlagChange();
    }
    if (this.selectedPreRequestForContinuanceValue == 'true') {
      this.isPreRequestPopupConfirmed = false;
      this.ValidatePreRequestPopupConfirmation();

    }

  }

  ValidatePreRequestPopupConfirmation() {
    if (!this.isPreRequestPopupConfirmed && this.isContinuanceEngagement &&
      this.engagement.preRequestDuringContinuance && !this.isCMSPreRequestSent && this.engagement.status == EngagementStatus.EngNotStarted) {
      this.DisplayContinuancePreRequestPopUp();
    }
    else {
      this.isPreRequestPopupConfirmed = true;
      this.onPreRequestFlagChange();
    }
    return this.isPreRequestPopupConfirmed;
  }

  RevertThePriorYearAssignments() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    this.arrEngagementAssignment.filter(model => model.isContinuanceCarryFrwdAssignment == true).forEach((item, index) => {
      item.solutionCodeId = this.UnmodifiedEngagementAssignmentData[index].solutionCodeId;
      // refer bux #603886 -- secondary assignment we set with default parentSolutioncode
      item.selectedsolutionCode =
        item.solutionCodeListData.find(element =>
          element.childSolutionCodeId == item.solutionCodeId);
      if (
        this.UnmodifiedEngagementAssignmentData[index].assignmentCodeStatus === this.AssignmentCodePending) {
        item.priorYearAssignmentName = this.UnmodifiedEngagementAssignmentData[index].assignmentName;
        item.assignmentName = '';
      } else {
        item.priorYearAssignmentName = '';
        item.assignmentName = this.UnmodifiedEngagementAssignmentData[index].assignmentName;
      }
      item.isContingent = this.UnmodifiedEngagementAssignmentData[index].isContingent;
      item.cmsEPPMDName = this.UnmodifiedEngagementAssignmentData[index].cmsEPPMDName;
      item.cmsEPPMDEmailId = this.UnmodifiedEngagementAssignmentData[index].cmsEPPMDEmailId;
      item.assignmentAdministrator = this.UnmodifiedEngagementAssignmentData[index].assignmentAdministrator;
      item.adminEmailId = this.UnmodifiedEngagementAssignmentData[index].adminEmailId;
      item.adminContactNo = this.UnmodifiedEngagementAssignmentData[index].adminContactNo;
      item.solutionCode = this.UnmodifiedEngagementAssignmentData[index].solutionCode;
      item.solutionCodeDescription = this.UnmodifiedEngagementAssignmentData[index].solutionCodeDescription;
      item.selectedengagementType =
        this.engtypekeyValueList.find(element => element.key == this.UnmodifiedEngagementAssignmentData[index].engagementTypeId.toString());
      item.assignmentCodeId = this.UnmodifiedEngagementAssignmentData[index].assignmentCodeId;
      item.realization = this.UnmodifiedEngagementAssignmentData[index].realization;
      item.isPrimaryAssignment = this.UnmodifiedEngagementAssignmentData[index].isPrimaryAssignment;
      item.netFeesInCurrencyFormat = formatter.format(Number(this.UnmodifiedEngagementAssignmentData[index].netFees));
      item.netFees = this.UnmodifiedEngagementAssignmentData[index].netFees;
      item.engagementTypeId = this.UnmodifiedEngagementAssignmentData[index].engagementTypeId;
      item.engagementTypeData = this.engtypekeyValueList;
      item.iscontingentData = this.contingentkeyValueList;
      item.assignmentCodeStatus = this.UnmodifiedEngagementAssignmentData[index].assignmentCodeStatus;
      item.engagementStartDate = this.UnmodifiedEngagementAssignmentData[index].engagementStartDate;
      item.engagementEndDate = this.UnmodifiedEngagementAssignmentData[index].engagementEndDate;
      item.estimatedGrossMarginInCurrencyFormat =
        (this.UnmodifiedEngagementAssignmentData[index].estimatedGrossMargin === null || this.UnmodifiedEngagementAssignmentData[index].estimatedGrossMargin.toString().length === 0)
          ? ''
          : formatter.format(Number(this.UnmodifiedEngagementAssignmentData[index].estimatedGrossMargin));
      item.estimatedGrossMargin = this.UnmodifiedEngagementAssignmentData[index].estimatedGrossMargin;
      item.officeLocationCodeId = this.UnmodifiedEngagementAssignmentData[index].officeLocationCodeId;
      if (this.UnmodifiedEngagementAssignmentData[index].officeLocationCodeId !== 0) {
        const clientLocation = this.ClientLocations.find(element =>
          element.id === this.UnmodifiedEngagementAssignmentData[index].officeLocationCodeId);
        if (clientLocation) {
          item.officeLocationCodeName = clientLocation.typeValue;
        }
      }
      item.clientBillingContactName = this.UnmodifiedEngagementAssignmentData[index].clientBillingContactName;
      item.clientBillingContactTitleId = this.UnmodifiedEngagementAssignmentData[index].clientBillingContactTitleId;
      if (this.UnmodifiedEngagementAssignmentData[index].clientBillingContactTitleId !== 0) {
        const contactTypeLookup = this.ClientContactTypes.find(element =>
          element.id === this.UnmodifiedEngagementAssignmentData[index].clientBillingContactTitleId);
        if (contactTypeLookup) {
          item.clientBillingContactTitle = contactTypeLookup.typeValue;
        }
      }
      item.clientBillingContactEmail = this.UnmodifiedEngagementAssignmentData[index].clientBillingContactEmail;
      item.isContinuanceCarryFrwdAssignment = this.UnmodifiedEngagementAssignmentData[index].isContinuanceCarryFrwdAssignment;
      item.clientCode = this.UnmodifiedEngagementAssignmentData[index].clientCode;
      item.assignmentCode = this.UnmodifiedEngagementAssignmentData[index].assignmentCode;
      item.eqcrEmailId = this.UnmodifiedEngagementAssignmentData[index].eqcrEmailId;
      item.eqcrName = this.UnmodifiedEngagementAssignmentData[index].eqcrName;
      item.budgetApproved = this.UnmodifiedEngagementAssignmentData[index].budgetApproved;
      if (item.budgetApproved != undefined && item.budgetApproved != null) {
        item.isAssignmentFinanceDataEditable = String(item.budgetApproved);
      }
      item.isEngagementStartDateRequired = this.UnmodifiedEngagementAssignmentData[index].isEngagementStartDateRequired;
      item.isEngagementEndDateRequired = this.UnmodifiedEngagementAssignmentData[index].isEngagementEndDateRequired;

      if (item.selectedengagementType == undefined) {
        const defaultSelectedengagementType = new KeyValue();
        defaultSelectedengagementType.key = '';
        defaultSelectedengagementType.value = '';
        item.selectedengagementType = defaultSelectedengagementType;
      }
      item.opportunityServiceId = this.UnmodifiedEngagementAssignmentData[index].opportunityServiceId;

      this.EditAssignmentTeamMembers(index);


    });
  }

  OpenOpportunityPopupOnInitiatingContinuanceEngagement() {
    if (this.isRedirectedFromReacceptance == 'true' && this.isContinuanceEngagement) {
      this.openAssignmentModelWithOneViewOpportunity(true);
    }
  }

  setUnsavedChangesForIwantTo() {
    if (!this.unsavedAlertfeature_IsSaveChangesOrNextBtnClicked) {
      if (this.unsavedAlertfeature_AnyFormFieldChanged) {
        const dialogData = {
          fromComponent: 'engagement',
          isUnsavedChangesExist: true
        };
        const dialogJSON = JSON.stringify(dialogData);
        this.dataService.setUnsavedChangesModel(dialogJSON);
      }
    }
  }
  ResetIwantToUnsavedFlag() {
    const dialogData = {
      fromComponent: '',
      isUnsavedChangesExist: false
    };
    const dialogJSON = JSON.stringify(dialogData);
    this.dataService.setUnsavedChangesModel(dialogJSON);
    this.dataService.setClientIdForPrePopulate(-1);
    this.dataService.setEngagementIdForPrePopulate(-1);
  }

  GetClientDetailsById(clientId: number) {
    this._clientService.GetClientDetailsById(clientId).subscribe(result => {
      this.client = result;
      console.log('recieved client info =>', result);
      this.engagement.clientName = result.clientName;
      this.engagement.clientId = result.clientId;
      $('#txtValue' + this.MappedValue + 0).val(this.engagement.clientName);
      $('#hdnKey' + this.MappedValue + 0).val(this.engagement.clientId);
      $('#hdnKeyCode' + this.MappedValue + 0).val(result.clientCode);
      $('#hdnclientOneViewAccountId' + this.MappedValue + 0).val(result.oneViewAccountId);
    },
      error => {

      });
  }

  setIsMemberFirm() {
    this.isMemberFirm = this.UserObject.isMemberFirmFeatureEnabled;
  }

  GetMemberFirmEngagementPrefillData() {
    let requestModel = new MemberFirmEngagementPrefillDataRequestModel();
    requestModel.memberFirmADGroup = this.UserObject.memberFirmADGroup;

    return this._engService.GetMemberFirmEngagementPrefillData(requestModel)
      .subscribe(
        result => {
          this.memberFirmEngagementPrefillData = result;
          this.memberFirmService.getMemberFirmDetailsById(this.memberFirmEngagementPrefillData?.memberFirmMappingId).subscribe(memberFirmData => {
            this.memberFirmData = memberFirmData
          })
          this.prefillMemberFirmEngagementDetails();
        });
  }

  prefillMemberFirmEngagementDetails() {
    //Set engagement model data from memberfirm prefill data model.
    this.engagement.clientCode = this.memberFirmEngagementPrefillData.clientCode;
    this.engagement.clientId = this.memberFirmEngagementPrefillData.clientId;
    this.engagement.clientName = this.memberFirmEngagementPrefillData.clientName + " (" + this.memberFirmEngagementPrefillData.clientCode + ")";
    this.engagement.parentSolutionCodeId = this.memberFirmEngagementPrefillData.solutionCodeId;
    this.engagement.solutionCode = this.memberFirmEngagementPrefillData.solutionCode + " - " + this.memberFirmEngagementPrefillData.solutionCodeDescription;
    this.engagement.engagementPartner = this.memberFirmEngagementPrefillData.epEmail;
    this.engagement.engagementPartnerName = this.memberFirmEngagementPrefillData.epName;
    this.engagement.isPeriodEndDateRequired = this.memberFirmEngagementPrefillData.isPeriodEndDateRequired;
    this.engagement.memberFirmMappingId = this.memberFirmEngagementPrefillData.memberFirmMappingId;
    this.engagement.agreementType = this.memberFirmEngagementPrefillData.templatePackageId;
    //Prepopulate values in UI
    this.dataService.setClientIdForPrePopulate(this.engagement.clientId);
    this.originalParentSolutionCodeId = 0;
    //Disable the fields in UI.
    this.disableMemberFirmEngagementData();
    this.SetBasicEngDataforEditMode();
    $("input[type='radio'][name='DocumentTypes'][ng-reflect-value='Create new engagement letter o']").prop('checked', true);
    this.OnSolutionCodeChange();
    this.isPeriodRequire = this.engagement.isPeriodEndDateRequired;
  }

  disableMemberFirmEngagementData() {
    $('#txtValue' + this.MappedValue + 0).attr('disabled', true);
    $('#txtValue' + this.SolutionCodeMappedValue + 0).attr('disabled', true);
    $('.autocomplete-dropdown-btn').attr('disabled', true);
    $('#btnClientSearch').attr('disabled', true);
    $('#txtPeoplePicker_EP_0').attr('disabled', true);
  }

  //assignment data
  prefillMemberFirmEngagementAssignmentDetails() {
    const defaultSelectedengagementType = new KeyValue();
    defaultSelectedengagementType.key = '';
    defaultSelectedengagementType.value = '';

    let selectedsolutionCode = this.secondarySolutionCode.find(s => s.childSolutionCodeId == this.engagement.parentSolutionCodeId);

    this.defaultEngAssignment = {
      solutionCodeListData: this.secondarySolutionCode.filter(solutionCode => solutionCode.status == 'A'),
      iscontingentData: this.contingentkeyValueList,
      engagementTypeData: this.engtypekeyValueList,
      selectedsolutionCode: selectedsolutionCode,
      selectedengagementType: defaultSelectedengagementType,
      assignmentCodeId: 0,
      engagementId: 0,
      solutionCodeId: selectedsolutionCode.childSolutionCodeId,
      assignmentName: '',
      realization: this.memberFirmEngagementPrefillData.realization,
      netFees: this.memberFirmEngagementPrefillData.netFees,
      createdBy: '',
      createdDate: new Date(),
      lastModifiedBy: '',
      lastModifiedDate: new Date(),
      assignmentAdministrator: this.memberFirmEngagementPrefillData.aaName,
      adminEmailId: this.memberFirmEngagementPrefillData.aaEmail,
      adminContactNo: '',
      cmsEPPMDName: '',
      cmsEPPMDEmailId: '',
      engagementTypeId: this.memberFirmEngagementPrefillData.engagementTypeId,
      isContingent: this.memberFirmEngagementPrefillData.contingent,
      isPrimaryAssignment: true,
      netFeesInCurrencyFormat: '',
      collaborationSiteId: 0,
      engagementStartDate:null,
      engagementEndDate: null,
      officeLocationCodeId: 0,
      estimatedGrossMargin: null,
      clientBillingContactName: '',
      clientBillingContactTitleId: 0,
      clientBillingContactEmail: '',
      collaborationSiteName: '',
      officeLocationCodeName: '',
      estimatedGrossMarginInCurrencyFormat: '',
      clientBillingContactTitle: '',
      assignmentCode: null,
      clientCode: this.localClientCode.length > 0 ? this.localClientCode : null,
      assignmentCodeStatus: '',
      isContinuanceCarryFrwdAssignment: false,
      solutionCodeOnSigature: false,
      priorYearAssignmentName: '',
      isGrossMarginRequire: false,
      eqcrName: '',
      eqcrEmailId: '',
      isEditEQCR: false,
      newEQCRName: '',
      newEQCREmailId: '',
      isvalidEQCR: true,
      isValidEQCRBusinessRule: true,
      isEQCRExists: false,
      isAssignmentFinanceDataEditable: 'null',
      solutionCode: this.memberFirmEngagementPrefillData.solutionCode,
      solutionCodeDescription: this.memberFirmEngagementPrefillData.solutionCodeDescription,
      isEngagementStartDateRequired: false,
      isEngagementEndDateRequired: false,
      opportunityServiceId: null,
      status: 'Add',
    };

    if (this.defaultEngAssignment.solutionCodeListData.length == 0) {
      this._engService.getSecondarySolutionCodeById(this.engagement.parentSolutionCodeId)
        .subscribe(
          res => {
            this.secondarySolutionCode = res;
            let selectedsolutionCode = this.secondarySolutionCode.find(s => s.childSolutionCodeId == this.engagement.parentSolutionCodeId);
            this.defaultEngAssignment.solutionCodeListData = this.secondarySolutionCode.filter(solutionCode => solutionCode.status == 'A'),
              this.defaultEngAssignment.selectedsolutionCode = selectedsolutionCode;
            this.defaultEngAssignment.solutionCodeId = selectedsolutionCode.childSolutionCodeId;
          });
    }

    this.isvalidAassignmentAdministrator = true;
    this.adminDisplayUserName = this.defaultEngAssignment.assignmentAdministrator;
    this.adminDisplayUserEmail = this.defaultEngAssignment.adminEmailId;
    this.userDetails.AA = JSON.parse(JSON.stringify({ displayName: this.defaultEngAssignment.assignmentAdministrator, mail: this.defaultEngAssignment.adminEmailId, businessPhones: this.defaultEngAssignment.adminContactNo }));
    this.disableMemberFirmAssignmentData();
  }

  disableMemberFirmAssignmentData() {
    $('#txtPeoplePicker_AS_EP_0').attr('disabled', true);
    $('#txtPeoplePicker_AS_AA_0').attr('disabled', true);
    $('#hdnValidInput_AS_AA_0').val(true);
    this.validateCMSAA = true;
    $('#drpisContingent').attr('disabled', true);
    $('#txtnetFees').attr('disabled', true);
    $('#txtrealization').attr('disabled', true);
  }

  LoadGoverningAgreementsFromCLM() {
    if (this.isCLMFeatureEnabled) {
      let isValidClientSelected = false;
      var clientValidationText = $('#clientValidationMessage').text();
      if (clientValidationText.length === 0) {
        let clientId = parseInt($('#hdnKey' + this.MappedValue + 0).val().split('-')[0]);
        let clientName = $('#txtValue' + this.MappedValue + 0).val();
        if (clientId > 0 && clientName.length > 0) { isValidClientSelected = true; }
      }

      let EPPMDName = $('#txtPeoplePicker_EP_0').val();
      let EPPMDEmail = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();

      if (!this.EditMode && isValidClientSelected
        && (this.currentEngagementName != undefined && this.currentEngagementName != "" && this.currentEngagementName.length > 0)
        && this.engagement.parentSolutionCodeId > 0 && $('#txtValue' + this.SolutionCodeMappedValue + 0).val() != ''
        && this.epDisplayUserEmail.length > 0 && this.epDisplayUserName.length > 0) {
        this.GetAllGoverningAgreements();
      }
      else if (this.EditMode && isValidClientSelected) {
        this.GetAllGoverningAgreements();
      } else {
        this.clmGoverningAgreementUIState = "PAGE_LOAD";
        this.clmGoverningAgreementDiv.nativeElement.innerHTML = "";
      }
    }
  }

  GetAllGoverningAgreements() {
    this.clmGoverningAgreementDiv.nativeElement.innerHTML = "";
    this.clmGoverningAgreementUIState = "CLM_LOADING";
    let ga = new GoverningAgreemenRequest();
    let clientId
    if (this.EditMode == true) {
      clientId = this.engagement.clientId
      if (this.isContinuanceEngagement && this.engagement.gaCategory == undefined) {
        ga.engagementid = this.engagement.priorYearEngagementId;
      }
      else if (this.engagement.engagementId > 0)
        ga.engagementid = this.engagement.engagementId;
    }
    else {
      clientId = parseInt($('#hdnKey' + this.MappedValue + 0).val().split('-')[0]);
    }
    this.SelectedTemplateList = [];
    this.selectedCLMRadioButtonValue = "";
    this._clientService.GetClientEditDetailById(clientId).subscribe(clientDetails => {
      let editClientViewModel: EditClientViewModel = clientDetails;
      let client: EditClient = editClientViewModel.client;
      ga['oneView Account ID'] = client.oneViewAccountId;
      ga.clientName = client.clientName;
      ga.engagementName = this.currentEngagementName;
      ga.industryType = client.industryType;
      ga.solutionCode = $('#hdnKey' + this.SolutionCodeMappedValue + 0).val().split('-!')[1];
      if (ga.solutionCode == undefined)
        ga.solutionCode = this.arrEngagementAssignment?.find(x => x.isPrimaryAssignment == true)?.solutionCode
      ga.engagementPPMDEmail = $('#UserHiddenProps_EP_0 #hdnUserEmail').val();
      ga.addressLine1 = client.addressLine1;
      ga.addressLine2 = client.addressLine2;
      ga.addressLine3 = client.addressLine3;
      ga.addressLine4 = client.addressLine4;
      ga.city = client.city;
      ga.state = client.stateName;
      ga.zipcode = client.zipCode;
      ga.country = client.countryName;
      this.governingAgreemenRequest = ga;
      this.clmService.getAllGoverningAgreementsById(ga).subscribe(result => {
        this.clmGoverningAgreementUIState = "CLM_LOADED";
        this.governingAgreementResponse = result;
        if (result?.statusCode == "OK") {
          this.clmGoverningAgreementDiv.nativeElement.innerHTML = this.governingAgreementResponse.htmlData;
          // this.PopupDisplayAndExtractUniqueID(this.governingAgreementResponse.htmlData);
        }
        this.loadingScreenService.stopLoading();
        if (this.EditMode)
          this.insertCLMApiLog(this.currentEngagementId);
        if (this.isCLMFeatureEnabled) {
          //To delay the CLM to load
          setTimeout(() => {
            clmInitComponent();
            handleRadioSelection();
            // gettextvalue();
            const floatContainers = document.querySelectorAll('input[name="radioGroup"]');
            floatContainers.forEach((element) => {
              console.log("parent lister", element);
              element.addEventListener('click', this.getCLMTemplateMaintenanceDataByParent.bind(this))
            });
            const childContainers = document.querySelectorAll('input[name="radioGroup1"]');
            childContainers.forEach((element) => {
              element.addEventListener('click', this.getCLMTemplateMaintenanceDataByChild.bind(this))
            });
            let idTextFieldVal = $('#idTextField').val();
            if (idTextFieldVal?.length > 0)
              this.getCLMTemplateMaintenanceData($('#idTextField').val().split('#')[1]);
            this.injectCLMCloseButtonDynamically();
            setTimeout(() => {
              const parentRadioGroup = document.querySelectorAll('input[name="radioGroup"]');
              for (let index = 0; index < parentRadioGroup.length; index++) {
                const element = parentRadioGroup[index] as HTMLInputElement;
                if (element.checked) {
                  this.selectedCLMRadioButtonValue = element.labels[0].textContent;
                }
              }
              if (this.selectedCLMRadioButtonValue?.length > 0)
                this.getCLMTemplateMaintenanceData(this.selectedCLMRadioButtonValue);
            }, 200);
          }, 100);
        }
      });
    },
      err => {
        this.loadingScreenService.stopLoading();
        this.logService.error('Failed to load client details', err);
      });
  }


  insertCLMApiLog(engagementId: number) {
    let clmApiLog = new CLMApiLog();
    clmApiLog.engagementId = engagementId;
    clmApiLog.requestType = CLMApiLogrRequestType.GoverningAgreementList;
    clmApiLog.requestPayload = JSON.stringify(this.governingAgreemenRequest);
    clmApiLog.responseBody = JSON.stringify(this.governingAgreementResponse);
    clmApiLog.createdBy = this.UserObject.loggedInUserEmail;
    if (this.governingAgreementResponse?.statusCode != "OK") {
      clmApiLog.errorCode = this.governingAgreementResponse?.statusCode;
      clmApiLog.errorDescription = "Failed to get load CLM details ." + (this.EditMode ? "Edit Mode" : "Create New Engagement") + "";
    }
    else {
      clmApiLog.errorCode = "";
      clmApiLog.errorDescription = "";
    }
    this.clmService.insertCLMApiLog(clmApiLog).subscribe({
      next: clmApiLogId => {
      }, error: err => {
        console.error("falied to insertCLMApiLog " + (this.EditMode ? "Edit Mode" : "Create New Engagement") + "", err, clmApiLog);
      }
    })
  }

  insertCLMApiLogLink(engagementId: number) {
    let clmApiLog = new CLMApiLog();
    clmApiLog.engagementId = engagementId;
    clmApiLog.requestType = CLMApiLogrRequestType.UpdateEngagementWithGoverningAgreement;
    clmApiLog.requestPayload = JSON.stringify(this.engagementLinkRequest);
    clmApiLog.responseBody = JSON.stringify(this.linkCLMResponse);
    clmApiLog.createdBy = this.UserObject.loggedInUserEmail;
    if (this.linkCLMResponse.statusCode != "OK") {
      clmApiLog.errorCode = this.linkCLMResponse.statusCode;
      clmApiLog.errorDescription = "Failed to get load CLM details ." + (this.EditMode ? "Edit Mode" : "Create New Engagement") + "";
    }
    else {
      clmApiLog.errorCode = "";
      clmApiLog.errorDescription = "";
    }
    this.clmService.insertCLMApiLog(clmApiLog).subscribe({
      next: clmApiLogId => {
      }, error: err => {
        console.error("falied to insertCLMApiLog " + (this.EditMode ? "Edit Mode" : "Create New Engagement") + "", err, clmApiLog);
      }
    })
  }

  CLMRadioButtonValidation(event: MouseEvent) { // this method replaced with new hidden field
    let selectedRadioLabel = $('#idTextField').val();
    this.existingGACheck = false;
    if ($("input[type='radio'][name='radioGroup']:checked").val() === undefined || $("input[type='radio'][name='radioGroup']:checked").val() === '') {
      this.SelectedTemplateList = [];
    }
    if (this.selectedCLMRadioButtonValue === "Use an existing governing agreement from the list below:" && this.SelectedDocumentSubType !== DocumentSubTypesEnum.NoOptionExists) {
      if ($("input[type='radio'][name='radioGroup1']:checked").val() === undefined || $("input[type='radio'][name='radioGroup1']:checked").val() === '') {
        this.existingGACheck = true;
      }
    }
  }

  PopupDisplayAndExtractUniqueID(htmlurl: string) { // this is unused method need to remove once CLM js in fully tested
    var newDiv = document.createElement("div");
    newDiv.innerHTML = htmlurl;
    var scripts = newDiv.getElementsByTagName('script');
    let uniqueid;
    for (var ix = 0; ix < scripts.length; ix++) {
      let clmScript: HTMLScriptElement = document.createElement('script');
      clmScript.async = true;
      clmScript.innerHTML = scripts[ix].text;
      const lines = scripts[ix].text.split("\n");
      let uline;
      lines.forEach(element => {
        if (element.includes("var uniqueid = '")) {
          uline = element.trim();
        }
      });
      if (uline != undefined) {
        let uworrds = uline?.trim().split("=");
        let duniquieIdList = uworrds[1].trim().split("'");
        if (duniquieIdList.length == 3) {
          uniqueid = duniquieIdList[1];
          break;// break the loop
        }
      }
      //document.head.prepend(clmScript);
    }
  }

  OnKeyTeamMemberChecked(index: number, isKeyTeamMember: boolean) {
    this.engTeamaMembeListrWithoutAssignment[index].isKeyTeamMember = isKeyTeamMember;
  }

  // To check primary solutioncode is audit or not
  CheckPrimarySolutionCodeIsAudit(primarySolutioncodeId: number) {
    if (primarySolutioncodeId > 0)
      this._engService.GetSolutionCodeMasterById(primarySolutioncodeId).subscribe(result => {
        this.isAudit = result.isAudit;
        console.log('Primary solutioncode is Audit : ' + this.isAudit);
      });
  }

  SaveKeyTeamMemberDescription(id: number) {
    let keyTeamMemberDescription = $('#txtKeyTeamMemberDescription_' + id).val();
    if (this.engTeamaMembeListrWithoutAssignment.length > 0) {
      this.engTeamaMembeListrWithoutAssignment[id].description = '';
      this.engTeamaMembeListrWithoutAssignment[id].description = keyTeamMemberDescription;
    }
  }

  insertCLMDetailsDB(engagementid: number) {
    let hiddenfieldvalue = $('#idTextField').val();
    if (hiddenfieldvalue?.length > 0) {
      let requestModel = new CLMDetails();
      requestModel.engagementId = engagementid;
      requestModel.createdBy = this.UserObject.loggedInUserEmail;
      requestModel.baseDetails = hiddenfieldvalue;
      this.clmService.insertCLMDetails(requestModel).subscribe({
        next: (result) => {
          if (result != "Success")
            console.error("falied to updatge CLM details to DB", requestModel);
        }, error: (err) => {
          console.error("falied to updatge CLM details to DB", err, requestModel);
        }
      })
    }
  }
  updateLinkEngagementtoCLM(engagementid: number) {
    this.engagementLinkRequest = new EngagementLinkRequest();
    let hiddenfieldvalue = $('#idTextField').val();
    if (hiddenfieldvalue?.length > 0) {
      this.engagementLinkRequest.GAValue = hiddenfieldvalue;
      this.engagementLinkRequest.engagementID = engagementid;
      this.dataService.setCLMGALinkRequestProcessing(true); // / status of CLM api for interview - updating
      this.clmService.linkEngagementtoCLM(this.engagementLinkRequest).subscribe({
        next: result => {
          this.linkCLMResponse = result;
          this.insertCLMApiLogLink(this.engagementLinkRequest.engagementID);
          this.dataService.setCLMGALinkRequestProcessing(false);
          this.dataService.setCLMGALinkRequestCompleted(result.statusCode); // raise event to load CLM GA inn intervirew screen 
          // status of CLM api for interview  - completed
          if (result.statusCode != "OK")
            console.error("falied to updatge CLM details", this.engagementLinkRequest);
        }, error: err => {
          this.dataService.setCLMGALinkRequestProcessing(false);
          console.error("falied to updatge CLM details", err, this.engagementLinkRequest);
        }
      })
    }
  }

  CheckAndUpdateKeyTeamMemberRoleData() {
    console.log('Team member list before CheckAndUpdateKeyTeamMemberRoleData():');
    console.log(this.engTeamaMembeListrWithoutAssignment);
    if (this.isAudit) {
      for (let t = 0; t < this.engTeamaMembeListrWithoutAssignment.length; t++) {
        if (this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP) ||
          this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR) ||
          this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A) ||
          this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP)
        ) {
          if (this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId === null ||
            this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId.toString() === "null" ||
            this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId === undefined ||
            this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId.toString() === "") {

            let teamMemberRole: number = this.engTeamaMembeListrWithoutAssignment[t].teamMemberRole;
            let keyTeamMemberRole: number;
            let isKeyTeamMember: boolean = false;

            if (teamMemberRole > 0) {
              switch (teamMemberRole) {
                case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP):
                  isKeyTeamMember = true;
                  this.isAudit ? keyTeamMemberRole = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.EP) : keyTeamMemberRole = 0;
                  break;
                case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP):
                  this.isAudit ? keyTeamMemberRole = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.CMSEP) : keyTeamMemberRole = 0;
                  break;
                case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A):
                  this.isAudit ? keyTeamMemberRole = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.A) : keyTeamMemberRole = 0;
                  break;
                case this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR):
                  this.isAudit ? keyTeamMemberRole = this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.PR) : keyTeamMemberRole = 0;
                  break;
                default:
                  break;
              }
              this.engTeamaMembeListrWithoutAssignment[t].isKeyTeamMember = (this.isKeyTeamMemberFeatureFlag && this.isAudit) ? isKeyTeamMember : false,
                this.engTeamaMembeListrWithoutAssignment[t].keyTeamMemberRoleId = (this.isAudit && keyTeamMemberRole == this.getTeamMemberRoleIdByCode(EnumTeamMemberRoleCodes.P)) ?
                  this.getKeyTeamMemberRoleIdByCode(EnumKeyTeamMemberRoleCodes.OTH) : keyTeamMemberRole;
            }
          }
        }
      }
      console.log('Team member list after CheckAndUpdateKeyTeamMemberRoleData():');
      console.log(this.engTeamaMembeListrWithoutAssignment);
    }
  }

  redirectToCorrectEngagementState(engagmentid: number) {
    let request = new EngagementStatusViewModel();
    request.engagementId = engagmentid;
    let isInProgressDataModificationExistsSub = this.dataModification.IsInProgressDataModificationExists(engagmentid, DataModificationModuleNames.EditEngagement);
    let getEngagementStatusSub = this._engService.getEngagementStatus(request);
    forkJoin([isInProgressDataModificationExistsSub, getEngagementStatusSub]).subscribe({
      next: result => {
        let questionnaireInprogressResult = result[0];
        let engStatus = result[1];
        if (questionnaireInprogressResult.editStatus == true && questionnaireInprogressResult.editingMembers?.length > 0)
          this.skipRedirect = true;
        let prevPath = this.dataService.previousUrl.getValue();
        let redirectURL = this._engService.getRedirectPathByEngagementStatus(engStatus);
        let isHardRefreshPageReloaded = sessionStorage.getItem('isHardRefreshPageReloaded');
        let isLoginLogoutURL = false;
        if (prevPath?.includes("Login?returnUrl=")) {
          isLoginLogoutURL = true;
        }
        if (!this.skipRedirect && !isHardRefreshPageReloaded) {
          this.activatedRoute.snapshot;
          if (redirectURL?.length > 0 && !prevPath?.includes(redirectURL) && (prevPath == undefined || isLoginLogoutURL)) {
            this.router.navigate([redirectURL]);
            sessionStorage.removeItem('isHardRefreshPageReloaded');
          }
          else {
            sessionStorage.setItem('isHardRefreshPageReloaded', "true");
          }
        }
      }
    });

  }
  getClientConsent(clientCode, oneViewAccountId) {
    let clientConsentRequest = new ClientConsentRequest();
    clientConsentRequest.clientCode = clientCode;
    clientConsentRequest.oneViewAccountId = oneViewAccountId;
    if (oneViewAccountId?.length > 0 && this.clientConsent?.hs_AccountId != oneViewAccountId)
      this._clientService.getClientConsent(clientConsentRequest).subscribe({
        next: result => {

        }, error: err => {
          console.error("Client Consent erro: " + err)
        }
      })
  }

} // end component class