import { CloseSiteUrl } from './../model/SharePoiint/close-site-url-list';
import { SPSiteMetadata } from './../model/sp-site-metadata';
import { ApiRequest } from './../model/Common/api-request.model';
import { Assignments } from './../model/assignments.model';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

import { EngagementClientVoiceDelegateViewModel, EngagementClientVoiceOptViewModel, EngagementClientVoiceViewModel, EngagementCollabData, Engagements, EngagementStatusRequestModel, MemberFirmEngagementPrefillData, MemberFirmEngagementPrefillDataRequestModel, UpdateEngagementCloseoutConfigurationModel, UpdateQuestionnairePreCheckModel, UpdateworkItemVersionModel } from '../model/Engagement/Engagements';
import { EngagementList, UpdatedAssignmentMembersViewModel, EngagementCollaborationsViewModel, UpdateEngagementNameMode, UpdateEngagementStatusModel, ClientVoiceTeamMemberViewModel, EngagementCloseoutConfiguration, IWantToDocumentsReference, ServiceLineFlagDetails, EngagementActualCloseoutInDays } from '../model/EngagementListModel'

import { ActionTiles } from '../model/actionTiles';
import { AppConfigService } from './appConfigService';
import { SecondarySolutionCode } from '../model/Engagement/SecondarySolutionCodes'
import { User } from '../model/User';
import { AzureADUsersArrayList } from '../model/AzureADUsersArrayList';
import { TeamMemberRole } from '../model/Engagement/TeamMemberRole';

import { NewEngagementData, EngagementAssignmentWithoutSCode, UpdateEngagementTnCModel, NewEngAssignmentWrapperModel } from "src/app/model/Engagement/CreateEngagementData";
import { EngagementListPopupModel as WorkspaceDocumentListPopupModel } from '../model/Popup/EngagementListPopupModel';
import { TeamMember } from '../model/Engagement/TeamMember';
import { EngagementAssignment, RemoveEngagementAssignmentModel, UpdateAssignmentEQCRViewModel, UpdatePreRequestAssignment } from '../model/Engagement/EngagementAssignments';
import { CollaborationSite, CollaborationSiteViewModel, CollaborationSiteMembers, CollaborationSiteMembersWithClientMemberModel, EngagementCallModel, CollaborationSiteIndusModel, UpdateEngagementSiteRequestModel, AddClientMmembersModel } from 'src/app/model/CollaborationSite';
import { AvepointResponseListModel } from 'src/app/model/SharePoiint/SharePointSite';
import { ClientTeamMembers } from '../model/Engagement/ClientTeamMember';
import { EngagementUniqueDataModel } from '../model/EngagementFilterModel';
import { HDQuestionBankData } from '../model/HDWorkItemModel';
import { HotDocsVariableMapping } from '../model/HotDocsVariableMapping';
import { EngagementCardViewFilterModel, EngagementCardViewForClientFilterModel, EngagementListViewFilterModel, EngagementListViewForClientFilterModel } from '../model/engagement-filter-model';
import { SolutionCodeMappingModel } from '../model/SolutionCodeMappingModel';
import { SharePointWrapper } from '../model/sharepoint-metadata-wrapper';
import { ContinuanceEngagementValidationModel } from '../model/continuanceEngagementValidationModel';
import { UserPreferenceData, UserPreferenceModel } from '../model/UserPreferenceModel';
import { EngageEvent } from '../model/Engagement/EngageEvent';
import { PowerAutomateRequestModel, RemoveClientTeamMemberModel } from '../model/Workspace/TeamMembers';
import { ConsentArray, EngagementConsentInsertModel, EngagementConsentModel } from '../model/Engagement/EngagementConsent';
import { EngagementClientVoiceDetailViewModel } from '../model/Engagement/EngagementClientVoice';
import { ClientVoiceApiLog } from '../model/ClientVoice/ClientVoiceRequestsModel';
import { EngagementCompletionDateViewModel } from '../model/Engagement/EngagementDetails';
import { RetentionExtensionDetailsModel, RetentionExtensionModel } from '../model/Engagement/RetentionExtentionModel';
import { CloseoutConfigurationResponse } from '../model/decisions/closeout/closeout-configuration-response';
import { ReviewNoteEngagementDetailModel } from '../model/WorkspaceReviewPoint';
import { EngagementArchivalInfoViewModel } from '../model/Engagement/EngagementArchivalInfoViewModel';

import { EngagementTeamMember, RFCMember } from '../model/Engagement/RFCMember'
import { DueDateExtensionRequestModal, EngagementDueDateExtension } from '../model/Engagement/EngagementDueDateExtension';
import { ApproveDenyExtensionModal } from '../model/Engagement/EngagementDueDateExtension';
import { NewYearOpportunityUpdateRequestModel, OpportunityServiceRequestModel, OpportunityServiceRequestModelByEngagementId, OpportunityServiceResponseViewModel } from '../model/Client/CreateClientViewModel';
import { ConveneGetProjetcsById, ConveneProjectDetailsResponse, ConveneProjectInsertModel, ConveneProjectsDetailResponse } from '../model/Engagement/Convene/ConveneBaseModel';
import { EngagementAuditChecklistViewModel } from 'src/app/model/Engagement/EngagementAuditChecklistViewModel';
import { DownloadTemplateEnagments, EngagementAdministrativeCloseModel, engagements, ExecutionDocumentEnagments, IndusFlags, UpdateINDUSFlag } from '../model/engagements';
import { CloseoutSectionConfigResponse, SolutionCodeConfigResponse } from '../model/decisions/solutioncodeproperties/Solutioncodeproperties-response';
import { InterchangeableSolutionCode } from '../model/Engagement/InterchangeableSolutioncodes';
import { UserClientDetails } from '../model/DocumentTemplate';
import { DownloadTemplatesConfiguration } from '../model/Engagement/DownloadTemplatesConfiguration';
import { EngagementStatusViewModel } from '../model/Engagement/engagement-status-view-model';

@Injectable()
export class EngagementService {


  APIEndPoint: string;
  IntegrationAPIEndPoint: string;
  userName: string;
  fullName: string;
  engList: EngagementList = new EngagementList();
  userEmail: string;
  userInfo: User = new User();
  constructor(private enviornment: AppConfigService, private http: HttpClient) {
    this.APIEndPoint = this.enviornment.config.DATA.API_END_POINT.API_URL;
    this.IntegrationAPIEndPoint = this.enviornment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.initializeUserDetails();
  }

  initializeUserDetails() {
    let nameJSON = localStorage.getItem('currentUser');
    this.userInfo = JSON.parse(nameJSON);
    console.log(nameJSON, nameJSON != null);
    if (nameJSON != null) {
      this.userName = this.userInfo.userName;
      this.fullName = this.userInfo.loggedInUserFullName;
      this.userEmail = this.userInfo.loggedInUserEmail;

    }
  }
  getEngagementListView(): Observable<EngagementList[]> {
    return this.http.get<EngagementList[]>(this.APIEndPoint + "/Engagement/GetAllEngagements/" + this.userName);
  }

  getActionTiles(): Observable<ActionTiles> {
    return this.http.get<ActionTiles>(this.APIEndPoint + "/ActionTiles/document");
  }
  getAllSecondarySolutionCodes(): Observable<SecondarySolutionCode[]> {
    return this.http.get<SecondarySolutionCode[]>(this.APIEndPoint + "/Engagement/GetAllSecondarySolutionCodes");
  }
  getSecondarySolutionCodeById(primarySolutionCodeId: number): Observable<SecondarySolutionCode[]> {
    return this.http.get<SecondarySolutionCode[]>(this.APIEndPoint + "/Engagement/GetSecondarySolutionCodeById/" + primarySolutionCodeId);
  }

  getAzureAdUsersByGroups(emailId: string): Observable<User> {
    return this.http.get<User>(this.IntegrationAPIEndPoint + '/Graph/UserByEmail/' + emailId);
  }

  UpdateEngagementTitle(apiRequest: ApiRequest) {
    // tslint:disable-next-line: max-line-length
    return this.http.post(`${this.IntegrationAPIEndPoint}/api/Avepoint/UpdateEngagementTitle`, apiRequest, { responseType: 'text' });
  }

  getAllEngagement(): Observable<Engagements[]> {
    return this.http.get<Engagements[]>(this.APIEndPoint + '/Engagement/GetAllEngagementList');
  }
  getMyEngagementCardView(engagementFilterModel: EngagementCardViewFilterModel,
    pageNumber: number, noOfRecordsPerPage: number,
    isLoggedInUserAdmin: string, orderByColumn: string, orderByType: string
  ): Observable<EngagementList[]> {
    engagementFilterModel.loggedInUser = this.userName;
    return this.http.post<EngagementList[]>(`${this.APIEndPoint}/Engagement/GetAllEngagementByFilters/${isLoggedInUserAdmin}/${pageNumber}/${noOfRecordsPerPage}/${orderByColumn}/${orderByType}`, engagementFilterModel);
  }
  getMyEngagementListView(engagementFilterModel: EngagementListViewFilterModel,
    pageNumber: number, noOfRecordsPerPage: number,
    isLoggedInUserAdmin: string, orderByColumn: string, orderByType: string
  ): Observable<EngagementList[]> {
    engagementFilterModel.loggedInUser = this.userName;
    return this.http.post<EngagementList[]>(`${this.APIEndPoint}/Engagement/GetAllEngagementByFilters/${isLoggedInUserAdmin}/${pageNumber}/${noOfRecordsPerPage}/${orderByColumn}/${orderByType}`, engagementFilterModel);
  }

  getMyEngagementCardViewByUserCount(isloggedInUserAdmin: string,
    engagementCardViewFilterModel: EngagementCardViewFilterModel):
    Observable<number> {
    engagementCardViewFilterModel.loggedInUser = this.userName;
    return this.http.post<number>(`${this.APIEndPoint}/Engagement/GetAllEngagementByUserCount/${isloggedInUserAdmin}`, engagementCardViewFilterModel);
  }

  getMyEngagementListViewByUserCount(isloggedInUserAdmin: string,
    engagementCardViewFilterModel: EngagementListViewFilterModel):
    Observable<number> {
    engagementCardViewFilterModel.loggedInUser = this.userName;
    return this.http.post<number>(`${this.APIEndPoint}/Engagement/GetAllEngagementByUserCount/${isloggedInUserAdmin}`, engagementCardViewFilterModel);
  }

  GetEngagementFilterSectionUniqueData(isloggedInUserAdmin: string): Observable<EngagementUniqueDataModel> {
    return this.http.post<EngagementUniqueDataModel>(this.APIEndPoint +
      '/Engagement/GetAllEngagementUniqueDataByUser',
      { userName: this.userName, isloggedInUserAdmin: isloggedInUserAdmin });
  }

  GetAllUserPreferenceData(userEmail: string): Observable<UserPreferenceModel> {
    return this.http.post<UserPreferenceModel>(this.APIEndPoint +
      '/UserPreference/GetUserPreferenceData',
      { userEmail: userEmail });
  }
  InsertUpdateUserPreferenceData(userPreferenceData: UserPreferenceData): Observable<string> {
    return this.http.post(this.APIEndPoint +
      '/UserPreference/InsertUpdateUserPreferenceData', userPreferenceData, { responseType: 'text' });
  }

  GetMyEngagementUniqueDataByClientId(isloggedInUserAdmin: string, clientId: number): Observable<EngagementUniqueDataModel> {
    return this.http.post<EngagementUniqueDataModel>(this.APIEndPoint +
      '/Engagement/GetMyEngagementUniqueDataByClientId',
      { userName: this.userName, isloggedInUserAdmin: isloggedInUserAdmin, clientId: clientId });
  }
  getMyEngagementCountByClientId(engagementCardViewForClientFilterModel: EngagementCardViewForClientFilterModel,
    isLoggedInUserAdmin: string, clientId: number): Observable<number> {
    engagementCardViewForClientFilterModel.loggedInUser = this.userName;
    return this.http.post<number>(`${this.APIEndPoint}/Engagement/GetMyEngagementCountByClientId/${isLoggedInUserAdmin}/${clientId}`, engagementCardViewForClientFilterModel);
  }

  getMyEngagementCardViewByClientId(engagementCardViewForClientFilterModel: EngagementCardViewForClientFilterModel,
    pageNumber: number,
    noOfRecordsPerPage: number,
    isLoggedInUserAdmin: string, orderByColumn: string,
    orderByType: string, clientId: number
  ): Observable<EngagementList[]> {
    engagementCardViewForClientFilterModel.loggedInUser = this.userName;
    return this.http.post<EngagementList[]>(`${this.APIEndPoint}/Engagement/GetAllEngagementsByClientId/${isLoggedInUserAdmin}/${pageNumber}/${noOfRecordsPerPage}/${orderByColumn}/${orderByType}/${clientId}`, engagementCardViewForClientFilterModel);
  }

  getMyEngagementListViewByClientId(engagementListViewForClientFilterModel: EngagementListViewForClientFilterModel,
    pageNumber: number,
    noOfRecordsPerPage: number,
    isLoggedInUserAdmin: string, orderByColumn: string,
    orderByType: string, clientId: number
  ): Observable<EngagementList[]> {
    engagementListViewForClientFilterModel.loggedInUser = this.userName;
    return this.http.post<EngagementList[]>(`${this.APIEndPoint}/Engagement/GetAllEngagementsByClientId/${isLoggedInUserAdmin}/${pageNumber}/${noOfRecordsPerPage}/${orderByColumn}/${orderByType}/${clientId}`, engagementListViewForClientFilterModel);
  }

  insertEngagement(newEngagementData: NewEngagementData) {
    newEngagementData.loggedInUserFullName = this.fullName;
    newEngagementData.engagements.createdBy = this.userName;
    return this.http.post(this.APIEndPoint + '/Engagement/InsertEngagementData', newEngagementData, { responseType: 'text' });

  }
  isEngagementExists(clientId: number, engagementName: string): Observable<number> {
    return this.http.get<number>(this.APIEndPoint + '/Engagement/IsEngagementExist/' + clientId + '/' + engagementName);
  }

  getAssigmentAdminList(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetAssigmentAdminList');
  }
  getEngagementDetailsById(engId: number): Observable<EngagementList> {
    this.engList.engagementId = engId;
    this.engList.LastModifiedBy = this.userName;
    return this.http.post<EngagementList>(this.APIEndPoint + '/Engagement/GetEngagementDetailsById', this.engList);
  }

  getWorkspaceDocumentsByClient(clientId: number): Observable<WorkspaceDocumentListPopupModel[]> {
    return this.http.post<WorkspaceDocumentListPopupModel[]>(this.APIEndPoint + '/Engagement/WorkspaceDocuments', { clientId: clientId.toString(), username: this.userName });
  }
  getTeamMembersList(): Observable<AzureADUsersArrayList> {
    return this.http.get<AzureADUsersArrayList>(this.APIEndPoint + '/AzureAD/GetTeamMembersList');
  }
  getEngagementListViewByClient(clientId: number): Observable<EngagementList[]> {
    return this.http.post<EngagementList[]>(this.APIEndPoint + "/Engagement/EngagementListByClient", { clientId: clientId.toString(), username: this.userName });
  }

  getEnagementListByClientforAcptDoc(clientId: number): Observable<EngagementList[]> {
    return this.http.get<EngagementList[]>(this.APIEndPoint + "/Engagement/EnagementListByClientforAcptDoc/" + clientId.toString());
  }

  getEnagementListByClientforOtherDoc(clientId: number): Observable<EngagementList[]> {
    return this.http.get<EngagementList[]>(this.APIEndPoint + "/Engagement/EnagementListByClientforOtherDoc/" + clientId.toString());
  }

  getWorkspaceDocumentsByEngagement(engagementId: number): Observable<WorkspaceDocumentListPopupModel[]> {
    return this.http.post<WorkspaceDocumentListPopupModel[]>(this.APIEndPoint + '/Engagement/WorkspaceDocumentsByEngagement', { engagementId: engagementId.toString(), username: this.userName });
  }

  GetTeamMemberRoles(): Observable<TeamMemberRole[]> {
    return this.http.get<TeamMemberRole[]>(this.APIEndPoint + '/Engagement/getTeamMemberRoles');
  }

  GetEditEngagementDetailsById(engId: number): Observable<Engagements> {
    return this.http.get<Engagements>(this.APIEndPoint + '/Engagement/GetEditEngagementDetailsById/' + engId);
  }

  GetEditEngagementCollabDetailsById(engId: number): Observable<EngagementCollabData> {
    return this.http.get<EngagementCollabData>(this.APIEndPoint + '/Engagement/GetEditEngagementCollabDetailsById/' + engId);
  }

  GetEngagementTeamMembersById(engId: number, isEditEngagement: boolean): Observable<TeamMember[]> {
    return this.http.get<TeamMember[]>(this.APIEndPoint + '/Engagement/GetEngagementTeamMembersById/' + engId + '/' + isEditEngagement);
  }
  GetCollaborationSiteMembersById(engId: number): Observable<CollaborationSiteMembers[]> {
    return this.http.get<CollaborationSiteMembers[]>(this.APIEndPoint + '/Engagement/GetCollaborationSiteMembersById/' + engId);
  }
  GetEngagementAssignmentsById(engId: number, isEditEngagement: boolean): Observable<EngagementAssignment[]> {
    return this.http.get<EngagementAssignment[]>(this.APIEndPoint + '/Engagement/GetEngagementAssignmentsById/' + engId + '/' + isEditEngagement);
  }

  UpdateEngagement(newEngagementData: NewEngagementData) {
    newEngagementData.engagements.createdBy = this.userName;
    newEngagementData.engagements.lastModifiedBy = this.userName;
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateEngagement', newEngagementData, { responseType: 'text' });
  }

  IsDiscussionOrReviewPointExistForUser(engagementId: number, username: string) {
    this.engList.engagementId = engagementId;
    this.engList.LastModifiedBy = username;
    return this.http.post<number>(this.APIEndPoint + '/Engagement/IsDiscussionOrReviewPointExistForUser', this.engList);
  }

  UpdateEngagementName(engObj: UpdateEngagementNameMode) {
    return this.http.post<boolean>(this.APIEndPoint + '/Engagement/UpdateEngagementName', engObj);
  }

  updateEPPMD(engObj: EngagementList) {
    engObj.LastModifiedBy = this.userEmail;
    return this.http.post<boolean>(this.APIEndPoint + '/Engagement/UpdateEPPMD', engObj);
  }

  UpdateAssignmentEQCR(updateAssignmentEQCRViewModel: UpdateAssignmentEQCRViewModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateAssignmentEQCR', updateAssignmentEQCRViewModel, { responseType: 'text' });
  }

  UpdateEngCompletionDate(engagementCompletionDateViewModel: EngagementCompletionDateViewModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateEngCompletionDate', engagementCompletionDateViewModel, { responseType: 'text' });
  }

  GetEngagementCollaborationSites(engId: number): Observable<CollaborationSite[]> {
    let engagementCallModel: EngagementCallModel = new EngagementCallModel();
    engagementCallModel.engagementId = engId;
    engagementCallModel.userName = this.userName;
    return this.http.post<CollaborationSite[]>(this.APIEndPoint + '/CollaborationSite/engagement-collaborations/', engagementCallModel);
  }

  addEngagementCollaboration(newCollaborationSite: CollaborationSite) {
    newCollaborationSite.createdBy = this.userName;
    return this.http.post(this.APIEndPoint + '/CollaborationSite/addcollaboration', newCollaborationSite, { responseType: 'text' });
  }

  updateCollaborationAssignmentMembers(updatedAssignmentMembersViewModel: UpdatedAssignmentMembersViewModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/updateCollaborationAssignmentMembers', updatedAssignmentMembersViewModel, { responseType: 'text' });
  }

  getEngagementCollaborationsById(engId: number, isloggedInUserAdmin: string): Observable<EngagementCollaborationsViewModel> {
    this.engList.engagementId = engId;
    this.engList.LastModifiedBy = this.userName;
    this.engList.isloggedInUserAdmin = isloggedInUserAdmin;
    return this.http.post<EngagementCollaborationsViewModel>(this.APIEndPoint +
      '/Engagement/GetEngagementCollaborationsById', this.engList);
  }

  getCollaborationSiteMembersDetailsBySiteId(collaborationSiteId: number) {
    return this.http.get<CollaborationSiteMembers[]>(this.APIEndPoint +
      '/CollaborationSite/CollaborationSiteMembers/' + collaborationSiteId);
  }

  UpdateEngagementStatus(updateEngagementStatusData: UpdateEngagementStatusModel) {
    return this.http.post<boolean>(this.APIEndPoint + '/Engagement/UpdateEngagementStatus', updateEngagementStatusData);
  }

  getEngagementClientSiteUrl(engagementId: number) {
    return this.http.get<string[]>(`${this.APIEndPoint}/Engagement/GetEngagementClientSiteUrl/${engagementId}`);
  }
  closeSite(engagementClientSiteUrlList: CloseSiteUrl) {
    return this.http.post(`${this.IntegrationAPIEndPoint}/api/Avepoint/CloseSite`, engagementClientSiteUrlList, { responseType: 'text' });
  }
  getMetadataList(): Observable<SPSiteMetadata[]> {
    return this.http.get<SPSiteMetadata[]>(`${this.IntegrationAPIEndPoint}/api/Avepoint/GetMetadataList`);
  }
  updateSiteMetadata(sharePointWrapper: SharePointWrapper) {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post<string>(`${this.IntegrationAPIEndPoint}/api/Avepoint/UpdateSiteMetadata`, sharePointWrapper, requestOptions);
  }



  getSharePointMetadataMapping(engagementId: number, collaborationSiteId: number) {
    return this.http.get(`${this.APIEndPoint}/Engagement/getSharepointMetadataMapping/${engagementId}/${collaborationSiteId}`);
  }

  AddEngagementAssignment(newEngAssignmentWrapperModel: NewEngAssignmentWrapperModel) {

    newEngAssignmentWrapperModel.newEngagementAssignment.createdBy = this.userName;
    newEngAssignmentWrapperModel.newEngagementAssignment.loggedInUserName = this.fullName;
    return this.http.post(this.APIEndPoint + '/Engagement/AddAssignment', newEngAssignmentWrapperModel, { responseType: 'text' });
  }

  updateEngagementSiteRequestData(avepointResponseJSON: AvepointResponseListModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateEngagementSiteRequestData', avepointResponseJSON, { responseType: 'text' });
  }

  GetClientTeamMembersByEngagementId(engId: number): Observable<ClientTeamMembers[]> {
    return this.http.get<ClientTeamMembers[]>(this.APIEndPoint + '/Engagement/client-members/' + engId);
  }

  AddClientTeamMembers(addClientMmembersModel: AddClientMmembersModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/AddClientTeamMembers', addClientMmembersModel, { responseType: 'text' });
  }

  UpdateClientTeamMembers(collaborationSiteViewModel: CollaborationSiteViewModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateClientTeamMembers', collaborationSiteViewModel, { responseType: 'text' });
  }

  RemoveClientTeamMember(removeClientTeamMemberModel: RemoveClientTeamMemberModel) {
    return this.http.post(
      this.APIEndPoint + '/Engagement/remove-clientTeamMember', removeClientTeamMemberModel, { responseType: 'text' }
    );
  }

  GetEngagementDetailsByworkspaceId(workspaceId: string): Observable<EngagementList> {
    return this.http.get<EngagementList>(this.APIEndPoint + '/Engagement/GetEngagementDetailsByworkspaceId/' + workspaceId);
  }

  GetEngagementPreviewDocumentFromSPByWorkspaceId(workspaceId: string, status: string): Observable<IWantToDocumentsReference> {
    return this.http.get<IWantToDocumentsReference>(this.APIEndPoint + '/Engagement/GetEngagementPreviewDocumentFromSPByWorkspaceId/' + workspaceId + '/' + status);
  }

  GetQuestionBankData(engagementId: number): Observable<HDQuestionBankData> {
    return this.http.get<HDQuestionBankData>(this.APIEndPoint + '/Engagement/GetEngagementClientDataByEngId/' + engagementId);
  }
  GetAllHDVariableMapping(templatePackageId: string): Observable<HotDocsVariableMapping[]> {
    return this.http.get<HotDocsVariableMapping[]>(this.APIEndPoint + '/Engagement/GetAllHotDocsVariableMapping/' + templatePackageId);
  }

  UpdateCollaborationSiteMembers(CollaborationSiteMembersWithClientMember: CollaborationSiteMembersWithClientMemberModel) {
    CollaborationSiteMembersWithClientMember.loggedInUser = this.userName;
    CollaborationSiteMembersWithClientMember.loggedInUserFullName = this.fullName;
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateCollaborationSiteMembers', CollaborationSiteMembersWithClientMember, { responseType: 'text' });
  }

  GetAssignmentNameCountByClientId(assignmentName: string, clientId: number): Observable<number> {
    return this.http.get<number>(this.APIEndPoint + '/Assignment/GetAssignmentNameCountByClientId/' + assignmentName + '/' + clientId);
  }
  updateCollaborationSiteIndus(collaborationSiteIndusModel: CollaborationSiteIndusModel) {
    collaborationSiteIndusModel.updatedby = this.userName;
    return this.http.post(this.APIEndPoint + '/CollaborationSite/UpdateCollaborationSiteIndus', collaborationSiteIndusModel, { responseType: 'text' });
  }

  GetReviewEngagementAssignmentsById(engagementId: number): Observable<Assignments[]> {
    return this.http.get<Assignments[]>(this.APIEndPoint + '/Assignment/GetReviewEngagementAssignmentsById/' + engagementId);
  }

  GetSolutionCodeMasterById(solutionCodeId: number) {
    return this.http.get<SolutionCodeMappingModel>(this.APIEndPoint + '/Engagement/GetSolutionCodeMasterById/' + solutionCodeId);
  }

  UpdateEngagementSiteRequestStatus(updateEngagementSiteRequestData: UpdateEngagementSiteRequestModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateEngagementSiteRequestStatus', updateEngagementSiteRequestData, { responseType: 'text' });
  }
  GenerateSiteUniqueId(engagement: Engagements): Observable<string> {
    let createdBy: string = this.userEmail;
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post<string>(this.APIEndPoint + '/Engagement/GenerateSiteUniqueId', { CreatedBy: createdBy }, requestOptions);
  }

  ValidateAuditEngagementAndGetHDWorkItemId(engagementId: number) {
    return this.http.get<any>(this.APIEndPoint + `/Engagement/auditengagements/${engagementId}/validate`);
  }

  UpdateAuditAssignmentCodeAndStatus(auditEngagementUpdateRequest: any) {
    return this.http.post<any>(this.APIEndPoint + `/Engagement/auditengagements/updateassignmentcodeandstatus`, auditEngagementUpdateRequest);
  }

  GetRPDeterminationAssignmentsDetailByWorkspaceId(workspaceId: string) {
    return this.http.get<any>(this.APIEndPoint + `/Engagement/getRPDeterminationAssignmentsDetailByWorkspaceId/${workspaceId}`);
  }

  GetNotificationPayload(engagementId: number): Observable<any> {
    return this.http.get<any>(this.APIEndPoint + `/Engagement/getNotificationPayload/${engagementId}`);
  }

  GetIndusConsentFlagByEngId(engagementId: number): Observable<boolean> {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetIndusConsentFlagByEngId/' + engagementId);
  }
  updateEngagementStatusWithError(engagementStatusRequestModel: EngagementStatusRequestModel) {
    engagementStatusRequestModel.updatedBy = this.userName
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateEngagementStatusWithError', engagementStatusRequestModel, { responseType: 'text' });
  }
  RemoveEngagementAssignment(removeEngagementAssignmentModel: RemoveEngagementAssignmentModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/RemoveEngagementAssignment', removeEngagementAssignmentModel, { responseType: 'text' });
  }
  UpdateEngagementAssignment(updateAssignment: UpdatePreRequestAssignment) {
    updateAssignment.assignment.createdBy = this.userEmail;
    return this.http.post<number>(this.APIEndPoint + '/Engagement/UpdateEngagementAssignment', updateAssignment);
  }
  GetEngageSupportCanvasLink(): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.get<string>(this.APIEndPoint + '/Engagement/GetEngageSupportCanvasLink', requestOptions);
  }

  GetContinuanceEngagementValidationDetails(engagemnetId): Observable<ContinuanceEngagementValidationModel[]> {
    return this.http.get<ContinuanceEngagementValidationModel[]>(this.APIEndPoint + `/Engagement/GetContinuanceEngagementValidationDetails/${engagemnetId}`);
  }

  SaveContinuaceEngagementApproval(engId, loggedInUser): Observable<any> {
    let requestPayload = {
      engagementId: engId,
      loggedInUser: loggedInUser
    };
    return this.http.post<any>(this.APIEndPoint + `/Engagement/SaveContinuaceEngagementApproval`, requestPayload);
  }
  UpdateSiteTitle(apiRequest: ApiRequest) {
    // tslint:disable-next-line: max-line-length
    return this.http.post(`${this.IntegrationAPIEndPoint}/api/Avepoint/UpdateSiteTitle`, apiRequest, { responseType: 'text' });
  }
  RemoveCollaborationSpace(engagementId: number, collabSiteId: number) {
    // tslint:disable-next-line: max-line-length
    const requestOptions: object = {
      responseType: 'text'
    };
    const FetchUrl: string = this.APIEndPoint + '/Engagement/RemoveCollaborationSpace/' + engagementId + '/' + collabSiteId;
    return this.http.get<string>(FetchUrl, requestOptions);
  }
  ResetCollaborationSiteIndusFlag(collaborationSiteId: number) {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/ResetCollaborationSiteIndusFlag/' + collaborationSiteId);
  }
  UpdateEngagementTnC(engagementData: UpdateEngagementTnCModel) {
    engagementData.loggedInUserEmail = this.userEmail;
    return this.http.post<boolean>(this.APIEndPoint + '/Engagement/UpdateEngagementTnC', engagementData);
  }
  GetEngagementCurrentStatus(engagementId: number) {

    return this.http.get<UpdateQuestionnairePreCheckModel>(this.APIEndPoint + '/Engagement/GetEngagementCurrentStatus/' + engagementId);
  }

  InsertUpdateEngagementConsent(engagementConsentInsertModel: ConsentArray) {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post<string>(this.APIEndPoint + '/Engagement/InsertUpdateEngagementConsent', engagementConsentInsertModel, requestOptions);
  }

  GenerateEventInEventGrid(event: EngageEvent): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint + '/EventGrid/GenerateEventInEventGrid', event);

  }

  GetEngagementConsentDetails(engagementId: number) {

    return this.http.get<EngagementConsentModel[]>(this.APIEndPoint + '/Engagement/GetEngagementConsentDetails/' + engagementId);
  }

  GetConsentFeatureFlag() {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetConsentFeatureFlag');
  }

  GetClientVoiceFeatureFlag() {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetClientVoiceFeatureFlag');
  }

  GetClientContactsByEngId(engagementId: number) {
    return this.http.get<ClientVoiceTeamMemberViewModel>(this.APIEndPoint + '/Engagement/GetClientContactsByEngId/' + engagementId);
  }

  saveEngagementClientVoice(engagementClientVoiceViewModel: EngagementClientVoiceViewModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/SaveEngagementClientVoice', engagementClientVoiceViewModel, { responseType: 'text' });
  }

  saveEngagementClientVoiceDelegate(engagementClientVoiceDelegateViewModel: EngagementClientVoiceDelegateViewModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/SaveEngagementClientVoiceDelegate', engagementClientVoiceDelegateViewModel, { responseType: 'text' });
  }

  saveEngagementClientVoiceOpt(engagementClientVoiceOptViewModel: EngagementClientVoiceOptViewModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/SaveEngagementClientVoiceOpt', engagementClientVoiceOptViewModel, { responseType: 'text' });
  }

  GetEngagementClientVoiceDetailByEngId(engagementId: number) {
    return this.http.get<EngagementClientVoiceDetailViewModel>(this.APIEndPoint + '/Engagement/GetEngagementClientVoiceDetailByEngId/' + engagementId);
  }

  addClientVoiceApiLog(clientVoiceApiLog: ClientVoiceApiLog) {
    return this.http.post(this.APIEndPoint + '/Engagement/AddClientVoiceApiLog', clientVoiceApiLog);
  }

  GeneratePowerAutomateTriggerOnClientUserCreated(powerAutomateRequestModel: PowerAutomateRequestModel): Observable<string> {
    return this.http.post<string>(this.IntegrationAPIEndPoint + '/PowerAutomate/GeneratePowerAutomateTriggerOnClientUserCreated', powerAutomateRequestModel);
  }

  IsAssignmentTaskActive(engagementId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.APIEndPoint}/Retention/IsAssignmentTaskActive/${engagementId}`);
  }

  GetAddUpdateRetentionExtention(retentionExtensionModel: RetentionExtensionModel) {
    return this.http.post<RetentionExtensionDetailsModel>(this.APIEndPoint + '/Engagement/GetAddUpdateRetentionExtention', retentionExtensionModel);
  }

  GetEngagementCloseoutConfigurations(engagementId: number): Observable<EngagementCloseoutConfiguration[]> {
    return this.http.get<EngagementCloseoutConfiguration[]>(`${this.APIEndPoint}/Engagement/CloseoutConfigurations/${engagementId}`);
  }

  AddEngagementCloseoutConfigurations(closeoutConfigurationResponse: CloseoutConfigurationResponse): Observable<boolean> {
    return this.http.post<boolean>(`${this.APIEndPoint}/Engagement/AddEngagementCloseoutConfigurations`, closeoutConfigurationResponse);
  }

  UpdateEngagementCloseoutConfigurations(updateEngCloseoutConfigModel: UpdateEngagementCloseoutConfigurationModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateEngagementCloseoutConfigurations', updateEngCloseoutConfigModel, { responseType: 'text' });
  }

  GetEngagementDetailsForReviewNotesById(engagementId: number): Observable<ReviewNoteEngagementDetailModel> {
    return this.http.get<ReviewNoteEngagementDetailModel>(`${this.APIEndPoint}/Engagement/GetEngagementDetailsForReviewNotesById/${engagementId}`);
  }

  UpdateWorkItemVersion(updateworkItemVersionModel: UpdateworkItemVersionModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateWorkItemVersion', updateworkItemVersionModel, { responseType: 'text' });
  }

  UpdateRFC(rfcMember: RFCMember): Observable<boolean> {
    return this.http.post<boolean>(this.APIEndPoint + '/Engagement/UpdateRFC',
      rfcMember);
  }

  GetCollaborationTeamMembers(engagementId: number): Observable<EngagementTeamMember[]> {
    return this.http.get<EngagementTeamMember[]>(this.APIEndPoint + '/Engagement/GetCollaborationTeamMembers/' + engagementId);
  }

  GetEngagementArchivalInformation(engagementId: number): Observable<EngagementArchivalInfoViewModel> {
    return this.http.get<EngagementArchivalInfoViewModel>(`${this.APIEndPoint}/Engagement/GetEngagementArchivalInformation/${engagementId}`);
  }
  GetPremLibraryFeatureFlag() {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetPremLibraryFeatureFlag');
  }

  GetEngagementDueDateExtensions(engagementId: number): Observable<EngagementDueDateExtension[]> {
    return this.http.get<EngagementDueDateExtension[]>(this.APIEndPoint + '/Engagement/GetEngagementDueDateExtensions/' + engagementId);
  }

  UpdateExtensionDueDateRequest(approveDenyExtensionModal: ApproveDenyExtensionModal) {
    return this.http.post(this.APIEndPoint + '/Engagement/UpdateExtensionDueDateRequest', approveDenyExtensionModal, { responseType: 'text' });
  }
  ADDUpdateExtensionDueDateRequest(dueDateRequestModal: DueDateExtensionRequestModal) {
    return this.http.post(this.APIEndPoint + '/Engagement/ADDUpdateExtensionDueDateRequest', dueDateRequestModal, { responseType: 'text' });
  }

  CheckClientOpportunityServiceAssigned(opportunityServiceRequestModel: OpportunityServiceRequestModel) {
    return this.http.post<OpportunityServiceResponseViewModel>(this.APIEndPoint + '/Engagement/CheckClientOpportunityServiceAssigned', opportunityServiceRequestModel);
  }

  GetOpportunityServiceFeatureFlag() {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetOpportunityServiceFeatureFlag');
  }

  UpdateNewYearOpportunityDetails(newYearOpportunityUpdateRequestModel: NewYearOpportunityUpdateRequestModel) {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post<string>(this.APIEndPoint + '/Engagement/UpdateNewYearOpportunityDetails', newYearOpportunityUpdateRequestModel, requestOptions);
  }

  GetConveneIntegrationFeatureFlag() {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetConveneIntegrationFeatureFlag');
  }

  GetConveneProjectsByEngagementId(conveneGetProjetcsById: ConveneGetProjetcsById) {
    return this.http.post<ConveneProjectsDetailResponse>(this.IntegrationAPIEndPoint + '/Closure/GetConveneProjectDetailsByEngagementId', conveneGetProjetcsById);
  }

  IsContinuanceEngagementExists(clientId: number, solutionCodeId: number): Observable<number> {
    return this.http.get<number>(this.APIEndPoint + '/Engagement/IsContinuanceEngagementExists/' + clientId + '/' + solutionCodeId);
  }

  AddUpdateConveneProjectDetails(conveneProjectInsertModel: ConveneProjectInsertModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/AddUpdateConveneProjectDetails', conveneProjectInsertModel, { responseType: 'text' });
  }

  GetConveneProjectDetails(engagementId: number) {
    return this.http.get<ConveneProjectDetailsResponse>(this.APIEndPoint + '/Engagement/GetConveneProjectDetails/' + engagementId);
  }

  GetSharepointFileLabelFeatureFlag() {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetSharepointFileLabelFeatureFlag');
  }
  saveAuditChecklist(engagementAuditChecklistViewModel: EngagementAuditChecklistViewModel) {
    return this.http.post(this.APIEndPoint + '/Engagement/saveAuditChecklistDetails', engagementAuditChecklistViewModel, { responseType: 'text' });
  }

  GetAuditChecklistDetailsByEngId(engagementId: number) {
    return this.http.get<EngagementAuditChecklistViewModel>(this.APIEndPoint + '/Engagement/GetAuditChecklistDetailsByEngId/' + engagementId);
  }

  GetActiveEngagementsByClientOfUser(
    loggedInUser: string,
    clientId: number,
    isloggedInUserAdmin: boolean
  ): Observable<ExecutionDocumentEnagments[]> {
    return this.http.get<ExecutionDocumentEnagments[]>(
      this.APIEndPoint + '/Engagement/GetActiveEngagementsByClientOfUser/' + loggedInUser + "/" + clientId + "/" + isloggedInUserAdmin
    );
  }
  GetSolutionCodeForCloseEngagement() {
    return this.http.get(this.APIEndPoint + '/Engagement/GetSolutionCodeForCloseEngagement/');
  }

  addUpdateDocumentReference(workspaceDocument: IWantToDocumentsReference): Observable<IWantToDocumentsReference> {
    var workspaceDocumentData = new IWantToDocumentsReference();
    workspaceDocumentData.engagementId = workspaceDocument.engagementId;
    workspaceDocumentData.fileGuid = workspaceDocument.fileGuid;
    workspaceDocumentData.fileName = workspaceDocument.fileName;
    workspaceDocumentData.folderPath = workspaceDocument.folderPath;
    workspaceDocumentData.isDeleted = workspaceDocument.isDeleted;
    workspaceDocumentData.siteUrl = workspaceDocument.siteUrl;
    workspaceDocumentData.workspaceId = workspaceDocument.workspaceId;
    workspaceDocumentData.createdBy = workspaceDocument.createdBy;
    workspaceDocumentData.status = workspaceDocument.status;
    let FetchUrl: string = this.APIEndPoint + "/Engagement/AddUpdateDocumentsReference";
    return this.http.post<IWantToDocumentsReference>(FetchUrl, workspaceDocumentData);
  }

  updateDocumentReference(workspaceDocument: IWantToDocumentsReference): Observable<boolean> {
    let FetchUrl: string = this.APIEndPoint + "/Engagement/UpdateDocumentReference";
    return this.http.post<boolean>(FetchUrl, workspaceDocument);
  }
  getSharepointBigFileFeatureFlag(): Observable<boolean> {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetSharepointBigFileFeatureFlag');
  }
  getSharepointSupplementFileUploadLimit(): Observable<number> {
    return this.http.get<number>(this.APIEndPoint + '/Engagement/GetSharepointSupplementFileUploadLimit');
  }

  getRetentionSecAppliedAndSuccessorFirmFlags(siteUniqueId: string): Observable<ServiceLineFlagDetails> {
    return this.http.get<ServiceLineFlagDetails>(this.APIEndPoint + '/Retention/GetRetentionSecAppliedAndSuccessorFirmFlags?siteUniqueId=' + siteUniqueId);
  }

  getEngagementActualCloseoutInDays(isRetentionSectionApplied: boolean): Observable<EngagementActualCloseoutInDays> {
    return this.http.get<EngagementActualCloseoutInDays>(`${this.APIEndPoint}/Retention/GetEngagementActualCloseoutInDays/${isRetentionSectionApplied}`);
  }

  getIndusFlagForReviewPage(engagementId: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.APIEndPoint}/Engagement/GetIndusFlagForReviewPage/${engagementId}`);
  }
  getIndusFlagForReviewPageBySolutionCode(solutionCode: number): Observable<boolean> {
    return this.http.get<boolean>(`${this.APIEndPoint}/Engagement/GetIndusFlagForReviewPageBySolutionCode/${solutionCode}`);
  }
  updateIndusFlagForReviewPage(updateINDUSFlag: UpdateINDUSFlag): Observable<boolean> {
    let FetchUrl: string = this.APIEndPoint + "/Engagement/UpdateIndusFlagForReviewPage";
    return this.http.post<boolean>(FetchUrl, updateINDUSFlag);
  }
  getCollaborationAuditTrail(engagementId: number): Observable<IndusFlags> {
    return this.http.get<IndusFlags>(`${this.APIEndPoint}/Engagement/GetCollaborationAuditTrail/${engagementId}`);
  }
  GetSolutionCodeConfigFeatureFlag() {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetSolutionCodeConfigFeatureFlag');
  }
  AddEngagementCloseoutConfigurationsFromDecision(closeoutConfigurationResponse: CloseoutSectionConfigResponse): Observable<boolean> {
    return this.http.post<boolean>(`${this.APIEndPoint}/Engagement/AddEngagementCloseoutConfigurationsFromDecision`, closeoutConfigurationResponse);
  }

  GetMemberFirmEngagementPrefillData(requestModel: MemberFirmEngagementPrefillDataRequestModel): Observable<MemberFirmEngagementPrefillData> {
    return this.http.post<MemberFirmEngagementPrefillData>(`${this.APIEndPoint}/MemberFirm/GetMemberFirmMappingByADGroup`, requestModel);
  }

  GetInterchangeableSolutionCodes(PrimarySolutionCodeId: number): Observable<InterchangeableSolutionCode[]> {
    return this.http.get<InterchangeableSolutionCode[]>(this.APIEndPoint + '/Engagement/GetInterchangeableSolutionCodes/' + PrimarySolutionCodeId);

  }
  CheckUserIsMember(engagementId: number, loggedInUser: string) {

    return this.http.get<boolean>(
      this.APIEndPoint + '/Engagement/CheckUserIsMember/' + engagementId + '/' + loggedInUser);
  }

  GetKeyTeamMemberFeatureFlag() {
    return this.http.get<boolean>(this.APIEndPoint + '/Engagement/GetKeyTeamMemberFeatureFlag');
  }
  GetActiveEngagementsByClientId(userClientDetails: UserClientDetails): Observable<DownloadTemplateEnagments[]> {
    let FetchUrl: string = this.APIEndPoint + "/Engagement/GetActiveEngagementsByClientId";
    return this.http.post<DownloadTemplateEnagments[]>(FetchUrl, userClientDetails);
  }
  GetDownloadTemplatesConfiguration() {
    return this.http.get<DownloadTemplatesConfiguration>(this.APIEndPoint + '/Engagement/GetDownloadTemplatesConfiguration');
  }

  InsertEngagementAdministrativeClose(engagementAdministrativeCloseModel: EngagementAdministrativeCloseModel): Observable<string> {
    let FetchUrl: string = this.APIEndPoint + "/Engagement/InsertEngagementAdministrativeClose";
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.http.post<string>(FetchUrl, engagementAdministrativeCloseModel, requestOptions);
  }

  getEngagementStatus(request: EngagementStatusViewModel) {
    return this.http.post<EngagementStatusViewModel>(this.APIEndPoint + '/Engagement/GetEngagementStatus', request);
  }

  getRedirectPathByEngagementStatus(engagementStatus: EngagementStatusViewModel) {
    let path: string = "";
    if (engagementStatus.status == "Active (pending CMS codes)" || engagementStatus.status == "CMS code error" ||
      engagementStatus.status == "Active" || engagementStatus.status == "Closed" || engagementStatus.status == "Active (pending signature)"
      || engagementStatus.status == "Voided" || engagementStatus.status == "Pending CMS codes")
      path = "EngagementDetail/" + engagementStatus.engagementId;
    else if (engagementStatus.status == "Team review" || engagementStatus.status == "RP review" ||
      engagementStatus.status == "EPPMD review" || engagementStatus.status == "Declined" ||
      engagementStatus.status == "Abandoned" || engagementStatus.status == "RP rule engine error" ||
      engagementStatus.status == "InReview" || engagementStatus.status == "Site Provisioning Error"
    )
      path = "Review/" + engagementStatus.workspaceId;
    else if (engagementStatus.status == "Draft")
      path = "Interviews/" + engagementStatus.workspaceId;
    else if (engagementStatus.status == "Not started")
      path = "EditEngagement/" + engagementStatus.engagementId;
    return path;
  }
  CheckClientOpportunityServiceAssignedByEngagementID(opportunityServiceRequestModel: OpportunityServiceRequestModelByEngagementId) {
    return this.http.post<OpportunityServiceResponseViewModel>(this.APIEndPoint + '/Engagement/CheckClientOpportunityServiceAssignedByEngagementId', opportunityServiceRequestModel);
  }
  getMyChangeOrderEngagements(loggedInUser: string, clientId: number, isloggedInUserAdmin: boolean): Observable<Engagements[]> {
    return this.http.get<Engagements[]>(this.APIEndPoint + '/Engagement/GetMyChangeOrderEngagements/' + loggedInUser + "/" + clientId + "/" + isloggedInUserAdmin);
  }
}
