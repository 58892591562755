<div class="containerDiv">
  <nav aria-label="breadcrumb" class="pt-3">
    <ol class="breadcrumb">
      <li class="breadcrumb-item disabled">
        <label>
          Basic info
          <span>
            <img style="vertical-align: top;"
              src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg" alt="Basic info" />
          </span>
        </label>
      </li>
      <li class="breadcrumb-item active">
        <label>
          Questionnaire
          <span>
            <img style="vertical-align: top;"
              src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg" alt="Questionnaire" />
          </span>
        </label>
      </li>
      <li class="breadcrumb-item disabled">
        Review
      </li>
    </ol>
  </nav>

  <!-- Page Title row  START-->
  <div class="d-flex justify-content-between pageTitleParent pb-0 mw-100">
    <div class="text-center">
      <h2 class=" float-md-left pageTitle largerFontSize2">Questionnaire</h2>
    </div>
    <div class="text-center">
      <span class="float-md-left">
        <app-shortcut></app-shortcut>
      </span>

      <span class="float-right mt-1 ml-2 mr-2 font-weight-bold"
        *ngIf="workspaceOpenReviewPointsCount > 0">{{workspaceOpenReviewPointsCount}}</span>
    </div>
  </div>
  <div class="d-flex justify-content-between pt-0 pb-3">
    Template: {{InterviewList?.templateName}}
  </div>
  <!-- Page Title row END-->
  <!-- Page Footer row START-->
  <div class="d-flex pb-4 pt-4">
    <div class="mr-auto pl-0 pt-2 pr-2">
      <button  class="btn btn-width SecondaryButton w-100 text-center" type="button"
        (click)="getExecutionWorkspaceStatus('EditBtn')">
        <img src="assets/images/EDP/Engagement/caret-left-blue.svg" alt="caret-left"
          style="width: 18px;height:18px;margin-right:2px;">
        <span class="d-inline-block align-middle">Edit Change Order</span>
      </button>
    </div>
    <div class="pl-3 pt-2 pr-0">
      <button class="btn btn-wide PrimaryButton w-100 text-center" type="button"
        (click)="getExecutionWorkspaceStatus('FinishBtn')">
        <span class="d-inline-block align-middle font-weight-bold">
          Save and go to review<img src="assets/images/EDP/Engagement/caret-right-white.svg" alt="caret-right"
            style="width: 18px;height:18px;margin-bottom: 3px;">
        </span>
      </button>
    </div>
    <div class="pl-3 pt-2 pr-0">
      <em class="pl-2 pt-2 pr-0 text-center" id='questionnaireLoadSpinner'></em>
    </div>

  </div>
  <!-- Page Footer row END-->
</div>