export const environment = {
    production: false,
    ENVIRONMENT: '#{{UI-VG-ENVIRONMENT}}#',
    GA_TRACKING_ID: '#{{UI-VG-GA-TRACKING-ID}}#',
    PENDO_API_KEY: '#{{UI-VG-PENDO-API-KEY}}#',
    VERSION: '1.0.0.0',
    API_URL: '#{{UI-VG-API-URL}}#',
    INTEGRATION_API_URL: '#{{UI-VG-INTEGRATION-API-URL}}#',
    ENGAGE_CALENDAR_JS_URL: '#{{UI-VG-ENGAGE-CALENDAR-JS-URL}}#',
    INSTANCE: 'https://login.microsoftonline.com/',
    TENANT: 'gtus365.onmicrosoft.com',
    CLIENT_ID: '#{{UI-VG-CLIENT-ID}}#',
    LOGIN_REDIRECT_URL: '#{{UI-VG-AZURE-LOGIN-REDIRECT-URL}}#',
    POST_LOGOUT_REDIRECT_URL: '#{{UI-VG-AZURE-LOGOUT-REDIRECT-URL}}#',
    HOME_URL_WEB_API: '#{{UI-VG-AZURE-HOME-URL}}#',
    TOKEN_URL: 'https://graph.microsoft.com',
    EXPIRE_OFFSET_SECONDS: 300,
    ADAL_LOGS: '#{{UI-VG-ADAL-LOGS}}#',
    ENGAGEMENT_ASSIGNMENT_ADMINISTRATOR_LIMIT: '300',
    TEAM_MEMBER_LIMIT: '15',
    SHOW_CLOSE_BUTTON_RECORD_LIMIT: '3',
    USERNAME: '#{{All-VG-APIConfig-UserName}}#',
    PASSWORD: '#{{All-VG-APIConfig-Password}}#',
    SUBSCRIPTION_KEY: '#{{All-VG-APIConfig-SubscriptionKey}}#',
    PRIMARY_ADMIN: '#{{UI-VG-SP-PRIMARY-ADMIN}}#',
    PRIMARY_CONTACT: '#{{UI-VG-SP-PRiMARY-CONTACT}}#',
    SECONDARY_CONTACT: '#{{UI-VG-SP-SECONDARY-CONTACT}}#',
    CONTINUANCE_SOLUTIONCODE: 'ADV023;ADV024',
    CONTINUANCE_HDTEMPLATEPACKAGEID: '58d5d831-9336-46fa-8732-663def27a260;e6366e12-69ff-495c-aecb-8b70a4095e63',
    LINE_OF_BUSINESS_ID_PS: 'Public Sector',
    ADD_MULTIPLE_DOCUMENT_LIMIT: '#{{UI-ADD-MULTIPLE-DOCUMENT-LIMIT}}#',
    ADD_MULTIPLE_DOCUMENT_LIMIT_IWantTo: '#{{UI-ADD-MULTIPLE-DOCUMENT-LIMIT-IWantTo}}#'
};
