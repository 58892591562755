<div class="containerDiv">
  <app-loading-screen></app-loading-screen>
  <nav aria-label="breadcrumb" class="pt-3">
    <ol class="breadcrumb">
      <li class="breadcrumb-item disabled">
        <label>
          Basic info
          <span>
            <img style="vertical-align: top;"
              src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg" alt="Basic info" />
          </span>
        </label>
      </li>
      <li class="breadcrumb-item active">
        <label>
          Questionnaire
          <span>
            <img style="vertical-align: top;"
              src="../../../assets/images/EDP/Workspace/keyboard_arrow_right-24px-lightgrey.svg" alt="Questionnaire" />
          </span>
        </label>
      </li>
      <li class="breadcrumb-item disabled">
        Review
      </li>
    </ol>
  </nav>
  <!-- Page Title row -->
  <div class="d-flex justify-content-between pageTitleParent pb-0 mw-100">
    <div class="text-center">
      <h2 *ngIf="!AppliedTnC" class=" float-md-left pageTitle largerFontSize2">Questionnaire</h2>
      <h2 *ngIf="AppliedTnC" class=" float-md-left pageTitle largerFontSize2">Questionnaire changes</h2>
    </div>
    <div class="text-center">
      <span class="float-md-left">
        <app-shortcut></app-shortcut>
      </span>
      <!-- <a (click)="DisplayReviewNotes()" data-toggle="tooltip" data-placement="bottom" title="Review notes"
        id="review-NotesIcon" class="ml-2 float-left position-relative btn-light btn-sm customDropdown" role="button"
        aria-haspopup="true" aria-expanded="false">
        <img src="../../../assets/images/EDP/Workspace/library_add_check-18px-blue.svg" alt="" class="p-1">
        <span class="redicon" *ngIf="workspaceOpenReviewPointsCount > 0"></span>
      </a> -->
      <span class="float-right mt-1 ml-2 mr-2 font-weight-bold"
        *ngIf="workspaceOpenReviewPointsCount > 0">{{workspaceOpenReviewPointsCount}}</span>
    </div>
  </div>
  <div class="d-flex justify-content-between pt-0 pb-3">
    Template: {{InterviewList?.templateName}}
  </div>

  <div *ngIf="QuestionnaireInProgressHDSession" class="alert-warning col-md-12">
    <span class="msg-icon">
      <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Inprogress session">
    </span>
    <span class="d-inline-block align-middle msg-text">
      This engagement is currently being edited by another team member.
    </span>
  </div>

  <!-- End Page Title row -->

  <div class="bg-edp-body p-1">
    <div id="hdPreviewDiv" class="p-1"></div>
    <!-- <div class="p-1" *ngIf=" WorkspaceDetails.engagementState !== 'Execution'">
      <div class="row m-0">
        <div class="col-md-12  bg-white">
          <div class="edp-font-16 font-weight-bold my-2">Supplemental documents&nbsp;&nbsp;&nbsp;&nbsp;<span
              *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')"><a
                *ngIf="isValidateSiteURL(sharePointURL)" href="{{sharePointURL}}" target="_blank">
                <img alt="sharepoint" src="../../../assets/images/EDP/Engagement/sharepoint-logo.svg" />
              </a></span>
          </div>
          <div *ngIf="AppliedTnC">
            <span>Please attach revised document(s) (e.g., redlined and/or executed contract, etc.). Any document
              uploaded will be read-only.</span>
          </div>
          <div class="alert alert-danger my-3" role="alert"
            *ngIf="!(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">
            <div class="row m-0">
              <div class="col-md-12 px-0 edp-font-14 font-weight-normal pl-1 l-h-14">
                <span id="review-notes-badge"
                  class="ml-auto badge badge-pill badge-primary review_note-count alert-pill-s-doc">
                  <img src="assets/images/EDP/Workspace/report_problem-24px.svg" alt="No site Created"
                    class="icons-s-doc"></span>
                <span class="text-alert-sdoc">This engagement’s site is still being created.</span>
                <span class="text-alert-msg">Last checked: {{lastdateTime}}
                  <em id='lastdateTimeSpinner'></em></span>
              </div>
            </div>
          </div>
          <div class="table-section"
            *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted')">
            <div  class=" mb-1"  *ngIf="isSharepointFileLabelFeatureFlagEnabled">
              <label for="filterSharepointFileLabel">Filter by label: <span
                  class="mandatory">*</span></label>
              <div class="selectdiv w-50">
                <select class="dropdown rounded-0 " name="filterSharepointFileLabel"
                  id="sharepointFileLabel" 
                  [(ngModel)]="sharePointSelectedFileLabelForFilter">
                  <option [value]="'All'" selected>
                    All
                  </option>
                  <option [value]="'Unlabeled'">
                    Unlabeled
                  </option>
                  <option
                    *ngFor="let sharePointApplicationMasterLabel of sharePointApplicationMasterLabelsFiltered"
                    [value]="sharePointApplicationMasterLabel.value">
                    {{sharePointApplicationMasterLabel.value}}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-12 px-0">
              <table class="table table-sm s-doc-table">
                <thead>
                  <tr>
                    <th scope="col">File</th>
                    <th scope="col">Type</th>
                    <th scope="col" *ngIf="isSharepointFileLabelFeatureFlagEnabled">Label</th>
                    <th scope="col">Last modified</th>
                    <th scope="col">Modified by</th>
                    <th scope="col"> &nbsp; </th>
                  </tr>
                </thead>
                <tbody *ngIf="supplementalDocumentList.length > 0">
                  
                  <tr *ngFor="let document of supplementalDocumentList | filterSpfileLabel : sharePointSelectedFileLabelForFilter; let i=index">
                    <td scope="row">
                      <span class="d-inline-block ml-1  font-weight-bold  l-spacing-1 edp-font-14 doc-filename"
                        title="{{document.documentName}}">
                        <a *ngIf="document.type =='pdf'" href="{{document.documentUrl}}" target="_blank" class="cp">
                          {{document.documentName}}</a>
                        <a *ngIf="document.type == 'docx' || document.type =='doc'                        
                        || document.type =='pptx' || document.type =='ppt'
                        || document.type =='xlsx' || document.type =='xls'" href="{{document.documentEditUrl}}"
                          target="_blank" class="cp">
                          {{document.documentName}}</a>

                        <a *ngIf="document.type!='docx' && document.type!='doc'
                        && document.type!='pptx' && document.type!='ppt' 
                        && document.type!='pdf' && document.type !='xlsx' && document.type !='xls'"
                          href="{{document.documentUrl}}" target="_blank" class="cp">
                          {{document.documentName}}
                        </a>
                      </span>
                    </td>
                    <td>{{document.type}}</td>
                    <td *ngIf="isSharepointFileLabelFeatureFlagEnabled">
                      <div *ngIf="document.label" ><a class="doc-label" style="color:#707070;">{{document.label}}</a></div>
                       <div  *ngIf="!document.label"><a class="anchor-button" style="margin-left:0px;" (click)="displaySharepointFileLabelPopup(document)">Add
                        label</a></div>
        
                    </td>
                    
                      
                    
                    
                    <td>{{document.modifiedDate}}</td>
                    <td>
                      <span class="suppDocModifiedByPopup link" id="suppDocModifiedByPopup_{{document.documentId}}"
                        (mousedown)="DisplayContactPopup(document.documentId, document.modifiedByEmail)">{{document.modifiedBy}}
                      </span>
                      <input type="hidden" id="modifiedByIndex" value="" />
                    </td>
                    <td>
                      <div *ngIf="isSharepointFileLabelFeatureFlagEnabled==false">
                      <a *ngIf="!AppliedTnC" style="color:#00A7B5" (click)="confirmModalRemoveSuppDoc(document)"><span
                          class="hide-me">Remove &nbsp;</span>
                        <img alt="remove" src="assets/images/EDP/Engagement/remove_circle_outline.svg">
                      </a>
                      </div>
                      <div *ngIf="isSharepointFileLabelFeatureFlagEnabled" id="supplementalDocuments">
                        <div class="btn-group d-block sharePointLabelcustomDropdown">
                          <a id="sharePointLabelcustomDropdown"
                             role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/images/EDP/Engagement/more_horiz-24px.svg" alt="">
                          </a>
                          <div class="dropdown-menu" aria-labelledby="customDropdown">
                            <span class="dropdown-menu-arrow"></span>
                              <a style="font-size: 15px;width:auto;"  class="dropdown-item" (click)="confirmModalRemoveSuppDoc(document)">Delete </a>
                              <a style="font-size: 15px;width:auto;" class="dropdown-item" href="#modal-SharepointFileLabel"
                              (click)="displaySharepointFileLabelPopup(document)" data-toggle="modal"
                              data-keyboard="false" data-backdrop="static">Label</a>
                            
                          </div>
                        </div>
                      </div>

                    </td>
                  </tr>
                </tbody>
                <div *ngIf="supplementalDocCount == 0 || supplementalDocCount == null ">
                  <div class="no-data-available no-data"> {{DataMessage}}</div>
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="p-1 my-2"
      *ngIf="(currentEngagement.siteRequestStatus ==='Completed' || currentEngagement.siteRequestStatus ==='PartialCompleted') && WorkspaceDetails.engagementState !== 'Execution'">
      <div class="row m-0">
        <div class="col-md-12  bg-white">

          <div class="upload-file-section">
            <div class="row m-0 py-2 my-2">
              <div class="col-md-12 edp-font-16 font-weight-bold px-0">
                Upload document
              </div>
              <div id="uploadfileDiv" class="col-md-12 col-lg-6 col-xl-8 px-0 invisible">
                <span class="mt-1 mr-5 pos-t-10 pdf-filename-t-w" title="{{currentfileName}}">{{currentfileName}}</span>
                <img src="../../../assets/images/EDP/Interviews/uploading-animation.gif" alt="uploading"
                  class=" pos-t-10 ml-5 t-3">
              </div>

              <div class="col-md-12 mt-md-3 mt-lg-0 col-lg-6 col-xl-4 px-0 d-flex justify-content-end" *ngIf=" WorkspaceDetails.engagementState !== 'Execution'">
                <div class="file-upload ">
                  <label for="fileuploadlbl" (click)="AddNewFileClicked()"
                    class="btn-bs-file btn PrimaryButton rounded-0 edp-font-13 mt-1 mb-0 btn-block cursor-pointer">
                    <img id="fileuploadimg" src="../../../assets/images/EDP/Workspace/file-upload-doc.svg"
                      alt="Attach  documents" class="doc-upload-icon mr-2">
                    <span class="doc-lable-pos">Attach supplemental documents</span>
                   
                  </label><input id="fileuploadlbl" hidden />
                  <label class="validationMessage text-danger" *ngIf="uploadFileValidationError">
                    File size is over the size limit or file name has special characters.
                  </label>
                </div>
                <input id="input-file" hidden type="file" (change)="onSelectFile($event.target.files)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="row m-0 p-0 pt-4 d-none" id='interview-save-success'>
    <div class="col-lg-12 col-md-12 questAlert">
      <span class="msg-icon">
        <img src="assets/images/EDP/Interviews/report_problem-24px.svg" alt="save successfully">
      </span>
      <span class="questAlertText">Questionnaire saved successfully.</span>
    </div>
  </div>
  <div *ngIf="QuestionnaireInProgressHDSession" class="alert-warning row m-0 mt-3">
    <span class="msg-icon">
      <img src="assets/images/EDP/Workspace/report_problem_warning.svg" alt="Inprogress session">
    </span>
    <span class="d-inline-block align-middle msg-text pt-3">
      This engagement is currently being edited by another team member.
    </span>
  </div>

  <!-- <div class="d-flex pb-4 pt-4" *ngIf=" WorkspaceDetails.engagementState !== 'Execution'">
    <div class="mr-auto pl-0 pt-2 pr-2">
      <button *ngIf="!AppliedTnC" class="btn btn-width SecondaryButton w-100 text-center" type="button"
        (click)="getWorkspaceStatus('EditBtn')">
        <img src="assets/images/EDP/Engagement/caret-left-white.svg" alt="caret-left"
          style="width: 18px;height:18px;margin-right:2px;">
        <span class="d-inline-block align-middle">Edit engagement </span>
      </button>
    </div>    
    <div class="pl-3 pt-2 pr-0" *ngIf="CurrentWorkspaceStatus === WorkspaceInterviewsStatus.DraftSession" hidden>
      <button *ngIf="!AppliedTnC" class="btn btn-width SecondaryButton w-100 text-center" type="button"
        (click)="getWorkspaceStatus('SaveBtn')">
        <span class="d-inline-block align-middle"> Save changes </span>
      </button>
    </div>
    <div class="pl-3 pt-2 pr-0">
      <button class="btn btn-wide PrimaryButton w-100 text-center" type="button"
        (click)="getWorkspaceStatus('FinishBtn')">
        <span class="d-inline-block align-middle font-weight-bold"
          *ngIf="CurrentWorkspaceStatus === WorkspaceInterviewsStatus.DraftSession">
          Go to team review <img src="assets/images/EDP/Engagement/caret-right-white.svg" alt="caret-right"
            style="width: 18px;height:18px;margin-bottom:3px;">
        </span>

        <span class="d-inline-block align-middle font-weight-bold" *ngIf="CurrentWorkspaceStatus !== 'Draft' ">
          Save and go to review <img src="assets/images/EDP/Engagement/caret-right-white.svg" alt="caret-right"
            style="width: 18px;height:18px;margin-bottom: 3px;">
        </span>
      </button>
      <em class="pl-2 pt-2 pr-0 text-center" id='questionnaireLoadSpinner'></em>
    </div>
  </div> -->
  <div class="d-flex pb-4 pt-4">
    <div class="mr-auto pl-0 pt-2 pr-2">      
    </div>
    <div class="pl-3 pt-2 pr-0">      
      <button class="btn btn-wide PrimaryButton w-100 text-center" type="button"
        (click)="getExecutionWorkspaceStatus('FinishBtn')">        
        <span class="d-inline-block align-middle font-weight-bold" *ngIf="CurrentWorkspaceStatus !== 'Draft' ">
          Next <img src="assets/images/EDP/Engagement/caret-right-white.svg" alt="caret-right"
            style="width: 18px;height:18px;margin-bottom: 3px;">
        </span>
      </button>
    </div>
    <div class="pl-3 pt-2 pr-0">
      <em class="pl-2 pt-2 pr-0 text-center" id='questionnaireLoadSpinner'></em>
    </div>

  </div>
</div>
<app-alerts></app-alerts>
<app-contact-popup [contactEmailId]="selectContactEmailId"></app-contact-popup>



<!-- work in progress Modal -->
<div class="message-dialog-wrapper" id="message-InProgress" *ngIf="isHiddenProgressModal">
  <div class="message-dialog-container">
    <div class="d-flex justify-content-center mt-4">
      <div class="message-background-icon information">
        <img src="../../../../assets/images/EDP/MessageDialog/information.svg" class="message-icon" alt="information" />
      </div>
    </div>
    <div class="d-flex justify-content-center message-title information pt-2">Working on it...</div>
    <div class="message-description mt-5">
      <span>
        Engage is currently processing your template. This may take a few seconds depending on the complexity of the
        document. You will be redirected as soon as this process is complete.
      </span>
    </div>
  </div>
</div>
<!--Start Sharepoint File Label POPUP-->
<app-sharepoint-file-label [sharePointSelectedFileLabel]="sharePointSelectedFileLabel"
  (close)="cancelSharepointFileLabel()" (ok)="okSharepointFileLabel($event)"></app-sharepoint-file-label>
<!--End Sharepoint File Label POPUP-->