import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { InterviewModel } from 'src/app/model/InterviewModel';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'app-change-order-interview',
  templateUrl: './change-order-interview.component.html',
  styleUrls: ['./change-order-interview.component.css']
})
export class ChangeOrderInterviewComponent implements OnInit {
  workspaceOpenReviewPointsCount: number;
  InterviewList: InterviewModel;
  constructor() { }

  ngOnInit() {
  }

  getExecutionWorkspaceStatus(btn) {

  }
  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return of(true);
  }

}
