export class ChangeOrderModel {
    changeOrderId: number;
    engagementId: number;
    changeOrderName: string;
    workspaceId: string;
    isFinancialUpdate: boolean;
    estimateCompletionDate: Date;
    fee: number;
    grossMargin: number;
    createdBy: string;
    createdDate: Date;
    lastModifiedBy: string;
    lastModifiedDate: Date;
}