import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './appConfigService';
import { ChangeOrderModel } from '../model/change-order/change-order-model';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ChangeOrderService {

  private APIEndPoint: string;
  private IntegrationAPIEndPoint: string;

  constructor(private environment: AppConfigService, private httpClient: HttpClient) {
    this.IntegrationAPIEndPoint = environment.config.DATA.API_END_POINT.INTEGRATION_API_URL;
    this.APIEndPoint = this.environment.config.DATA.API_END_POINT.API_URL;
  }
  getChangeOrderHistory(engagementId: number): Observable<ChangeOrderModel[]> {
    return this.httpClient.get<ChangeOrderModel[]>(this.APIEndPoint + '/ChangeOrder/GetChangeOrderHistory/' + engagementId);
  }

  createChangeOrder(changeOrderModel: ChangeOrderModel) {
    return this.httpClient.post(this.APIEndPoint + '/ChangeOrder/CreateChangeOrder', changeOrderModel, { responseType: 'text' });
  }

 
}
