import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class AppConfigService {
    private appConfig: any;

    constructor() {

    }

    loadAppConfig() {
        this.appConfig = {
            DATA: {
                ENVIORNMENT: environment.ENVIRONMENT,
                GA_TRACKING_ID: environment.GA_TRACKING_ID,
                PENDO_API_KEY: environment.PENDO_API_KEY,
                ENGAGE_CALENDAR_JS_URL: environment.ENGAGE_CALENDAR_JS_URL,
                LINE_OF_BUSINESS_ID_PS: environment.LINE_OF_BUSINESS_ID_PS,
                Version: environment.VERSION,
                API_END_POINT: {
                    API_URL: environment.API_URL,
                    INTEGRATION_API_URL: environment.INTEGRATION_API_URL
                },
                AZURE_AD_AUTHENTICATION: {
                    INSTANCE: environment.INSTANCE,
                    TENANT: environment.TENANT,
                    CLIENT_ID: environment.CLIENT_ID,
                    LOGIN_REDIRECT_URL: environment.LOGIN_REDIRECT_URL,
                    POST_LOGOUT_REDIRECT_URL: environment.POST_LOGOUT_REDIRECT_URL,
                    HOME_URL_WEB_API: environment.HOME_URL_WEB_API,
                    TOKEN_URL: environment.TOKEN_URL,
                    EXPIRE_OFFSET_SECONDS: environment.EXPIRE_OFFSET_SECONDS,
                    ADAL_LOGS: environment.ADAL_LOGS
                },
                ENGAGEMENT_ASSIGNMENT_ADMINISTRATOR_LIMIT: environment.ENGAGEMENT_ASSIGNMENT_ADMINISTRATOR_LIMIT,
                TEAM_MEMEBR_LIMIT: environment.TEAM_MEMBER_LIMIT,
                SHOW_CLOSE_BUTTON_RECORD_LIMIT: environment.SHOW_CLOSE_BUTTON_RECORD_LIMIT,
                API_USER: {
                    USERNAME: environment.USERNAME,
                    PASSWORD: environment.PASSWORD,
                    SUBSCRIPTION_KEY: environment.SUBSCRIPTION_KEY
                }
            },
            SP_EMAIL_PROVISION: {
                PRIMARY_ADMIN: environment.PRIMARY_ADMIN,
                PRIMARY_CONTACT: environment.PRIMARY_CONTACT,
                SECONDARY_CONTACT: environment.SECONDARY_CONTACT
            },
            CONTINUANCE_ELIGIBILITY_DATA:{
                SOLUTIONCODE: environment.CONTINUANCE_SOLUTIONCODE,
                HDTEMPLATEPACKAGEID: environment.CONTINUANCE_HDTEMPLATEPACKAGEID
            },
            ADD_MULTIPLE_DOCUMENT_LIMIT: environment.ADD_MULTIPLE_DOCUMENT_LIMIT,
            ADD_MULTIPLE_DOCUMENT_LIMIT_IWantTo:environment.ADD_MULTIPLE_DOCUMENT_LIMIT_IWantTo
        };

        return JSON.stringify(this.appConfig);

    }

    get config() {
        return this.appConfig;
    }
}
