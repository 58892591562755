import { TeamMembers } from './TeamMembers';
import { DecisionsApiLogModel } from './DecisionsApiLogModel';


export class DecisionsRPListModel{
    isSuccess: boolean;
    workspaceId: string;
    decisionsRPList: TeamMembers[] = []; 
    riskPoints: number;
    errorCode: string;
    errorDescription: string;
    loggedInUserFullName: string;
    isDecisionsValuePersisted: boolean;
    decisionsApiLogModel: DecisionsApiLogModel = new DecisionsApiLogModel();
}